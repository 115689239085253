import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eLopHanhChinhSoDiemActionTypeIds, ILopHanhChinhSoDiemActionTypes } from '../../action-types/lop-hanh-chinh/ILopHanhChinhSoDiemActionTypes';
import { ILopHanhChinhSoDiemPageState } from '../../page-state-model/lop-hanh-chinh/ILopHanhChinhSoDiemPageState';

const iniState: ILopHanhChinhSoDiemPageState = {
    isShowDgdkNlpcSelectionModal: false,
    isShowDgtxNlpcSelectionModal: false,
    lopHanhChinhs: [],
    lopHanhChinhSelectedIds: [],
    status: ePageBaseStatus.is_not_initialization
}
export const lopHanhChinhSoDiemReducer = (state: ILopHanhChinhSoDiemPageState = iniState, action: ILopHanhChinhSoDiemActionTypes): ILopHanhChinhSoDiemPageState => {
    
    switch (action.type) {
        case eLopHanhChinhSoDiemActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopHanhChinhSoDiemActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lopHanhChinhs: action.payload
            }
        case eLopHanhChinhSoDiemActionTypeIds.LOAD_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eLopHanhChinhSoDiemActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                lopHanhChinhSelectedIds: action.payload
            }


        // dgdk nlpc
        case eLopHanhChinhSoDiemActionTypeIds.SHOW_DGDK_NLPC_SELECTION_MODAL:
            return {
                ...state,
                isShowDgdkNlpcSelectionModal: action.payload
            }
        case eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGDK_NLPC_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGDK_NLPC_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDgdkNlpcSelectionModal: false
            }
        case eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGDK_NLPC_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        // dgtx
        case eLopHanhChinhSoDiemActionTypeIds.SHOW_DGTX_NLPC_SELECTION_MODAL:
            return {
                ...state,
                isShowDgtxNlpcSelectionModal: action.payload
            }
        case eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGTX_NLPC_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGTX_NLPC_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDgtxNlpcSelectionModal: false
            }
        case eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGTX_NLPC_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }

        default:
            return {
                ...state
            }
    }
}
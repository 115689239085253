import { ILopBoMonAddHocSinhRequest } from './../models/request/lop-bo-mon-hoc-sinh/ILopBoMonAddHocSinhRequest';
import { IDeleteMultipleBaseRequest } from './../models/request/base/deleteRequest';
import { ILopBoMonSelectHocSinhRequest } from '../models/request/lop-bo-mon-hoc-sinh/ILopBoMonSelectHocSinhRequest';
import { ILopBoMonChangeHocSinhClassRequest } from "../models/request/lop-bo-mon/ILopBoMonChangeHocSinhClassRequest";
import { pl_lopbomon_hocsinh } from './../models/response/lop-bo-mon/pl_lopbomon_hocsinh';
import { apiClient } from './apiClient';
import { BaseCRUDApi } from "./baseCRUDApi";
import { ILopBoMonImportHocSinhRequest } from '../models/request/lop-bo-mon/ILopBoMonImportHocSinhRequest';
import { ILopBoMonSelectByHocSinhRequest } from '../models/request/lop-bo-mon-hoc-sinh/ILopBoMonSelectByHocSinhRequest';
import { ILopBoMonChangeThuTuHocSinhRequest } from '../models/request/lop-bo-mon-hoc-sinh/ILopBoMonChangeThuTuHocSinhRequest';
export const API_LOPBOMON_HOCSINH_PATH = 'lop-bo-mon/hoc-sinh';

class LopBoMonHocSinhApi extends BaseCRUDApi<pl_lopbomon_hocsinh>{
    constructor({ path }: { path: string; }) {
        super(path);
    }
    Select(request: ILopBoMonSelectHocSinhRequest) {
        return apiClient.post(`${this._path}`, request)
    }
    SelectLopBoMonByHocSinh(request: ILopBoMonSelectByHocSinhRequest) {
        return apiClient.get(`lop-bo-mon/nam-hoc/${request.nam_hoc}/hoc-sinh/${request.ts_hocsinh_id}`)
    }
    DeleteHocSinh(request: IDeleteMultipleBaseRequest) {
        return apiClient.post(`${this._path}/deletes`, request)
    }
    DeleteHocSinhByLop(request: ILopBoMonAddHocSinhRequest) {
        return apiClient.post(`${this._path}/delete`, request)
    }
    AddHocSinh(request: ILopBoMonAddHocSinhRequest) {
        return apiClient.post(`${this._path}/add`, request)
    }
    LoadFromExcel(request: FormData) {
        return apiClient.upload(`${this._path}/import/load-from-excel`, request)
    }
    SaveDataExcel(request: ILopBoMonImportHocSinhRequest) {
        return apiClient.post(`${this._path}/import/save-data-excel`, request)
    }
    
    ChangeThuTuTheoHoTenABCAsync(dm_truong_id: number) {
        return apiClient.put(`${this._path}/hoc-sinh/truong/${dm_truong_id}/change-thutu-macdinh`)
    }
    ChangeThuTuHocSinhAsync(request: ILopBoMonChangeThuTuHocSinhRequest) {
        return apiClient.put(`${this._path}/hoc-sinh/change-thutu`, request)
    }
    
    ChangeHocSinhClassAsync(request: ILopBoMonChangeHocSinhClassRequest) {
        return apiClient.put(`${this._path}/change-class`, request)
    }
}
export const lopBoMonHocSinhApi = new LopBoMonHocSinhApi({ path: API_LOPBOMON_HOCSINH_PATH })
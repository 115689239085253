import { ILoginSSORequestModel } from "../../models/request/account/index";
export enum eAccountActionTypeIds {
    LOGIN_START = "LOGIN_START",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_ERROR = "LOGIN_ERROR",
    LOG_OUT = "LOG_OUT",
}
export interface ILoginStartAction {
    type: eAccountActionTypeIds.LOGIN_START,
    payload: ILoginSSORequestModel
}
export interface ILoginSuccessAction {
    type: eAccountActionTypeIds.LOGIN_SUCCESS,
}
export interface ILoginErrorAction {
    type: eAccountActionTypeIds.LOGIN_ERROR,
    payload?: string
}
export interface ILogOutAction {
    type: eAccountActionTypeIds.LOG_OUT,
}

export type IAccountActionTypes = ILoginStartAction | ILoginSuccessAction | ILoginErrorAction | ILogOutAction
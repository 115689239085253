import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { NotifyHelper } from './../../../helpers/toast';
import { eSoDiemConfigActionTypeIds, ISoDiemConfigActionTypes } from './../../action-types/so-diem/ISoDiemConfigActionTypes';
import { ISoDiemConfigStateModel } from './../../page-state-model/so-diem/ISoDiemConfigStateModel';

export const soDiemConfigIniDataSource = [{
    id: 1,
    ho_ten: "Học sinh test"
}];
const iniState: ISoDiemConfigStateModel = {
    soDiemThanhPhans: [],
    status: ePageBaseStatus.is_not_initialization,
    isShowAddThanhPhanModal: false,
    isShowRemoveThanhPhanModal: false,
    dataSource: soDiemConfigIniDataSource
}

export const soDiemConfigReducer = (state: ISoDiemConfigStateModel = iniState, action: ISoDiemConfigActionTypes): ISoDiemConfigStateModel => {
    switch (action.type) {
        case eSoDiemConfigActionTypeIds.LOAD_SODIEM_THANHPHAN_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eSoDiemConfigActionTypeIds.LOAD_SODIEM_THANHPHAN_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                soDiemThanhPhans: action.payload
            }
        case eSoDiemConfigActionTypeIds.LOAD_SODIEM_THANHPHAN_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eSoDiemConfigActionTypeIds.SHOW_ADD_THANHPHAN_MODAL:
            return {
                ...state,
                isShowAddThanhPhanModal: action.payload
            }
        case eSoDiemConfigActionTypeIds.ADD_THANHPHAN_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eSoDiemConfigActionTypeIds.ADD_THANHPHAN_SUCCESS:
            NotifyHelper.Success("Cập nhật thành công");
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eSoDiemConfigActionTypeIds.ADD_THANHPHAN_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eSoDiemConfigActionTypeIds.SHOW_REMOVE_THANHPHAN_MODAL:
            return {
                ...state,
                isShowRemoveThanhPhanModal: action.payload
            }
        case eSoDiemConfigActionTypeIds.REMOVE_THANHPHAN_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eSoDiemConfigActionTypeIds.REMOVE_THANHPHAN_SUCCESS:
            NotifyHelper.Success("Xóa đầu điểm thành công")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eSoDiemConfigActionTypeIds.REMOVE_THANHPHAN_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eSoDiemConfigActionTypeIds.CHANGE_DATA_SOURCE:
            return {
                ...state,
                dataSource: action.payload
            }
        case eSoDiemConfigActionTypeIds.UPDATE_THANHPHAN_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eSoDiemConfigActionTypeIds.UPDATE_THANHPHAN_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eSoDiemConfigActionTypeIds.UPDATE_THANHPHAN_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        default:
            return { ...state }
    }
}
import { ePageBaseStatus } from "../../../../models/ePageBaseStatus";
import { eDgdkHocTapActionTypeIds, IDgdkHocTapActionTypes } from "../../../action-types/danh-gia/dinh-ky/IDgdkHocTapActionType";
import { IDgdkHocTapPageState } from "../../../page-state-model/danh-gia/dinh-ky/IDgdkHocTapPageState";
import { IDgdkKetQuaHocTap } from './../../../../models/response/danh-gia/dinh-ky/IDgdkKetQuaHocTap';

const iniState: IDgdkHocTapPageState = {
    filter: {
        dm_khoi_id: 0,
        pl_lopbomon_id: 0,
        dm_truong_id: 0,
        nam_hoc: '',
        sis_giaidoan_id: 0,
        hoc_ky: 0,
        sis_monhoc_id: 0
    },
    loading: {
        hocSinh: "",
        soNhanXetItems: "",
        ketQuas: ""
    },
    status: ePageBaseStatus.is_not_initialization,
    hocSinhs: [],
    soNhanXetItems: [],
    ketQuas: [],
    importData: []
}
const mergeKetQuas = (ketQuas: IDgdkKetQuaHocTap[], newKetQuas: IDgdkKetQuaHocTap[]): IDgdkKetQuaHocTap[] => {
    let result = ketQuas.map(x => {
        var _newDiem = newKetQuas.find(y => x.id == y.id);
        if (_newDiem) return _newDiem;
        return x;
    })
    newKetQuas.map(y => {
        var _existedDiem = ketQuas.find(x => x.id == y.id)
        if (!_existedDiem) {
            result.push(y);
        }
    })
    return result;
}
export const dgdkHocTapReducer = (state: IDgdkHocTapPageState = iniState, action: IDgdkHocTapActionTypes): IDgdkHocTapPageState => {
    switch (action.type) {
        case eDgdkHocTapActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload,
                loading: {
                    ...state.loading,
                    hocSinh: ""
                },
                // hocSinhs: []
            }
        case eDgdkHocTapActionTypeIds.HOCSINH_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinh: "is_loading"
                }
            }
        case eDgdkHocTapActionTypeIds.HOCSINH_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinh: ""
                },
                hocSinhs: action.payload
            }
        case eDgdkHocTapActionTypeIds.SONHANXET_ITEM_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soNhanXetItems: "is_loading"
                },
            }
        case eDgdkHocTapActionTypeIds.SONHANXET_ITEM_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soNhanXetItems: ""
                },
                soNhanXetItems: action.payload
            }
        case eDgdkHocTapActionTypeIds.SONHANXET_ITEM_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soNhanXetItems: ""
                },
                soNhanXetItems: []
            }
        case eDgdkHocTapActionTypeIds.KETQUA_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: "is_loading"
                },
            }
        case eDgdkHocTapActionTypeIds.KETQUA_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
                ketQuas: action.payload
            }
        case eDgdkHocTapActionTypeIds.KETQUA_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
                ketQuas: []
            }
        case eDgdkHocTapActionTypeIds.KETQUA_SAVE_SUCCESS:
            return {
                ...state,
                ketQuas: mergeKetQuas(state.ketQuas, [action.payload])
            }
        case eDgdkHocTapActionTypeIds.VALIDATE_IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDgdkHocTapActionTypeIds.VALIDATE_IMPORT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: action.payload
            }
        case eDgdkHocTapActionTypeIds.VALIDATE_IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: []
            }
        case eDgdkHocTapActionTypeIds.IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDgdkHocTapActionTypeIds.IMPORT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eDgdkHocTapActionTypeIds.IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        default:
            return {
                ...state
            }

    }

}
import { sis_khaosat } from "../../models/response/form-khaosat/sis_khaosat";
import { apiClient } from "../apiClient";
import { BaseCRUDApi } from "../baseCRUDApi";

export const API_KHAOSAT_PATH = "khao-sat";

export const khaoSatApi = new class khaoSatApi extends BaseCRUDApi<sis_khaosat> {
    constructor(path: string) {
        super(path);
    }
    SelectByTruongAsync(dm_truong_id: number, nam_hoc: string) {
        return apiClient.get(`${this._path}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/select-all`);
    }
    KhaoSatMarkAsync(request: { sis_khaosat_id: number }) {
        return apiClient.post(`${this._path}/khao-sat-publish-mark`, request)
    }
    KetQuaKhaoSatResultAsync(request: { sis_khaosat_id: number }) {
        const requestQuery = new URLSearchParams(request as any).toString();
        return apiClient.get(`${this._path}/ket-qua-khao-sat${requestQuery ? `?${requestQuery}` : ""}`)
    }
    KetQuaKhaoSatResultDetailAsync(request: { sis_khaosat_id: number }) {
        const requestQuery = new URLSearchParams(request as any).toString();
        return apiClient.get(`${this._path}/ket-qua-khao-sat-chi-tiet${requestQuery ? `?${requestQuery}` : ""}`)
    }
    SelectKhaoSatResultAsync(request: { dm_truong_id: number, sis_khaosat_id: number; nam_hoc: string }) {
        const requestQuery = new URLSearchParams(request as any).toString();
        return apiClient.get(`${this._path}/select-khao-sat-result${requestQuery ? `?${requestQuery}` : ""}`);
    }
    SelectKhaoSatResultDetailAsync(request: { sis_form_khaosat_result_id: number }) {
        return apiClient.get(`${this._path}/khao-sat-result/${request.sis_form_khaosat_result_id}/select-khao-sat-result-detail`);
    }
}(API_KHAOSAT_PATH);
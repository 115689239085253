import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eAppMenuActionTypeIds, IAppMenuDeleteStart, IAppMenuSaveStart } from "../../action-types/app-phuhuynh/IAppMenuActionTypes";
import { actions } from "../../actions/actionsWrapper";

const appMenuApi = apiWrapper.appMenu;

export function* appMenuSaga(): any {
    yield takeLatest(eAppMenuActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eAppMenuActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eAppMenuActionTypeIds.SAVE_START, saveWorker)
}

function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([appMenuApi, appMenuApi.SelectAllView])
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appMenu.loadSuccess(res.data))
    } else {
        yield put(actions.appPhuHuynhWrapper.appMenu.loadError(res.message || "Không tải được chức năng"))
    }
}
function* deleteWorker(action: IAppMenuDeleteStart): any {
    const res: IBaseResponeModel = yield call([appMenuApi, appMenuApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appMenu.deleteSuccess())
        yield put(actions.appPhuHuynhWrapper.appMenu.loadStart())
    } else {
        yield put(actions.appPhuHuynhWrapper.appMenu.deleteError(res.message || "Không xóa được chức năng"))
    }
}
function* saveWorker(action: IAppMenuSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([appMenuApi, appMenuApi.Update], action.payload);
    } else {
        res = yield call([appMenuApi, appMenuApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appMenu.saveSuccess())
        yield put(actions.appPhuHuynhWrapper.appMenu.loadStart())
    } else {
        yield put(actions.appPhuHuynhWrapper.appMenu.saveError(res.message || "Không lưu được chức năng"))
    }
}
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { NotifyHelper } from './../../../helpers/toast';
import { eThangDiemActionTypeIds, IThangDiemActionTypes } from './../../action-types/so-diem/IThangDiemActions';
import { IThangDiemStateModel } from './../../page-state-model/so-diem/IThangDiemStateModel';
const iniState: IThangDiemStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    thangDiems: [],
    is_show_delete_confirm: false,
    is_show_detail_modal: false,
    thangDiemSelectedIds: [],
    is_show_option_delete_confirm: false,
    is_show_option_detail_modal: false,
    thangDiemOptions: []
}
export const thangDiemReducer = (state: IThangDiemStateModel = iniState, action: IThangDiemActionTypes): IThangDiemStateModel => {
    switch (action.type) {
        case eThangDiemActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eThangDiemActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                thangDiems: action.payload
            }
        case eThangDiemActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                thangDiems: []
            }
        case eThangDiemActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                thangDiemEditing: action.payload
            }
        case eThangDiemActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                thangDiemEditing: undefined
            }
        case eThangDiemActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                thangDiemSelectedIds: action.payload
            }
        case eThangDiemActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eThangDiemActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eThangDiemActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eThangDiemActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eThangDiemActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eThangDiemActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eThangDiemActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eThangDiemActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }
        case eThangDiemActionTypeIds.SHOW_OPTION_DETAIL:
            return {
                ...state,
                is_show_option_detail_modal: true,
                thangDiemEditing: action.payload
            }
        case eThangDiemActionTypeIds.CLOSE_OPTION_DETAIL:
            return {
                ...state,
                is_show_option_detail_modal: false
            }

        case eThangDiemActionTypeIds.SAVE_OPTION_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eThangDiemActionTypeIds.SAVE_OPTION_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eThangDiemActionTypeIds.SAVE_OPTION_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        case eThangDiemActionTypeIds.DELETE_OPTION_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eThangDiemActionTypeIds.DELETE_OPTION_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eThangDiemActionTypeIds.DELETE_OPTION_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }
        case eThangDiemActionTypeIds.LOAD_OPTION_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eThangDiemActionTypeIds.LOAD_OPTION_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                thangDiemOptions: action.payload,
                is_show_detail_modal: false
            }
        case eThangDiemActionTypeIds.LOAD_OPTION_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        default:
            return {
                ...state
            }
    }
}
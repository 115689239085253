import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { ePhongHocActionTypeIds, IPhongHocDeleteStart, IPhongHocSaveStart } from "../../action-types/phong-hoc/IPhongHocActionTypes";
import { actions } from "../../actions/actionsWrapper";

const phongHocApi = apiWrapper.phongHoc;

export function* phongHocSaga(): any {
    yield takeLatest(ePhongHocActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(ePhongHocActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(ePhongHocActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([phongHocApi, phongHocApi.SelectAllView])
    if (res.is_success) {
        yield put(actions.phongHoc.loadSuccess(res.data))
    } else {
        yield put(actions.phongHoc.loadError(res.message || "Không tải được phòng học"))
    }
}
function* deleteWorker(action: IPhongHocDeleteStart): any {
    const res: IBaseResponeModel = yield call([phongHocApi, phongHocApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.phongHoc.deleteSuccess())
        yield put(actions.phongHoc.loadStart())
    } else {
        yield put(actions.phongHoc.deleteErr(res.message || "Không tải được phòng học"))
    }
}
function* saveWorker(action: IPhongHocSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([phongHocApi, phongHocApi.Update], action.payload);
    } else {
        res = yield call([phongHocApi, phongHocApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.phongHoc.saveSuccess())
        yield put(actions.phongHoc.loadStart())
    } else {
        yield put(actions.phongHoc.saveTError(res.message || "Không lưu được phòng học"))
    }
}
import { IBaoCaoThuongXuyenCreateRequest } from '../models/request/bao-cao-thuong-xuyen/IBaoCaoTienBoCreateRequest';
import { IBaoCaoThuongXuyenImportRequest } from '../models/request/bao-cao-thuong-xuyen/IBaoCaoTienBoImportRequest';
import { IBaoCaoThuongXuyenSaveRequest } from '../models/request/bao-cao-thuong-xuyen/IBaoCaoTienBoSaveRequest';
import { IBaoCaoThuongXuyenSelectRequest } from '../models/request/bao-cao-thuong-xuyen/IBaoCaoTienBoSelectRequest';
import { IBaoCaoThuongXuyenViewHtmlRequest } from '../models/request/bao-cao-thuong-xuyen/IBaoCaoTienBoViewHtmlRequest';
import { apiClient } from './apiClient';
export const baoCaoThuongXuyenApi = {
    Select: (rq: IBaoCaoThuongXuyenSelectRequest) => apiClient.post('bao-cao-thuong-xuyen/select', rq),
    Create: (rq: IBaoCaoThuongXuyenCreateRequest) => apiClient.post('bao-cao-thuong-xuyen/create', rq),
    SendNotify: (rq: IBaoCaoThuongXuyenCreateRequest) => apiClient.post('bao-cao-thuong-xuyen/send-notify', rq),
    Save: (rq: IBaoCaoThuongXuyenSaveRequest) => apiClient.post('bao-cao-thuong-xuyen/save', rq),
    ViewHtml: (rq: IBaoCaoThuongXuyenViewHtmlRequest) => apiClient.post('bao-cao-thuong-xuyen/html', rq),
    ValidateImport: (request: IBaoCaoThuongXuyenImportRequest) => apiClient.post(`bao-cao-thuong-xuyen/import/validate`, request),
    Import: (request: IBaoCaoThuongXuyenImportRequest) => apiClient.post(`bao-cao-thuong-xuyen/import`, request),
    GetAllHtml: (request: IBaoCaoThuongXuyenCreateRequest) => apiClient.post(`bao-cao-thuong-xuyen/get-all-html`, request),

}


import { realpath } from 'fs';
import { INamHocTruongFilter } from './../models/INamHocTruongFilter';
import { IDiemVanMinhTieuChi } from '../models/response/diemvanminh/IDiemVanMinhTieuChi';
import { apiClient } from './apiClient';
import { BaseCRUDApi } from './baseCRUDApi';

export const API_DIEM_VAN_MINH_TIEU_CHI_PATH = 'diemvanminh-tieuchi'

class DiemVanMinhTieuChiApi extends BaseCRUDApi<IDiemVanMinhTieuChi>{
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(path: string) {
        super(path);
    }
    SelectByNamHocTruong(filter: INamHocTruongFilter) {
        return apiClient.get(`${API_DIEM_VAN_MINH_TIEU_CHI_PATH}/namhoc/${filter.nam_hoc}/truong/${filter.dm_truong_id}`)
    }

}
export const diemVanMinhTieuChiApi = new DiemVanMinhTieuChiApi(API_DIEM_VAN_MINH_TIEU_CHI_PATH)
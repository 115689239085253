import { IBaoCaoTienBoSelectRequest } from '../../../models/request/bao-cao-tien-do/BaoCaoTienBoSelectRequest';
import { IBaoCaoTienBoCreateRequest } from '../../../models/request/bao-cao-tien-do/IBaoCaoTienBoCreateRequest';
import { IBaoCaoTienBoImportRequest } from '../../../models/request/bao-cao-tien-do/IBaoCaoTienBoImportRequest';
import { IBaoCaoTienBoSaveRequest } from '../../../models/request/bao-cao-tien-do/IBaoCaoTienBoSaveRequest';
import { IHocSinhSelectRequest } from '../../../models/request/hoc-sinh/IHocSinhSelectRequest';
import { IBaoCaoTienDo } from '../../../models/response/bao-cao-tien-do/IBaoCaoTienDo';
import { IHocSinhSelectResponeItem } from '../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { IBaoCaoTienDoFilter } from '../../page-state-model/bao-cao-tien-bo/IBaoCaoTienBoPageState';
import { baseAction } from "../IActionBase";
import { NotifyHelper } from './../../../helpers/toast';
import { IBaoCaoTienBoRespone } from './../../../models/response/bao-cao-tien-do/IBaoCaoTienDoRespone';
import { eBaoCaoTienBoActionTypeIds } from './../../action-types/bao-cao-tien-bo/IBaoCaoTienBoActionType';

export const baoCaoTienBoActions = {
    changeFilter: (filter: IBaoCaoTienDoFilter) => baseAction(eBaoCaoTienBoActionTypeIds.CHANGE_FILTER, filter),
    changeSelectedIds: (ids: number[]) => baseAction(eBaoCaoTienBoActionTypeIds.CHANGE_SELECTED_IDS, ids),

    loadHocSinhStart: (request: IHocSinhSelectRequest) => baseAction(eBaoCaoTienBoActionTypeIds.HOCSINH_LOAD_START, request),
    loadHocSinhSuccess: (data: IHocSinhSelectResponeItem[]) => baseAction(eBaoCaoTienBoActionTypeIds.HOCSINH_LOAD_SUCCESS, data),
    loadHocSinhError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaoCaoTienBoActionTypeIds.HOCSINH_LOAD_ERROR, message)
    },
    loadDataStart: (request: IBaoCaoTienBoSelectRequest) => baseAction(eBaoCaoTienBoActionTypeIds.DATA_LOAD_START, request),
    loadDataSuccess: (data: IBaoCaoTienBoRespone) => baseAction(eBaoCaoTienBoActionTypeIds.DATA_LOAD_SUCCESS, data),
    loadDataError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaoCaoTienBoActionTypeIds.DATA_LOAD_ERROR, message)
    },
    showCreateConfirm: (isOpen: boolean) => baseAction(eBaoCaoTienBoActionTypeIds.SHOW_CREATE_CONFIRM, isOpen),
    createStart: (request: IBaoCaoTienBoCreateRequest) => baseAction(eBaoCaoTienBoActionTypeIds.CREATE_START, request),
    createSuccess: (data: IBaoCaoTienDo[]) => {
        NotifyHelper.Success("Tạo file thành công")
        return baseAction(eBaoCaoTienBoActionTypeIds.CREATE_SUCCESS, data)
    },
    createError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaoCaoTienBoActionTypeIds.CREATE_ERROR, message)
    },

    showSendNotifyConfirm: (isOpen: boolean) => baseAction(eBaoCaoTienBoActionTypeIds.SHOW_SEND_NOTIFY_CONFIRM, isOpen),

    sendNotifyStart: (request: IBaoCaoTienBoCreateRequest) => baseAction(eBaoCaoTienBoActionTypeIds.SEND_NOTIFY_START, request),
    sendNotifySuccess: (data: IBaoCaoTienDo[]) => baseAction(eBaoCaoTienBoActionTypeIds.SEND_NOTIFY_SUCCESS, data),
    sendNotifyError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaoCaoTienBoActionTypeIds.SEND_NOTIFY_ERROR, message)
    },

    saveStart: (request: IBaoCaoTienBoSaveRequest) => baseAction(eBaoCaoTienBoActionTypeIds.SAVE_START, request),
    saveSuccess: (data: IBaoCaoTienDo) => baseAction(eBaoCaoTienBoActionTypeIds.SAVE_SUCCESS, data),
    saveError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaoCaoTienBoActionTypeIds.SAVE_ERROR, message)
    },

    validateImportStart: (request: IBaoCaoTienBoImportRequest) => baseAction(eBaoCaoTienBoActionTypeIds.VALIDATE_IMPORT_START, request),
    validateImportSuccess: (data: any) => baseAction(eBaoCaoTienBoActionTypeIds.VALIDATE_IMPORT_SUCCESS, data),
    validateImportError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaoCaoTienBoActionTypeIds.VALIDATE_IMPORT_ERRROR, message)
    },
    importStart: (request: IBaoCaoTienBoImportRequest) => baseAction(eBaoCaoTienBoActionTypeIds.IMPORT_START, request),
    importSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eBaoCaoTienBoActionTypeIds.IMPORT_SUCCESS, undefined)
    },
    importError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaoCaoTienBoActionTypeIds.IMPORT_ERRROR, message)
    },
}
import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eAppMenuNhomActionTypeIds, IAppMenuNhomActionTypes } from "../../action-types/app-phuhuynh/IAppMenuNhomActionTypes"
import { IAppMenuNhomPageState } from "../../page-state-model/app-phuhuynh/IAppMenuNhomPageState"


const initialState: IAppMenuNhomPageState = {
    status: ePageBaseStatus.is_not_initialization,
    app_menu_nhoms: [],
    app_menu_nhom_selected_ids: [],
    app_menu_nhom_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const appMenuNhomReducer = (state: IAppMenuNhomPageState = initialState, action: IAppMenuNhomActionTypes): IAppMenuNhomPageState => {
    switch (action.type) {
        case eAppMenuNhomActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppMenuNhomActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_menu_nhoms: action.payload
            }
        case eAppMenuNhomActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_menu_nhoms: []
            }
        case eAppMenuNhomActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                app_menu_nhom_editing: action.payload
            }
        case eAppMenuNhomActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                app_menu_nhom_editing: undefined
            }
        case eAppMenuNhomActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                app_menu_nhom_selected_ids: action.payload
            }
        case eAppMenuNhomActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eAppMenuNhomActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        
            case eAppMenuNhomActionTypeIds.DELETE_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_deleting
                }
            case eAppMenuNhomActionTypeIds.DELETE_SUCCESS:
                NotifyHelper.Success("Xoá dữ liệu thành công.")
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }
            case eAppMenuNhomActionTypeIds.DELETE_ERROR:
                NotifyHelper.Error(action.payload)
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }

        
            case eAppMenuNhomActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eAppMenuNhomActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }
        case eAppMenuNhomActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }

        default:
            return state;
    }

}
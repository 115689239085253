import { ePageBaseStatus } from "../../../../models/ePageBaseStatus";
import { IDgtxKetQuaMonHocActionTypes } from "../../../action-types/danh-gia/thuong-xuyen/IDgtxKetQuaHocTapActionType";
import { IDgtxKetQuaMonHocPageState } from "../../../page-state-model/danh-gia/thuong-xuyen/IDgtxKetQuaMonHocPageState";
import { IDgtxKetQuaMonHoc } from './../../../../models/response/danh-gia/thuong-xuyen/IDgtxKetQuaMonHoc';
import { eDgtxKetQuaMonHocActionTypeIds } from './../../../action-types/danh-gia/thuong-xuyen/IDgtxKetQuaHocTapActionType';
const iniState: IDgtxKetQuaMonHocPageState = {
    filter: {
        dm_khoi_id: 0,
        pl_lopbomon_id: 0,
        dm_truong_id: 0,
        nam_hoc: '',
        thang: 0,
        hoc_ky: 0,
        sis_monhoc_id: 0
    },
    loading: {
        hocSinh: "",
        soNhanXetItems: "",
        ketQuas: ""
    },
    status: ePageBaseStatus.is_not_initialization,
    hocSinhs: [],
    soNhanXetItems: [],
    ketQuas: [],
    importData: []
}
const mergeKetQuas = (ketQuas: IDgtxKetQuaMonHoc[], newKetQuas: IDgtxKetQuaMonHoc[]): IDgtxKetQuaMonHoc[] => {
    let result = ketQuas.map(x => {
        var _newDiem = newKetQuas.find(y => x.id == y.id);
        if (_newDiem) return _newDiem;
        return x;
    })
    newKetQuas.map(y => {
        var _existedDiem = ketQuas.find(x => x.id == y.id)
        if (!_existedDiem) {
            result.push(y);
        }
    })
    return result;
}
export const dgtxKetQuaMonHocReducer = (state: IDgtxKetQuaMonHocPageState = iniState, action: IDgtxKetQuaMonHocActionTypes): IDgtxKetQuaMonHocPageState => {
    switch (action.type) {
        case eDgtxKetQuaMonHocActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case eDgtxKetQuaMonHocActionTypeIds.HOCSINH_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinh: "is_loading"
                }
            }
        case eDgtxKetQuaMonHocActionTypeIds.HOCSINH_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinh: ""
                },
                hocSinhs: action.payload
            }
        case eDgtxKetQuaMonHocActionTypeIds.HOCSINH_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinh: ""
                },
                hocSinhs: []
            }
        case eDgtxKetQuaMonHocActionTypeIds.SONHANXET_ITEM_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soNhanXetItems: "is_loading"
                },
            }
        case eDgtxKetQuaMonHocActionTypeIds.SONHANXET_ITEM_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soNhanXetItems: ""
                },
                soNhanXetItems: action.payload
            }
        case eDgtxKetQuaMonHocActionTypeIds.SONHANXET_ITEM_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soNhanXetItems: ""
                },
                soNhanXetItems: []
            }
        case eDgtxKetQuaMonHocActionTypeIds.KETQUA_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: "is_loading"
                },
            }
        case eDgtxKetQuaMonHocActionTypeIds.KETQUA_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
                ketQuas: action.payload
            }
        case eDgtxKetQuaMonHocActionTypeIds.KETQUA_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
                ketQuas: []
            }
        case eDgtxKetQuaMonHocActionTypeIds.KETQUA_SAVE_SUCCESS:
            return {
                ...state,
                ketQuas: mergeKetQuas(state.ketQuas, [action.payload])
            }
        case eDgtxKetQuaMonHocActionTypeIds.VALIDATE_IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDgtxKetQuaMonHocActionTypeIds.VALIDATE_IMPORT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: action.payload
            }
        case eDgtxKetQuaMonHocActionTypeIds.VALIDATE_IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: []
            }
        case eDgtxKetQuaMonHocActionTypeIds.IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDgtxKetQuaMonHocActionTypeIds.IMPORT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eDgtxKetQuaMonHocActionTypeIds.IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        default:
            return {
                ...state
            }

    }

}
import { ILopHanhChinhUpdateSoDiemRequest } from "../../../models/request/dm-lop/ILopHanhChinhUpdateSoDiemRequest";
import { ILopSelectRequest } from "../../../models/request/dm-lop/ILopSelectRequest";
import { ILopItemResponse } from "../../../models/response/lop/ILopItemResponse";
import { IActionTypeBase } from "../IActionTypeBase";

export enum eLopHanhChinhSoDiemActionTypeIds {
    LOAD_START = "LOPHANHCHINH_SODIEM_LOAD_START",
    LOAD_SUCCESS = "LOPHANHCHINH_SODIEM_LOAD_SUCCESS",
    LOAD_ERROR = "LOPHANHCHINH_SODIEM_LOAD_ERROR",

    CHANGE_SELECTED_IDS = "CHANGE_SELECTED_IDS",

    SHOW_DGDK_NLPC_SELECTION_MODAL = "SHOW_DGDK_NLPC_SELECTION_MODAL",

    UPDATE_DGDK_NLPC_START = "LOPHANHCHINH_UPDATE_DGDK_NLPC_START",
    UPDATE_DGDK_NLPC_SUCCESS = "LOPHANHCHINH_UPDATE_DGDK_NLPC_SUCCESS",
    UPDATE_DGDK_NLPC_ERROR = "LOPHANHCHINH_UPDATE_DGDK_NLPC_ERROR",

    SHOW_DGTX_NLPC_SELECTION_MODAL = "SHOW_DGTX_NLPC_SELECTION_MODAL",

    UPDATE_DGTX_NLPC_START = "LOPHANHCHINH_UPDATE_DGTX_NLPC_START",
    UPDATE_DGTX_NLPC_SUCCESS = "LOPHANHCHINH_UPDATE_DGTX_NLPC_SUCCESS",
    UPDATE_DGTX_NLPC_ERROR = "LOPHANHCHINH_UPDATE_DGTX_NLPC_ERROR",
}

export interface ILopHanhChinhSoDiemLoadStart extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.LOAD_START, ILopSelectRequest> { }
export interface ILopHanhChinhSoDiemLoadSuccess extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.LOAD_SUCCESS, ILopItemResponse[]> { }
export interface ILopHanhChinhSoDiemLoadError extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.LOAD_ERROR, string> { }

export interface ILopHanhChinhSoDiemChangeSelectedIds extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }


export interface ILopHanhChinhSoDiemShowDgDkNlpcSelectionModal extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.SHOW_DGDK_NLPC_SELECTION_MODAL, boolean> { }
export interface ILopHanhChinhUpdateDgdkNlpcStart extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGDK_NLPC_START, ILopHanhChinhUpdateSoDiemRequest> { }
export interface ILopHanhChinhUpdateDgdkNlpcSuccess extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGDK_NLPC_SUCCESS, undefined> { }
export interface ILopHanhChinhUpdateDgdkNlpcError extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGDK_NLPC_ERROR, string> { }


export interface ILopHanhChinhSoDiemShowDgtxNlpcSelectionModal extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.SHOW_DGTX_NLPC_SELECTION_MODAL, boolean> { }
export interface ILopHanhChinhUpdateDgtxNlpcStart extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGTX_NLPC_START, ILopHanhChinhUpdateSoDiemRequest> { }
export interface ILopHanhChinhUpdateDgtxNlpcSuccess extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGTX_NLPC_SUCCESS, undefined> { }
export interface ILopHanhChinhUpdateDgtxNlpcError extends IActionTypeBase<eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGTX_NLPC_ERROR, string> { }




export type ILopHanhChinhSoDiemActionTypes = ILopHanhChinhSoDiemLoadStart | ILopHanhChinhSoDiemLoadSuccess | ILopHanhChinhSoDiemLoadError |
    ILopHanhChinhSoDiemChangeSelectedIds |
    ILopHanhChinhSoDiemShowDgDkNlpcSelectionModal |
    ILopHanhChinhUpdateDgdkNlpcStart | ILopHanhChinhUpdateDgdkNlpcSuccess | ILopHanhChinhUpdateDgdkNlpcError |
    ILopHanhChinhSoDiemShowDgtxNlpcSelectionModal |
    ILopHanhChinhUpdateDgtxNlpcStart | ILopHanhChinhUpdateDgtxNlpcSuccess | ILopHanhChinhUpdateDgtxNlpcError



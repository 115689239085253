import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_pl_dotdangky_nhommonhoc } from "../../../models/response/dangky-monhoc/sis_pl_dotdangky_nhommonhoc";

import { IDotDangKyNhomMonHocChangeSelectedIds, IDotDangKyNhomMonHocCloseConfirm, IDotDangKyNhomMonHocCloseDetail, IDotDangKyNhomMonHocDeleteErr, IDotDangKyNhomMonHocDeleteStart, IDotDangKyNhomMonHocDeleteSuccess, IDotDangKyNhomMonHocLoadErr, IDotDangKyNhomMonHocLoadStart, IDotDangKyNhomMonHocLoadSuccess, IDotDangKyNhomMonHocSaveErr, IDotDangKyNhomMonHocSaveStart, IDotDangKyNhomMonHocSaveSuccess, IDotDangKyNhomMonHocShowConfirm, IDotDangKyNhomMonHocShowDetail, eDotDangKyNhomMonHocActionTypeIds } from "../../action-types/dangky-monhoc/IDotDangKyNhomMonHocActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const dotDangKyNhomMonHocActions = {
    loadStart: (dotdangky_id: number): IDotDangKyNhomMonHocLoadStart => BaseAction(dotdangky_id, eDotDangKyNhomMonHocActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_pl_dotdangky_nhommonhoc[]): IDotDangKyNhomMonHocLoadSuccess => BaseAction(respone, eDotDangKyNhomMonHocActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IDotDangKyNhomMonHocLoadErr => BaseAction(messege, eDotDangKyNhomMonHocActionTypeIds.LOAD_ERROR),

    showDetail: (data?: sis_pl_dotdangky_nhommonhoc): IDotDangKyNhomMonHocShowDetail => BaseAction(data, eDotDangKyNhomMonHocActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IDotDangKyNhomMonHocCloseDetail => BaseAction(undefined, eDotDangKyNhomMonHocActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IDotDangKyNhomMonHocShowConfirm => BaseAction(undefined, eDotDangKyNhomMonHocActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IDotDangKyNhomMonHocCloseConfirm => BaseAction(undefined, eDotDangKyNhomMonHocActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IDotDangKyNhomMonHocChangeSelectedIds => BaseAction(ids, eDotDangKyNhomMonHocActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IDotDangKyNhomMonHocDeleteStart => BaseAction(ids, eDotDangKyNhomMonHocActionTypeIds.DELETE_START),
    deleteSuccess: (): IDotDangKyNhomMonHocDeleteSuccess => BaseAction(undefined, eDotDangKyNhomMonHocActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IDotDangKyNhomMonHocDeleteErr => BaseAction(message, eDotDangKyNhomMonHocActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: sis_pl_dotdangky_nhommonhoc): IDotDangKyNhomMonHocSaveStart => BaseAction(data, eDotDangKyNhomMonHocActionTypeIds.SAVE_START),
    saveSuccess: ():IDotDangKyNhomMonHocSaveSuccess => BaseAction(undefined, eDotDangKyNhomMonHocActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IDotDangKyNhomMonHocSaveErr =>  BaseAction(message, eDotDangKyNhomMonHocActionTypeIds.SAVE_ERR)
}
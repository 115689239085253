import { IMappingCreateRequest } from '../../../models/request/mapping/IMappingCreateRequest';
import { IMappingRespone } from '../../../models/response/mapping/IMappingRespone';
import { IActionTypeBase } from './../IActionTypeBase';
export enum eMappingActionTypeIds {
    SAVING_START = "MAPPING_SAVING_START",
    SAVING_SUCCESS = "MAPPING_SAVING_SUCCESS",
    SAVING_ERROR = "MAPPING_SAVING_ERROR",

    GET_START = "MAPPING_GET_START",
    GET_SUCCESS = "MAPPING_GET_SUCCESS",
    GET_ERROR = "MAPPING_GET_ERROR",

    CLEAR_STATE = "MAPPING_CLEAR_STATE"
}
export interface IMappingSavingStart extends IActionTypeBase<eMappingActionTypeIds.SAVING_START, IMappingCreateRequest> { }
export interface IMappingSavingSuccess extends IActionTypeBase<eMappingActionTypeIds.SAVING_SUCCESS, number> { }
export interface IMappingSavingError extends IActionTypeBase<eMappingActionTypeIds.SAVING_ERROR, string> { }

export interface IMappingGetStart extends IActionTypeBase<eMappingActionTypeIds.GET_START, number> { }
export interface IMappingGetSuccess extends IActionTypeBase<eMappingActionTypeIds.GET_SUCCESS, IMappingRespone> { }
export interface IMappingGetError extends IActionTypeBase<eMappingActionTypeIds.GET_ERROR, string> { }

export interface IMappingClearState extends IActionTypeBase<eMappingActionTypeIds.CLEAR_STATE, undefined> { }

export type IMappingActionTypes = IMappingSavingStart | IMappingSavingSuccess | IMappingSavingError |
    IMappingGetStart | IMappingGetSuccess | IMappingGetError | IMappingClearState

import { sis_monhoc } from '../models/response/mon-hoc/sis_monhoc';
import { apiClient } from './apiClient';
import { BaseCRUDApi } from './baseCRUDApi';


export const API_MON_HOC_PATH = "mon-hoc"

class MonHocApi extends BaseCRUDApi<sis_monhoc>{
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(path: string) {
        super(path);
    }
    SelectByTruong(truongId: number) {
        return apiClient.get(`${API_MON_HOC_PATH}/truong/${truongId}`);
    }

}
export const monHocApi = new MonHocApi(API_MON_HOC_PATH)

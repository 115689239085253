import { IBaiTapKetQua } from '../../../models/response/bai-tap/IBaiTapKetQua';
import { pl_lopbomon_hocsinh_viewmodel } from './../../../models/response/lop-bo-mon/pl_lopbomon_hocsinh';
import { IActionTypeBase } from './../IActionTypeBase';
export enum eBaiTapKetQuaLopTypeIds {
    LOAD_HOCSINH_START = "BAITAP_KETQUA_LOP_LOAD_HOCSINH_START",
    LOAD_HOCSINH_SUCCESS = "BAITAP_KETQUA_LOP_LOAD_HOCSINH_SUCCESS",
    LOAD_HOCSINH_ERROR = "BAITAP_KETQUA_LOP_LOAD_HOCSINH_ERROR",

    LOAD_KETQUA_START = "BAITAP_KETQUA_LOP_LOAD_KETQUA_START",
    LOAD_KETQUA_SUCCESS = "BAITAP_KETQUA_LOP_LOAD_KETQUA_SUCCESS",
    LOAD_KETQUA_ERROR = "BAITAP_KETQUA_LOP_LOAD_KETQUA_ERROR",

    SYNC_LMS_START = "BAITAP_KETQUA_LOP_SYNC_LMS_START",
    SYNC_LMS_SUCCESS = "BAITAP_KETQUA_LOP_SYNC_LMS_SUCCESS",
    SYNC_LMS_ERROR = "BAITAP_KETQUA_LOP_SYNC_LMS_ERROR",
}

export interface IBaiTapKetQuaLopLoadHocSinhStart extends IActionTypeBase<eBaiTapKetQuaLopTypeIds.LOAD_HOCSINH_START, number> { }
export interface IBaiTapKetQuaLopLoadHocSinhSuccess extends IActionTypeBase<eBaiTapKetQuaLopTypeIds.LOAD_HOCSINH_SUCCESS, pl_lopbomon_hocsinh_viewmodel[]> { }
export interface IBaiTapKetQuaLopLoadHocSinhError extends IActionTypeBase<eBaiTapKetQuaLopTypeIds.LOAD_HOCSINH_ERROR, string> { }

export interface IBaiTapKetQuaLopLoadKetQuaStart extends IActionTypeBase<eBaiTapKetQuaLopTypeIds.LOAD_KETQUA_START, number> { }
export interface IBaiTapKetQuaLopLoadKetQuaSuccess extends IActionTypeBase<eBaiTapKetQuaLopTypeIds.LOAD_KETQUA_SUCCESS, IBaiTapKetQua[]> { }
export interface IBaiTapKetQuaLopLoadKetQuaError extends IActionTypeBase<eBaiTapKetQuaLopTypeIds.LOAD_KETQUA_ERROR, string> { }

export interface IBaiTapKetQuaLopSyncLmsStart extends IActionTypeBase<eBaiTapKetQuaLopTypeIds.SYNC_LMS_START, number> { }
export interface IBaiTapKetQuaLopSyncLmsSuccess extends IActionTypeBase<eBaiTapKetQuaLopTypeIds.SYNC_LMS_SUCCESS, undefined> { }
export interface IBaiTapKetQuaLopSyncLmsError extends IActionTypeBase<eBaiTapKetQuaLopTypeIds.SYNC_LMS_ERROR, string> { }

export type IBaiTapKetQuaLopActionTypes = IBaiTapKetQuaLopLoadHocSinhStart | IBaiTapKetQuaLopLoadHocSinhSuccess | IBaiTapKetQuaLopLoadHocSinhError |
    IBaiTapKetQuaLopLoadKetQuaStart | IBaiTapKetQuaLopLoadKetQuaSuccess | IBaiTapKetQuaLopLoadKetQuaError |
    IBaiTapKetQuaLopSyncLmsStart | IBaiTapKetQuaLopSyncLmsSuccess | IBaiTapKetQuaLopSyncLmsError


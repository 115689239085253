import { ISoNhanXet } from "../models/response/dgdk-dm/ISoNhanXet";
import { ISoNhanXetSelectRequest } from '../models/request/so-tieu-hoc/ISoNhanXetSelectRequest';
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_SO_NHAN_XET_PATH = "so-nhan-xet"

class SoNhanXetApi extends BaseCRUDApi<ISoNhanXet> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    Select(request: ISoNhanXetSelectRequest) {
        return apiClient.get(`${this._path}/school/${request.dm_truong_id}/nam-hoc/${request.nam_hoc}`)
    }
}
export const soNhanXetApi = new SoNhanXetApi({ path: API_SO_NHAN_XET_PATH });


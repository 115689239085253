import { eBaiTapKetQuaLopTypeIds } from "../../action-types/bai-tap/IBaiTapKetQuaLop";
import { baseAction } from "../IActionBase";
import { NotifyHelper } from '../../../helpers/toast';
import { IBaiTapKetQua } from '../../../models/response/bai-tap/IBaiTapKetQua';
import { pl_lopbomon_hocsinh_viewmodel } from "../../../models/response/lop-bo-mon/pl_lopbomon_hocsinh";

export const baiTapKetQuaLopActions = {
    loadHocSinhStart: (lopBoMonId: number) => baseAction(eBaiTapKetQuaLopTypeIds.LOAD_HOCSINH_START, lopBoMonId),
    loadHocSinhSuccess: (data: pl_lopbomon_hocsinh_viewmodel[]) => baseAction(eBaiTapKetQuaLopTypeIds.LOAD_HOCSINH_SUCCESS, data),
    loadHocSinhError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaiTapKetQuaLopTypeIds.LOAD_HOCSINH_SUCCESS, message)
    },
    loadKetQuaStart: (lopBoMonId: number) => baseAction(eBaiTapKetQuaLopTypeIds.LOAD_KETQUA_START, lopBoMonId),
    loadKetQuaSuccess: (baiTapKetQuas: IBaiTapKetQua[]) => baseAction(eBaiTapKetQuaLopTypeIds.LOAD_KETQUA_SUCCESS, baiTapKetQuas),
    loadKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaiTapKetQuaLopTypeIds.LOAD_KETQUA_ERROR, message)
    },
    syncLmsStart: (lopBoMonId: number) => baseAction(eBaiTapKetQuaLopTypeIds.SYNC_LMS_START, lopBoMonId),
    syncLmsSuccess: () => {
        NotifyHelper.Success("Đồng bộ thành công.")
        return baseAction(eBaiTapKetQuaLopTypeIds.SYNC_LMS_SUCCESS, undefined);
    },
    syncLmsError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaiTapKetQuaLopTypeIds.SYNC_LMS_ERROR, message)
    }
}
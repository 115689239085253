import { diemApi } from './../../../api/diemApi';
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { apiWrapper } from './../../../api/apiWrapper';
import { eNhapDiemActionTypeIds, INhapDiemLoadHocSinhStart, INhapDiemLoadSoDiemInfoStart, INhapDiemUpdateDiemStart, INhapDiemLoadDiemStart, INhapDiemCalculateStart, INhapDiemMappingStart, INhapDiemConvertMoetStart, INhapDiemValidateStart, INhapDiemImportStart, INhapDiemLoadDiemChuyenDeStart } from './../../action-types/nhap-diem/INhapDiemActionType';
import { actions } from './../../actions/actionsWrapper';
export function* nhapDiemSaga(): any {
    yield takeLatest(eNhapDiemActionTypeIds.LOAD_HOCSINH_START, loadHocSinh)
    yield takeLatest(eNhapDiemActionTypeIds.LOAD_SODIEM_INFO_START, loadSoDiemInfoWorker)
    yield takeLatest(eNhapDiemActionTypeIds.LOAD_SODIEM_MOET_INFO_START, loadSoDiemMoetInfoWorker)
    yield takeEvery(eNhapDiemActionTypeIds.UPDATE_DIEM_START, updateDiemWorker)
    yield takeEvery(eNhapDiemActionTypeIds.CALCULATE_START, calculateWorker)
    yield takeLatest(eNhapDiemActionTypeIds.LOAD_DIEM_START, loadDiemWorker)
    yield takeLatest(eNhapDiemActionTypeIds.LOAD_DIEM_CHUYEN_DE_START, loadDiemChuyenDeWorker)
    yield takeLatest(eNhapDiemActionTypeIds.LOAD_DIEM_MOET_START, loadDiemMoetWorker)
    yield takeEvery(eNhapDiemActionTypeIds.MAPPING_START, mappingWorker)
    yield takeEvery(eNhapDiemActionTypeIds.CONVERT_MOET_START, convertMoetWorker)
    yield takeEvery(eNhapDiemActionTypeIds.VALIDATE_IMPORT_START, validateImportWorker)
    yield takeEvery(eNhapDiemActionTypeIds.IMPORT_START, importWorker)
    yield takeEvery(eNhapDiemActionTypeIds.VALIDATE_IMPORT_LOP_START, validateImportLopWorker)
    yield takeEvery(eNhapDiemActionTypeIds.IMPORT_LOP_START, importLopWorker)
    yield takeEvery(eNhapDiemActionTypeIds.UPDATE_DIEM_ALL_THANHPHAN_START, updateDiemAllWorker)
}
function* loadHocSinh(data: INhapDiemLoadHocSinhStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMonHocSinh, apiWrapper.lopBoMonHocSinh.Select], {
        pl_lopbomon_ids: [data.payload]
    })
    if (res.is_success) {
        yield put(actions.nhapDiem.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.nhapDiem.loadHocSinhError(res.message ?? ""))
    }
}

function* loadSoDiemInfoWorker(action: INhapDiemLoadSoDiemInfoStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMon, apiWrapper.lopBoMon.SelectSoDiem], action.payload, false)
    if (res.is_success) {
        yield put(actions.nhapDiem.loadSoDiemInfoSuccess(res.data))
    } else {
        yield put(actions.nhapDiem.loadSoDiemInfoError(res.message ?? ""))
    }
}
function* loadSoDiemMoetInfoWorker(action: INhapDiemLoadSoDiemInfoStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMon, apiWrapper.lopBoMon.SelectSoDiem], action.payload, true)
    if (res.is_success) {
        yield put(actions.nhapDiem.loadSoDiemMoetInfoSuccess(res.data))
    } else {
        yield put(actions.nhapDiem.loadSoDiemMoetInfoError(res.message ?? ""))
    }
}

function* updateDiemWorker(action: INhapDiemUpdateDiemStart): any {
    const res: IBaseResponeModel = yield call(diemApi.updateDiem, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.updateDiemSuccess(res.data))
    } else {
        yield put(actions.nhapDiem.updateDiemError(res.message ?? ""))
    }
}
function* calculateWorker(action: INhapDiemCalculateStart): any {
    const res: IBaseResponeModel = yield call(diemApi.calculateDiem, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.calculateDiemSuccess(res.data))
    } else {
        yield put(actions.nhapDiem.calculateDiemError(res.message ?? ""))
    }
}
function* mappingWorker(action: INhapDiemMappingStart): any {
    const res: IBaseResponeModel = yield call(diemApi.mapping, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.mappingSuccess())
    } else {
        yield put(actions.nhapDiem.mappingError(res.message ?? ""))
    }
}
function* convertMoetWorker(action: INhapDiemConvertMoetStart): any {
    const res: IBaseResponeModel = yield call(diemApi.convertMoet, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.convertMoetSuccess())
    } else {
        yield put(actions.nhapDiem.convertMoetError(res.message ?? ""))
    }
}

function* loadDiemWorker(action: INhapDiemLoadDiemStart): any {
    const res: IBaseResponeModel = yield call(diemApi.selectByLop, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.loadDiemSuccess(res.data))
    } else {
        yield put(actions.nhapDiem.loadDiemError(res.message ?? ""))
    }
}


function* loadDiemChuyenDeWorker(action: INhapDiemLoadDiemChuyenDeStart): any {
    const res: IBaseResponeModel = yield call(diemApi.selectDiemChuyenDeByLop, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.loadDiemChuyenDeSuccess(res.data))
    } else {
        yield put(actions.nhapDiem.loadDiemChuyenDeError(res.message ?? ""))
    }
}

function* loadDiemMoetWorker(action: INhapDiemLoadDiemStart): any {
    const res: IBaseResponeModel = yield call(diemApi.selectByLop, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.loadDiemMoetSuccess(res.data))
    } else {
        yield put(actions.nhapDiem.loadDiemMoetError(res.message ?? ""))
    }
}

function* validateImportWorker(action: INhapDiemValidateStart): any {
    const res: IBaseResponeModel = yield call(diemApi.ValidateImport, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.validateImportSuccess(res.data))
    } else {
        yield put(actions.nhapDiem.validateImportError(res.message ?? ""))
    }
}

function* importWorker(action: INhapDiemImportStart): any {
    const res: IBaseResponeModel = yield call(diemApi.Import, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.importSuccess())
    } else {
        yield put(actions.nhapDiem.importError(res.message ?? ""))
    }
}

function* validateImportLopWorker(action: INhapDiemValidateStart): any {
    const res: IBaseResponeModel = yield call(diemApi.ValidateImportLop, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.validateImportSuccess(res.data))
    } else {
        yield put(actions.nhapDiem.validateImportError(res.message ?? ""))
    }
}

function* importLopWorker(action: INhapDiemImportStart): any {
    const res: IBaseResponeModel = yield call(diemApi.ImportLop, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.importSuccess())
    } else {
        yield put(actions.nhapDiem.importError(res.message ?? ""))
    }
}

function* updateDiemAllWorker(action: INhapDiemUpdateDiemStart): any {
    const res: IBaseResponeModel = yield call(diemApi.updateDiemThanhPhanAll, action.payload)
    if (res.is_success) {
        yield put(actions.nhapDiem.updateDiemThanhPhanAllSuccess())
    } else {
        yield put(actions.nhapDiem.updateDiemThanhPhanAllError(res.message ?? ""))
    }
}

import { IMappingRespone } from './../../../models/response/mapping/IMappingRespone';
import { IMappingCreateRequest } from '../../../models/request/mapping/IMappingCreateRequest';
import { baseAction } from "../IActionBase";
import { NotifyHelper } from './../../../helpers/toast';
import { eMappingActionTypeIds } from './../../action-types/mapping/IMappingActionTypes';

export const mappingActions = {
    saveStart: (request: IMappingCreateRequest) => baseAction(eMappingActionTypeIds.SAVING_START, request),
    saveSuccess: (mappingId: number) => baseAction(eMappingActionTypeIds.SAVING_SUCCESS, mappingId),
    saveError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eMappingActionTypeIds.SAVING_ERROR, message)
    },
    getStart: (id: number) => baseAction(eMappingActionTypeIds.GET_START, id),
    getSuccess: (respone: IMappingRespone) => baseAction(eMappingActionTypeIds.GET_SUCCESS, respone),
    getError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eMappingActionTypeIds.GET_ERROR, message)
    },
    clearState: () => baseAction(eMappingActionTypeIds.CLEAR_STATE, undefined)
}
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eBaiTapTypeActionIds, IBaiTapTypeActionTypes } from "../../action-types/bai-tap/IBaiTapTypeActionType";
import { IBaiTapTypePageState } from "../../page-state-model/bai-tap/IBaiTapTypePageState";

const iniState: IBaiTapTypePageState = {
    status: ePageBaseStatus.is_not_initialization,
    baiTapTypes: []
}

export const baiTapTypeReducer = (state: IBaiTapTypePageState=iniState, action: IBaiTapTypeActionTypes): IBaiTapTypePageState => {
    switch (action.type) {
        case eBaiTapTypeActionIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiTapTypeActionIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                baiTapTypes: action.payload
            }
        case eBaiTapTypeActionIds.LOAD_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }

        default:
            return {
                ...state
            }
    }
}
import { ILopBoMonUpdateSoDiemRequest } from './../models/request/lop-bo-mon/ILopBoMonUpdateSoDiemRequest';
import { ILopBoMonGenerateRequest } from "../models/request/lop-bo-mon/ILopBoMonGenerateRequest";
import { ILopBoMonSelectRequest } from "../models/request/lop-bo-mon/ILopBoMonSelectRequest";
import { ILopBoMonCungMonHocLopBoMonSelectRequest } from "../models/request/lop-bo-mon/ILopBoMonCungMonHocLopBoMonSelectRequest";
import { pl_lopbomon } from "../models/response/lop-bo-mon/pl_lopbomon";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";
import { ILopSelectRequest } from '../models/request/dm-lop/ILopSelectRequest';
import { ILopBoMonSetLockGiaoVienRequest } from '../models/request/lop-bo-mon/ILopBoMonSetLockGiaoVienRequest';

export const API_LOPBOMON_PATH = 'lop-bo-mon';

class LopBoMonApi extends BaseCRUDApi<pl_lopbomon>{
    constructor({ path }: { path: string; }) {
        super(path);
    }
    SelectTreeView(request: ILopBoMonSelectRequest) {
        return apiClient.post(`${this._path}/treeview`, request)
    }
    Select(request: ILopBoMonSelectRequest) {
        return apiClient.post(`${this._path}/select`, request)
    }
    SelectByGiaoVien(request: ILopBoMonSelectRequest) {
        return apiClient.post(`${this._path}/select-by-giaovien`, request)
    }
    Generate(request: ILopBoMonGenerateRequest) {
        return apiClient.post(`${this._path}/generate`, request)
    }
    UpdateGiaoVien(request: pl_lopbomon) {
        return apiClient.put(`${this._path}/giao-vien`, request)
    }
    SelectSoDiem(lopBoMonId: number, isMoet: boolean) {
        return apiClient.get(`${this._path}/${lopBoMonId}/so-diem-config?isMoet=${isMoet}`)
    }
    UpdateSoDiem(request: ILopBoMonUpdateSoDiemRequest) {
        return apiClient.post(`${this._path}/so-diem`, request)
    }
    UpdateSoNhanXetDinhKy(request: ILopBoMonUpdateSoDiemRequest) {
        return apiClient.post(`${this._path}/so-danh-gia/hoctap-dinhky`, request)
    }
    UpdateSoNhanXetThuongXuyen(request: ILopBoMonUpdateSoDiemRequest) {
        return apiClient.post(`${this._path}/so-danh-gia/hoctap-thuongxuyen`, request)
    }
    GenerateFromLopMonHoc(request: ILopSelectRequest) {
        return apiClient.post(`lop-bo-mon/generate-from-lopmonhoc`, request)
    }
    SetLockGiaoVien(request: ILopBoMonSetLockGiaoVienRequest) {
        return apiClient.put(`${this._path}/giao-vien/lock`, request)

    }
    SelectSameMonHocLopBoMon(request: ILopBoMonCungMonHocLopBoMonSelectRequest) {
        return apiClient.post(`${this._path}/select-same-monhoc-lopbomon`, request)
    }
    
    SelectDetail(id: number) {
        return apiClient.get(`${this._path}/${id}`)
    }
}
export const lopBoMonApi = new LopBoMonApi({ path: API_LOPBOMON_PATH })
import { IPLNamHocSelectRequest } from "../models/request/danh-muc/IPLNamHocSelectRequest";
import { pl_namhoc } from "../models/response/danh-muc/pl_namhoc";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_PL_NAMHOC_PATH = "pl-nam-hoc"
class PLNamHocApi extends BaseCRUDApi<pl_namhoc> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    Select(request: IPLNamHocSelectRequest) {
        return apiClient.post(`${this._path}/select`, request);
    }
}
export const plNamHocApi = new PLNamHocApi({ path: API_PL_NAMHOC_PATH });
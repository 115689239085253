import { IImportDgtxHocTapRequest } from '../models/request/danh-gia/thuong-xuyen/IImportDgtxHocTapRequest';
import { ISelectDgtxKetQuaHocTapRequest } from '../models/request/danh-gia/thuong-xuyen/ISelectDgtxKetQuaHocTapRequest';
import { IUpdateDgtxHocTapRequest } from '../models/request/danh-gia/thuong-xuyen/IUpdateDgtxHocTapRequest';
import { apiClient } from './apiClient';
export const API_APP_DGTX_KETQUA_HOCTAP_PATH = "dgtx-hoctap"
export const dgtxKetQuaHocTapApi = {
    Select: (request: ISelectDgtxKetQuaHocTapRequest) => apiClient.post(`${API_APP_DGTX_KETQUA_HOCTAP_PATH}/select`, request),
    Save: (request: IUpdateDgtxHocTapRequest) => apiClient.post(`${API_APP_DGTX_KETQUA_HOCTAP_PATH}/save`, request),
    ValidateImport: (request: IImportDgtxHocTapRequest) => apiClient.post(`${API_APP_DGTX_KETQUA_HOCTAP_PATH}/import/validate`, request),
    Import: (request: IImportDgtxHocTapRequest) => apiClient.post(`${API_APP_DGTX_KETQUA_HOCTAP_PATH}/import`, request),
}
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { pl_phonghoc, pl_phonghoc_view } from "../../../models/response/phong-hoc/pl_phonghoc";
import { ePhongHocActionTypeIds, IPhongHocChangeSelectedIds, IPhongHocCloseConfirm, IPhongHocCloseDetail, IPhongHocDeleteErr, IPhongHocDeleteStart, IPhongHocDeleteSuccess, IPhongHocLoadErr, IPhongHocLoadSuccess, IPhongHocSaveErr, IPhongHocSaveStart, IPhongHocSaveSuccess, IPhongHocShowConfirm, IPhongHocShowDetail } from "../../action-types/phong-hoc/IPhongHocActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const phongHocActions = {
    loadStart: () => BaseAction(undefined, ePhongHocActionTypeIds.LOAD_START),
    loadSuccess: (respone: pl_phonghoc_view[]): IPhongHocLoadSuccess => BaseAction(respone, ePhongHocActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IPhongHocLoadErr => BaseAction(messege, ePhongHocActionTypeIds.LOAD_ERROR),
    showDetail: (data?: pl_phonghoc_view): IPhongHocShowDetail => BaseAction(data, ePhongHocActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IPhongHocCloseDetail => BaseAction(undefined, ePhongHocActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IPhongHocShowConfirm => BaseAction(undefined, ePhongHocActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IPhongHocCloseConfirm => BaseAction(undefined, ePhongHocActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IPhongHocChangeSelectedIds => BaseAction(ids, ePhongHocActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IPhongHocDeleteStart => BaseAction(ids, ePhongHocActionTypeIds.DELETE_START),
    deleteSuccess: (): IPhongHocDeleteSuccess => BaseAction(undefined, ePhongHocActionTypeIds.DELETE_SUCCESS),
    deleteErr: (message: string): IPhongHocDeleteErr => BaseAction(message, ePhongHocActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: pl_phonghoc) : IPhongHocSaveStart => BaseAction(data, ePhongHocActionTypeIds.SAVE_START),
    saveSuccess: (): IPhongHocSaveSuccess => BaseAction(undefined, ePhongHocActionTypeIds.SAVE_SUCCESS),
    saveTError: (message: string): IPhongHocSaveErr =>  BaseAction(message, ePhongHocActionTypeIds.SAVE_ERR)
}
import { IBaiTapKetQua } from './../models/response/bai-tap/IBaiTapKetQua';
import { IBaiTap } from '../models/response/bai-tap/IBaiTap';
import { IBaiTapSelectRequest } from './../models/request/bai-tap/IBaiTapSelectRequest';
import { apiClient } from './apiClient';
export const API_BAI_TAP_PATH = 'bai-tap'
export const API_BAI_TAP_KETQUA_PATH = 'bai-tap-ket-qua'
export const baiTapApi = {
    SelectTreeView: (request: IBaiTapSelectRequest) => apiClient.post(`${API_BAI_TAP_PATH}/treeview`, request),
    SelectKetQua: (baiTapId: number) => apiClient.get(`${API_BAI_TAP_PATH}/${baiTapId}/ket-qua`),
    Select: (lopBoMonId: number) => apiClient.get(`${API_BAI_TAP_PATH}/class/${lopBoMonId}`),
    Insert: (request: IBaiTap) => apiClient.post(`${API_BAI_TAP_PATH}`, request),
    Update: (request: IBaiTap) => apiClient.put(`${API_BAI_TAP_PATH}`, request),
    UpdateKetQua: (request: IBaiTapKetQua) => apiClient.put(`${API_BAI_TAP_KETQUA_PATH}`, request),
    Delete: (id: number) => apiClient.delete(`${API_BAI_TAP_PATH}/${id}`),
    PublishMark: (baiTapId: number) => apiClient.post(`${API_BAI_TAP_PATH}/${baiTapId}/publish-mark`),
    SendNotify: (baiTapId: number) => apiClient.post(`${API_BAI_TAP_PATH}/${baiTapId}/send-notify`),
    SyncLms: (lopBoMonId: number) => apiClient.post(`${API_BAI_TAP_PATH}/class/${lopBoMonId}/sync-lms`)
}
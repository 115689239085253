import { ILocalizedResource } from "../../../models/response/localized-resource/ILocalizedResource";
import { baseAction } from "../IActionBase";
import { eLocalizedResourceActionTypeIds, ILocalizedResourceLoadError, ILocalizedResourceLoadStart, ILocalizedResourceLoadSuccess } from './../../action-types/localized-resource/ILocalizedResourceActions';

export const localizedResourceAction = {
    loadStart: (lan:string): ILocalizedResourceLoadStart => baseAction(eLocalizedResourceActionTypeIds.LOAD_START, lan),
    loadSuccess: (data: ILocalizedResource[]): ILocalizedResourceLoadSuccess => {
        let maps = new Map<string,string>();
        data.forEach(e=>{
            maps.set(e.code, e.value)
        })
        return baseAction(eLocalizedResourceActionTypeIds.LOAD_SUCCESS, maps)
    },
    loadError: (message: string): ILocalizedResourceLoadError => baseAction(eLocalizedResourceActionTypeIds.LOAD_ERROR, message),
}
import { IMonHocSoDiem } from '../../../models/response/so-diem/IMonHocSoDiem';
import { IActionTypeBase } from './../IActionTypeBase';
export enum eMonHocSoDiemActionIds {
    LOAD_START = "MONHOC_SODIEM_LOAD_START",
    LOAD_SUCCESS = "MONHOC_SODIEM_LOAD_SUCCESS",
    LOAD_ERROR = "MONHOC_SODIEM_LOAD_ERROR",
}

export interface IMonHocSoDiemLoadStart extends IActionTypeBase<eMonHocSoDiemActionIds.LOAD_START, undefined> { }
export interface IMonHocSoDiemLoadSuccess extends IActionTypeBase<eMonHocSoDiemActionIds.LOAD_SUCCESS, IMonHocSoDiem[]> { }
export interface IMonHocSoDiemLoadError extends IActionTypeBase<eMonHocSoDiemActionIds.LOAD_ERROR, string> { }

export type IMonHocSoDiemActionTypes = IMonHocSoDiemLoadStart | IMonHocSoDiemLoadSuccess | IMonHocSoDiemLoadError
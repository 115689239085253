import { diemVanMinhKetQuaApi } from './../../../api/diemVanMinhKetQuaApi';
import { call, put, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eDiemVanMinhKetQuaActionTypeIds, IDiemVanMinhDeleteStart, IDiemVanMinhLoadKetQuaStart } from "../../action-types/diemvanminh-ketqua/IDiemVanMinhKetQuaActionTypes";
import { actions } from "../../actions/actionsWrapper";
import { lopApi } from './../../../api/lopApi';
import { IDiemVanMinhLoadHocSinhStart, IDiemVanMinhSaveStart } from './../../action-types/diemvanminh-ketqua/IDiemVanMinhKetQuaActionTypes';


export function* diemVanMinhKetQuaSaga(): any {
    yield takeLatest(eDiemVanMinhKetQuaActionTypeIds.LOAD_HOCSINH_START, loadHocSinhWorker)
    yield takeLatest(eDiemVanMinhKetQuaActionTypeIds.LOAD_KETQUA_START, loadKetQuaWorker)
    yield takeLatest(eDiemVanMinhKetQuaActionTypeIds.SAVE_START, saveWorker)
    yield takeLatest(eDiemVanMinhKetQuaActionTypeIds.DELETE_START, deleteWorker)
}

function* loadHocSinhWorker(action: IDiemVanMinhLoadHocSinhStart): any {

    const res: IBaseResponeModel = yield call([lopApi, lopApi.SelectHocSinhByLopsAsync], action.payload)
    if (res.is_success) {
        yield put(actions.diemVanMinhKetQua.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.diemVanMinhKetQua.loadHocSinhError(res.message || ""))
    }
}
function* loadKetQuaWorker(action: IDiemVanMinhLoadKetQuaStart): any {

    const res: IBaseResponeModel = yield call([diemVanMinhKetQuaApi, diemVanMinhKetQuaApi.Select], action.payload)
    if (res.is_success) {
        yield put(actions.diemVanMinhKetQua.loadKetQuaSuccess(res.data))
    } else {
        yield put(actions.diemVanMinhKetQua.loadKetQuaError(res.message || ""))
    }
}
function* deleteWorker(action: IDiemVanMinhDeleteStart): any {

    const res: IBaseResponeModel = yield call([diemVanMinhKetQuaApi, diemVanMinhKetQuaApi.Delete], action.payload)
    if (res.is_success) {
        yield put(actions.diemVanMinhKetQua.deleteSuccess())
    } else {
        yield put(actions.diemVanMinhKetQua.deleteError(res.message || ""))
    }
}

function* saveWorker(action: IDiemVanMinhSaveStart): any {
    if (action.payload.id <= 0) {
        const res: IBaseResponeModel = yield call([diemVanMinhKetQuaApi, diemVanMinhKetQuaApi.Insert], action.payload)
        if (res.is_success) {
            yield put(actions.diemVanMinhKetQua.saveSuccess(res.data))
        } else {
            yield put(actions.diemVanMinhKetQua.saveError(res.message || ""))
        }
    } else {
        const res: IBaseResponeModel = yield call([diemVanMinhKetQuaApi, diemVanMinhKetQuaApi.Update], action.payload)
        if (res.is_success) {
            yield put(actions.diemVanMinhKetQua.saveSuccess(res.data))
        } else {
            yield put(actions.diemVanMinhKetQua.saveError(res.message || ""))
        }
    }
}

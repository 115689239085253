import DataGrid, { Column, Editing, FilterPanel, FilterRow, HeaderFilter, Pager, Paging, Selection, Sorting, StateStoring } from "devextreme-react/data-grid";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce/lib";
import { RootState } from "../../state/reducers";
import { useCommonContext } from "../../contexts/common";
export type SelectionProps = {
    mode: "multiple" | "single",
    onSelectionChanged: (e: any) => void,
    selectedRowKeys?: number[]
}
export type DefaultDataGridProps = {
    keyExpr: string,
    dataSource: any,
    storageKey?: string,
    defaultPageSize?: number,
    columns?: Column[],
    height?: any,
    gridRef?: any,
    selection?: SelectionProps,
    isShowIdx?: boolean,
    isLoading?: boolean,
    isFocusedRowEnabled?: boolean,
    children?: React.ReactNode,
    rowAlternationEnabled?: boolean,
    showFilterPanel?: boolean
    wordWrapEnabled?: boolean,
    // isPagingDefault?: boolean,
    isEditing?: boolean,
    width?: any
    isFeedToRight?: boolean

}
const DefaultDataGrid = (props: DefaultDataGridProps) => {
    const { translate } = useCommonContext();
    const { language } = useSelector((x: RootState) => x.common);
    const isShowIdx = props.isShowIdx === undefined ? true : props.isShowIdx;
    const [isLoading] = useDebounce<boolean>(props.isLoading || false, 100);
    const { menu_mode } = useSelector((x: RootState) => x.common);

    const IdxCellRender = useCallback((cell: any): React.ReactNode => {
        return <span>{cell.rowIndex + 1}</span>
    }, [])
    useEffect(() => {

        function onWindowResize(event: any) {
            const element = props.gridRef.current;
            var x = element._element.getBoundingClientRect().x;
            props.gridRef.current.instance.option('width', window.innerWidth - x - 5);
        }
        if (props.gridRef && (props.isFeedToRight === true || props.isFeedToRight == undefined)) {

            // Bind the event listener
            window.addEventListener("resize", onWindowResize);
            return () => {
                // Unbind the event listener on clean up
                window.removeEventListener("resize", onWindowResize);
            };
        }
    }, [props.gridRef, props.isFeedToRight])
    useEffect(() => {
        if (props.gridRef && (props.isFeedToRight === true || props.isFeedToRight == undefined)) {
            const element = props.gridRef.current;
            var x = element._element.getBoundingClientRect().x;
            props.gridRef.current.instance.option('width', window.innerWidth - x - 5);
        }
    }, [props.isFeedToRight, props.width, menu_mode])
    return (
        <DataGrid
            ref={props.gridRef}
            keyExpr={props.keyExpr || 'id'}
            dataSource={props.dataSource}
            columnHidingEnabled={false}
            columnAutoWidth={true}
            showBorders={true}
            showColumnLines={true}
            showRowLines={true}
            rowAlternationEnabled={props.rowAlternationEnabled === undefined ? true : props.rowAlternationEnabled}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            hoverStateEnabled={true}
            focusedRowEnabled={props.isFocusedRowEnabled != undefined ? props.isFocusedRowEnabled : true}
            height={props.height || window.innerHeight - 150}
            // height={props.height || window.innerHeight - 120}
            width={props.width}
            className="table-noborder-left"
            noDataText={isLoading ? translate('Base.Label.Loading') : translate('Base.Label.NoData')}
            onSelectionChanged={props.selection ? props.selection.onSelectionChanged : () => { }}
            selectedRowKeys={(props.selection && props.selection.selectedRowKeys) ? props.selection.selectedRowKeys : undefined}
            wordWrapEnabled={props.wordWrapEnabled || false}
            allowColumnReordering={true}
            loadPanel={{ enabled: false }}
        >
            {/* <Scrolling mode="infinite" /> */}
            {props.selection && <Selection
                mode={props.selection.mode}
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
            />}
            {props.selection &&
                <Column type={"selection"} visibleIndex={1}></Column>}
            {props.storageKey && <StateStoring enabled={true} type={"localStorage"} storageKey={`${props.storageKey}`} />}
            <Sorting mode="multiple" />
            <Paging enabled={props.defaultPageSize} defaultPageSize={props.defaultPageSize ?? 50} />
            <FilterRow visible={true} />
            {props.showFilterPanel === true && <FilterPanel visible={true} />}
            <HeaderFilter visible={true} />
            <HeaderFilter visible={false} />
            {props.defaultPageSize && <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 500]}
                infoText={`${language==='en' ? 'Page' : 'Trang'} {0} /{1} ({2} ${language==='en' ? 'rows' : 'dòng'})`}
                showInfo={true} />}
            {isShowIdx && <Column visibleIndex={0} caption={"#"} cellRender={IdxCellRender} width={40} alignment="center" />}
            {props.isEditing && <Editing mode="cell" allowUpdating={true} allowAdding={false} allowDeleting={false} />}
            {props.columns ? props.columns : null}
            {props.children}

        </DataGrid>
    );
}
export { DefaultDataGrid };


import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eThaiDoHocTapActionTypeIds, IThaiDoHocTapDeleteStart, IThaiDoHocTapSaveStart, IThaiDoHocTapSelectStart } from "../../action-types/nhan-xet/IThaiDoHocTapActionTypes";
import { actions } from "../../actions/actionsWrapper";

const thaiDoHocTapApi = apiWrapper.thaiDoHocTapWrapper.thaiDoHocTap;

export function* thaiDoHocTapSaga(): any {
    yield takeLatest(eThaiDoHocTapActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eThaiDoHocTapActionTypeIds.SELECT_START, selectWorker)
    yield takeEvery(eThaiDoHocTapActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eThaiDoHocTapActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([thaiDoHocTapApi, thaiDoHocTapApi.SelectAll])
    if (res.is_success) {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTap.loadSuccess(res.data))
    } else {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTap.loadError(res.message || "Không tải được thái độ học tập"))
    }
}
function* selectWorker(action: IThaiDoHocTapSelectStart): any {
    const res: IBaseResponeModel = yield call([thaiDoHocTapApi, thaiDoHocTapApi.Select], action.payload)
    if (res.is_success) {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTap.selectSuccess(res.data))
    } else {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTap.selectError(res.message || "Không tải được thái độ học tập"))
    }
}
function* deleteWorker(action: IThaiDoHocTapDeleteStart): any {
    const res: IBaseResponeModel = yield call([thaiDoHocTapApi, thaiDoHocTapApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTap.deleteSuccess())
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTap.loadStart())
    } else {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTap.deleteError(res.message || "Không xóa được thái độ học tập"))
    }
}
function* saveWorker(action: IThaiDoHocTapSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([thaiDoHocTapApi, thaiDoHocTapApi.Update], action.payload);
    } else {
        res = yield call([thaiDoHocTapApi, thaiDoHocTapApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTap.saveSuccess())
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTap.loadStart())
    } else {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTap.saveError(res.message || "Không lưu được thái độ học tập"))
    }
}
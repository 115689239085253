import { eCategorySourceActionTypeIds, ICategorySourceActionTypes } from "../action-types/ICategorySourceActionTypes"
import { ICategorySourceState } from "../page-state-model/ICategorySourceState"

const initialState: ICategorySourceState = {
    dm_hes: [],
    dm_khois: [],
    dm_namhocs: [],
    dm_truongs: [],
    dm_truong_khoi_hes: [],
    dm_trangthaihocsinhs: [],
    
}

export const categorySourceReducer = (state: ICategorySourceState = initialState, action: ICategorySourceActionTypes): ICategorySourceState => {
    switch (action.type) {
        case eCategorySourceActionTypeIds.DM_HE_LOAD_SUCCESS:
            return {
                ...state,
                dm_hes: action.payload
            }
        case eCategorySourceActionTypeIds.DM_KHOI_LOAD_SUCCESS:
            return {
                ...state,
                dm_khois: action.payload
            }
        case eCategorySourceActionTypeIds.DM_NAMHOC_LOAD_SUCESS:
            return {
                ...state,
                dm_namhocs: action.payload
            }
        case eCategorySourceActionTypeIds.DM_TRUONG_LOAD_SUCCESS:
            return {
                ...state,
                dm_truongs: action.payload
            }
        case eCategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_SUCESS:
            return {
                ...state,
                dm_truong_khoi_hes: action.payload
            }
        case eCategorySourceActionTypeIds.DM_TRANGTHAIHOCSINH_LOAD_SUCCESS:
            return {
                ...state,
                dm_trangthaihocsinhs: action.payload
            }

        default:
            return state;
    }
}
import { NotifyHelper } from "../../../helpers/toast";
import { INamHocTruongKhoiHeFilter } from "../../../models/INamHocTruongKhoiHeFilter";
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { ILopChangeHocSinhClassRequest } from "../../../models/request/dm-lop/ILopChangeHocSinhClassRequest";
import { ILopChangeThongTuRequest } from "../../../models/request/dm-lop/ILopChangeThongTuRequest";
import { ILopChangeThuTuHocSinhRequest } from "../../../models/request/dm-lop/ILopChangeThuTuHocSinhRequest";
import { ILopChuNhiemRequest } from "../../../models/request/dm-lop/ILopChuNhiemRequest";
import { ILopLockParentAppHocSinhRequest } from "../../../models/request/dm-lop/ILopLockParentAppHocSinhRequest";
import { ILopRemoveHocSinhClassRequest } from "../../../models/request/dm-lop/ILopRemoveHocSinhClassRequest";
import { ILopSelectHocSinhRequest } from "../../../models/request/dm-lop/ILopSelectHocSinhRequest";
import { ILopImportPhanLopRequest } from "../../../models/request/dm-lop/LopImportPhanLopRequest";
import { IHocSinhSelectRequest } from "../../../models/request/hoc-sinh/IHocSinhSelectRequest";
import { ILopImportPhanLopResponse } from "../../../models/response/dm-lop/LopImportPhanLopResponse";
import { ILopTreeViewItemResponse } from "../../../models/response/dm-lop/LopTreeViewItem";
import { dm_lop, dm_lop_viewmodel } from "../../../models/response/dm-lop/dm_lop";
import { IHocSinhChuaPhanLopResponse } from "../../../models/response/hoc-sinh/IHocSinhChuaPhanLopResponse";
import { IHocSinhSelectResponeItem } from "../../../models/response/hoc-sinh/IHocSinhSelectRespone";
import { IPhuHuynhItemRespone } from "../../../models/response/phu-huynh/IPhuHuynhItemRespone";
import { ILopImportDsLopRequest } from "../../../models/request/dm-lop/LopImportDsLopRequest";
import { ILopImportDsLopResponse } from "../../../models/response/dm-lop/LopImportDsLopResponse";
import { ILopChangeHocSinhError, ILopChangeHocSinhStart, ILopChangeHocSinhSuccess, 
    ILopChangeHocSinhThuTuError, ILopChangeHocSinhThuTuStart, ILopChangeHocSinhThuTuSuccess, 
    ILopChangeSelectedIds, ILopChangeThongTuError, ILopChangeThongTuStart, ILopChangeThongTuSuccess, 
    ILopChangeThuTuMacDinhError, ILopChangeThuTuMacDinhStart, ILopChangeThuTuMacDinhSuccess, 
    ILopCloseConfirm, ILopCloseDetail, ILopDeleteErr, ILopDeleteStart, ILopDeleteSuccess, ILopLoadAllError, 
    ILopLoadAllStart, ILopLoadAllSuccess, ILopLoadError, ILopLoadStart, ILopLoadSuccess, 
    ILopRemoveHocSinhError, ILopRemoveHocSinhStart, ILopRemoveHocSinhSuccess, ILopSaveErr, 
    ILopSaveStart, ILopSaveSuccess, ILopSelectDetailError, 
    ILopSelectDetailStart, ILopSelectDetailSuccess, ILopSelectError, 
    ILopSelectHocSinhChuaPhanLopError, ILopSelectHocSinhChuaPhanLopStart, ILopSelectHocSinhChuaPhanLopSuccess, 
    ILopSelectHocSinhError, ILopSelectHocSinhStart, ILopSelectHocSinhSuccess, 
    ILopSelectLopChuNhiemError, ILopSelectLopChuNhiemStart, ILopSelectLopChuNhiemSuccess, 
    ILopSelectStart, ILopSelectSuccess, ILopShowConfirm, ILopShowDetail, 
    ILopTreeCteLoadError, ILopTreeCteLoadStart, ILopTreeCteLoadSuccess, 
    ILopUploadExcelErrorAction, ILopUploadExcelStartAction, ILopUploadExcelSuccessAction, 
    ILopSaveExcelErrorAction, ILopSaveExcelStartAction, ILopSaveExcelSuccessAction, 
    ILopUploadLopExcelErrorAction, ILopUploadLopExcelStartAction, ILopUploadLopExcelSuccessAction, 
    ILopSaveLopExcelErrorAction, ILopSaveLopExcelStartAction, ILopSaveLopExcelSuccessAction, 
    eLopActionTypeIds } from "../../action-types/danh-muc/ILopActionTypes";
import { baseAction } from "../IActionBase";
import { BaseAction } from "../IBaseActionResult";

export const lopActions = {
    loadStart: (nam_hoc: string, dm_truong_id: number, theo_truong?: boolean): ILopLoadStart => BaseAction({
        nam_hoc,
        dm_he_id: 0,
        dm_khoi_id: 0,
        dm_truong_id,
        theo_truong: theo_truong
    }, eLopActionTypeIds.LOAD_START),
    loadSuccess: (respone: dm_lop_viewmodel[]): ILopLoadSuccess => BaseAction(respone, eLopActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): ILopLoadError => BaseAction(messege, eLopActionTypeIds.LOAD_ERROR),

    loadAllStart: (): ILopLoadAllStart => BaseAction(undefined, eLopActionTypeIds.LOAD_ALL_START),
    loadAllSuccess: (respone: dm_lop_viewmodel[]): ILopLoadAllSuccess => BaseAction(respone, eLopActionTypeIds.LOAD_ALL_SUCCESS),
    loadAllError: (messege: string): ILopLoadAllError => BaseAction(messege, eLopActionTypeIds.LOAD_ALL_ERROR),

    loadTreeCteStart: (request: INamHocTruongKhoiHeFilter): ILopTreeCteLoadStart => BaseAction(request, eLopActionTypeIds.LOAD_TREE_CTE_START),
    loadTreeCteSuccess: (respone: ILopTreeViewItemResponse[]): ILopTreeCteLoadSuccess => BaseAction(respone, eLopActionTypeIds.LOAD_TREE_CTE_SUCCESS),
    loadTreeCteError: (messege: string): ILopTreeCteLoadError => BaseAction(messege, eLopActionTypeIds.LOAD_TREE_CTE_ERROR),

    selectLopChuNhiemStart: (request: ILopChuNhiemRequest): ILopSelectLopChuNhiemStart => BaseAction(request, eLopActionTypeIds.SELECT_LOPCHUNHIEM_START),
    selectLopChuNhiemSuccess: (respone: dm_lop_viewmodel): ILopSelectLopChuNhiemSuccess => BaseAction(respone, eLopActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS),
    selectLopChuNhiemError: (messege: string): ILopSelectLopChuNhiemError => BaseAction(messege, eLopActionTypeIds.SELECT_LOPCHUNHIEM_ERROR),

    selectStart: (request: INamHocTruongKhoiHeFilter): ILopSelectStart => BaseAction(request, eLopActionTypeIds.SELECT_START),
    selectSuccess: (respone: dm_lop_viewmodel[]): ILopSelectSuccess => BaseAction(respone, eLopActionTypeIds.SELECT_SUCCESS),
    selectError: (messege: string): ILopSelectError => BaseAction(messege, eLopActionTypeIds.SELECT_ERROR),

    selectDetailStart: (id: number): ILopSelectDetailStart => BaseAction(id, eLopActionTypeIds.SELECT_DETAIL_START),
    selectDetailSuccess: (respone?: dm_lop): ILopSelectDetailSuccess => BaseAction(respone, eLopActionTypeIds.SELECT_DETAIL_SUCCESS),
    selectDetailError: (messege: string): ILopSelectDetailError => BaseAction(messege, eLopActionTypeIds.SELECT_DETAIL_ERROR),

    showDetail: (data?: dm_lop_viewmodel): ILopShowDetail => BaseAction(data, eLopActionTypeIds.SHOW_DETAIL),
    closeDetail: (): ILopCloseDetail => BaseAction(undefined, eLopActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): ILopShowConfirm => BaseAction(undefined, eLopActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): ILopCloseConfirm => BaseAction(undefined, eLopActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): ILopChangeSelectedIds => BaseAction(ids, eLopActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): ILopDeleteStart => BaseAction(ids, eLopActionTypeIds.DELETE_START),
    deleteSuccess: (): ILopDeleteSuccess => BaseAction(undefined, eLopActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): ILopDeleteErr => BaseAction(message, eLopActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: dm_lop): ILopSaveStart => BaseAction(data, eLopActionTypeIds.SAVE_START),
    saveSuccess: (): ILopSaveSuccess => BaseAction(undefined, eLopActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): ILopSaveErr => BaseAction(message, eLopActionTypeIds.SAVE_ERR),

    changeThongTuStart: (request: ILopChangeThongTuRequest): ILopChangeThongTuStart => BaseAction(request, eLopActionTypeIds.CHANGE_THONGTU_START),
    changeThongTuSuccess: (): ILopChangeThongTuSuccess => BaseAction(undefined, eLopActionTypeIds.CHANGE_THONGTU_SUCCESS),
    changeThongTuError: (messege: string): ILopChangeThongTuError => BaseAction(messege, eLopActionTypeIds.CHANGE_THONGTU_ERROR),

    selectHocSinhStart: (request: ILopSelectHocSinhRequest): ILopSelectHocSinhStart => BaseAction(request, eLopActionTypeIds.SELECT_HOCSINH_START),
    selectHocSinhSuccess: (respone: IHocSinhSelectResponeItem[]): ILopSelectHocSinhSuccess => BaseAction(respone, eLopActionTypeIds.SELECT_HOCSINH_SUCCESS),
    selectHocSinhError: (messege: string): ILopSelectHocSinhError => BaseAction(messege, eLopActionTypeIds.SELECT_HOCSINH_ERROR),

    selectHocSinhChuaPhanLopStart: (request: IHocSinhSelectRequest): ILopSelectHocSinhChuaPhanLopStart => BaseAction(request, eLopActionTypeIds.SELECT_HOCSINH_CHUAPHANLOP_START),
    selectHocSinhChuaPhanLopSuccess: (respone: IHocSinhChuaPhanLopResponse[]): ILopSelectHocSinhChuaPhanLopSuccess => BaseAction(respone, eLopActionTypeIds.SELECT_HOCSINH_CHUAPHANLOP_SUCCESS),
    selectHocSinhChuaPhanLopError: (messege: string): ILopSelectHocSinhChuaPhanLopError => BaseAction(messege, eLopActionTypeIds.SELECT_HOCSINH_CHUAPHANLOP_ERROR),

    changeHocSinhStart: (request: ILopChangeHocSinhClassRequest): ILopChangeHocSinhStart => BaseAction(request, eLopActionTypeIds.CHANGE_HOCSINH_START),
    changeHocSinhSuccess: (): ILopChangeHocSinhSuccess => BaseAction(undefined, eLopActionTypeIds.CHANGE_HOCSINH_SUCCESS),
    changeHocSinhError: (messege: string): ILopChangeHocSinhError => BaseAction(messege, eLopActionTypeIds.CHANGE_HOCSINH_ERROR),

    changeHocSinhThuTuStart: (request: ILopChangeThuTuHocSinhRequest): ILopChangeHocSinhThuTuStart => BaseAction(request, eLopActionTypeIds.CHANGE_HOCSINH_THUTU_START),
    changeHocSinhThuTuSuccess: (): ILopChangeHocSinhThuTuSuccess => BaseAction(undefined, eLopActionTypeIds.CHANGE_HOCSINH_THUTU_SUCCESS),
    changeHocSinhThuTuError: (messege: string): ILopChangeHocSinhThuTuError => BaseAction(messege, eLopActionTypeIds.CHANGE_HOCSINH_THUTU_ERROR),

    changeThuTuMacDinhStart: (dm_lop_id: number): ILopChangeThuTuMacDinhStart => BaseAction(dm_lop_id, eLopActionTypeIds.CHANGE_THUTU_MACDINH_START),
    changeThuTuMacDinhSuccess: (): ILopChangeThuTuMacDinhSuccess => BaseAction(undefined, eLopActionTypeIds.CHANGE_THUTU_MACDINH_SUCCESS),
    changeThuTuMacDinhError: (messege: string): ILopChangeThuTuMacDinhError => BaseAction(messege, eLopActionTypeIds.CHANGE_THUTU_MACDINH_ERROR),
    
    removeHocSinhStart: (request: ILopRemoveHocSinhClassRequest): ILopRemoveHocSinhStart => BaseAction(request, eLopActionTypeIds.REMOVE_HOCSINH_START),
    removeHocSinhSuccess: (): ILopRemoveHocSinhSuccess => BaseAction(undefined, eLopActionTypeIds.REMOVE_HOCSINH_SUCCESS),
    removeHocSinhError: (messege: string): ILopRemoveHocSinhError => BaseAction(messege, eLopActionTypeIds.REMOVE_HOCSINH_ERROR),

    loadFromExcelStart: (data: FormData): ILopUploadExcelStartAction => BaseAction(data, eLopActionTypeIds.UPLOAD_HOCSINH_EXCEL_START),
    loadFromExcelSuccess: (respone: ILopImportPhanLopResponse): ILopUploadExcelSuccessAction => BaseAction(respone, eLopActionTypeIds.UPLOAD_HOCSINH_EXCEL_SUCCESS),
    loadFromExcelError: (message: string): ILopUploadExcelErrorAction => BaseAction(message, eLopActionTypeIds.UPLOAD_HOCSINH_EXCEL_ERROR),

    saveFromExcelStart: (data: ILopImportPhanLopRequest): ILopSaveExcelStartAction => BaseAction(data, eLopActionTypeIds.SAVE_HOCSINH_EXCEL_START),
    saveFromExcelSuccess: (respone: ILopImportPhanLopResponse): ILopSaveExcelSuccessAction => BaseAction(respone, eLopActionTypeIds.SAVE_HOCSINH_EXCEL_SUCCESS),
    saveFromExcelError: (message: string): ILopSaveExcelErrorAction => BaseAction(message, eLopActionTypeIds.SAVE_HOCSINH_EXCEL_ERROR),

    lockParentAppStart: (data: ILopLockParentAppHocSinhRequest) => baseAction(eLopActionTypeIds.LOCK_PARENT_APP_START, data),
    lockParentAppSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eLopActionTypeIds.LOCK_PARENT_APP_SUCCESS, undefined)
    },
    lockParentAppError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopActionTypeIds.LOCK_PARENT_APP_ERROR, message)
    },

    selectPhuHuynhStart: (request: ILopSelectHocSinhRequest) => baseAction(eLopActionTypeIds.LOAD_PHUHUYNH_START, request),
    selectPhuHuynhSuccess: (respone: IPhuHuynhItemRespone[]) => baseAction(eLopActionTypeIds.LOAD_PHUHUYNH_SUCCESS, respone),
    selectPhuHuynhError: (messege: string) => {
        return baseAction(eLopActionTypeIds.LOAD_PHUHUYNH_SUCCESS, messege)
    },
    
    loadLopFromExcelStart: (data: FormData): ILopUploadLopExcelStartAction => BaseAction(data, eLopActionTypeIds.UPLOAD_LOP_EXCEL_START),
    loadLopFromExcelSuccess: (respone: ILopImportDsLopResponse): ILopUploadLopExcelSuccessAction => BaseAction(respone, eLopActionTypeIds.UPLOAD_LOP_EXCEL_SUCCESS),
    loadLopFromExcelError: (message: string): ILopUploadLopExcelErrorAction => BaseAction(message, eLopActionTypeIds.UPLOAD_LOP_EXCEL_ERROR),

    saveLopFromExcelStart: (data: ILopImportDsLopRequest): ILopSaveLopExcelStartAction => BaseAction(data, eLopActionTypeIds.SAVE_LOP_EXCEL_START),
    saveLopFromExcelSuccess: (respone: ILopImportDsLopResponse): ILopSaveLopExcelSuccessAction => BaseAction(respone, eLopActionTypeIds.SAVE_LOP_EXCEL_SUCCESS),
    saveLopFromExcelError: (message: string): ILopSaveLopExcelErrorAction => BaseAction(message, eLopActionTypeIds.SAVE_LOP_EXCEL_ERROR),

}
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../api/apiWrapper";
import { IBaseResponeModel } from "../../models/response/base-response";
import { eGiaoVienNhanXetActionTypeIds, IGiaoVienNhanXetDeleteStart, IGiaoVienNhanXetSaveStart } from "../action-types/IGiaoVienNhanXetActionTypes";
import { actions } from "../actions/actionsWrapper";

const giaoVienNhanXetApi = apiWrapper.giaoVienNhanXet;

export function* giaoVienNhanXetSaga(): any {
    yield takeLatest(eGiaoVienNhanXetActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eGiaoVienNhanXetActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eGiaoVienNhanXetActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([giaoVienNhanXetApi, giaoVienNhanXetApi.SelectAll])
    if (res.is_success) {
        yield put(actions.giaoVienNhanXet.loadSuccess(res.data))
    } else {
        yield put(actions.giaoVienNhanXet.loadError(res.message || "Không tải được danh mục nhận xét"))
    }
}
function* deleteWorker(action: IGiaoVienNhanXetDeleteStart): any {
    const res: IBaseResponeModel = yield call([giaoVienNhanXetApi, giaoVienNhanXetApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.giaoVienNhanXet.deleteSuccess())
        yield put(actions.giaoVienNhanXet.loadStart())
    } else {
        yield put(actions.giaoVienNhanXet.deleteErr(res.message || "Không xóa được danh mục nhận xét"))
    }
}
function* saveWorker(action: IGiaoVienNhanXetSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([giaoVienNhanXetApi, giaoVienNhanXetApi.Update], action.payload);
    } else {
        res = yield call([giaoVienNhanXetApi, giaoVienNhanXetApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.giaoVienNhanXet.saveSuccess())
        yield put(actions.giaoVienNhanXet.loadStart())
    } else {
        yield put(actions.giaoVienNhanXet.saveError(res.message || "Không lưu được danh mục nhận xét"))
    }
}

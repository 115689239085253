import { IDictionaryModel } from "../../models/response/dictionary";
import { eDictionaryActionTypeIds, ILoadDictionaryStart, ILoadDictionarySuccess } from "../action-types/IDictionaryActionTypes";

export const dictionaryActions = {
    loadDictionary: (): ILoadDictionaryStart => {
        return {
            type: eDictionaryActionTypeIds.LOAD_DICTIONARY_START
        }
    },
    loadDictionarySuccess: (data: IDictionaryModel[]): ILoadDictionarySuccess => {
        return {
            type: eDictionaryActionTypeIds.LOAD_DICTIONARY_SUCCESS,
            payload: data
        }
    }
}
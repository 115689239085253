import { pl_lopbomon } from './../../../models/response/lop-bo-mon/pl_lopbomon';
import { ILopBoMonChangeHocSinhClassRequest } from "./../../../models/request/lop-bo-mon/ILopBoMonChangeHocSinhClassRequest";
import { ILopBoMonSelectRequest } from './../../../models/request/lop-bo-mon/ILopBoMonSelectRequest';
import { ILopBoMonTreeViewItemRespone } from "../../../models/response/lop-bo-mon/LopBoMonTreeViewItem"
import { ILopBoMonGenerateRequest } from '../../../models/request/lop-bo-mon/ILopBoMonGenerateRequest';
import { IActionTypeBase } from '../IActionTypeBase';
import { IDeleteMultipleBaseRequest } from '../../../models/request/base/deleteRequest';


export enum eLopBoMonListActionTypeIds {
    LOAD_START = "LOPBOMON_LIST_LOAD_START",
    LOAD_SUCCESS = "LOPBOMON_LIST_LOAD_SUCCESS",
    LOAD_ERROR = "LOPBOMON_LIST_LAOD_ERROR",

    SHOW_DETAIL = "LOPBOMON_LIST_SHOW_DETAIL",
    CLOSE_DETAIL = "LOPBOMON_LIST_CLOSE_DETAIL",

    SAVE_START = "LOPBOMON_LIST_SAVE_START",
    SAVE_SUCCESS = "LOPBOMON_LIST_SAVE_SUCCESS",
    SAVE_ERR = "LOPBOMON_LIST_SAVE_ERR",

    SHOW_GENERATE = "LOPBOMON_LIST_SHOW_GENERATE",
    CLOSE_GENERATE = "LOPBOMON_LIST_CLOSE_GENERATE",

    GENERATE_START = "LOPBOMON_LIST_GENERATE_START",
    GENERATE_SUCCESS = "LOPBOMON_LIST_GENERATE_SUCCESS",
    GENERATE_ERROR = "LOPBOMON_LIST_GENERATE_ERROR",

    CHANGE_SELECTED_IDS = "LOPBOMON_LIST_CHANGE_SELECTED_IDS",

    SHOW_PHANCONG_MODAL = "LOPBOMON_LIST_SHOW_PHANCONG_MODAL",
    CLOSE_PHANCONG_MODAL = "LOPBOMON_LIST_CLOSE_PHANCONG_MODAL",

    SHOW_DELETE_MODAL = "LOPBOMON_LIST_SHOW_DELETE_MODAL",
    CLOSE_DELETE_MODAL = "LOPBOMON_LIST_CLOSE_DELETE_MODAL",

    DELETE_START = "LOPBOMON_LIST_DELETE_START",
    DELETE_SUCCESS = "LOPBOMON_LIST_DELETE_SUCCESS",
    DELETE_ERROR = "LOPBOMON_LIST_DELETE_ERROR",

    CHANGE_HOCSINH_START = "LOPBOMON_CHANGE_HOCSINH_START",
    CHANGE_HOCSINH_SUCCESS = "LOPBOMON_CHANGE_HOCSINH_SUCCESS",
    CHANGE_HOCSINH_ERROR = "LOPBOMON_CHANGE_HOCSINH_ERROR",

}

export interface ILopBoMonListLoadStart {
    type: eLopBoMonListActionTypeIds.LOAD_START,
    payload: ILopBoMonSelectRequest
}
export interface ILopBoMonListLoadSuccess {
    type: eLopBoMonListActionTypeIds.LOAD_SUCCESS,
    payload: ILopBoMonTreeViewItemRespone[]
}
export interface ILopBoMonListLoadErr {
    type: eLopBoMonListActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface ILopBoMonListShowDetail {
    type: eLopBoMonListActionTypeIds.SHOW_DETAIL,
    payload: number
}
export interface ILopBoMonListCloseDetail {
    type: eLopBoMonListActionTypeIds.CLOSE_DETAIL
}

export interface ILopBoMonListSaveStart {
    type: eLopBoMonListActionTypeIds.SAVE_START,
    payload: pl_lopbomon
}
export interface ILopBoMonListSaveSuccess {
    type: eLopBoMonListActionTypeIds.SAVE_SUCCESS
}
export interface ILopBoMonListSaveErr {
    type: eLopBoMonListActionTypeIds.SAVE_ERR,
    payload: string
}

export interface ILopBoMonShowGenerate {
    type: eLopBoMonListActionTypeIds.SHOW_GENERATE
}
export interface ILopBoMonCloseGenerate {
    type: eLopBoMonListActionTypeIds.CLOSE_GENERATE
}

export interface ILopBoMonGenerateStart {
    type: eLopBoMonListActionTypeIds.GENERATE_START,
    payload: ILopBoMonGenerateRequest
}
export interface ILopBoMonGenerateSuccess {
    type: eLopBoMonListActionTypeIds.GENERATE_SUCCESS
}
export interface ILopBoMonGenerateError {
    type: eLopBoMonListActionTypeIds.GENERATE_ERROR,
    payload: string
}

export interface ILopBoMonChangeSelectedIds {
    type: eLopBoMonListActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}

export interface ILopBoMonShowPhanCongModal extends IActionTypeBase<eLopBoMonListActionTypeIds.SHOW_PHANCONG_MODAL, undefined> { }
export interface ILopBoMonClosePhanCongModal extends IActionTypeBase<eLopBoMonListActionTypeIds.CLOSE_PHANCONG_MODAL, undefined> { }

export interface ILopBoMonShowDeleteModal extends IActionTypeBase<eLopBoMonListActionTypeIds.SHOW_DELETE_MODAL, undefined> { }
export interface ILopBoMonCloseDeleteModal extends IActionTypeBase<eLopBoMonListActionTypeIds.CLOSE_DELETE_MODAL, undefined> { }



export interface ILopBoMonDeleteStart {
    type: eLopBoMonListActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface ILopBoMonDeleteSuccess {
    type: eLopBoMonListActionTypeIds.DELETE_SUCCESS
}
export interface ILopBoMonDeleteError {
    type: eLopBoMonListActionTypeIds.DELETE_ERROR,
    payload: string
}

export type ILopBoMonListActionTypes = ILopBoMonListLoadStart | ILopBoMonListLoadSuccess | ILopBoMonListLoadErr |
    ILopBoMonListShowDetail | ILopBoMonListCloseDetail |
    ILopBoMonListSaveStart | ILopBoMonListSaveSuccess | ILopBoMonListSaveErr |
    ILopBoMonShowGenerate | ILopBoMonCloseGenerate |
    ILopBoMonGenerateStart | ILopBoMonGenerateSuccess | ILopBoMonGenerateError |
    ILopBoMonChangeSelectedIds |
    ILopBoMonShowPhanCongModal | ILopBoMonClosePhanCongModal |
    ILopBoMonShowDeleteModal | ILopBoMonCloseDeleteModal |
    ILopBoMonDeleteStart | ILopBoMonDeleteSuccess | ILopBoMonDeleteError



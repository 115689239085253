import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../../models/response/base-response";
import { IDgdkNlpcDmLoadStart, IDgdkNlpcHocSinhLoadStart } from '../../../action-types/danh-gia/dinh-ky/IDgdkNlpcActionType';
import { actions } from '../../../actions/actionsWrapper';
import { eDgtxKetQuaActionTypeIds, IDgtxKetQuaLoadBaoCaoThuongXuyenError, IDgtxKetQuaLoadKetQuaStart, IDgtxKetQuaSaveKetQuaStart, IDgtxKetQuaLoadBaoCaoThuongXuyenStart, IDgtxNlpcValidateStart, IDgtxNlpcImportStart } from './../../../action-types/danh-gia/thuong-xuyen/IDgtxKetQuaActionType';
import { baoCaoThuongXuyenApi } from "../../../../api/baoCaoThuongXuyenApi";

const hocSinhApi = apiWrapper.hocSinh;
const dgdkDmPhamChatApi = apiWrapper.soNhanXetItem;
const dgtxKetQuaApi = apiWrapper.danhGia.thuongXuyen.nlpc;

export function* dgtxKetQuaSaga(): any {
    yield takeLatest(eDgtxKetQuaActionTypeIds.HOCSINH_LOAD_START, loadHocSinhWorker)
    yield takeLatest(eDgtxKetQuaActionTypeIds.SONHANXET_ITEM_LOAD_START, loadDmNLPCWorker)
    yield takeLatest(eDgtxKetQuaActionTypeIds.KETQUA_LOAD_START, loadKetQuaWorker)
    yield takeEvery(eDgtxKetQuaActionTypeIds.KETQUA_SAVE_START, saveKetQuaWorker)
    yield takeEvery(eDgtxKetQuaActionTypeIds.BCTX_LOAD_START, loadBaoCaoThuongXuyens)
    yield takeEvery(eDgtxKetQuaActionTypeIds.VALIDATE_IMPORT_START, validateImportWorker)
    yield takeEvery(eDgtxKetQuaActionTypeIds.IMPORT_START, importWorker)
}

function* loadHocSinhWorker(action: IDgdkNlpcHocSinhLoadStart): any {
    const res: IBaseResponeModel = yield call(hocSinhApi.Select, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.nlpc.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.danhGia.thuongXuyen.nlpc.loadHocSinhError(res.message ?? ""))
    }
}

function* loadDmNLPCWorker(action: IDgdkNlpcDmLoadStart): any {
    const res: IBaseResponeModel = yield call([dgdkDmPhamChatApi, dgdkDmPhamChatApi.SelectBySet], action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.nlpc.loadSoNhanXetItemsSuccess(res.data))
    } else {
        yield put(actions.danhGia.thuongXuyen.nlpc.loadSoNhanXetItemsError(res.message ?? ""))
    }
}

function* loadKetQuaWorker(action: IDgtxKetQuaLoadKetQuaStart): any {
    const res: IBaseResponeModel = yield call(dgtxKetQuaApi.Select, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.nlpc.loadKetQuaSuccess(res.data))
    } else {
        yield put(actions.danhGia.thuongXuyen.nlpc.loadKetQuaError(res.message ?? ""))
    }
}

function* loadBaoCaoThuongXuyens(action: IDgtxKetQuaLoadBaoCaoThuongXuyenStart): any {
    const res: IBaseResponeModel = yield call(baoCaoThuongXuyenApi.Select, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.nlpc.loadBaoCaoThuongXuyensSuccess(res.data))
    } else {
        yield put(actions.danhGia.thuongXuyen.nlpc.loadBaoCaoThuongXuyensError(res.message ?? ""))
    }
}

function* saveKetQuaWorker(action: IDgtxKetQuaSaveKetQuaStart): any {
    const res: IBaseResponeModel = yield call(dgtxKetQuaApi.Save, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.nlpc.saveKetQuaSuccess(res.data))
    } else {
        yield put(actions.danhGia.thuongXuyen.nlpc.saveKetQuaError(res.message ?? ""))
    }
}

function* validateImportWorker(action: IDgtxNlpcValidateStart): any {
    const res: IBaseResponeModel = yield call(dgtxKetQuaApi.ValidateImport, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.nlpc.validateImportSuccess(res.data))
    } else {
        yield put(actions.danhGia.thuongXuyen.nlpc.validateImportError(res.message ?? ""))
    }
}

function* importWorker(action: IDgtxNlpcImportStart): any {
    const res: IBaseResponeModel = yield call(dgtxKetQuaApi.Import, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.nlpc.importSuccess())
    } else {
        yield put(actions.danhGia.thuongXuyen.nlpc.importError(res.message ?? ""))
    }
}

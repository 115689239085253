import { soDiemMappingActions } from './soDIemMappingAction';
import { monHocSoDiemActions } from './monHocSoDiemActionTypes';
import { giaiDoanActions } from './giaiDoanActions';
import { nhomThanhPhanDiemActions } from './nhomThanhPhanDiemActions';
import { soDiemConfigActions } from './soDiemConfigActions';
import { soDiemListActions } from './soDiemListActions';
import { thangDiemActions } from './thangDiemActions';
import { thanhPhanDiemActions } from './thanhPhanDiemActions';
export const soDiemActionsWrapper = {
    soDiemList: soDiemListActions,
    soDiemConfig: soDiemConfigActions,
    thanhPhanDiem: thanhPhanDiemActions,
    nhomThanhPhanDiem: nhomThanhPhanDiemActions,
    giaiDoan: giaiDoanActions,
    thangDiem: thangDiemActions,
    monHocSoDiem: monHocSoDiemActions,
    soDiemMapping: soDiemMappingActions
}
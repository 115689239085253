
import { dm_he } from "../../models/response/category/dm_he";
import { dm_khoi } from "../../models/response/category/dm_khoi";
import { dm_namhoc } from "../../models/response/category/dm_namhoc";
import { dm_truong } from "../../models/response/category/dm_truong";
import { dm_truong_khoi_he } from "../../models/response/category/dm_truong_khoi_he";
import { dm_trangthaihocsinh } from "../../models/response/category/dm_trangthaihocsinh";
import {
    eCategorySourceActionTypeIds, IHeLoadStart, IHeLoadSuccess,
    IKhoiLoadStart, IKhoiLoadSuccess, INamHocLoadStart,
    ITrangThaiHocSinhLoadStart, ITrangThaiHocSinhLoadSuccess,
    INamHocLoadSuccess, ITruongKhoiHeLoadStart, ITruongKhoiHeLoadSuccess, ITruongLoadStart, ITruongLoadSuccess
} from "../action-types/ICategorySourceActionTypes";

export const categorySourceActions = {

    loadDmTruongStart: (): ITruongLoadStart => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRUONG_LOAD_START
        }
    },
    loadDmTruongSuccess: (payload: dm_truong[]): ITruongLoadSuccess => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRUONG_LOAD_SUCCESS,
            payload: payload
        }
    },
    loadHeStart: (): IHeLoadStart => {
        return {
            type: eCategorySourceActionTypeIds.DM_HE_LOAD_START
        }
    },
    loadHeSuccess: (payload: dm_he[]): IHeLoadSuccess => {
        return {
            type: eCategorySourceActionTypeIds.DM_HE_LOAD_SUCCESS,
            payload: payload
        }
    },
    loadKhoiStart: (): IKhoiLoadStart => {
        return {
            type: eCategorySourceActionTypeIds.DM_KHOI_LOAD_START
        }
    },
    loadKhoiSucess: (payload: dm_khoi[]): IKhoiLoadSuccess => {
        return {
            type: eCategorySourceActionTypeIds.DM_KHOI_LOAD_SUCCESS,
            payload: payload
        }
    },
    
    loadTrangThaiHocSinhStart: (dm_truong_id: number): ITrangThaiHocSinhLoadStart => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRANGTHAIHOCSINH_LOAD_START,
            payload: dm_truong_id
        }
    },
    loadTrangThaiHocSinhSucess: (payload: dm_trangthaihocsinh[]): ITrangThaiHocSinhLoadSuccess => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRANGTHAIHOCSINH_LOAD_SUCCESS,
            payload: payload
        }
    },

    loadNamHocStart: (): INamHocLoadStart => {
        return {
            type: eCategorySourceActionTypeIds.DM_NAMHOC_LOAD_START
        }
    },
    loadNamHocSuccess: (payload: dm_namhoc[]): INamHocLoadSuccess => {
        return {
            type: eCategorySourceActionTypeIds.DM_NAMHOC_LOAD_SUCESS,
            payload: payload
        }
    },

    loadDmTruongKhoiHeStart: (): ITruongKhoiHeLoadStart => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_START
        }
    },
    loadDmTruongKhoiHeSuccess: (payload: dm_truong_khoi_he[]): ITruongKhoiHeLoadSuccess => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_SUCESS,
            payload: payload
        }
    }


}

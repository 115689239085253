import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eAppAccountActionTypeIds, IAppAccountActionTypes } from "../../action-types/app-phuhuynh/IAppAccountActionTypes"
import { IAppAccountPageState } from "../../page-state-model/app-phuhuynh/IAppAccountPageState"

const initialState: IAppAccountPageState = {
    status: ePageBaseStatus.is_not_initialization,
    app_accounts_parent: [],
    app_account_selected_ids: [],
    is_show_update_confirm: false,
    is_show_update_reset: false
}

export const appAccountReducer = (state: IAppAccountPageState = initialState, action: IAppAccountActionTypes): IAppAccountPageState => {
    switch (action.type) {
        case eAppAccountActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppAccountActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_accounts_parent: action.payload
            }
        case eAppAccountActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_accounts_parent: []
            }
        case eAppAccountActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                app_account_selected_ids: action.payload
            }
        case eAppAccountActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_update_confirm: true
            }
        case eAppAccountActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_update_confirm: false
            }

        case eAppAccountActionTypeIds.SHOW_RESET:
            return {
                ...state,
                is_show_update_reset: true
            }
        case eAppAccountActionTypeIds.CLOSE_RESET:
            return {
                ...state,
                is_show_update_reset: false
            }

        case eAppAccountActionTypeIds.UPDATE_STATUS_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eAppAccountActionTypeIds.UPDATE_STATUS_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_update_confirm: false,
                app_account_selected_ids: []
            }
        case eAppAccountActionTypeIds.UPDATE_STATUS_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_update_confirm: false
            }
        case eAppAccountActionTypeIds.CHANGE_PASSWORD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eAppAccountActionTypeIds.CHANGE_PASSWORD_SUCCESS:
            NotifyHelper.Success("Cập nhật mật khẩu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_update_reset: false,
                app_account_selected_ids: []
            }
        case eAppAccountActionTypeIds.CHANGE_PASSWORD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_update_reset: false
            }
        default:
            return state;
    }

}
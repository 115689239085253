import { lopBoMonHocSinhListReducer } from './lopBoMonHocSinhReducer';
import { lopBoMonListReducer } from './lopBoMonListReducer';
import { combineReducers } from 'redux';
import { lopBoMonSoDiemReducer } from './lopBoMonSoDiemReducer';
import { lopBoMonTypeReducer } from './lopBoMonTypeReducer';
export const lopBoMonReducer = combineReducers({
    lopBoMonList: lopBoMonListReducer,
    lopBoMonHocSinhList: lopBoMonHocSinhListReducer,
    lopBoMonSoDiem: lopBoMonSoDiemReducer,
    lopBoMonType: lopBoMonTypeReducer
})
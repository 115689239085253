import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_form_khaosat } from "../../../models/response/form-khaosat/sis_form_khaosat";
import {
    IFormKhaoSatChangeDetail,
    IFormKhaoSatChangeSelectedIds,
    IFormKhaoSatDeleteErr, IFormKhaoSatDeleteStart, IFormKhaoSatDeleteSuccess,
    IFormKhaoSatLoadByTruongErr, IFormKhaoSatLoadByTruongStart, IFormKhaoSatLoadByTruongSuccess, IFormKhaoSatLoadErr,
    IFormKhaoSatLoadStart, IFormKhaoSatLoadSuccess, IFormKhaoSatShowConfirm,
    IFormKhaoSatShowModal,
    eFormKhaoSatActionTypeIds
} from "../../action-types/form-khaosat/IFormKhaoSatActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const formKhaoSatActions = {
    //ALL
    loadStart: (): IFormKhaoSatLoadStart => BaseAction(undefined, eFormKhaoSatActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_form_khaosat[]): IFormKhaoSatLoadSuccess => BaseAction(respone, eFormKhaoSatActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IFormKhaoSatLoadErr => BaseAction(messege, eFormKhaoSatActionTypeIds.LOAD_ERROR),
    //By truong
    loadByTruongStart: (dm_truong_id: number, nam_hoc: string): IFormKhaoSatLoadByTruongStart => BaseAction({ dm_truong_id, nam_hoc }, eFormKhaoSatActionTypeIds.LOAD_BYTRUONG_START),
    loadByTruongSuccess: (respone: sis_form_khaosat[]): IFormKhaoSatLoadByTruongSuccess => BaseAction(respone, eFormKhaoSatActionTypeIds.LOAD_BYTRUONG_SUCCESS),
    loadByTruongError: (messege: string): IFormKhaoSatLoadByTruongErr => BaseAction(messege, eFormKhaoSatActionTypeIds.LOAD_BYTRUONG_ERROR),
    //not in truong
    showModal: (show: boolean): IFormKhaoSatShowModal => BaseAction(show, eFormKhaoSatActionTypeIds.SHOW_MODAL),
    changeDetail: (data?: sis_form_khaosat): IFormKhaoSatChangeDetail => BaseAction(data, eFormKhaoSatActionTypeIds.CHANGE_DETAIL),
    showConfirm: (show: boolean): IFormKhaoSatShowConfirm => BaseAction(show, eFormKhaoSatActionTypeIds.SHOW_CONFIRM),
    changeSelectedIds: (ids: number[]): IFormKhaoSatChangeSelectedIds => BaseAction(ids, eFormKhaoSatActionTypeIds.CHANGE_SELECTED_IDS),
    //delete
    deleteStart: (ids: IDeleteMultipleBaseRequest): IFormKhaoSatDeleteStart => BaseAction(ids, eFormKhaoSatActionTypeIds.DELETE_START),
    deleteSuccess: (): IFormKhaoSatDeleteSuccess => BaseAction(undefined, eFormKhaoSatActionTypeIds.DELETE_SUCCESS),
    deleteErr: (message: string): IFormKhaoSatDeleteErr => BaseAction(message, eFormKhaoSatActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: sis_form_khaosat) => BaseAction(data, eFormKhaoSatActionTypeIds.SAVE_START),
    saveSuccess: () => BaseAction(undefined, eFormKhaoSatActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string) => BaseAction(message, eFormKhaoSatActionTypeIds.SAVE_ERR),
    clearStatus: () => BaseAction(undefined, eFormKhaoSatActionTypeIds.CLEAR_STATUS)
}



import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { app_nhombaiviet } from "../../../models/response/app-phuhuynh/app_nhombaiviet";
import { eAppNhomBaiVietActionTypeIds, IAppNhomBaiVietChangeSelectedIds, IAppNhomBaiVietCloseConfirm, IAppNhomBaiVietCloseDetail, IAppNhomBaiVietDeleteErr, IAppNhomBaiVietDeleteStart, IAppNhomBaiVietDeleteSuccess, IAppNhomBaiVietLoadByTruongError, IAppNhomBaiVietLoadByTruongStart, IAppNhomBaiVietLoadByTruongSuccess, IAppNhomBaiVietLoadErr, IAppNhomBaiVietLoadStart, IAppNhomBaiVietLoadSuccess, IAppNhomBaiVietSaveErr, IAppNhomBaiVietSaveStart, IAppNhomBaiVietSaveSuccess, IAppNhomBaiVietShowConfirm, IAppNhomBaiVietShowDetail } from "../../action-types/bai-viet/IAppNhomBaiVietActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const appNhomBaiVietActions = {
    loadStart: (): IAppNhomBaiVietLoadStart => BaseAction(undefined, eAppNhomBaiVietActionTypeIds.LOAD_START),
    loadSuccess: (respone: app_nhombaiviet[]): IAppNhomBaiVietLoadSuccess => BaseAction(respone, eAppNhomBaiVietActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IAppNhomBaiVietLoadErr => BaseAction(messege, eAppNhomBaiVietActionTypeIds.LOAD_ERROR),
    loadByTruongStart: (dm_truong_id: number): IAppNhomBaiVietLoadByTruongStart => BaseAction(dm_truong_id, eAppNhomBaiVietActionTypeIds.LOAD_BY_TRUONG_START),
    loadByTruongSuccess: (respone: app_nhombaiviet[]): IAppNhomBaiVietLoadByTruongSuccess => BaseAction(respone, eAppNhomBaiVietActionTypeIds.LOAD_BY_TRUONG_SUCCESS),
    loadByTruongError: (messege: string): IAppNhomBaiVietLoadByTruongError => BaseAction(messege, eAppNhomBaiVietActionTypeIds.LOAD_BY_TRUONG_ERROR),
    showDetail: (data?: app_nhombaiviet): IAppNhomBaiVietShowDetail => BaseAction(data, eAppNhomBaiVietActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IAppNhomBaiVietCloseDetail => BaseAction(undefined, eAppNhomBaiVietActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IAppNhomBaiVietShowConfirm => BaseAction(undefined, eAppNhomBaiVietActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IAppNhomBaiVietCloseConfirm => BaseAction(undefined, eAppNhomBaiVietActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IAppNhomBaiVietChangeSelectedIds => BaseAction(ids, eAppNhomBaiVietActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IAppNhomBaiVietDeleteStart => BaseAction(ids, eAppNhomBaiVietActionTypeIds.DELETE_START),
    deleteSuccess: (): IAppNhomBaiVietDeleteSuccess => BaseAction(undefined, eAppNhomBaiVietActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IAppNhomBaiVietDeleteErr => BaseAction(message, eAppNhomBaiVietActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: app_nhombaiviet): IAppNhomBaiVietSaveStart => BaseAction(data, eAppNhomBaiVietActionTypeIds.SAVE_START),
    saveSuccess: (): IAppNhomBaiVietSaveSuccess => BaseAction(undefined, eAppNhomBaiVietActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IAppNhomBaiVietSaveErr =>  BaseAction(message, eAppNhomBaiVietActionTypeIds.SAVE_ERR)
}
import { pl_thu } from "../../../models/response/danh-muc/pl_thu"

export enum eThuActionTypeIds {
    LOAD_START = "THU_LOAD_START",
    LOAD_SUCCESS = "THU_LOAD_SUCCESS",
}

export interface IThuLoadStart {
    type: eThuActionTypeIds.LOAD_START
}
export interface IThuLoadSuccess {
    type: eThuActionTypeIds.LOAD_SUCCESS,
    payload: pl_thu[]
}

export type IThuActionTypes = IThuLoadStart | IThuLoadSuccess
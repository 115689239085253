import { NotifyHelper } from './../../../helpers/toast';
import { type } from 'os';
import { IKhenThuongKyLuatActionTypes, eKhenThuongKyLuatActionTypeIds } from './../../action-types/khen-thuong-ky-luat/IKhenThuongKyLuatActionType';
import { IKhenThuongKyLuatReducer } from './../../page-state-model/khen-thuong-ky-luat/IKhenThuongKyLuatReducer';
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
const iniState: IKhenThuongKyLuatReducer = {
    isShowDeleteConfirm: false,
    isShowDetailModal: false,
    khenThuongKyLuats: [],
    status: ePageBaseStatus.is_not_initialization,
    khenThuongKyLuatEditing: undefined
}
export const khenThuongKyLuatReducer = (state: IKhenThuongKyLuatReducer = iniState,
    actions: IKhenThuongKyLuatActionTypes
): IKhenThuongKyLuatReducer => {
    switch (actions.type) {
        case eKhenThuongKyLuatActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eKhenThuongKyLuatActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                khenThuongKyLuats: actions.payload
            }
        case eKhenThuongKyLuatActionTypeIds.LOAD_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eKhenThuongKyLuatActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eKhenThuongKyLuatActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Success")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDeleteConfirm:false,
                isShowDetailModal: false
            }
        case eKhenThuongKyLuatActionTypeIds.SAVE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eKhenThuongKyLuatActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eKhenThuongKyLuatActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Success")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDeleteConfirm:false,
                isShowDetailModal:false
            }
        case eKhenThuongKyLuatActionTypeIds.DELETE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eKhenThuongKyLuatActionTypeIds.SHOW_DETAIL_MODAL:
            return {
                ...state,
                isShowDetailModal: true,
                khenThuongKyLuatEditing: actions.payload
            }
        case eKhenThuongKyLuatActionTypeIds.CLOSE_DETAIL_MODAL:
            return {
                ...state,
                isShowDetailModal: false,
                khenThuongKyLuatEditing: undefined
            }
        case eKhenThuongKyLuatActionTypeIds.SHOW_DELETE_CONFIRM:
            return {
                ...state,
                isShowDeleteConfirm: true,
                khenThuongKyLuatEditing: actions.payload
            }
        case eKhenThuongKyLuatActionTypeIds.CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                isShowDeleteConfirm: false,
                khenThuongKyLuatEditing: undefined
            }

        default:
            return {
                ...state
            };
    }
}

export enum eCommonActionTypeIds {
    HEADER_SHOW_COMBOBOX_NAMHOC = "HEADER_COMBOBOX_NAMHOC_SHOW",
    HEADER_CLOSE_COMBOBOX_NAMHOC = "HEADER_COMBOBOX_NAMHOC_CLOSE",
    HEADER_COMBOBOX_NAMHOC_CHANGED = "HEADER_COMBOBOX_NAMHOC_CHANGED",
    HEADER_COMBOBOX_TRUONG_CHANGED = "HEADER_COMBOBOX_TRUONG_CHANGED",
    HEADER_COMBOBOX_HOCKY_CHANGED = "HEADER_COMBOBOX_HOCKY_CHANGED",
    HEADER_SHOW_COMBOBOX_TRUONG = "HEADER_COMBOBOX_TRUONG_SHOW",
    HEADER_CLOSE_COMBOBOX_TRUONG = "HEADER_COMBOBOX_TRUONG_CLOSE",
    HEADER_SHOW_COMBOBOX_HOCKY = "HEADER_SHOW_COMBOBOX_HOCKY",
    HEADER_CLOSE_COMBOBOX_HOCKY = "HEADER_CLOSE_COMBOBOX_HOCKY",
    HEADER_SHOW_NOTIFY_PANEL = "HEADER_SHOW_NOTIFY_PANEL",
    HEADER_CLOSE_NOTIFY_PANEL = "HEADER_CLOSE_NOTIFY_PANEL",

    CHANGE_MENU_MODE = "CHANGE_MENU_MODE",
    CHANGE_DIRECTION_MODE = "CHANGE_DIRECTION_MODE",
    CHANGE_LANGUAGE = "CHANGE_LANGUAGE",
}



export interface IHeaderComboboxNamHocShowAction {
    type: eCommonActionTypeIds.HEADER_SHOW_COMBOBOX_NAMHOC,
}
export interface IHeaderComboboxNamHocChangedAction {
    type: eCommonActionTypeIds.HEADER_COMBOBOX_NAMHOC_CHANGED,
    payload: string
}
export interface IHeaderComboboxHocKyChangedAction {
    type: eCommonActionTypeIds.HEADER_COMBOBOX_HOCKY_CHANGED,
    payload: number
}

export interface IHeaderComboboxNamHocCloseAction {
    type: eCommonActionTypeIds.HEADER_CLOSE_COMBOBOX_NAMHOC,
}

export interface IHeaderComboboxTruongShowAction {
    type: eCommonActionTypeIds.HEADER_SHOW_COMBOBOX_TRUONG
}
export interface IHeaderComboboxHocKyShowAction {
    type: eCommonActionTypeIds.HEADER_SHOW_COMBOBOX_HOCKY
}
export interface IHeaderComboboxTruongChangedAction {
    type: eCommonActionTypeIds.HEADER_COMBOBOX_TRUONG_CHANGED,
    payload: number
}
export interface IHeaderComboboxTruongCloseAction {
    type: eCommonActionTypeIds.HEADER_CLOSE_COMBOBOX_TRUONG
}
export interface IHeaderComboboxHocKyCloseAction {
    type: eCommonActionTypeIds.HEADER_CLOSE_COMBOBOX_HOCKY
}
export interface IHeaderShowNofityPanel {
    type: eCommonActionTypeIds.HEADER_SHOW_NOTIFY_PANEL
}
export interface IHeaderCloseNotifyPanel {
    type: eCommonActionTypeIds.HEADER_CLOSE_NOTIFY_PANEL

}
export interface IChangeMenuMode {
    type: eCommonActionTypeIds.CHANGE_MENU_MODE,
    payload: "compact" | "full"

}

export interface IChangeDirectionMode {
    type: eCommonActionTypeIds.CHANGE_DIRECTION_MODE,
    payload: "column" | "row"

}


export interface IChangeLangugae {
    type: eCommonActionTypeIds.CHANGE_LANGUAGE,
    payload: "vi" | "en"

}


export type ICommonActionTypes =
    IHeaderComboboxNamHocShowAction | IHeaderComboboxNamHocChangedAction | IHeaderComboboxNamHocCloseAction |
    IHeaderComboboxTruongChangedAction | IHeaderComboboxTruongShowAction | IHeaderComboboxTruongCloseAction |
    IHeaderShowNofityPanel | IHeaderCloseNotifyPanel |
    IHeaderComboboxHocKyChangedAction | IHeaderComboboxHocKyShowAction | IHeaderComboboxHocKyCloseAction |
    IChangeMenuMode | IChangeDirectionMode |IChangeLangugae
import { DangKyMonHocKetQuaUpdateRequest } from "../../../models/request/dangky-monhoc/DangKyMonHocKetQuaUpdateRequest";
import { DangKyMonHocKetQuaHocSinhResponse, sis_pl_dotdangky_ketqua } from "../../../models/response/dangky-monhoc/sis_pl_dotdangky_ketqua";
import { sis_pl_dotdangky_ketqua_chitiet } from "../../../models/response/dangky-monhoc/sis_pl_dotdangky_ketqua_chitiet";
import { IDotDangKyKetQuaHocSinhDangKyErr, IDotDangKyKetQuaHocSinhDangKyStart, IDotDangKyKetQuaHocSinhDangKySuccess, IDotDangKyKetQuaHocSinhLoadErr, IDotDangKyKetQuaHocSinhLoadStart, IDotDangKyKetQuaHocSinhLoadSuccess, IDotDangKyKetQuaLoadChiTietErr, IDotDangKyKetQuaLoadChiTietStart, IDotDangKyKetQuaLoadChiTietSuccess, IDotDangKyKetQuaLoadErr, IDotDangKyKetQuaLoadStart, IDotDangKyKetQuaLoadSuccess, eDotDangKyKetQuaActionTypeIds } from "../../action-types/dangky-monhoc/IDotDangKyKetQuaActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const dotDangKyKetQuaActions = {
    loadStart: (dotdangky_id: number): IDotDangKyKetQuaLoadStart => BaseAction(dotdangky_id, eDotDangKyKetQuaActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_pl_dotdangky_ketqua[]): IDotDangKyKetQuaLoadSuccess => BaseAction(respone, eDotDangKyKetQuaActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IDotDangKyKetQuaLoadErr => BaseAction(messege, eDotDangKyKetQuaActionTypeIds.LOAD_ERROR),

    loadchitietStart: (dotdangky_id: number): IDotDangKyKetQuaLoadChiTietStart => BaseAction(dotdangky_id, eDotDangKyKetQuaActionTypeIds.LOAD_CHITIET_START),
    loadchitietSuccess: (respone: sis_pl_dotdangky_ketqua_chitiet[]): IDotDangKyKetQuaLoadChiTietSuccess => BaseAction(respone, eDotDangKyKetQuaActionTypeIds.LOAD_CHITIET_SUCCESS),
    loadchitietError: (messege: string): IDotDangKyKetQuaLoadChiTietErr => BaseAction(messege, eDotDangKyKetQuaActionTypeIds.LOAD_CHITIET_ERROR),

    loadHocSinhStart: (dotdangky_id: number): IDotDangKyKetQuaHocSinhLoadStart => BaseAction(dotdangky_id, eDotDangKyKetQuaActionTypeIds.LOAD_HOCSINH_START),
    loadHocSinhSuccess: (respone: DangKyMonHocKetQuaHocSinhResponse[]): IDotDangKyKetQuaHocSinhLoadSuccess => BaseAction(respone, eDotDangKyKetQuaActionTypeIds.LOAD_HOCSINH_SUCCESS),
    loadHocSinhError: (messege: string): IDotDangKyKetQuaHocSinhLoadErr => BaseAction(messege, eDotDangKyKetQuaActionTypeIds.LOAD_HOCSINH_ERROR),

    dangkyHocSinhStart: (request: DangKyMonHocKetQuaUpdateRequest): IDotDangKyKetQuaHocSinhDangKyStart => BaseAction(request, eDotDangKyKetQuaActionTypeIds.DANGKY_HOCSINH_START),
    dangkyHocSinhSuccess: (): IDotDangKyKetQuaHocSinhDangKySuccess => BaseAction(undefined, eDotDangKyKetQuaActionTypeIds.DANGKY_HOCSINH_SUCCESS),
    dangkyHocSinhError: (messege: string): IDotDangKyKetQuaHocSinhDangKyErr => BaseAction(messege, eDotDangKyKetQuaActionTypeIds.DANGKY_HOCSINH_ERROR),
}
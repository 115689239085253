import { ISdtAccountPhuHuynh, IEmailAccountGiaoVien, ISdtAccountPhuHuynhDetail, IEmailAccountGiaoVienDetail } from "../../models/response/account/process-account";
import { eProcessAccountActionTypeIds, IProcessAccountActionTypes } from "../action-types/IProcessAccountActionTypes";

export interface IProcessAccountState {
    isLoadingList: boolean,
    isLoadingDetail: boolean,
    is_show_detail_phuhuynh_modal: boolean,
    is_show_detail_giaovien_modal: boolean,
    message?: string,
    email?: IEmailAccountGiaoVien,
    sdt?: ISdtAccountPhuHuynh,
    listSdtPhuHuynh: ISdtAccountPhuHuynh[],
    listSdtPhuHuynhDetail: ISdtAccountPhuHuynhDetail[],
    listEmailGiaoVien: IEmailAccountGiaoVien[],
    listEmailGiaoVienDetail: IEmailAccountGiaoVienDetail[],
}
const initialState: IProcessAccountState = {
    isLoadingList: false,
    isLoadingDetail: false,
    is_show_detail_phuhuynh_modal: false,
    is_show_detail_giaovien_modal: false,
    email: undefined,
    sdt: undefined,
    message: undefined,    
    listSdtPhuHuynh: [],
    listSdtPhuHuynhDetail: [],
    listEmailGiaoVien: [],
    listEmailGiaoVienDetail: [],
}
const processAccountReducer = (state: IProcessAccountState =initialState, action: IProcessAccountActionTypes): IProcessAccountState => {
    switch (action.type) {
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_START:
            return {
                ...state,
                isLoadingList: true
            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_SUCCESS:
            return {
                ...state,
                isLoadingList: false,
                listSdtPhuHuynh: action.payload
            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_ERROR:
            return {
                ...state,
                isLoadingList: false,
                message: action.payload,
                listSdtPhuHuynh: []
            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_START:
            return {
                ...state,
                isLoadingDetail: true
            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_SUCCESS:
            return {
                ...state,
                isLoadingDetail: false,
                listSdtPhuHuynhDetail: action.payload
            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_ERROR:
            return {
                ...state,
                isLoadingDetail: false,
                message: action.payload,
                listSdtPhuHuynhDetail: []

            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_START:
            return {
                ...state,
                isLoadingList: true
            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_SUCCESS:
            return {
                ...state,
                isLoadingList: false,
                listEmailGiaoVien: action.payload
            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_ERROR:
            return {
                ...state,
                isLoadingList: false,
                message: action.payload,
                listEmailGiaoVien: []

            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_START:
            return {
                ...state,
                isLoadingDetail: true,
                listEmailGiaoVienDetail: []
            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_SUCCESS:
            return {
                ...state,
                isLoadingDetail: false,
                listEmailGiaoVienDetail: action.payload
            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_ERROR:
            return {
                ...state,
                isLoadingDetail: false,
                message: action.payload,
                listEmailGiaoVienDetail: []

            }
        
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_SHOW_DETAIL:
            console.log(action.payload);
            return {
                ...state,
                is_show_detail_giaovien_modal: true,
                email: action.payload
            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_giaovien_modal: false,
                email: undefined
            }
        
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_phuhuynh_modal: true,
                sdt: action.payload
            }
        case eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_phuhuynh_modal: false,
                sdt: undefined
            }
        default:
            return state;
    }
}
export { processAccountReducer }
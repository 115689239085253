import { NotifyHelper } from './../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eLopBoMonHocSinhActionTypeIds, ILopBoMonHocSinhActionTypes } from '../../action-types/lop-bo-mon/ILopBoMonHocSinhActionTypes';
import { ILopBoMonHocSinhListStateModel } from './../../page-state-model/lop-bo-mon/ILopBoMonHocSinhListStateModel';

const initialState: ILopBoMonHocSinhListStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    lopBoMonHocSinhs: [],
    lopBoMonHocSinhSelectedIds: [],
    isShowConfirmDelete: false,
    isShowAddHocSinhModal: false,
    data_from_excel: undefined
}
export const lopBoMonHocSinhListReducer = (
    state: ILopBoMonHocSinhListStateModel = initialState,
    action: ILopBoMonHocSinhActionTypes): ILopBoMonHocSinhListStateModel => {
    switch (action.type) {
        case eLopBoMonHocSinhActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopBoMonHocSinhActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lopBoMonHocSinhs: action.payload
            }
        case eLopBoMonHocSinhActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eLopBoMonHocSinhActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                lopBoMonHocSinhSelectedIds: action.payload
            }
        case eLopBoMonHocSinhActionTypeIds.SHOW_CONFIRM_DELETE:
            return {
                ...state,
                isShowConfirmDelete: action.payload
            }
        case eLopBoMonHocSinhActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eLopBoMonHocSinhActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Đã bỏ học sinh thành công")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowConfirmDelete: false
            }
        case eLopBoMonHocSinhActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eLopBoMonHocSinhActionTypeIds.SHOW_ADD_HOCSINH_MODAL:
            return {
                ...state,
                isShowAddHocSinhModal: action.payload
            }
        case eLopBoMonHocSinhActionTypeIds.ADD_HOCSINH_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopBoMonHocSinhActionTypeIds.ADD_HOCSINH_SUCCESS:
            NotifyHelper.Success("Thêm học sinh thành công")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eLopBoMonHocSinhActionTypeIds.ADD_HOCSINH_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eLopBoMonHocSinhActionTypeIds.UPLOAD_EXCEL_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopBoMonHocSinhActionTypeIds.UPLOAD_EXCEL_SUCCESS:
            NotifyHelper.Success("Load file excel thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                data_from_excel: action.payload
            }
        case eLopBoMonHocSinhActionTypeIds.UPLOAD_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eLopBoMonHocSinhActionTypeIds.SAVE_EXCEL_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopBoMonHocSinhActionTypeIds.SAVE_EXCEL_SUCCESS:
            NotifyHelper.Success("Import dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                data_from_excel: action.payload
            }
        case eLopBoMonHocSinhActionTypeIds.SAVE_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_THUTU_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_THUTU_SUCCESS:
            NotifyHelper.Success("Thay đổi thứ tự thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_THUTU_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eLopBoMonHocSinhActionTypeIds.CHANGE_THUTU_MACDINH_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopBoMonHocSinhActionTypeIds.CHANGE_THUTU_MACDINH_SUCCESS:
            NotifyHelper.Success("Đặt thứ tự mặc định thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eLopBoMonHocSinhActionTypeIds.CHANGE_THUTU_MACDINH_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        default:
            return state;
    }

}

import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { IDotDangKyNhomMonHocActionTypes, eDotDangKyNhomMonHocActionTypeIds } from "../../action-types/dangky-monhoc/IDotDangKyNhomMonHocActionTypes"
import { IDotDangKyNhomMonHocState } from "../../page-state-model/dangky-monhoc/IDotDangKyNhomMonHocState"

const initialState: IDotDangKyNhomMonHocState = {
    status: ePageBaseStatus.is_not_initialization,
    sis_pl_dotdangky_nhommonhocs: [],
    sis_pl_dotdangky_nhommonhoc_selected_ids: [],
    sis_pl_dotdangky_nhommonhoc_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const dotDangKyNhomMonHocReducer = (state: IDotDangKyNhomMonHocState = initialState, action: IDotDangKyNhomMonHocActionTypes): IDotDangKyNhomMonHocState => {
    switch (action.type) {
        case eDotDangKyNhomMonHocActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDotDangKyNhomMonHocActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_pl_dotdangky_nhommonhocs: action.payload
            }
        case eDotDangKyNhomMonHocActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_pl_dotdangky_nhommonhocs: []
            }
        case eDotDangKyNhomMonHocActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_pl_dotdangky_nhommonhoc_editing: action.payload
            }
        case eDotDangKyNhomMonHocActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_pl_dotdangky_nhommonhoc_editing: undefined
            }
        case eDotDangKyNhomMonHocActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_pl_dotdangky_nhommonhoc_selected_ids: action.payload
            }
        case eDotDangKyNhomMonHocActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eDotDangKyNhomMonHocActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eDotDangKyNhomMonHocActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eDotDangKyNhomMonHocActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eDotDangKyNhomMonHocActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eDotDangKyNhomMonHocActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDotDangKyNhomMonHocActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eDotDangKyNhomMonHocActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        default:
            return state;
    }

}
import { NotifyHelper } from './../../../helpers/toast';
import { eSoDiemListActionTypeIds, ISoDiemListActionTypes } from './../../action-types/so-diem/ISoDiemListAcitonTypes';
import { ISoDiemListStateModel } from "../../page-state-model/so-diem/ISoDiemListStateModel";
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';

const iniState: ISoDiemListStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    soDiems: [],
    isShowDeleteConfirm: false,
    soDiemEditing: undefined,
    isShowDetailModal: false
}

export const soDiemListReducer = (state: ISoDiemListStateModel = iniState, action: ISoDiemListActionTypes): ISoDiemListStateModel => {
    switch (action.type) {
        case eSoDiemListActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eSoDiemListActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                soDiems: action.payload
            }
        case eSoDiemListActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eSoDiemListActionTypeIds.CHANGE_EDITING_DATA:
            return {
                ...state,
                soDiemEditing: action.payload
            }
        case eSoDiemListActionTypeIds.SHOW_DELETE_CONFIRM:
            return {
                ...state,
                isShowDeleteConfirm: action.payload
            }
        case eSoDiemListActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eSoDiemListActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Đã xóa sổ điểm")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDeleteConfirm: false
            }
        case eSoDiemListActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eSoDiemListActionTypeIds.SHOW_DETAIL_MODAL:
            return {
                ...state,
                isShowDetailModal: action.payload
            }
        case eSoDiemListActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eSoDiemListActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Cập nhật thành công")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDetailModal: false
            }
        case eSoDiemListActionTypeIds.SAVE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        default:
            return {
                ...state
            }
    }
}
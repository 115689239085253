import { AppBaiVietFileUpdateSortIdxRequest } from "../../models/request/bai-viet/AppBaiVietFileUpdateSortIdxRequest";
import { app_baiviet } from "../../models/response/app-phuhuynh/app_baiviet";
import { apiClient } from "../apiClient";
import { BaseCRUDApi } from "../baseCRUDApi";

export const API_APP_BAIVIET_PATH = "app-baiviet"

class AppBaiVietApi extends BaseCRUDApi<app_baiviet> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    SelectBySchoolAsync(schoolId: number) {
        return apiClient.get(`${API_APP_BAIVIET_PATH}/truong/${schoolId}`)
    }
    SelectUserBySchoolAsync(schoolId: number) {
        return apiClient.get(`${API_APP_BAIVIET_PATH}/user-by-truong/${schoolId}`)
    }
    SelectChoDuyetByTruongAsync(schoolId: number) {
        return apiClient.get(`${API_APP_BAIVIET_PATH}/bai-cho-duyet/${schoolId}`)
    }
    SelectDaDuyetByTruongAsync(schoolId: number) {
        return apiClient.get(`${API_APP_BAIVIET_PATH}/bai-da-duyet/${schoolId}`)
    }
    SelectSoBaiChoDuyetByTruongAsync(schoolId: number) {
        return apiClient.get(`${API_APP_BAIVIET_PATH}/so-bai-cho-duyet/${schoolId}`)
    }
    SelectBaiVietFilesAsync(app_baiviet_id: number) {
        return apiClient.get(`${API_APP_BAIVIET_PATH}/${app_baiviet_id}/select-baiviet-files`)
    }
    UpdateBaiVietFileSortIdxAsync(request: AppBaiVietFileUpdateSortIdxRequest[]) {
        return apiClient.post(`${API_APP_BAIVIET_PATH}/update-baiviet-file-sort-idx`, { items: request })
    }
    DeleteBaiVietFileAsync(app_baiviet_file_id: number) {
        return apiClient.post(`${API_APP_BAIVIET_PATH}/${app_baiviet_file_id}/delete-baiviet-file`)
    }
    UploadBaiVietFilesAsync(app_baiviet_id: number, files: File[] | FileList) {
        let formData = new FormData();
        formData.append("app_baiviet_id", `${app_baiviet_id}`);
        if(files){
            if (files.length > 0) {
                Array.from(files).map((file) => {
                    return formData.append("form_files", file);
                })
            }
        }
        return apiClient.upload(`${API_APP_BAIVIET_PATH}/upload-baiviet-files`, formData)
    }
    Insert(data: app_baiviet) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            if (key == "anh_bia") {
                formData.append(`${key}`, `${value ? value : ''}`);
            }
            else if (key != "file_dinh_kems" && value !== null) {
                formData.append(`${key}`, `${value}`);
            }
        }
        if(data.file_dinh_kems){
            if (data.file_dinh_kems.length > 0) {
                Array.from(data.file_dinh_kems).map((file) => {
                    return formData.append("file_dinh_kems", file);
                })
            }
        }
        return apiClient.post(this._path_insert, formData);
    }
}
export const appBaiVietApi = new AppBaiVietApi({ path: API_APP_BAIVIET_PATH });
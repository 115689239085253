import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";

import { actions } from "../../actions/actionsWrapper";
import { IDotDangKyMonHocADDMONHOCStart, IDotDangKyMonHocDeleteStart, IDotDangKyMonHocLoadStart, IDotDangKyMonHocSaveStart, eDotDangKyMonHocActionTypeIds } from "../../action-types/dangky-monhoc/IDotDangKyMonHocActionTypes";

const monHocApi = apiWrapper.dangKyMonHoc.dotDangKyMonHoc;

export function* dotDangKyMonHocSaga(): any {
    yield takeLatest(eDotDangKyMonHocActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eDotDangKyMonHocActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eDotDangKyMonHocActionTypeIds.SAVE_START, saveWorker)
    yield takeEvery(eDotDangKyMonHocActionTypeIds.ADDMONHOC_START, addMonHocWorker)
}
function* loadWorker(action: IDotDangKyMonHocLoadStart): any {
    const res: IBaseResponeModel = yield call([monHocApi, monHocApi.SelectByDotDangKyAsync], action.payload)
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKyMonHoc.loadSuccess(res.data))
    } else {
        yield put(actions.dangKyMonHoc.dotDangKyMonHoc.loadError(res.message || "Không tải được môn học"))
    }
}
function* deleteWorker(action: IDotDangKyMonHocDeleteStart): any {
    const res: IBaseResponeModel = yield call([monHocApi, monHocApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKyMonHoc.deleteSuccess())
    } else {
        yield put(actions.dangKyMonHoc.dotDangKyMonHoc.deleteError(res.message || "Không xóa được môn học"))
    }
}
function* saveWorker(action: IDotDangKyMonHocSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([monHocApi, monHocApi.Update], action.payload);
    } else {
        res = yield call([monHocApi, monHocApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKyMonHoc.saveSuccess())
    } else {
        yield put(actions.dangKyMonHoc.dotDangKyMonHoc.saveError(res.message || "Không lưu được môn học"))
    }
}

function* addMonHocWorker(action: IDotDangKyMonHocADDMONHOCStart): any {
    let res: IBaseResponeModel = yield call([monHocApi, monHocApi.AddMonHocAsync], action.payload);
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKyMonHoc.addMonHocSuccess())
    } else {
        yield put(actions.dangKyMonHoc.dotDangKyMonHoc.addMonHocError(res.message || "Không lưu được môn học"))
    }
}
import { IDotDangKySelectRequest } from "../models/request/dangky-monhoc/IDotDangKySelectRequest";
import { sis_pl_dotdangky } from "../models/response/dangky-monhoc/sis_pl_dotdangky";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_DOTDANGKY_PATH = "dang-ky-mon-hoc/dot-dang-ky"

export const dotDangKyApi = new class DotDangKyApi extends BaseCRUDApi<sis_pl_dotdangky>{
    constructor(path: string) {
        super(path);
    }
    SelectByTruongAsync(request: IDotDangKySelectRequest) {
        return apiClient.get(`${this._path}/truong/${request.dm_truong_id}/nam-hoc/${request.nam_hoc}/select-by-truong`)
    }
}(API_DOTDANGKY_PATH)





import { call, put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from "../../../api/apiWrapper";
import { actions } from '../../actions/actionsWrapper';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { eBaiTapTypeActionIds } from '../../action-types/bai-tap/IBaiTapTypeActionType';

const baiTapTypeApi = apiWrapper.baiTapWrapper.type;

export function* baiTapTypeSaga(): any {
    yield takeLatest(eBaiTapTypeActionIds.LOAD_START, loadWorker)
}

function* loadWorker(): any {
    const res: IBaseResponeModel = yield call([baiTapTypeApi, baiTapTypeApi.SelectAll])
    if (res.is_success) {
        yield put(actions.baiTapWrapper.type.loadSuccess(res.data))
    } else {
        yield put(actions.baiTapWrapper.type.loadError(res.message || ""))
    }
}

import { AppYeuCauHoTroSelectRequest } from "../../models/request/app-phuhuynh/AppYeuCauHoTroSelectRequest";
import { AppYeuCauHoTroUpdateRequest } from "../../models/request/app-phuhuynh/AppYeuCauHoTroUpdateRequest";
import { AppYeuCauHoTroGroupAddOrUpdateRequest } from "../../models/request/app-phuhuynh/AppYeuCauHoTroGroupAddOrUpdateRequest";
import { IDeleteMultipleBaseRequest } from "../../models/request/base/deleteRequest"
import { apiClient } from "../apiClient";
import { app_yeu_cau_ho_tro_group } from "../../models/response/app-phuhuynh/app_yeu_cau_ho_tro_group";

export const API_APP_YEUCAUHOTRO_PATH = "app-yeu-cau-ho-tro"
export const appYeuCauHoTroApi = {
    selectAsync: (request: AppYeuCauHoTroSelectRequest) => {
        let queryModel: any = request ? { ...request } : undefined;
        let queryString = queryModel ? new URLSearchParams(queryModel) : undefined;
        return apiClient.get(`${API_APP_YEUCAUHOTRO_PATH}/select${queryString ? `?${queryString}` : ''}`)
    },
    selectDetailAsync: (id: number) => {
        return apiClient.get(`${API_APP_YEUCAUHOTRO_PATH}/${id}`)
    },
    updateKetQuaAsync: (request: AppYeuCauHoTroUpdateRequest) => {
        return apiClient.put(`${API_APP_YEUCAUHOTRO_PATH}`, request)
    },
    selectAllGroupAsync: (dm_coso_id: number) => {
        return apiClient.get(`${API_APP_YEUCAUHOTRO_PATH}/group/${dm_coso_id}`)
    },
    selectDetailGroupAsync: (id: number) => {
        return apiClient.get(`${API_APP_YEUCAUHOTRO_PATH}/group/${id}`)
    },
    insertGroupAsync: (request: AppYeuCauHoTroGroupAddOrUpdateRequest) => {
        return apiClient.post(`${API_APP_YEUCAUHOTRO_PATH}/group`, request)
    },
    updateGroupAsync: (request: AppYeuCauHoTroGroupAddOrUpdateRequest) => {
        return apiClient.put(`${API_APP_YEUCAUHOTRO_PATH}/group`, request)
    },
    deleteGroupAsync: (id: number) => {
        return apiClient.delete(`${API_APP_YEUCAUHOTRO_PATH}/group/${id}`)
    },
    DeleteMultipleGroupAsync: (ids: IDeleteMultipleBaseRequest) => {
        return apiClient.post(`${API_APP_YEUCAUHOTRO_PATH}/group/deletes`, ids)
    }
};
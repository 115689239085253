import { pl_phonghoc } from "../models/response/phong-hoc/pl_phonghoc";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_PHONG_HOC_PATH = "phong-hoc"

class PhongHocApi extends BaseCRUDApi<pl_phonghoc> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    SelectAllView() {
        return apiClient.get(`${this._path}/select-all-view`)
    }
}
export const phongHocApi = new PhongHocApi({ path: API_PHONG_HOC_PATH });
import { combineReducers } from "redux";
import { hockyReducer } from "./hocKyReducer";
import { lopReducer } from "./lopReducer";
import { namhocReducer } from "./plNamHocReducer";
import { thongTuReducer } from "./thongTuReducer";
import { thuReducer } from "./thuReducer";
import { tietReducer } from "./tietReducer";
import { tuanReducer } from "./tuanReducer";

export const danhMucReducerWrapper = combineReducers({
    thu: thuReducer,
    tiet: tietReducer,
    tuan: tuanReducer,
    namHoc: namhocReducer,
    hocKy: hockyReducer,
    lop: lopReducer,
    thongTu: thongTuReducer
})
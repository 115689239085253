import { pl_lopbomon_hocsinh_viewmodel } from './../../../models/response/lop-bo-mon/pl_lopbomon_hocsinh';
import { IHocSinhModel } from '../../../models/response/hoc-sinh/IHocSinhModel';
import { ILopBoMonSelectHocSinhRequest } from '../../../models/request/lop-bo-mon-hoc-sinh/ILopBoMonSelectHocSinhRequest';
import { ILopBoMonChangeThuTuHocSinhRequest } from "../../../models/request/lop-bo-mon-hoc-sinh/ILopBoMonChangeThuTuHocSinhRequest";
import { ILopBoMonChangeHocSinhClassRequest } from "./../../../models/request/lop-bo-mon/ILopBoMonChangeHocSinhClassRequest";
import { IDeleteMultipleBaseRequest } from '../../../models/request/base/deleteRequest';
import { ILopBoMonAddHocSinhRequest } from '../../../models/request/lop-bo-mon-hoc-sinh/ILopBoMonAddHocSinhRequest';
import { IActionTypeBase } from '../IActionTypeBase';
import { ILopBoMonImportHocSinhRequest } from '../../../models/request/lop-bo-mon/ILopBoMonImportHocSinhRequest';
import { ILopBoMonImportHocSinhResponse } from '../../../models/response/lop-bo-mon/ILopBoMonImportHocSinhResponse';


export enum eLopBoMonHocSinhActionTypeIds {
    LOAD_START = "LOPBOMON_HOCSINH_LOAD_START",
    LOAD_SUCCESS = "LOPBOMON_HOCSINH_LOAD_SUCCESS",
    LOAD_ERROR = "LOPBOMON_HOCSINH_LAOD_ERROR",

    CHANGE_SELECTED_IDS = "LOPBOMON_HOCSINH_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM_DELETE = "LOPBOMON_HOCSINH_SHOW_CONFIRM_DELETE",
    DELETE_START = "LOPBOMON_HOCSINH_DELETE_START",
    DELETE_SUCCESS = "LOPBOMON_HOCSINH_DELETE_SUCCESS",
    DELETE_ERROR = "LOPBOMON_HOCSINH_DELETE_ERROR",

    SHOW_ADD_HOCSINH_MODAL = "LOPBOMON_SHOW_ADD_HOCSINH_MODAL",
    ADD_HOCSINH_START = "LOPBOMON_ADD_HOCSINH_START",
    ADD_HOCSINH_SUCCESS = "LOPBOMON_ADD_HOCSINH_SUCCESS",
    ADD_HOCSINH_ERROR = "LOPBOMON_ADD_HOCSINH_ERROR",

    UPLOAD_EXCEL_START = "LOPBOMON_UPLOAD_EXCEL_START",
    UPLOAD_EXCEL_SUCCESS = "LOPBOMON_UPLOAD_EXCEL_SUCCESS",
    UPLOAD_EXCEL_ERROR = "LOPBOMON_UPLOAD_EXCEL_ERROR",

    SAVE_EXCEL_START = "LOPBOMON_SAVE_EXCEL_START",
    SAVE_EXCEL_SUCCESS = "LOPBOMON_SAVE_EXCEL_SUCCESS",
    SAVE_EXCEL_ERROR = "LOPBOMON_SAVE_EXCEL_ERROR",

    CHANGE_HOCSINH_THUTU_START = "LOPBOMON_CHANGE_HOCSINH_THUTU_START",
    CHANGE_HOCSINH_THUTU_SUCCESS = "LOPBOMON_CHANGE_HOCSINH_THUTU_SUCCESS",
    CHANGE_HOCSINH_THUTU_ERROR = "LOPBOMON_CHANGE_HOCSINH_THUTU_ERROR",

    CHANGE_THUTU_MACDINH_START = "LOPBOMON_CHANGE_THUTU_MACDINH_START",
    CHANGE_THUTU_MACDINH_SUCCESS = "LOPBOMON_CHANGE_THUTU_MACDINH_SUCCESS",
    CHANGE_THUTU_MACDINH_ERROR = "LOPBOMON_CHANGE_THUTU_MACDINH_ERROR",

    CHANGE_HOCSINH_START = "LOPBOMON_CHANGE_HOCSINH_START",
    CHANGE_HOCSINH_SUCCESS = "LOPBOMON_CHANGE_HOCSINH_SUCCESS",
    CHANGE_HOCSINH_ERROR = "LOPBOMON_CHANGE_HOCSINH_ERROR",
}

export interface ILopBoMonHocSinhLoadStart extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.LOAD_START, ILopBoMonSelectHocSinhRequest> { }
export interface ILopBoMonHocSinhLoadSuccess extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.LOAD_SUCCESS, pl_lopbomon_hocsinh_viewmodel[]> { }
export interface ILopBoMonHocSinhLoadErr extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.LOAD_ERROR, string> { }
export interface ILopBoMonHocSinhChangeSelectedIds extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
export interface ILopBoMonHocSinhShowConfirmDelete extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.SHOW_CONFIRM_DELETE, boolean> { }
export interface ILopBoMonHocSinhDeleteStart extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.DELETE_START, IDeleteMultipleBaseRequest> { }
export interface ILopBoMonHocSinhDeleteSuccess extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface ILopBoMonHocSinhDeleteError extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.DELETE_ERROR, string> { }
export interface ILopBoMonHocSinhShowAddHocSinhModal extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.SHOW_ADD_HOCSINH_MODAL, boolean> { }
export interface ILopBoMonAddHocSinhStart extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.ADD_HOCSINH_START, ILopBoMonAddHocSinhRequest> { }
export interface ILopBoMonAddHocSinhSuccess extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.ADD_HOCSINH_SUCCESS, undefined> { }
export interface ILopBoMonAddHocSinhError extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.ADD_HOCSINH_ERROR, string> { }

export interface ILopBoMonUploadExcelStartAction extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.UPLOAD_EXCEL_START, FormData> { }
export interface ILopBoMonUploadExcelSuccessAction extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.UPLOAD_EXCEL_SUCCESS, ILopBoMonImportHocSinhResponse> { }
export interface ILopBoMonUploadExcelErrorAction extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.UPLOAD_EXCEL_ERROR, string> { }

export interface ILopBoMonSaveExcelStartAction extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.SAVE_EXCEL_START, ILopBoMonImportHocSinhRequest> { }
export interface ILopBoMonSaveExcelSuccessAction extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.SAVE_EXCEL_SUCCESS, ILopBoMonImportHocSinhResponse> { }
export interface ILopBoMonSaveExcelErrorAction extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.SAVE_EXCEL_ERROR, string> { }

export interface ILopBoMonChangeHocSinhThuTuStart extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_THUTU_START, ILopBoMonChangeThuTuHocSinhRequest> { }
export interface ILopBoMonChangeHocSinhThuTuSuccess extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_THUTU_SUCCESS, undefined> { }
export interface ILopBoMonChangeHocSinhThuTuError extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_THUTU_ERROR, string> { }

export interface ILopBoMonChangeThuTuMacDinhStart extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.CHANGE_THUTU_MACDINH_START, number> { }
export interface ILopBoMonChangeThuTuMacDinhSuccess extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.CHANGE_THUTU_MACDINH_SUCCESS, undefined> { }
export interface ILopBoMonChangeThuTuMacDinhError extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.CHANGE_THUTU_MACDINH_ERROR, string> { }

export interface ILopBoMonChangeHocSinhStart extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_START, ILopBoMonChangeHocSinhClassRequest> { }
export interface ILopBoMonChangeHocSinhSuccess extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_SUCCESS, undefined> { }
export interface ILopBoMonChangeHocSinhError extends IActionTypeBase<eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_ERROR, string> { }

export type ILopBoMonHocSinhActionTypes = ILopBoMonHocSinhLoadStart | ILopBoMonHocSinhLoadSuccess | ILopBoMonHocSinhLoadErr |
    ILopBoMonHocSinhChangeSelectedIds | ILopBoMonHocSinhShowConfirmDelete |
    ILopBoMonHocSinhDeleteStart | ILopBoMonHocSinhDeleteSuccess | ILopBoMonHocSinhDeleteError |
    ILopBoMonHocSinhShowAddHocSinhModal |
    ILopBoMonAddHocSinhStart | ILopBoMonAddHocSinhSuccess | ILopBoMonAddHocSinhError |
    ILopBoMonUploadExcelStartAction | ILopBoMonUploadExcelSuccessAction | ILopBoMonUploadExcelErrorAction | 
    ILopBoMonSaveExcelStartAction | ILopBoMonSaveExcelSuccessAction | ILopBoMonSaveExcelErrorAction | ILopBoMonChangeHocSinhThuTuStart| ILopBoMonChangeHocSinhThuTuSuccess |
    ILopBoMonChangeHocSinhThuTuError | ILopBoMonChangeThuTuMacDinhStart | ILopBoMonChangeThuTuMacDinhSuccess | ILopBoMonChangeThuTuMacDinhError | 
    ILopBoMonChangeHocSinhStart | ILopBoMonChangeHocSinhSuccess | ILopBoMonChangeHocSinhError
import { sis_nhomthanhphandiem } from '../models/response/so-diem/sis_nhomthanhphandiem';
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_NHOM_THANH_PHAN_DIEM_PATH = 'nhom-thanh-phan-diem';

class NhomThanhPhanDiemApi extends BaseCRUDApi<sis_nhomthanhphandiem>{
    constructor(path: string) {
        super(path)
    }
}
export const nhomThanhPhanDiemApi = new NhomThanhPhanDiemApi(API_NHOM_THANH_PHAN_DIEM_PATH)
import { IBaoCaoTienBoSelectRequest } from '../../../models/request/bao-cao-tien-do/BaoCaoTienBoSelectRequest';
import { IBaoCaoTienBoCreateRequest } from '../../../models/request/bao-cao-tien-do/IBaoCaoTienBoCreateRequest';
import { IBaoCaoTienBoImportRequest } from '../../../models/request/bao-cao-tien-do/IBaoCaoTienBoImportRequest';
import { IBaoCaoTienBoSaveRequest } from '../../../models/request/bao-cao-tien-do/IBaoCaoTienBoSaveRequest';
import { IHocSinhSelectRequest } from '../../../models/request/hoc-sinh/IHocSinhSelectRequest';
import { IBaoCaoTienDo } from '../../../models/response/bao-cao-tien-do/IBaoCaoTienDo';
import { IHocSinhSelectResponeItem } from '../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { IBaoCaoTienDoFilter } from '../../page-state-model/bao-cao-tien-bo/IBaoCaoTienBoPageState';
import { IActionTypeBase } from './../IActionTypeBase';
export enum eBaoCaoTienBoActionTypeIds {
    CHANGE_FILTER = "BCTB_CHANGE_FILTER",
    CHANGE_SELECTED_IDS = "BCTB_CHANGE_SELECTED_IDS",

    HOCSINH_LOAD_START = "BCTB_HOCSINH_LOAD_START",
    HOCSINH_LOAD_SUCCESS = "BCTB_HOCSINH_LOAD_SUCCESS",
    HOCSINH_LOAD_ERROR = "BCTB_HOCSINH_LOAD_ERROR",

    DATA_LOAD_START = "BCTB_DATA_LOAD_START",
    DATA_LOAD_SUCCESS = "BCTB_DATA_LOAD_SUCCESS",
    DATA_LOAD_ERROR = "BCTB_DATA_LOAD_ERROR",

    SAVE_START = "BCTB_SAVE_START",
    SAVE_SUCCESS = "BCTB_SAVE_SUCCESS",
    SAVE_ERROR = "BCTB_SAVE_ERROR",

    SHOW_CREATE_CONFIRM = "BCTB_SHOW_CREATE_CONFIRM",

    CREATE_START = "BCTB_CREATE_START",
    CREATE_SUCCESS = "BCTB_CREATE_SUCCESS",
    CREATE_ERROR = "BCTB_CREATE_ERROR",

    SHOW_SEND_NOTIFY_CONFIRM = "BCTB_SHOW_SEND_NOTIFY_CONFIRM",


    SEND_NOTIFY_START = "BCTB_SEND_NOTIFY_START",
    SEND_NOTIFY_SUCCESS = "BCTB_SEND_NOTIFY_SUCCESS",
    SEND_NOTIFY_ERROR = "BCTB_SEND_NOTIFY_ERROR",

    VALIDATE_IMPORT_START = "BCTB_VALIDATE_IMPORT_START",
    VALIDATE_IMPORT_SUCCESS = "BCTB_VALIDATE_IMPORT_SUCCESS",
    VALIDATE_IMPORT_ERRROR = "BCTB_VALIDATE_IMPORT_ERRROR",

    IMPORT_START = "BCTB_IMPORT_START",
    IMPORT_SUCCESS = "BCTB_IMPORT_SUCCESS",
    IMPORT_ERRROR = "BCTB_IMPORT_ERRROR",

}

export interface IBaoCaoTienBoChangeFilter extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.CHANGE_FILTER, IBaoCaoTienDoFilter> { }
export interface IBaoCaoTienBoChangeSelectedIds extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }

export interface IBaoCaoTienDoHocSinhLoadStart extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.HOCSINH_LOAD_START, IHocSinhSelectRequest> { }
export interface IBaoCaoTienDoHocSinhLoadSuccess extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.HOCSINH_LOAD_SUCCESS, IHocSinhSelectResponeItem[]> { }
export interface IBaoCaoTienDoHocSinhLoadError extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.HOCSINH_LOAD_ERROR, string> { }

export interface IBaoCaoTienDoDataLoadStart extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.DATA_LOAD_START, IBaoCaoTienBoSelectRequest> { }
export interface IBaoCaoTienDoDataLoadSuccess extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.DATA_LOAD_SUCCESS, IBaoCaoTienDo[]> { }
export interface IBaoCaoTienDoDataLoadError extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.DATA_LOAD_ERROR, string> { }

export interface IBaoCaoTienDoShowCreateConfirm extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.SHOW_CREATE_CONFIRM, boolean> { }

export interface IBaoCaoTienDoCreateStart extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.CREATE_START, IBaoCaoTienBoCreateRequest> { }
export interface IBaoCaoTienDoCreateSuccess extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.CREATE_SUCCESS, IBaoCaoTienDo[]> { }
export interface IBaoCaoTienDoCreateError extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.CREATE_ERROR, string> { }

export interface IBaoCaoTienDoShowSendNotifyConfirm extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.SHOW_SEND_NOTIFY_CONFIRM, boolean> { }

export interface IBaoCaoTienDoSendNotifyStart extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.SEND_NOTIFY_START, IBaoCaoTienBoSelectRequest> { }
export interface IBaoCaoTienDoSendNotifySuccess extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.SEND_NOTIFY_SUCCESS, IBaoCaoTienDo[]> { }
export interface IBaoCaoTienDoSendNotifyError extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.SEND_NOTIFY_ERROR, string> { }

export interface IBaoCaoTienDoSaveStart extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.SAVE_START, IBaoCaoTienBoSaveRequest> { }
export interface IBaoCaoTienDoSaveSuccess extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.SAVE_SUCCESS, IBaoCaoTienDo> { }
export interface IBaoCaoTienDoSaveError extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.SAVE_ERROR, string> { }

export interface IBaoCaoTienDoValidateImportStart extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.VALIDATE_IMPORT_START, IBaoCaoTienBoImportRequest> { }
export interface IBaoCaoTienDoValidateImportSuccess extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.VALIDATE_IMPORT_SUCCESS, any> { }
export interface IBaoCaoTienDoValidateImportError extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.VALIDATE_IMPORT_ERRROR, any> { }

export interface IBaoCaoTienDoImportStart extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.IMPORT_START, IBaoCaoTienBoImportRequest> { }
export interface IBaoCaoTienDoImportSuccess extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.IMPORT_SUCCESS, undefined> { }
export interface IBaoCaoTienDoImportError extends IActionTypeBase<eBaoCaoTienBoActionTypeIds.IMPORT_ERRROR, any> { }

export type IBaoCaoTienBoActionTypes = IBaoCaoTienBoChangeFilter | IBaoCaoTienBoChangeSelectedIds |
    IBaoCaoTienDoHocSinhLoadStart | IBaoCaoTienDoHocSinhLoadSuccess | IBaoCaoTienDoHocSinhLoadError |
    IBaoCaoTienDoDataLoadStart | IBaoCaoTienDoDataLoadSuccess | IBaoCaoTienDoDataLoadError |
    IBaoCaoTienDoShowCreateConfirm |
    IBaoCaoTienDoCreateStart | IBaoCaoTienDoCreateSuccess | IBaoCaoTienDoCreateError |
    IBaoCaoTienDoShowSendNotifyConfirm |
    IBaoCaoTienDoSendNotifyStart | IBaoCaoTienDoSendNotifySuccess | IBaoCaoTienDoSendNotifyError |
    IBaoCaoTienDoSaveStart | IBaoCaoTienDoSaveSuccess | IBaoCaoTienDoSaveError |
    IBaoCaoTienDoValidateImportStart | IBaoCaoTienDoValidateImportSuccess | IBaoCaoTienDoValidateImportError |
    IBaoCaoTienDoImportStart | IBaoCaoTienDoImportSuccess | IBaoCaoTienDoImportError


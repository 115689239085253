import { NotifyHelper } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eLopActionTypeIds, ILopActionTypes } from "../../action-types/danh-muc/ILopActionTypes";
import { ILopPageState } from "../../page-state-model/danh-muc/ILopPageState";

const initialState: ILopPageState = {
    status: ePageBaseStatus.is_not_initialization,
    dm_lops: [],
    dm_lop_detail: undefined,
    lop_chu_nhiem: undefined,
    treeViewItems: [],
    dm_lop_selected_ids: [],
    is_show_delete_confirm: false,
    is_show_detail_modal: false,
    dm_lop_editing: undefined,

    hocsinh_status: ePageBaseStatus.is_not_initialization,
    ds_hocsinh_theo_lops: [],
    ds_hocsinh_selected_ids: [],
    ds_hocsinh_chuaphanlops: [],

    upload_status: ePageBaseStatus.is_not_initialization,
    data_from_excel: undefined,
    phuHuynhs: []
}
export const lopReducer = (state: ILopPageState = initialState, action: ILopActionTypes): ILopPageState => {
    switch (action.type) {
        case eLopActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                dm_lops: action.payload
            }
        case eLopActionTypeIds.LOAD_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                dm_lops: []
            }
        case eLopActionTypeIds.LOAD_ALL_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopActionTypeIds.LOAD_ALL_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                dm_lops: action.payload
            }
        case eLopActionTypeIds.LOAD_ALL_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                dm_lops: []
            }
        case eLopActionTypeIds.LOAD_TREE_CTE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopActionTypeIds.LOAD_TREE_CTE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                treeViewItems: action.payload
            }
        case eLopActionTypeIds.LOAD_TREE_CTE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                treeViewItems: []
            }
        case eLopActionTypeIds.SELECT_LOPCHUNHIEM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lop_chu_nhiem: action.payload
            }
        case eLopActionTypeIds.SELECT_LOPCHUNHIEM_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lop_chu_nhiem: undefined
            }
        case eLopActionTypeIds.SELECT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopActionTypeIds.SELECT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                dm_lops: action.payload
            }
        case eLopActionTypeIds.SELECT_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                dm_lops: []
            }
        case eLopActionTypeIds.SELECT_DETAIL_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopActionTypeIds.SELECT_DETAIL_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                dm_lop_detail: action.payload
            }
        case eLopActionTypeIds.SELECT_DETAIL_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                dm_lop_detail: undefined
            }
        case eLopActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                dm_lop_editing: action.payload
            }
        case eLopActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                dm_lop_editing: undefined
            }
        case eLopActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                dm_lop_selected_ids: action.payload
            }
        case eLopActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eLopActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eLopActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eLopActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eLopActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eLopActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eLopActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        case eLopActionTypeIds.CHANGE_THONGTU_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopActionTypeIds.CHANGE_THONGTU_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eLopActionTypeIds.CHANGE_THONGTU_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eLopActionTypeIds.SELECT_HOCSINH_START:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_loading
            }
        case eLopActionTypeIds.SELECT_HOCSINH_SUCCESS:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed,
                ds_hocsinh_theo_lops: action.payload
            }
        case eLopActionTypeIds.SELECT_HOCSINH_ERROR:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed,
                ds_hocsinh_theo_lops: []
            }
        case eLopActionTypeIds.SELECT_HOCSINH_CHUAPHANLOP_START:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_loading
            }
        case eLopActionTypeIds.SELECT_HOCSINH_CHUAPHANLOP_SUCCESS:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed,
                ds_hocsinh_chuaphanlops: action.payload
            }
        case eLopActionTypeIds.SELECT_HOCSINH_CHUAPHANLOP_ERROR:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed,
                ds_hocsinh_chuaphanlops: []
            }
        case eLopActionTypeIds.CHANGE_HOCSINH_START:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_saving
            }
        case eLopActionTypeIds.CHANGE_HOCSINH_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_need_reload,
            }
        case eLopActionTypeIds.CHANGE_HOCSINH_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed,
            }
        case eLopActionTypeIds.CHANGE_HOCSINH_THUTU_START:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_saving
            }
        case eLopActionTypeIds.CHANGE_HOCSINH_THUTU_SUCCESS:
            NotifyHelper.Success("Thay đổi thứ tự thành công.")
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_need_reload,
            }
        case eLopActionTypeIds.CHANGE_HOCSINH_THUTU_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed,
            }
        case eLopActionTypeIds.CHANGE_THUTU_MACDINH_START:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_saving
            }
        case eLopActionTypeIds.CHANGE_THUTU_MACDINH_SUCCESS:
            NotifyHelper.Success("Đặt thứ tự mặc định thành công.")
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_need_reload,
            }
        case eLopActionTypeIds.CHANGE_THUTU_MACDINH_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed,
            }
        case eLopActionTypeIds.REMOVE_HOCSINH_START:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_saving
            }
        case eLopActionTypeIds.REMOVE_HOCSINH_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_need_reload,
            }
        case eLopActionTypeIds.REMOVE_HOCSINH_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed,
            }
        case eLopActionTypeIds.UPLOAD_HOCSINH_EXCEL_START:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_loading
            }
        case eLopActionTypeIds.UPLOAD_HOCSINH_EXCEL_SUCCESS:
            NotifyHelper.Success("Load file excel thành công.")
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed,
                data_from_excel: action.payload
            }
        case eLopActionTypeIds.UPLOAD_HOCSINH_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed
            }
        case eLopActionTypeIds.SAVE_HOCSINH_EXCEL_START:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_saving
            }
        case eLopActionTypeIds.SAVE_HOCSINH_EXCEL_SUCCESS:
            NotifyHelper.Success("Import dữ liệu thành công.")
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_need_reload,
                data_from_excel: action.payload
            }
        case eLopActionTypeIds.SAVE_HOCSINH_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed
            }
        case eLopActionTypeIds.LOCK_PARENT_APP_START:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_saving
            }
        case eLopActionTypeIds.LOCK_PARENT_APP_SUCCESS:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_need_reload,
            }
        case eLopActionTypeIds.LOCK_PARENT_APP_ERROR:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_need_reload
            }
        case eLopActionTypeIds.LOAD_PHUHUYNH_START:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_loading
            }
        case eLopActionTypeIds.LOAD_PHUHUYNH_SUCCESS:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed,
                phuHuynhs: action.payload
            }
        case eLopActionTypeIds.LOAD_PHUHUYNH_ERROR:
            return {
                ...state,
                hocsinh_status: ePageBaseStatus.is_completed,
                phuHuynhs: []
            }
        case eLopActionTypeIds.UPLOAD_LOP_EXCEL_START:
            return {
                ...state,
                upload_status: ePageBaseStatus.is_loading
            }
        case eLopActionTypeIds.UPLOAD_LOP_EXCEL_SUCCESS:
            NotifyHelper.Success("Load file excel thành công.")
            return {
                ...state,
                upload_status: ePageBaseStatus.is_completed,
                data_from_excel: action.payload
            }
        case eLopActionTypeIds.UPLOAD_LOP_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                upload_status: ePageBaseStatus.is_completed
            }
        case eLopActionTypeIds.SAVE_LOP_EXCEL_START:
            return {
                ...state,
                upload_status: ePageBaseStatus.is_saving
            }
        case eLopActionTypeIds.SAVE_LOP_EXCEL_SUCCESS:
            NotifyHelper.Success("Xử lý Import dữ liệu thành công. Vui lòng kiểm tra kết quả trong cột Trạng thái.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                upload_status: ePageBaseStatus.is_need_reload,
                data_from_excel: action.payload
            }
        case eLopActionTypeIds.SAVE_LOP_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                hocsinh_status: ePageBaseStatus.is_completed
            }
        default:
            return state;
    }
}
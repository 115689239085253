import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eNotifyActionTypeIds, INotifyActionTyes } from './../../action-types/notify/INotifyActionTypes';
import { INotifyPageState } from './../../page-state-model/notify/INotifyPageState';
const iniState: INotifyPageState = {
    notifies: [],
    page_number: 1,
    status: ePageBaseStatus.is_not_initialization,
    total_unread: 0
}
export const notifyReducer = (state: INotifyPageState = iniState, action: INotifyActionTyes): INotifyPageState => {
    switch (action.type) {
        case eNotifyActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eNotifyActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                notifies: action.payload
            }
        case eNotifyActionTypeIds.LOAD_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                notifies: []
            }

        case eNotifyActionTypeIds.COUNT_UN_READ_SUCCESS:
            return {
                ...state,
                total_unread: action.payload,
            }
        case eNotifyActionTypeIds.MARK_AS_READ_SUCCESS:
            return {
                ...state,
                notifies: state.notifies.map(x => {
                    if (x.app_notify_id === action.payload) {
                        return {
                            ...x,
                            seem_time: new Date().toJSON()
                        }
                    }
                    return x;
                })
            }
        default:
            return {
                ...state
            }
    }
}
import { sis_sodiem_thanhphandiem } from './../../../models/response/so-diem/sis_sodiem_thanhphandiem';
import { NotifyHelper } from './../../../helpers/toast';
import { ISoDiemMapping } from './../../../models/response/so-diem/ISoDiemMapping';
import { eSoDiemMappingActionTypeIds } from './../../action-types/so-diem/ISoDiemMappingActionType';
import { baseAction } from "../IActionBase";
import { ISoDiemUpdateMoetRequest } from '../../../models/request/so-diem/ISoDiemUpdateMoetRequest';

export const soDiemMappingActions = {
    loadStart: (soDiemId: number) => baseAction(eSoDiemMappingActionTypeIds.LOAD_MAPPING_START, soDiemId),
    loadSuccess: (data: ISoDiemMapping[]) => baseAction(eSoDiemMappingActionTypeIds.LOAD_MAPPING_SUCCESS, data),
    loadError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eSoDiemMappingActionTypeIds.LOAD_MAPPING_ERROR, message)
    },
    loadThanhPhanStart: (soDiemId: number) => baseAction(eSoDiemMappingActionTypeIds.LOAD_MOET_THANHPHAN_START, soDiemId),
    loadThanhPhanSuccess: (data: sis_sodiem_thanhphandiem[]) => {
        return baseAction(eSoDiemMappingActionTypeIds.LOAD_MOET_THANHPHAN_SUCCESS, data)
    },
    loadThanhPhanError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eSoDiemMappingActionTypeIds.LOAD_MOET_THANHPHAN_ERROR, message)
    },

    updateMoetStart: (request: ISoDiemUpdateMoetRequest) => baseAction(eSoDiemMappingActionTypeIds.UPDATE_MOET_START, request),
    updateMoetSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eSoDiemMappingActionTypeIds.UPDATE_MOET_SUCCESS, undefined)
    },
    updateMoetError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eSoDiemMappingActionTypeIds.UPDATE_MOET_ERROR, message)
    },
    showMappingModal: (data: sis_sodiem_thanhphandiem) => baseAction(eSoDiemMappingActionTypeIds.SHOW_MAPPING_MODAL, data),
    closeMappingModal: () => baseAction(eSoDiemMappingActionTypeIds.CLOSE_MAPPING_MODAL, undefined),

    saveMappingStart: (data: ISoDiemMapping) => baseAction(eSoDiemMappingActionTypeIds.SAVE_MAPPING_START, data),
    saveMappingSucess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eSoDiemMappingActionTypeIds.SAVE_MAPPING_SUCCESS, undefined)
    },
    saveMappingError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eSoDiemMappingActionTypeIds.SAVE_MAPPING_ERRROR, message)
    },

    showDeleteConfirm: (data: sis_sodiem_thanhphandiem) => baseAction(eSoDiemMappingActionTypeIds.SHOW_DELETE_CONFIRM, data),
    closeDeleteConfirm: () => baseAction(eSoDiemMappingActionTypeIds.CLOSE_DELETE_CONFIRM, undefined),
}
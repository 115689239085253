import { NotifyHelper } from './../../../helpers/toast';
import { INotifyUserItemRespone } from '../../../models/response/notify/INotifyUserItemRespone';
import { baseAction } from '../IActionBase';
import { INotifySelectRequest } from './../../../models/request/notify/INotifySelectRequest';
import { eNotifyActionTypeIds, INotifyLoadStart, INotifyLoadSuccess, INotifyLoadError, INotifyMarkAsReadStart, INotifyMarkAsReadSuccess, INotifyMarkAsReadError, INotifyMarkAllAsReadStart, INotifyMarkAllAsReadSuccess, INotifyMarkAllAsReadError, INotifyCountUnReadStart, INotifyCountUnReadSuccess, INotifyCountUnReadError } from './../../action-types/notify/INotifyActionTypes';
export const notifyActions = {
    loadStart: (payload: INotifySelectRequest): INotifyLoadStart => baseAction(eNotifyActionTypeIds.LOAD_START, payload),
    loadSuccess: (data: INotifyUserItemRespone[]): INotifyLoadSuccess => baseAction(eNotifyActionTypeIds.LOAD_SUCCESS, data),
    loadError: (message: string): INotifyLoadError => {
        NotifyHelper.Error(message);
        return baseAction(eNotifyActionTypeIds.LOAD_ERROR, message)
    },

    markAsReadStart: (notifyId: number): INotifyMarkAsReadStart => baseAction(eNotifyActionTypeIds.MARK_AS_READ_START, notifyId),
    markAsReadSuccess: (notifyId: number): INotifyMarkAsReadSuccess => baseAction(eNotifyActionTypeIds.MARK_AS_READ_SUCCESS, notifyId),
    markAsReadError: (message: string): INotifyMarkAsReadError => {
        NotifyHelper.Error(message)
        return baseAction(eNotifyActionTypeIds.MARK_AS_READ_ERROR, message)
    },

    markAllAsReadStart: (): INotifyMarkAllAsReadStart => baseAction(eNotifyActionTypeIds.MARK_ALL_AS_READ_START, undefined),
    markAllAsReadSuccess: (): INotifyMarkAllAsReadSuccess => baseAction(eNotifyActionTypeIds.MARK_ALL_AS_READ_SUCCESS, undefined),
    markAllAsReadError: (message: string): INotifyMarkAllAsReadError => {
        NotifyHelper.Error(message)
        return baseAction(eNotifyActionTypeIds.MARK_ALL_AS_READ_ERROR, message)
    },

    countUnReadStart: (): INotifyCountUnReadStart => baseAction(eNotifyActionTypeIds.COUNT_UN_READ_START, undefined),
    countUnReadSuccess: (total: number): INotifyCountUnReadSuccess => baseAction(eNotifyActionTypeIds.COUNT_UN_READ_SUCCESS, total),
    countUnReadError: (message: string): INotifyCountUnReadError => {
        NotifyHelper.Error(message);
        return baseAction(eNotifyActionTypeIds.COUNT_UN_READ_ERROR, message)
    }
}
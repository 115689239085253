// import LoadPanel from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { appInfo } from './AppInfo';
import { LazyPage } from './components/loadable';
import { CommonProvider } from './contexts/common';
import { NavigationProvider } from './contexts/navigation';
import './dx-styles.scss';
import { actions } from './state/actions/actionsWrapper';
import { localizedResourceAction } from './state/actions/localized-resource/localizedResourceAction';
import { RootState } from './state/reducers';
import './style.css';
import './themes/generated/theme.additional.css';
import './themes/generated/theme.base.css';
import { useScreenSizeClass } from './utils/media-query';
import path from 'path';
import { PhoDiemPage } from './pages';
const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};
const Content = LazyPage(import('./Content'));

const App = () => {
	const user = useSelector((state: RootState) => state.auth.user_info);
	const isLoginPrcessing = useSelector((state: RootState) => state.account.isLoading);
	const isGettingUser = useSelector((state: RootState) => state.auth.isLoading);
	const isLoading = isLoginPrcessing || isGettingUser;
	const query = useQuery();
	const hash = query.get('hash') || '';
	const user_id: string = query.get('user') || '0';
	const ticket_key = query.get('key') || '';


	const dispatch = useDispatch();

	//1. Nếu đã đăng nhập, đúng user từ portal -> OK
	//2. Nếu đã đăng nhập nhưng không đúng user từ portal -> đăng xuất
	//3. Nếu chưa đăng nhập, chưa có tickey_key, hash -> Chuyển sang portal
	//4. Nếu chưa đăng nhập, có ticket_key, hash -> thực hiện đăng nhập

	//Đọc access token để set user
	//Nếu user chưa đc set: undefined
	// Đã được set (dù có hợp lệ hay không): != undefined
	useEffect(() => {
		if (user == undefined) {
			dispatch(actions.auth.getUser());
		}
	}, [user]);
	//Sau khi check từ access token
	useEffect(() => {
		if (user != undefined) {
			if (user.id > 0 && user_id !== '0' && user.id.toString() !== user_id) {
				dispatch(actions.account.logOut());
			}
			if (!user || user.id <= 0) {
				if (ticket_key != '' && hash != '') {
					dispatch(actions.account.loginStart({ ticket_key: ticket_key, hash: hash }));
				} else {
					const url = appInfo.homeURL + '/login?service=' + window.location.origin + '&system_id=' + appInfo.subSytemId;
					window.location.href = url;
				}
			}
		}
	}, [user, ticket_key, hash, user_id]);

	useEffect(() => {
		if (user && user.id) {
			// dispatch(loadDictionary())
		}
	}, [user]);

	if (user && user.id > 0 && (user.id.toString() === user_id || user_id === '0')) {
		return <Content />;
	}
	return (
		<div
			style={{
				display: 'flex',
				flex: 1,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<img
				src={`${appInfo.homeURL}/images/logo.png`}
				style={{
					width: '100px',
					height: 'auto',
				}}
			/>
		</div>
	);
};
const RouteredContent = () => {
	const location = useLocation();
	const currentPath = location.pathname;
	console.log({
		path: currentPath
	});
	return (
		<>
			{currentPath.startsWith('/pho-diem') && <PhoDiemPage />}
			{!currentPath.startsWith('/pho-diem') && <App />}
		</>
	);
}
export default function () {
	const screenSizeClass = useScreenSizeClass();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(localizedResourceAction.loadStart(localStorage.getItem('language') ?? 'vi'));
	}, []);

	return (
		<Router>
			<NavigationProvider>
				<CommonProvider>
					<div
						className={`app ${screenSizeClass}`}
						style={{
							backgroundImage: 'url(../../images/test.jpg)',
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
						}}
					>
						<RouteredContent />
						{/* <App /> */}
					</div>
				</CommonProvider>
			</NavigationProvider>
		</Router>
	);
}

import { call, put, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { hocSinhApi } from '../../../api/hocSinhApi';
import { IBaseResponeModel } from '../../../models/response/base-response';
import { actions } from '../../actions/actionsWrapper';
import { ITongHopDinhKyHocSinhLoadStart, ITongHopDinhKyKetQuaLoadStart, ITongHopDinhKyMonHocLoadStart, eTongHopDinhKyActionTypeIds } from './../../action-types/tong-hop/ITongHopDinhKyActionTypes';


export function* tongHopDinhKySaga(): any {
    yield takeLatest(eTongHopDinhKyActionTypeIds.HOCSINH_LOAD_START, loadHocSinhWorker)
    yield takeLatest(eTongHopDinhKyActionTypeIds.MONHOC_LOAD_START, loadMonHocWorker)
    yield takeLatest(eTongHopDinhKyActionTypeIds.KETQUA_DANHGIA_LOAD_START, loadKetQuaWorker)
}

function* loadHocSinhWorker(action: ITongHopDinhKyHocSinhLoadStart): any {
    const res: IBaseResponeModel = yield call([hocSinhApi, hocSinhApi.Select], action.payload)
    if (res.is_success) {
        yield put(actions.tongHop.dinhKy.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.tongHop.dinhKy.loadHocSinhError(res.message ?? ""))
    }
}

function* loadMonHocWorker(action: ITongHopDinhKyMonHocLoadStart): any {
    const res: IBaseResponeModel = yield call(apiWrapper.tongHop.dinhKy.selectMonHocs, action.payload)
    if (res.is_success) {
        yield put(actions.tongHop.dinhKy.loadMonHocSuccess(res.data))
    } else {
        yield put(actions.tongHop.dinhKy.loadMonHocError(res.message ?? ""))
    }
}

function* loadKetQuaWorker(action: ITongHopDinhKyKetQuaLoadStart): any {
    const res: IBaseResponeModel = yield call(apiWrapper.tongHop.dinhKy.selectKetQuas, action.payload)
    if (res.is_success) {
        yield put(actions.tongHop.dinhKy.loadKetQuaDinhKySuccess(res.data))
    } else {
        yield put(actions.tongHop.dinhKy.loadKetQuaDinhKyError(res.message ?? ""))
    }
}
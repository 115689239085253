import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eGiaiDoanActionTypeIds, IGiaiDoanActionTypes } from '../../action-types/so-diem/IGiaiDoanActions';
import { NotifyHelper } from './../../../helpers/toast';
import { IGiaiDoanStateModel } from './../../page-state-model/so-diem/IGiaiDoanStateModel';
const iniState: IGiaiDoanStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    giaiDoans: []
}
export const giaiDoanReducer = (state: IGiaiDoanStateModel = iniState, action: IGiaiDoanActionTypes): IGiaiDoanStateModel => {
    switch (action.type) {
        case eGiaiDoanActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eGiaiDoanActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                giaiDoans: action.payload
            }
        case eGiaiDoanActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                giaiDoans: []
            }
        default:
            return {
                ...state
            }
    }
}
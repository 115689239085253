import { IDiem } from './../../../models/response/diem/IDiem';
import { ePageBaseStatus } from './../../../models/ePageBaseStatus';
import { eNhapDiemActionTypeIds, INhapDiemActionTypes } from './../../action-types/nhap-diem/INhapDiemActionType';
import { eNhapDiemPageState, eNhapDiemViewMode, INhapDiemPagaState } from './../../page-state-model/nhap-diem/INhapDiemPageState';
const iniState: INhapDiemPagaState = {
    diems: [],
    diems_chuyende: [],
    lopBoMonHocSinhs: [],
    status: ePageBaseStatus.is_not_initialization,
    filter: {
        dm_khoi_id: 0,
        pl_lopbomon_id: 0,
        sis_monhoc_id: 0,
    },
    loading: {
        diem: "",
        hocSinh: "",
        soDiem: ""
    },
    isShowMappingDiemBaiTapModal: false,
    soDiemInfo: undefined,
    isShowCalculatorModal: false,
    soDiemThanhPhanDiemCalculating: undefined,
    viewMode: eNhapDiemViewMode.subject,
    diems_moet: [],
    isShowConvertMoetConfirm: false,
    importData: []
}
const mergeDiems = (diems: IDiem[], newDiems: IDiem[]): IDiem[] => {
    let result = diems.map(x => {
        var _newDiem = newDiems.find(y => x.id == y.id);
        if (_newDiem) return _newDiem;
        return x;
    })
    newDiems.map(y => {
        var _existedDiem = diems.find(x => x.id == y.id)
        if (!_existedDiem) {
            result.push(y);
        }
    })
    return result;
}
export const nhapDiemReducer = (state: INhapDiemPagaState = iniState, action: INhapDiemActionTypes): INhapDiemPagaState => {
    switch (action.type) {
        case eNhapDiemActionTypeIds.LOAD_HOCSINH_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading,
                loading: {
                    ...state.loading,
                    hocSinh: "pending"
                }
            }
        case eNhapDiemActionTypeIds.LOAD_HOCSINH_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lopBoMonHocSinhs: action.payload,
                loading: {
                    ...state.loading,
                    hocSinh: ""
                }
            }
        case eNhapDiemActionTypeIds.LOAD_HOCSINH_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lopBoMonHocSinhs: [],
                loading: {
                    ...state.loading,
                    hocSinh: ""
                }
            }
        case eNhapDiemActionTypeIds.LOAD_DIEM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading,
                diems_chuyende:[],
                loading: {
                    ...state.loading,
                    diem: "pending"
                }
            }
        case eNhapDiemActionTypeIds.LOAD_DIEM_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                diems: action.payload,
                loading: {
                    ...state.loading,
                    diem: ""
                }
            }
        case eNhapDiemActionTypeIds.LOAD_DIEM_CHUYEN_DE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                diems_chuyende: action.payload,

            }
        case eNhapDiemActionTypeIds.LOAD_DIEM_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                diems: [],
                loading: {
                    ...state.loading,
                    diem: ""
                }
            }
        case eNhapDiemActionTypeIds.LOAD_DIEM_MOET_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading,
                loading: {
                    ...state.loading,
                    diem: "pending"
                }
            }
        case eNhapDiemActionTypeIds.LOAD_DIEM_MOET_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                diems_moet: action.payload,
                loading: {
                    ...state.loading,
                    diem: ""
                }
            }
        case eNhapDiemActionTypeIds.LOAD_DIEM_MOET_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                diems_moet: [],
                loading: {
                    ...state.loading,
                    diem: ""
                }
            }
        case eNhapDiemActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case eNhapDiemActionTypeIds.SHOW_MAPPING_DIEMBAITAP_MODAL:
            return {
                ...state,
                isShowMappingDiemBaiTapModal: action.payload
            }
        case eNhapDiemActionTypeIds.LOAD_SODIEM_INFO_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soDiem: "pending"
                }
            }
        case eNhapDiemActionTypeIds.LOAD_SODIEM_INFO_SUCCESS:
            return {
                ...state,
                soDiemInfo: action.payload,
                loading: {
                    ...state.loading,
                    soDiem: ""
                }
            }
        case eNhapDiemActionTypeIds.LOAD_SODIEM_INFO_ERROR:
            return {
                ...state,
                soDiemInfo: undefined,
                loading: {
                    ...state.loading,
                    soDiem: ""
                }
            }
        case eNhapDiemActionTypeIds.LOAD_SODIEM_MOET_INFO_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soDiem: "pending"
                }
            }
        case eNhapDiemActionTypeIds.LOAD_SODIEM_MOET_INFO_SUCCESS:
            return {
                ...state,
                soDiemMoetInfo: action.payload,
                loading: {
                    ...state.loading,
                    soDiem: ""
                }
            }
        case eNhapDiemActionTypeIds.LOAD_SODIEM_MOET_INFO_ERROR:
            return {
                ...state,
                soDiemMoetInfo: undefined,
                loading: {
                    ...state.loading,
                    soDiem: ""
                }
            }
        case eNhapDiemActionTypeIds.UPDATE_DIEM_SUCCESS:
            let newDiemAfterUpdated = mergeDiems(state.diems, [action.payload.diem])
            return {
                ...state,
                diems: newDiemAfterUpdated
            }
        case eNhapDiemActionTypeIds.SHOW_CALCULATOR_MODAL:
            return {
                ...state,
                soDiemThanhPhanDiemCalculating: action.payload,
                isShowCalculatorModal: true
            }
        case eNhapDiemActionTypeIds.CLOSE_CALCULATOR_MODAL:
            return {
                ...state,
                soDiemThanhPhanDiemCalculating: undefined,
                isShowCalculatorModal: false
            }
        case eNhapDiemActionTypeIds.CALCULATE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhapDiemActionTypeIds.CALCULATE_SUCCESS:
            // let newDiemAfterCalculated = mergeDiems(state.diems, action.payload)
            return {
                ...state,
                isShowCalculatorModal: false,
                status: eNhapDiemPageState.is_need_reload_diems
            }
        case eNhapDiemActionTypeIds.CALCULATE_ERROR:
            return {
                ...state,
                status: eNhapDiemPageState.is_need_reload_diems
            }
        case eNhapDiemActionTypeIds.MAPPING_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhapDiemActionTypeIds.MAPPING_SUCCESS:
            return {
                ...state,
                status: eNhapDiemPageState.is_need_reload_diems,
                isShowMappingDiemBaiTapModal: false
            }
        case eNhapDiemActionTypeIds.MAPPING_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eNhapDiemActionTypeIds.CHANGE_VIEW_MODE:
            return {
                ...state,
                viewMode: action.payload
            }
        case eNhapDiemActionTypeIds.SHOW_CONVERT_MOET_CONFIRM:
            return {
                ...state,
                isShowConvertMoetConfirm: true
            }
        case eNhapDiemActionTypeIds.CLOSE_CONVERT_MOET_CONFIRM:
            return {
                ...state,
                isShowConvertMoetConfirm: false
            }
        case eNhapDiemActionTypeIds.CONVERT_MOET_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhapDiemActionTypeIds.CONVERT_MOET_SUCCESS:
            // let newDiemAfterCalculated = mergeDiems(state.diems, action.payload)
            return {
                ...state,
                isShowConvertMoetConfirm: false,
                status: eNhapDiemPageState.is_need_reload_diems,
                viewMode: eNhapDiemViewMode.moet
            }
        case eNhapDiemActionTypeIds.CONVERT_MOET_ERROR:
            return {
                ...state,
                status: eNhapDiemPageState.is_need_reload_diems
            }
        case eNhapDiemActionTypeIds.VALIDATE_IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eNhapDiemActionTypeIds.VALIDATE_IMPORT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: action.payload
            }
        case eNhapDiemActionTypeIds.VALIDATE_IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: []
            }
        case eNhapDiemActionTypeIds.IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhapDiemActionTypeIds.IMPORT_SUCCESS:
            return {
                ...state,
                status: eNhapDiemPageState.is_need_reload_diems,
            }
        case eNhapDiemActionTypeIds.IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eNhapDiemActionTypeIds.UPDATE_DIEM_ALL_THANHPHAN_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eNhapDiemActionTypeIds.UPDATE_DIEM_ALL_THANHPHAN_SUCCESS:
            return {
                ...state,
                status: eNhapDiemPageState.is_need_reload_diems,
            }
        case eNhapDiemActionTypeIds.UPDATE_DIEM_ALL_THANHPHAN_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        default:
            return state;
    }
}
import { ImportDgtxNlpcRequest } from '../models/request/danh-gia/dinh-ky/ImportDgtxNlpcRequest';
import { ISelectDgtxKetQuaRequest } from '../models/request/danh-gia/thuong-xuyen/ISelectDgtxKetQuaRequest';
import { IUpdateDgtxKetQuaRequest } from '../models/request/danh-gia/thuong-xuyen/IUpdateDgtxKetQuaRequest';
import { apiClient } from './apiClient';
export const API_APP_DGTX_KETQUA_NLPC_PATH = "dgtx-nlpc"
export const dgtxKetQuaApi = {
    Select: (request: ISelectDgtxKetQuaRequest) => apiClient.post(`${API_APP_DGTX_KETQUA_NLPC_PATH}/select`, request),
    Save: (request: IUpdateDgtxKetQuaRequest) => apiClient.post(`${API_APP_DGTX_KETQUA_NLPC_PATH}/save`, request),
    Import: (request: ImportDgtxNlpcRequest) => apiClient.post(`${API_APP_DGTX_KETQUA_NLPC_PATH}/import`, request),
    ValidateImport: (request: ImportDgtxNlpcRequest) => apiClient.post(`${API_APP_DGTX_KETQUA_NLPC_PATH}/import/validate`, request),

    // Export: (request: IExportDgtxNlpcRequest) => apiClient.post(`${API_APP_DGDK_KETQUA_NLPC_PATH}/export`, request),
}
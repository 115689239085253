import { IXepHangHocSinhSelectRequest } from './../../../models/request/xep-hang-hoc-sinh/IXepHangHocSinhRequest';
import { IHocSinhSelectRequest } from '../../../models/request/hoc-sinh/IHocSinhSelectRequest';
import { IXepHangHocSinhRequest } from '../../../models/request/xep-hang-hoc-sinh/IXepHangHocSinhSelectRequest';
import { IHocSinhSelectResponeItem } from '../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { ILopMonHocItemRespone } from '../../../models/response/lop-mon-hoc/ILopMonHocItemRespone';
import { IXepHangHocSinh } from '../../../models/response/xep-hang-hoc-sinh/IXepHangHocSinh';
import { IXepHangHocSinhPageFilter } from '../../page-state-model/xep-hang-hoc-sinh/IXepHangHocSinhPageState';
import { IActionTypeBase } from '../IActionTypeBase';
import { IDiem } from '../../../models/response/diem/IDiem';
export enum eXepHangHocSinhActionTypeIds {
    CHANGE_FILTER = "XEPHANG_HOCSINH_CHANGE_FILTER",

    HOCSINH_LOAD_START = "XEPHANG_HOCSINH_HOCSINH_LOAD_START",
    HOCSINH_LOAD_SUCCESS = "XEPHANG_HOCSINH_HOCSINH_LOAD_SUCCESS",
    HOCSINH_LOAD_ERROR = "XEPHANG_HOCSINH_HOCSINH_LOAD_ERROR",

    MONHOC_LOAD_START = "XEPHANG_HOCSINH_MONHOC_LOAD_START",
    MONHOC_LOAD_SUCCESS = "XEPHANG_HOCSINH_MONHOC_LOAD_SUCCESS",
    MONHOC_LOAD_ERROR = "XEPHANG_HOCSINH_MONHOC_LOAD_ERROR",

    KETQUA_LOAD_START = "XEPHANG_HOCSINH_KETQUA_LOAD_START",
    KETQUA_LOAD_SUCCESS = "XEPHANG_HOCSINH_KETQUA_LOAD_SUCCESS",
    KETQUA_LOAD_ERROR = "XEPHANG_HOCSINH_KETQUA_LOAD_ERROR",

    SHOW_XEPHANG_CONFIRM = "XEPHANG_HOCSINH_SHOW_XEPHANG_CONFIRM",

    XEPHANG_START = "XEPHANG_HOCSINH_START",
    XEPHANG_SUCCESS = "XEPHANG_HOCSINH_SUCCESS",
    XEPHANG_ERROR = "XEPHANG_HOCSINH_ERROR",

    DIEM_LOAD_START = "XEPHANG_HOCSINH_DIEM_LOAD_START",
    DIEM_LOAD_SUCCESS = "XEPHANG_HOCSINH_DIEM_LOAD_SUCCESS",
    DIEM_LOAD_ERROR = "XEPHANG_HOCSINH_DIEM_LOAD_ERROR",
}

export interface IXepHangHocSinhChangeFilter extends IActionTypeBase<eXepHangHocSinhActionTypeIds.CHANGE_FILTER, IXepHangHocSinhPageFilter> { }

export interface IXepHangHocSinhHocSinhLoadStart extends IActionTypeBase<eXepHangHocSinhActionTypeIds.HOCSINH_LOAD_START, IHocSinhSelectRequest> { }
export interface IXepHangHocSinhHocSinhLoadSuccess extends IActionTypeBase<eXepHangHocSinhActionTypeIds.HOCSINH_LOAD_SUCCESS, IHocSinhSelectResponeItem[]> { }
export interface IXepHangHocSinhHocSinhLoadError extends IActionTypeBase<eXepHangHocSinhActionTypeIds.HOCSINH_LOAD_ERROR, string> { }

export interface IXepHangHocSinhMonHocLoadStart extends IActionTypeBase<eXepHangHocSinhActionTypeIds.MONHOC_LOAD_START, number> { }
export interface IXepHangHocSinhMonHocLoadSuccess extends IActionTypeBase<eXepHangHocSinhActionTypeIds.MONHOC_LOAD_SUCCESS, ILopMonHocItemRespone[]> { }
export interface IXepHangHocSinhMonHocLoadError extends IActionTypeBase<eXepHangHocSinhActionTypeIds.MONHOC_LOAD_ERROR, string> { }

export interface IXepHangShowConfirm extends IActionTypeBase<eXepHangHocSinhActionTypeIds.SHOW_XEPHANG_CONFIRM, boolean> { }

export interface IXepHangHocSinhStart extends IActionTypeBase<eXepHangHocSinhActionTypeIds.XEPHANG_START, IXepHangHocSinhRequest> { }
export interface IXepHangHocSinhSuccess extends IActionTypeBase<eXepHangHocSinhActionTypeIds.XEPHANG_SUCCESS, IXepHangHocSinh[]> { }
export interface IXepHangHocSinhError extends IActionTypeBase<eXepHangHocSinhActionTypeIds.XEPHANG_ERROR, string> { }

export interface IXepHangKetQuaLoadStart extends IActionTypeBase<eXepHangHocSinhActionTypeIds.KETQUA_LOAD_START, IXepHangHocSinhSelectRequest> { }
export interface IXepHangKetQuaLoadSuccess extends IActionTypeBase<eXepHangHocSinhActionTypeIds.KETQUA_LOAD_SUCCESS, IXepHangHocSinh[]> { }
export interface IXepHangKetQuaLoadError extends IActionTypeBase<eXepHangHocSinhActionTypeIds.KETQUA_LOAD_ERROR, string> { }

export interface IXepHangDiemLoadStart extends IActionTypeBase<eXepHangHocSinhActionTypeIds.DIEM_LOAD_START, IXepHangHocSinhSelectRequest> { }
export interface IXepHangDiemLoadSuccess extends IActionTypeBase<eXepHangHocSinhActionTypeIds.DIEM_LOAD_SUCCESS, IDiem[]> { }
export interface IXepHangDiemLoadError extends IActionTypeBase<eXepHangHocSinhActionTypeIds.DIEM_LOAD_ERROR, string> { }

export type IXepHangHocSinhActionTypes = IXepHangHocSinhChangeFilter |
    IXepHangHocSinhHocSinhLoadStart | IXepHangHocSinhHocSinhLoadSuccess | IXepHangHocSinhHocSinhLoadError |
    IXepHangHocSinhMonHocLoadStart | IXepHangHocSinhMonHocLoadSuccess | IXepHangHocSinhMonHocLoadError |
    IXepHangHocSinhStart | IXepHangHocSinhSuccess | IXepHangHocSinhError |
    IXepHangShowConfirm |
    IXepHangKetQuaLoadStart | IXepHangKetQuaLoadSuccess | IXepHangKetQuaLoadError |
    IXepHangDiemLoadStart | IXepHangDiemLoadSuccess | IXepHangDiemLoadError
import { NotifyHelper } from '../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eBaiVietTrangThaiActionTypeIds, IBaiVietTrangThaiActionTypes } from './../../action-types/bai-viet/IBaiVietTrangThaiActionTypes';
import { IBaiVietTrangThaiPageState } from './../../page-state-model/bai-viet/IBaiVietTrangThaiPageState';

const initialState: IBaiVietTrangThaiPageState = {
    status: ePageBaseStatus.is_not_initialization,
    app_baiviet_trangthais: []
}

export const baiVietTrangThaiReducer = (state: IBaiVietTrangThaiPageState = initialState, action: IBaiVietTrangThaiActionTypes): IBaiVietTrangThaiPageState => {
    switch (action.type) {
        case eBaiVietTrangThaiActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiVietTrangThaiActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviet_trangthais: action.payload
            }
        case eBaiVietTrangThaiActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviet_trangthais: []
            }
        default:
            return state;
    }

}
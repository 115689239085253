import { sis_sodiem_thanhphandiem } from './../../../models/response/so-diem/sis_sodiem_thanhphandiem';
import { eSoDiemConfigActionTypeIds } from "../../action-types/so-diem/ISoDiemConfigActionTypes";
import { BaseAction } from "../IBaseActionResult";
import { ISoDiemAddThanhPhanRequest } from '../../../models/request/so-diem/ISoDiemAddThanhPhanRequest';
import { ISoDiemRemoveThanhPhanRequest } from '../../../models/request/so-diem/ISoDiemRemoveThanhPhanRequest';

export const soDiemConfigActions = {
    loadThanhPhanStart: (sis_sodiem_id: number) => BaseAction(sis_sodiem_id, eSoDiemConfigActionTypeIds.LOAD_SODIEM_THANHPHAN_START),
    loadThanhPhanSuccess: (respone: sis_sodiem_thanhphandiem[]) => BaseAction(respone, eSoDiemConfigActionTypeIds.LOAD_SODIEM_THANHPHAN_SUCCESS),
    loadThanhPhanError: (message: string) => BaseAction(message, eSoDiemConfigActionTypeIds.LOAD_SODIEM_THANHPHAN_ERROR),
    showAddThanhPhanModal: (isShow: boolean) => BaseAction(isShow, eSoDiemConfigActionTypeIds.SHOW_ADD_THANHPHAN_MODAL),
    addThanhPhanStart: (request: ISoDiemAddThanhPhanRequest) => BaseAction(request, eSoDiemConfigActionTypeIds.ADD_THANHPHAN_START),
    addThanhPhanSuccess: () => BaseAction(undefined, eSoDiemConfigActionTypeIds.ADD_THANHPHAN_SUCCESS),
    addThanhPhanError: (mesage: string) => BaseAction(mesage, eSoDiemConfigActionTypeIds.ADD_THANHPHAN_ERROR),
    showRemoveThanhPhanModal: (isShow: boolean) => BaseAction(isShow, eSoDiemConfigActionTypeIds.SHOW_REMOVE_THANHPHAN_MODAL),
    removeThanhPhanStart: (request: ISoDiemRemoveThanhPhanRequest) => BaseAction(request, eSoDiemConfigActionTypeIds.REMOVE_THANHPHAN_START),
    removeThanhPhanSuccess: () => BaseAction(undefined, eSoDiemConfigActionTypeIds.REMOVE_THANHPHAN_SUCCESS),
    removeThanhPhanError: (message: string) => BaseAction(message, eSoDiemConfigActionTypeIds.REMOVE_THANHPHAN_ERROR),
    changeDataSouce: (dataSouce: any[]) => BaseAction(dataSouce, eSoDiemConfigActionTypeIds.CHANGE_DATA_SOURCE),
    updateThanhPhanStart: (request: sis_sodiem_thanhphandiem) => BaseAction(request, eSoDiemConfigActionTypeIds.UPDATE_THANHPHAN_START),
    updateThanhPhanSuccess: () => BaseAction(undefined, eSoDiemConfigActionTypeIds.UPDATE_THANHPHAN_SUCCESS),
    updateThanhPhanError: (message: string) => BaseAction(message, eSoDiemConfigActionTypeIds.UPDATE_THANHPHAN_ERROR)
}
import { NotifyHelper, showNotify } from '../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eChamTietLoaiTietActionTypeIds, IChamTietLoaiTietActionTypes } from "../../action-types/cham-tiet/IChamTietLoaiTietActionTypes";
import { IChamTietLoaiTietPageStateModel } from "../../page-state-model/cham-tiet/IChamTietLoaiTietState";

const initialState: IChamTietLoaiTietPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    sis_chamtiet_loaitiets: [],
    is_show_delete_confirm: false,
    sis_chamtiet_loaitiet_selected_ids: [],
    sis_chamtiet_loaitiet_editing: undefined,
    is_show_detail_modal: false,
    is_show_add_modal: false
}
export const chamTietLoaiTietReducer = (state: IChamTietLoaiTietPageStateModel = initialState, action: IChamTietLoaiTietActionTypes): IChamTietLoaiTietPageStateModel => {
    switch (action.type) {
        case eChamTietLoaiTietActionTypeIds.LOAD_START:
        case eChamTietLoaiTietActionTypeIds.LOAD_BYTRUONG_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eChamTietLoaiTietActionTypeIds.LOAD_SUCCESS:
        case eChamTietLoaiTietActionTypeIds.LOAD_BYTRUONG_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_chamtiet_loaitiets: action.payload
            }
        case eChamTietLoaiTietActionTypeIds.LOAD_ERROR:
        case eChamTietLoaiTietActionTypeIds.LOAD_BYTRUONG_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_chamtiet_loaitiets: []
            }
        case eChamTietLoaiTietActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_chamtiet_loaitiet_editing: action.payload
            }
        case eChamTietLoaiTietActionTypeIds.SHOW_ADD_MODAL:
            return {
                ...state,
                is_show_add_modal: action.payload,
                sis_chamtiet_loaitiet_selected_ids: []
            }
        case eChamTietLoaiTietActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_chamtiet_loaitiet_editing: undefined
            }
        case eChamTietLoaiTietActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_chamtiet_loaitiet_selected_ids: action.payload
            }
        case eChamTietLoaiTietActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eChamTietLoaiTietActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eChamTietLoaiTietActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eChamTietLoaiTietActionTypeIds.DELETE_SUCCESS:
            showNotify({ type: "success", message: 'Xóa dữ liệu thành công.' })
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eChamTietLoaiTietActionTypeIds.DELETE_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eChamTietLoaiTietActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eChamTietLoaiTietActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eChamTietLoaiTietActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }
        case eChamTietLoaiTietActionTypeIds.CLEAR_STATUS:
            return {
                ...initialState
            }
        default:
            return state;
    }

}

import React, { useCallback, useEffect } from "react";
import { useCommonContext } from "../../contexts/common";
import { getDefaultAnimation } from "../../helpers/pop-up-animation";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";


interface Window {
    animationShowPopup(animationOf: string): void;
    animationHidePopup(animationOf: string): void;
}
declare global {
    interface Window {
        animationShowPopup(animationOf: string): void;
        animationHidePopup(animationOf: string): void;
    }
}
type DefaultModalProps = {
    title?: string
    maxWidth?: any,
    minHeight?: number,
    children: React.ReactNode,
    height?: string
}
type AnimationModalProps = {
    title?: string
    animationOf: string,
    maxWidth?: any,
    minHeight?: number,
    children: React.ReactNode,
    height?: string,
    mustClose?: boolean,
    wrapperClass?: string
}
export const AnimationPopup = (props: AnimationModalProps) => {
    const { translate } = useCommonContext();
    const animaition = props.animationOf ? getDefaultAnimation({ of: props.animationOf }) : undefined;
    const onClose = useCallback(() => {
        window.animationHidePopup(props.animationOf || "")
    }, [props.animationOf])
    useEffect(() => {
        if (props.mustClose) onClose();
    }, [props.mustClose])


    return (
        <Popup
            maxWidth={props.maxWidth || 400}
            height={props.height || "auto"}
            maxHeight={window.innerHeight}
            minHeight={props.minHeight || 200}
            showTitle={props.title ? true : false}
            title={translate(props.title ?? "")}
            dragEnabled={true}
            closeOnOutsideClick={false}
            visible={true}
            showCloseButton={false}
            animation={animaition}
            width={props.maxWidth || 400}
            wrapperAttr={{
                class: (animaition ? `my-animation-popup` : '') + (" ") + (props.wrapperClass ? props.wrapperClass : "")
            }}
            onShown={(e: any) => {
                if (animaition) {
                    window.animationShowPopup(props.animationOf || "")
                }
            }}


        >
            <ScrollView width='100%' height='100%'>
                {props.children}
            </ScrollView>

        </ Popup>
    );
}

export const DefaultPopup = (props: DefaultModalProps) => {

    return (
        <Popup
            maxWidth={props.maxWidth || 400}
            height={props.height || "auto"}
            maxHeight={window.innerHeight}
            minHeight={props.minHeight || 250}
            showTitle={props.title ? true : false}
            title={props.title}
            dragEnabled={true}
            closeOnOutsideClick={false}
            visible={true}
            showCloseButton={false}
            animation={undefined}
            width={props.maxWidth || 400}
        >
            <ScrollView width='100%' height='100%'>
                {props.children}
            </ScrollView>

        </ Popup>
    );
}


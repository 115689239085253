import { AppAccountResetPasswordRequest } from "../../../models/request/app-phuhuynh/AppAccountResetPasswordRequest";
import { AppAccountSelectRequest } from "../../../models/request/app-phuhuynh/AppAccountSelectRequest";
import { IAppAccountUpdateStatusRequest } from "../../../models/request/app-phuhuynh/AppAccountUpdateStatusRequest";
import { AppAccountParentResponse } from "../../../models/response/app-phuhuynh/AppAccountParentResponse";
import { IAppAccountChangePasswordErr, IAppAccountChangePasswordStart, IAppAccountChangePasswordSuccess, IAppAccountChangeSelectedIds, IAppAccountCloseConfirm, IAppAccountCloseReset, IAppAccountLoadErr, IAppAccountLoadStart, IAppAccountLoadSuccess, IAppAccountShowConfirm, IAppAccountShowReset, IAppAccountUpdateStatusErr, IAppAccountUpdateStatusStart, IAppAccountUpdateStatusSuccess, eAppAccountActionTypeIds } from "../../action-types/app-phuhuynh/IAppAccountActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const appAccountActions = {
    loadStart: (request: AppAccountSelectRequest): IAppAccountLoadStart => BaseAction(request, eAppAccountActionTypeIds.LOAD_START),
    loadSuccess: (respone: AppAccountParentResponse[]): IAppAccountLoadSuccess => BaseAction(respone, eAppAccountActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IAppAccountLoadErr => BaseAction(messege, eAppAccountActionTypeIds.LOAD_ERROR),

    showConfirm: (): IAppAccountShowConfirm => BaseAction(undefined, eAppAccountActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IAppAccountCloseConfirm => BaseAction(undefined, eAppAccountActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IAppAccountChangeSelectedIds => BaseAction(ids, eAppAccountActionTypeIds.CHANGE_SELECTED_IDS),

    updateStatusStart: (data: IAppAccountUpdateStatusRequest): IAppAccountUpdateStatusStart => BaseAction(data, eAppAccountActionTypeIds.UPDATE_STATUS_START),
    updateStatusSuccess: (): IAppAccountUpdateStatusSuccess => BaseAction(undefined, eAppAccountActionTypeIds.UPDATE_STATUS_SUCCESS),
    updateStatusError: (message: string): IAppAccountUpdateStatusErr => BaseAction(message, eAppAccountActionTypeIds.UPDATE_STATUS_ERROR),

    showReset: (): IAppAccountShowReset => BaseAction(undefined, eAppAccountActionTypeIds.SHOW_RESET),
    closeReset: (): IAppAccountCloseReset => BaseAction(undefined, eAppAccountActionTypeIds.CLOSE_RESET),

    changePasswordStart: (data: AppAccountResetPasswordRequest): IAppAccountChangePasswordStart => BaseAction(data, eAppAccountActionTypeIds.CHANGE_PASSWORD_START),
    changePasswordSuccess: (): IAppAccountChangePasswordSuccess => BaseAction(undefined, eAppAccountActionTypeIds.CHANGE_PASSWORD_SUCCESS),
    changePasswordError: (message: string): IAppAccountChangePasswordErr => BaseAction(message, eAppAccountActionTypeIds.CHANGE_PASSWORD_ERROR),
}
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eBaiTapActionTypeIds, IBaiTapActionTypes } from './../../action-types/bai-tap/IBaiTapActionType';
import { IBaiTapPageState } from './../../page-state-model/bai-tap/IBaiTapPageState';
const iniState: IBaiTapPageState = {
    baiTapTreeViewItems: [],
    status: ePageBaseStatus.is_not_initialization,
    isShowDetailModal: false,
    isShowDeleteConfirm: false,
    baiTapTreeViewExpandedNodeKey: []
}
export const baiTapReducer = (state: IBaiTapPageState = iniState, action: IBaiTapActionTypes): IBaiTapPageState => {
    switch (action.type) {
        case eBaiTapActionTypeIds.LOAD_TREEVIEW_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiTapActionTypeIds.LOAD_TREEVIEW_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                baiTapTreeViewItems: action.payload
            }
        case eBaiTapActionTypeIds.LOAD_TREEVIEW_ERRR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                baiTapTreeViewItems: []
            }
        case eBaiTapActionTypeIds.SHOW_DETAIL_MODAL:
            return {
                ...state,
                isShowDetailModal: true,
                baiTapEditing: action.payload
            }
        case eBaiTapActionTypeIds.CLOSE_DETAIL_MODAL:
            return {
                ...state,
                isShowDetailModal: false
            }
        case eBaiTapActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eBaiTapActionTypeIds.SAVE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDetailModal: false
            }
        case eBaiTapActionTypeIds.SAVE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        
        case eBaiTapActionTypeIds.CHANGE_SELECTED:
            return {
                ...state,
                baiTapTreeViewSelectedItem: action.payload,
                baiTapEditing: action.payload.bai_tap
            }
        
        case eBaiTapActionTypeIds.SHOW_DELETE_CONFIRM:
            return {
                ...state,
                isShowDeleteConfirm: true,
                baiTapEditing: action.payload
            }
        case eBaiTapActionTypeIds.CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                isShowDeleteConfirm: false,
            }
        case eBaiTapActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eBaiTapActionTypeIds.DELETE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDeleteConfirm: false
            }
        case eBaiTapActionTypeIds.DELETE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,

            }
        case eBaiTapActionTypeIds.CHANGE_EXPANDED_NODE_KEYS:
            return {
                ...state,
                baiTapTreeViewExpandedNodeKey: action.payload

            }

        default:
            return { ...state }
    }
}
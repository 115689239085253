import { soDiemThanhPhanApi } from './../../../api/soDiemThanhPhanApi';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IBaseResponeModel } from '../../../models/response/base-response';
import { eSoDiemMappingActionTypeIds } from '../../action-types/so-diem/ISoDiemMappingActionType';
import { actions } from '../../actions/actionsWrapper';
import { soDiemMappingApi } from './../../../api/soDiemMappingApi';
import { ISoDiemMappingLoadStart, ISoDiemMappingLoadThanhPhanStart, ISoDiemMappingUpdateMoetStart, ISoDiemMappingSaveMappingStart } from './../../action-types/so-diem/ISoDiemMappingActionType';
export function* soDiemMappingSaga(): any {
    yield takeEvery(eSoDiemMappingActionTypeIds.LOAD_MAPPING_START, loadWorker);
    yield takeEvery(eSoDiemMappingActionTypeIds.LOAD_MOET_THANHPHAN_START, loadThanhPhanWorker);
    yield takeEvery(eSoDiemMappingActionTypeIds.UPDATE_MOET_START, updateMoetStart);
    yield takeEvery(eSoDiemMappingActionTypeIds.SAVE_MAPPING_START, saveMappingWorker);
}

function* loadWorker(action: ISoDiemMappingLoadStart): any {
    const res: IBaseResponeModel = yield call(soDiemMappingApi.loadBySoDiem, action.payload)
    if (res.is_success) {
        yield put(actions.soDiem.soDiemMapping.loadSuccess(res.data))
    } else {
        yield put(actions.soDiem.soDiemMapping.loadError(res.message || ""))

    }
}

function* loadThanhPhanWorker(action: ISoDiemMappingLoadThanhPhanStart): any {
    const res: IBaseResponeModel = yield call([soDiemThanhPhanApi, soDiemThanhPhanApi.SelectBySoDiem], action.payload)
    if (res.is_success) {
        yield put(actions.soDiem.soDiemMapping.loadThanhPhanSuccess(res.data))
    } else {
        yield put(actions.soDiem.soDiemMapping.loadThanhPhanError(res.message || ""))

    }
}

function* updateMoetStart(action: ISoDiemMappingUpdateMoetStart): any {
    const res: IBaseResponeModel = yield call(soDiemMappingApi.updateMoet, action.payload)
    if (res.is_success) {
        yield put(actions.soDiem.soDiemMapping.updateMoetSuccess())
    } else {
        yield put(actions.soDiem.soDiemMapping.updateMoetError(res.message || ""))

    }
}

function* saveMappingWorker(action: ISoDiemMappingSaveMappingStart): any {
    const res: IBaseResponeModel = yield call(soDiemMappingApi.saveMapping, action.payload)
    if (res.is_success) {
        yield put(actions.soDiem.soDiemMapping.saveMappingSucess())
    } else {
        yield put(actions.soDiem.soDiemMapping.saveMappingError(res.message || ""))

    }
}
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eAppMenuNhomActionTypeIds, IAppMenuNhomDeleteStart, IAppMenuNhomSaveStart } from "../../action-types/app-phuhuynh/IAppMenuNhomActionTypes";
import { actions } from "../../actions/actionsWrapper";

const appMenuNhomApi = apiWrapper.appMenuNhom;

export function* appMenuNhomSaga(): any {
    yield takeLatest(eAppMenuNhomActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eAppMenuNhomActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eAppMenuNhomActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([appMenuNhomApi, appMenuNhomApi.SelectAll])
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appMenuNhom.loadSuccess(res.data))
    } else {
        yield put(actions.appPhuHuynhWrapper.appMenuNhom.loadError(res.message || "Không tải được nhóm chức năng"))
    }
}
function* deleteWorker(action: IAppMenuNhomDeleteStart): any {
    const res: IBaseResponeModel = yield call([appMenuNhomApi, appMenuNhomApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appMenuNhom.deleteSuccess())
        yield put(actions.appPhuHuynhWrapper.appMenuNhom.loadStart())
    } else {
        yield put(actions.appPhuHuynhWrapper.appMenuNhom.deleteError(res.message || "Không xóa được nhóm chức năng"))
    }
}
function* saveWorker(action: IAppMenuNhomSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([appMenuNhomApi, appMenuNhomApi.Update], action.payload);
    } else {
        res = yield call([appMenuNhomApi, appMenuNhomApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appMenuNhom.saveSuccess())
        yield put(actions.appPhuHuynhWrapper.appMenuNhom.loadStart())
    } else {
        yield put(actions.appPhuHuynhWrapper.appMenuNhom.saveError(res.message || "Không lưu được nhóm chức năng"))
    }
}
import { call, put, takeLatest } from 'redux-saga/effects';
import { nhomThanhPhanDiemApi } from './../../../api/nhomThanhPhanDiemApi';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { eNhomThanhPhanDiemActionTypeIds } from './../../action-types/so-diem/INhomThanhPhanDiemActions';
import { actions } from './../../actions/actionsWrapper';
export function* nhomThanhPhanDiemSaga(): any {
    yield takeLatest(eNhomThanhPhanDiemActionTypeIds.LOAD_START, loadWorker);
}
function* loadWorker(): any {
    const res: IBaseResponeModel = yield call([nhomThanhPhanDiemApi, nhomThanhPhanDiemApi.SelectAll])
    if (res.is_success) {
        yield put(actions.soDiem.nhomThanhPhanDiem.loadSuccess(res.data))
    } else {
        yield put(actions.soDiem.nhomThanhPhanDiem.loadError(res.message || "Không tải được nhóm thành phần điểm"))

    }
}
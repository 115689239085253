import { IHocSinhSelectResponeItem } from './../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { IHocSinhSelectRequest } from './../../../models/request/hoc-sinh/IHocSinhSelectRequest';

export enum eHocSinhSelectionActionTypeIds {
    CHANGE_FILTER = "HOCSINH_SELECTION_CHANGE_FILTER",
    LOAD_START = "HOCSINH_SELECTION_LOAD_START",
    LOAD_SUCCESS = "HOCSINH_SELECTION_LOAD_SUCCESS",
    LOAD_ERROR = "HOCSINH_SELECTION_LOAD_ERROR",
    CHANGE_SELECTED_IDS = "HOCSINH_SELECTION_CHANGE_SELECTED_IDS"
}

export interface IHocSinhSelectionChangeFilter {
    type: eHocSinhSelectionActionTypeIds.CHANGE_FILTER,
    payload: IHocSinhSelectRequest
}
export interface IHocSinhSelectionLoadStart {
    type: eHocSinhSelectionActionTypeIds.LOAD_START,
    payload: IHocSinhSelectRequest
}
export interface IHocSinhSelectionLoadSuccess {
    type: eHocSinhSelectionActionTypeIds.LOAD_SUCCESS,
    payload: IHocSinhSelectResponeItem[]
}
export interface IHocSinhSelectionLoadError {
    type: eHocSinhSelectionActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IHocSinhSelectionChangeSelectdIds {
    type: eHocSinhSelectionActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export type IHocSinhSelectionActionTypes = IHocSinhSelectionChangeFilter |
    IHocSinhSelectionLoadStart | IHocSinhSelectionLoadSuccess | IHocSinhSelectionLoadError |
    IHocSinhSelectionChangeSelectdIds
import { IDeleteMultipleBaseRequest } from './../models/request/base/deleteRequest';
import { apiClient } from "./apiClient";

export abstract class BaseCRUDApi<T> {
    _path: string;
    _path_select_all: string;
    _path_insert: string;
    _path_update: string;
    _path_delete: string;
    _path_detail: string;
    _path_delete_multiple: string;
    constructor(path: string) {
        this._path = path
        this._path_select_all = path;
        this._path_insert = path;
        this._path_update = path;
        this._path_delete = `${path}/{id}`
        this._path_detail = `${path}/{id}`
        this._path_delete_multiple = `${path}/deletes`
    }
    SelectAll() {
        return apiClient.get(this._path_select_all);
    }
    SelectById(id: number) {
        return apiClient.get(this._path_detail.replaceAll("{id}", id.toString()));
    }
    Insert(data: T) {
        return apiClient.post(this._path_insert, data);
    }
    Update(data: T) {
        return apiClient.put(this._path_update, data);
    }
    Delete(id: number) {
        return apiClient.delete(this._path_delete.replaceAll("{id}", id.toString()));
    }
    DeleteMultiple(request: IDeleteMultipleBaseRequest) {
        return apiClient.post(this._path_delete_multiple, request)
    }
}
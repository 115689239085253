import { NotifyHelper } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { IHocSinhHoTroActionTypes, eHocSinhHoTroActionTypeIds } from "../../action-types/hoc-sinh/IHocSinhHoTroActionTypes";
import { IHocSinhHoTroState } from "../../page-state-model/hoc-sinh/IHocSinhHoTroState";

const initialState: IHocSinhHoTroState = {
    status: ePageBaseStatus.is_not_initialization,
    ts_hocsinh_hotros: [],
}

export const hocSinhHoTroReducer = (state: IHocSinhHoTroState = initialState, action: IHocSinhHoTroActionTypes): IHocSinhHoTroState => {
    switch (action.type) {
        case eHocSinhHoTroActionTypeIds.LOAD_BY_TRUONG_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eHocSinhHoTroActionTypeIds.LOAD_BY_TRUONG_SUCCESS:
            // debugger
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                ts_hocsinh_hotros: action.payload
            }
        case eHocSinhHoTroActionTypeIds.LOAD_BY_TRUONG_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                ts_hocsinh_hotros: []
            }
        case eHocSinhHoTroActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eHocSinhHoTroActionTypeIds.SAVE_SUCCESS:
            // NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eHocSinhHoTroActionTypeIds.SAVE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }

        default:
            return state;
    }

}
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFormKhaoSatEditorDeleteStart, IFormKhaoSatEditorLoadByFormStart, IFormKhaoSatEditorSaveStart, IFormKhaoSatEditorUpdateSelectOptionsStart, eFormKhaoSatEditorActionTypeIds } from "../../action-types/form-khaosat/IFormKhaoSatEditorActionTypes";

import { IBaseResponeModel } from "../../../models/response/base-response";
import { actions } from "../../actions/actionsWrapper";
import { apiWrapper } from "../../../api/apiWrapper";

const formKhaoSatEditorApi = apiWrapper.formKhaoSatWrapper.formKhaoSatEditor;

export function* formKhaoSatEditorSaga(): any {
    yield takeLatest(eFormKhaoSatEditorActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eFormKhaoSatEditorActionTypeIds.LOAD_EDITORTYPE_START, loadEditorTypeWorker)
    yield takeEvery(eFormKhaoSatEditorActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eFormKhaoSatEditorActionTypeIds.SAVE_START, saveWorker) 
    yield takeEvery(eFormKhaoSatEditorActionTypeIds.UPDATE_SELECT_OPTIONS_START, updateSelectOptionsWorker) 
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([formKhaoSatEditorApi, formKhaoSatEditorApi.SelectAll])
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatEditor.loadSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatEditor.loadError(res.message || "No data"))
    }
}

function* loadEditorTypeWorker(): any {
    const res: IBaseResponeModel = yield call([formKhaoSatEditorApi, formKhaoSatEditorApi.SelectAllTypeAsync])
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatEditor.loadEdItorTypeSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatEditor.loadEdItorTypeError(res.message || "No data"))
    }
}

function* deleteWorker(action: IFormKhaoSatEditorDeleteStart): any {
    const res: IBaseResponeModel = yield call([formKhaoSatEditorApi, formKhaoSatEditorApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatEditor.deleteSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatEditor.deleteErr(res.message || "No data"))
    }
}
function* saveWorker(action: IFormKhaoSatEditorSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([formKhaoSatEditorApi, formKhaoSatEditorApi.Update], action.payload);
    } else {
        res = yield call([formKhaoSatEditorApi, formKhaoSatEditorApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatEditor.saveSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatEditor.saveError(res.message || "Save fail"))
    }
}
function* updateSelectOptionsWorker(action: IFormKhaoSatEditorUpdateSelectOptionsStart): any {
    let res: IBaseResponeModel = yield call([formKhaoSatEditorApi, formKhaoSatEditorApi.UpdateSelectOptionsAsync], action.payload);
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatEditor.updateselectoptionsSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatEditor.updateselectoptionsError(res.message || "Save fail"))
    }
}

import { combineReducers } from "redux";
import { accountReducer } from "./accountReducer";
import { processAccountReducer } from "./processAccountReducer";
import { appPhuHuynhReducerWrapper } from './app-phuhuynh/appPhuHuynhReducerWrapper';
import { authReducer } from "./authReducer";
import { baiTapReducerWrapper } from './bai-tap/baiTapReducerWrapper';
import { baiVietDetailReducer } from './bai-viet/baiVietDetailReducer';
import { baiVietReducer } from './bai-viet/baiVietReducer';
import { baiVietTrangThaiReducer } from './bai-viet/baiVietTrangThaiReducer';
import { categorySourceReducer } from "./categorySourceReducer";
import { commonReducer } from "./commonReducer";
import { dgdkHocTapReducer } from './danh-gia/dinh-ky/dgdkHocTapReducer';
import { dgdkNlpcReducer } from './danh-gia/dinh-ky/dgdkNlpcReducer';
import { dgtxKetQuaMonHocReducer } from './danh-gia/thuong-xuyen/dgtxKetQuaMonHocReducer';
import { dgtxKetQuaReducer } from './danh-gia/thuong-xuyen/dgtxKetQuaReducer';
import { danhMucReducerWrapper } from './danh-muc/danhMucReducerWrapper';
import { giaoVienNhanXetReducer } from './danh-muc/giaoVienNhanXetReducer';
import { giaoVienReducer } from "./danh-muc/giaoVienReducer";
import { monHocReducer } from "./danh-muc/monHocReducer";
import { toKhoiReducer } from "./danh-muc/toKhoiReducer";
import { dictionaryReducer } from "./dictionaryReducer";
import { diemDanhReducer } from './diem-danh/diemDanhReducer';
import { diemDanhAnReducer } from './diem-danh-an/diemDanhAnReducer';
import { diemDanhStatusReducer } from './diem-danh/diemDanhStatusReducer';
import { hocSinhReducer } from './hoc-sinh/hocSinhReducer';
import { localizedResourceReducer } from './localized-resource/localizedResourceReducer';
import { lopBoMonReducer } from './lop-bo-mon/lopBoMonReducer';
import { lopChuNhiemReducer } from './lop-chu-nhiem/lopChuNhiemReducer';
import { lopHanhChinhSoDiemReducer } from './lop-hanh-chinh/lopHanhChinhSoDiemReducer';
import { mappingReducer } from './mapping/mappingReducer';
import { nhanXetHangNgayReducer } from './nhan-xet/nhanXetHangNgayReducer';
import { thaiDoHocTapReducer } from './nhan-xet/thaiDoHocTapReducer';
import { thaiDoHocTapTieuChiReducer } from './nhan-xet/thaiDoHocTapTieuChiReducer';
import { nhapDiemReducer } from './nhap-diem/nhapDiemReducer';
import { notifyReducer } from './notify/notifyReducer';
import { loaiPhongReducer } from './phong-hoc/loaiPhongReducer';
import { phongHocReducer } from './phong-hoc/phongHocReducer';
import { soNhanXetReducer } from './so-diem-tieu-hoc/dinh-ky/soNhanXetReducer';
import { soNhanXetItemReducer } from './so-diem-tieu-hoc/soNhanXetItemReducer';
import { monHocSoDiemReducer } from './so-diem/monHocSoDiemReducer';
import { soDiemReducerWrapper } from './so-diem/soDiemReducerWrapper';
import { thoiKhoaBieuReducerWrapper } from './thoi-khoa-bieu/thoiKhoaBieuReducerWrapper';
import { uploadReducer } from './upload-file/uploadReducer';

import { baoCaoTienBoReducer } from './bao-cao-tien-bo/baoCaoTienBoReducer';
import { diemVanMinhKetQuaReducer } from './diemvanminh-ketqua/diemVanMinhKetQuaReducer';
import { diemVanMinhTieuChiReducer } from './diemvanminh-tieuchi/diemVanMinhTieuChiReducer';
import { lopMonHocReducer } from './lop-mon-hoc/lopMonHocReducer';
import { tongHopDinhKyReducer } from "./tong-hop/tongHopDinhKyReducer";
import { xepHangHocSinhReducer } from './xep-hang-hoc-sinh/xepHangHocSinhReducer';
import { baiVietCommentReducer } from "./bai-viet-comment/baiVietCommentReducer";
import { dotDangKyReducer } from "./dangky-monhoc/dotDangKyReducer";
import { dotDangKyMonHocReducer } from "./dangky-monhoc/dotDangKyMonHocReducer";
import { dotDangKyNhomMonHocReducer } from "./dangky-monhoc/dotDangKyNhomMonHocReducer";
import { dotDangKyKetQuaReducer } from "./dangky-monhoc/dotDangKyKetQuaReducer";
import { khenThuongKyLuatReducer } from "./khen-thuong-ky-luat/khenThuongKyLuatReducer";
import { chamTietReducer } from "./cham-tiet/chamTietReducer";
import { chamTietLoaiTietReducer } from "./cham-tiet/chamTietLoaiTietReducer";
import { formKhaoSatReducerWrapper } from "./form-khaosat/formKhaoSatReducerWrapper";
import { appNhomBaiVietReducer } from "./bai-viet/appNhomBaiVietReducer";
import { hocSinhHoTroReducer } from "./hoc-sinh/hocSinhHoTroReducer";
import { mealReducer } from "./danh-muc/mealReducer";
import { lopBoMonTypeReducer } from "./lop-bo-mon/lopBoMonTypeReducer";
const reducers = combineReducers({
    auth: authReducer,
    account: accountReducer,
    processAccount: processAccountReducer,
    appPhuHuynhWrapper: appPhuHuynhReducerWrapper,
    dictionary: dictionaryReducer,
    categorySource: categorySourceReducer,
    common: commonReducer,
    monHoc: monHocReducer,
    lopBoMon: lopBoMonReducer,
    hocSinhHoTro: hocSinhHoTroReducer,
    hocSinh: hocSinhReducer,
    soDiem: soDiemReducerWrapper,
    toKhoi: toKhoiReducer,
    giaoVien: giaoVienReducer,
    soNhanXet: soNhanXetReducer,
    soNhanXetItem: soNhanXetItemReducer,
    giaoVienNhanXet: giaoVienNhanXetReducer,
    loaiPhong: loaiPhongReducer,
    phongHoc: phongHocReducer,
    thoiKhoaBieuWrapper: thoiKhoaBieuReducerWrapper,
    danhMucWrapper: danhMucReducerWrapper,
    uploadFile: uploadReducer,
    baiViet: baiVietReducer,
    baiVietTrangThai: baiVietTrangThaiReducer,
    baiVietDetail: baiVietDetailReducer,
    baiVietNhom: appNhomBaiVietReducer,
    notify: notifyReducer,
    diemDanh: diemDanhReducer,
    diemDanhStatus: diemDanhStatusReducer,
    diemDanhAn: diemDanhAnReducer,
    nhanXetHangNgay: nhanXetHangNgayReducer,
    localizedResource: localizedResourceReducer,
    baiTapWrapper: baiTapReducerWrapper,
    nhapDiem: nhapDiemReducer,
    monHocSoDiem: monHocSoDiemReducer,
    lopChuNhiem: lopChuNhiemReducer,
    mapping: mappingReducer,
    thaiDoHocTapWrapper: combineReducers({
        thaiDoHocTap: thaiDoHocTapReducer,
        thaiDoHocTapTieuChi: thaiDoHocTapTieuChiReducer
    }),
    diemVanMinhTieuChi: diemVanMinhTieuChiReducer,
    diemVanMinhKetQua: diemVanMinhKetQuaReducer,
    dgdkNlpc: dgdkNlpcReducer,
    dgtxKetQua: dgtxKetQuaReducer,
    dgdkHocTap: dgdkHocTapReducer,
    dgtxKetQuaMonHoc: dgtxKetQuaMonHocReducer,
    lopHanhChinhSoDiem: lopHanhChinhSoDiemReducer,
    baoCaoTienBo: baoCaoTienBoReducer,
    xepHangHocSinh: xepHangHocSinhReducer,
    lopMonHoc: lopMonHocReducer,
    tongHop: combineReducers({
        dinhKy: tongHopDinhKyReducer
    }),
    baiVietComment: baiVietCommentReducer,
    dangKyMonHoc: combineReducers({
        dotDangKy: dotDangKyReducer,
        dotDangKyMonHoc: dotDangKyMonHocReducer,
        dotDangKyNhomMonHoc: dotDangKyNhomMonHocReducer,
        dotDangKyKetQua: dotDangKyKetQuaReducer
    }),
    khenThuongKyLuat: khenThuongKyLuatReducer,
    chamTiet: chamTietReducer,
    chamTietLoaiTiet: chamTietLoaiTietReducer,
    formKhaoSatWrapper: formKhaoSatReducerWrapper,    
    meal: mealReducer,
    lopBoMonType: lopBoMonTypeReducer,
})

export default reducers

export type RootState = ReturnType<typeof reducers>
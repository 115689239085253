import { BaseAction } from "../IBaseActionResult";
import { eDotDangKyActionTypeIds } from '../../action-types/dangky-monhoc/IDotDangKyActionTypes';
import { sis_pl_dotdangky } from "../../../models/response/dangky-monhoc/sis_pl_dotdangky";
import { IDotDangKySelectRequest } from "../../../models/request/dangky-monhoc/IDotDangKySelectRequest";

export const dotDangKyActions = {
    loadStart: (request: IDotDangKySelectRequest) => BaseAction(request, eDotDangKyActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_pl_dotdangky[]) => BaseAction(respone, eDotDangKyActionTypeIds.LOAD_SUCCESS),
    loadError: (message: string) => BaseAction(message, eDotDangKyActionTypeIds.LOAD_ERROR),
    showDeleteConfirm: (isShow: boolean) => BaseAction(isShow, eDotDangKyActionTypeIds.SHOW_DELETE_CONFIRM),
    changeEditingData: (data?: sis_pl_dotdangky) => BaseAction(data, eDotDangKyActionTypeIds.CHANGE_EDITING_DATA),
    deleteStart: (id: number) => BaseAction(id, eDotDangKyActionTypeIds.DELETE_START),
    deleteSuccess: () => BaseAction(undefined, eDotDangKyActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string) => BaseAction(message, eDotDangKyActionTypeIds.DELETE_ERROR),
    showDetailModal: (isShow: boolean) => BaseAction(isShow, eDotDangKyActionTypeIds.SHOW_DETAIL_MODAL),
    saveStart: (request: sis_pl_dotdangky) => BaseAction(request, eDotDangKyActionTypeIds.SAVE_START),
    saveSuccess: () => BaseAction(undefined, eDotDangKyActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string) => BaseAction(message, eDotDangKyActionTypeIds.SAVE_ERROR),
}
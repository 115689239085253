import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { pl_loaiphong } from "../../../models/response/phong-hoc/pl_loaiphong";
import { eLoaiPhongActionTypeIds, ILoaiPhongChangeSelectedIds, ILoaiPhongCloseConfirm, ILoaiPhongCloseDetail, ILoaiPhongDeleteErr, ILoaiPhongDeleteStart, ILoaiPhongDeleteSuccess, ILoaiPhongLoadErr, ILoaiPhongLoadSuccess, ILoaiPhongSaveErr, ILoaiPhongSaveStart, ILoaiPhongSaveSuccess, ILoaiPhongShowConfirm, ILoaiPhongShowDetail } from "../../action-types/phong-hoc/ILoaiPhongActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const loaiPhongActions = {
    loadStart: () => BaseAction(undefined, eLoaiPhongActionTypeIds.LOAD_START),
    loadSuccess: (respone: pl_loaiphong[]): ILoaiPhongLoadSuccess => BaseAction(respone, eLoaiPhongActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): ILoaiPhongLoadErr => BaseAction(messege, eLoaiPhongActionTypeIds.LOAD_ERROR),
    showDetail: (data?: pl_loaiphong): ILoaiPhongShowDetail => BaseAction(data, eLoaiPhongActionTypeIds.SHOW_DETAIL),
    closeDetail: (): ILoaiPhongCloseDetail => BaseAction(undefined, eLoaiPhongActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): ILoaiPhongShowConfirm => BaseAction(undefined, eLoaiPhongActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): ILoaiPhongCloseConfirm => BaseAction(undefined, eLoaiPhongActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): ILoaiPhongChangeSelectedIds => BaseAction(ids, eLoaiPhongActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): ILoaiPhongDeleteStart => BaseAction(ids, eLoaiPhongActionTypeIds.DELETE_START),
    deleteSuccess: (): ILoaiPhongDeleteSuccess => BaseAction(undefined, eLoaiPhongActionTypeIds.DELETE_SUCCESS),
    deleteErr: (message: string): ILoaiPhongDeleteErr => BaseAction(message, eLoaiPhongActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: pl_loaiphong) : ILoaiPhongSaveStart => BaseAction(data, eLoaiPhongActionTypeIds.SAVE_START),
    saveSuccess: (): ILoaiPhongSaveSuccess => BaseAction(undefined, eLoaiPhongActionTypeIds.SAVE_SUCCESS),
    saveTError: (message: string): ILoaiPhongSaveErr =>  BaseAction(message, eLoaiPhongActionTypeIds.SAVE_ERR)
}
import { chamTietApi } from "../../../api/chamTietApi"
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { actions } from "../../actions/actionsWrapper";
import { IChamTiet_CHAMLAI_START, IChamTiet_CHANGE_TRANGTHAI_START, IChamTiet_DELETE_START, IChamTiet_DUYET_START, IChamTiet_SAVE_START, IChamTiet_SELECT_CANHAN_START, IChamTiet_SELECT_DUYET_START, IChamTiet_SELECT_QUANLY_START, eChamTietActionTypeIds } from "../../action-types/cham-tiet/IChamTietActionTypes";

export function* chamTietSaga(): any {
    yield takeLatest(eChamTietActionTypeIds.SELECT_TRANGTHAIDUYET_START, selectTrangthaiduyetWorker)
    yield takeLatest(eChamTietActionTypeIds.SELECT_CANHAN_START, selectCanhanWorker)
    yield takeLatest(eChamTietActionTypeIds.SELECT_DUYET_START, selectDuyetWorker)
    yield takeLatest(eChamTietActionTypeIds.SELECT_QUANLY_START, selectQuanlyWorker)
    yield takeLatest(eChamTietActionTypeIds.DELETE_START, deleteWorker)
    yield takeLatest(eChamTietActionTypeIds.SAVE_START, saveWorker)
    yield takeLatest(eChamTietActionTypeIds.CHANGE_TRANGTHAI_START, changTinhTrangWorker)
    yield takeLatest(eChamTietActionTypeIds.CHAMLAI_START, changeChamLaiWorker)
    yield takeLatest(eChamTietActionTypeIds.DUYET_START, duyetWorker)
}

function* selectTrangthaiduyetWorker(): any {
    const res: IBaseResponeModel = yield call([chamTietApi, chamTietApi.SelectAllTrangThaiDuyetAsync])
    if (res.is_success) {
        yield put(actions.chamTiet.selectTrangthaiduyetSuccess(res.data))
    } else {
        yield put(actions.chamTiet.selectTrangthaiduyetError(res.message || "Load data fail"))
    }
}

function* selectCanhanWorker(action: IChamTiet_SELECT_CANHAN_START): any {
    const res: IBaseResponeModel = yield call([chamTietApi, chamTietApi.SelectChamTietCaNhanAsync], action.payload)
    if (res.is_success) {
        yield put(actions.chamTiet.selectCanhanSuccess(res.data))
    } else {
        yield put(actions.chamTiet.selectCanhanError(res.message || "Load data fail"))
    }
}

function* selectDuyetWorker(action: IChamTiet_SELECT_DUYET_START): any {
    const res: IBaseResponeModel = yield call([chamTietApi, chamTietApi.SelectChamTietDuyetAsync], action.payload)
    if (res.is_success) {
        yield put(actions.chamTiet.selectDuyetSuccess(res.data))
    } else {
        yield put(actions.chamTiet.selectDuyetError(res.message || "Load data fail"))
    }
}

function* selectQuanlyWorker(action: IChamTiet_SELECT_QUANLY_START): any {
    const res: IBaseResponeModel = yield call([chamTietApi, chamTietApi.SelectChamTietQuanLyAsync], action.payload)
    if (res.is_success) {
        yield put(actions.chamTiet.selectQuanlySuccess(res.data))
    } else {
        yield put(actions.chamTiet.selectQuanlyError(res.message || "Load data fail"))
    }
}

function* deleteWorker(action: IChamTiet_DELETE_START) {
    const res: IBaseResponeModel = yield call([chamTietApi, chamTietApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.chamTiet.deleteSuccess())
    } else {
        yield put(actions.chamTiet.deleteError(res.message || "Delele fail"))
    }
}

function* saveWorker(action: IChamTiet_SAVE_START): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([chamTietApi, chamTietApi.Update], action.payload)
    } else {
        res = yield call([chamTietApi, chamTietApi.Insert], action.payload)
    }
    if (res.is_success) {
        yield put(actions.chamTiet.saveSuccess())
    } else {
        yield put(actions.chamTiet.saveError(res.message || "Save fail"))
    }
}

function* changTinhTrangWorker(action: IChamTiet_CHANGE_TRANGTHAI_START): any {
    let res: IBaseResponeModel = yield call([chamTietApi, chamTietApi.ChangeTrangThaiAsync], action.payload)
    if (res.is_success) {
        yield put(actions.chamTiet.changeTrangThaiSuccess())
    } else {
        yield put(actions.chamTiet.changeTrangThaiError(res.message || "Change fail"))
    }
}

function* changeChamLaiWorker(action: IChamTiet_CHAMLAI_START): any {
    let res: IBaseResponeModel = yield call([chamTietApi, chamTietApi.ChamLaiAsync], action.payload)
    if (res.is_success) {
        yield put(actions.chamTiet.changeChamLaiSuccess())
    } else {
        yield put(actions.chamTiet.changeChamLaiError(res.message || "Change fail"))
    }
}

function* duyetWorker(action: IChamTiet_DUYET_START): any {
    let res: IBaseResponeModel = yield call([chamTietApi, chamTietApi.DuyetAsync], action.payload)
    if (res.is_success) {
        yield put(actions.chamTiet.duyetSuccess())
    } else {
        yield put(actions.chamTiet.duyetError(res.message || "Change fail"))
    }
}
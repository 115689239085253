import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { pl_lopbomon_type } from "../../../models/response/lop-bo-mon/pl_lopbomon_type";
import { eLopBoMonTypeActionTypeIds, ILopBoMonTypeChangeSelectedIds, ILopBoMonTypeCloseConfirm, ILopBoMonTypeCloseDetail, ILopBoMonTypeDeleteErr, ILopBoMonTypeDeleteStart, ILopBoMonTypeDeleteSuccess, ILopBoMonTypeLoadByTruongError, ILopBoMonTypeLoadByTruongStart, ILopBoMonTypeLoadByTruongSuccess, ILopBoMonTypeLoadErr, ILopBoMonTypeLoadStart, ILopBoMonTypeLoadSuccess, ILopBoMonTypeSaveErr, ILopBoMonTypeSaveStart, ILopBoMonTypeSaveSuccess, ILopBoMonTypeShowConfirm, ILopBoMonTypeShowDetail } from "../../action-types/lop-bo-mon/ILopBoMonTypeActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const lopBoMonTypeActions = {
    loadStart: (): ILopBoMonTypeLoadStart => BaseAction(undefined, eLopBoMonTypeActionTypeIds.LOAD_START),
    loadSuccess: (respone: pl_lopbomon_type[]): ILopBoMonTypeLoadSuccess => BaseAction(respone, eLopBoMonTypeActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): ILopBoMonTypeLoadErr => BaseAction(messege, eLopBoMonTypeActionTypeIds.LOAD_ERROR),
    loadByTruongStart: (dm_truong_id: number): ILopBoMonTypeLoadByTruongStart => BaseAction(dm_truong_id, eLopBoMonTypeActionTypeIds.LOAD_BY_TRUONG_START),
    loadByTruongSuccess: (respone: pl_lopbomon_type[]): ILopBoMonTypeLoadByTruongSuccess => BaseAction(respone, eLopBoMonTypeActionTypeIds.LOAD_BY_TRUONG_SUCCESS),
    loadByTruongError: (messege: string): ILopBoMonTypeLoadByTruongError => BaseAction(messege, eLopBoMonTypeActionTypeIds.LOAD_BY_TRUONG_ERROR),
    showDetail: (data?: pl_lopbomon_type): ILopBoMonTypeShowDetail => BaseAction(data, eLopBoMonTypeActionTypeIds.SHOW_DETAIL),
    closeDetail: (): ILopBoMonTypeCloseDetail => BaseAction(undefined, eLopBoMonTypeActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): ILopBoMonTypeShowConfirm => BaseAction(undefined, eLopBoMonTypeActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): ILopBoMonTypeCloseConfirm => BaseAction(undefined, eLopBoMonTypeActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): ILopBoMonTypeChangeSelectedIds => BaseAction(ids, eLopBoMonTypeActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): ILopBoMonTypeDeleteStart => BaseAction(ids, eLopBoMonTypeActionTypeIds.DELETE_START),
    deleteSuccess: (): ILopBoMonTypeDeleteSuccess => BaseAction(undefined, eLopBoMonTypeActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): ILopBoMonTypeDeleteErr => BaseAction(message, eLopBoMonTypeActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: pl_lopbomon_type): ILopBoMonTypeSaveStart => BaseAction(data, eLopBoMonTypeActionTypeIds.SAVE_START),
    saveSuccess: (): ILopBoMonTypeSaveSuccess => BaseAction(undefined, eLopBoMonTypeActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): ILopBoMonTypeSaveErr =>  BaseAction(message, eLopBoMonTypeActionTypeIds.SAVE_ERR)
}
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { khenThuongKyLuatApi } from "../../../api/khenThuongKyLuatApi";
import { actions } from "../../actions/actionsWrapper";
import { IKhenThuongKyLuatDeleteStart, IKhenThuongKyLuatLoadStart, IKhenThuongKyLuatSaveStart, eKhenThuongKyLuatActionTypeIds } from './../../action-types/khen-thuong-ky-luat/IKhenThuongKyLuatActionType';
import { IBaseResponeModel } from "../../../models/response/base-response";



export function* khenThuongKyLuatSaga(): any {
    yield takeLatest(eKhenThuongKyLuatActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eKhenThuongKyLuatActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eKhenThuongKyLuatActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(action:IKhenThuongKyLuatLoadStart): any {

    const res: IBaseResponeModel = yield call(khenThuongKyLuatApi.SelectAsync,action.payload)
    if (res.is_success) {
        yield put(actions.khenThuongKyLuat.loadSuccess(res.data))
    } else {
        yield put(actions.khenThuongKyLuat.loadError(res.message || ""))
    }
}
function* deleteWorker(action: IKhenThuongKyLuatDeleteStart): any {
    const res: IBaseResponeModel = yield call(khenThuongKyLuatApi.Delete, action.payload)
    if (res.is_success) {
        yield put(actions.khenThuongKyLuat.deleteSuccess())
    } else {
        yield put(actions.khenThuongKyLuat.deleteError(res.message || ""))
    }
}
function* saveWorker(action: IKhenThuongKyLuatSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call(khenThuongKyLuatApi.Update, action.payload);
    } else {
        res = yield call(khenThuongKyLuatApi.Insert, action.payload);
    }
    if (res.is_success) {
        yield put(actions.khenThuongKyLuat.saveSuccess())
    } else {
        yield put(actions.khenThuongKyLuat.saveError(res.message || ""))
    }
}


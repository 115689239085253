import { IDeleteMultipleBaseRequest } from "../../models/request/base/deleteRequest"
import { sis_tokhoi, sis_tokhoi_view } from "../../models/response/to-khoi/sis_tokhoi"


export enum eToKhoiActionTypeIds {
    LOAD_START = "TOKHOI_LOAD_START",
    LOAD_SUCCESS = "TOKHOI_LOAD_SUCCESS",
    LOAD_ERROR = "TOKHOI_LOAD_ERROR",
    
    LOAD_SELECT_VIEW_START = "TOKHOI_LOAD_SELECT_VIEW_START",
    LOAD_SELECT_VIEW_SUCCESS = "TOKHOI_LOAD_SELECT_VIEW_SUCCESS",
    LOAD_SELECT_VIEW_ERROR = "TOKHOI_LOAD_SELECT_VIEW_ERROR",

    SHOW_DETAIL = "TOKHOI_SHOW_DETAIL",
    CLOSE_DETAIL = "TOKHOI_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "TOKHOI_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "TOKHOI_SHOW_CONFIRM",
    CLOSE_CONFIRM = "TOKHOI_CLOSE_CONFIRM",

    DELETE_START = "TOKHOI_DELETE_START",
    DELETE_SUCCESS = "TOKHOI_DELETE_SUCCESS",
    DELETE_ERROR = "TOKHOI_DELETE_ERROR",

    SAVE_START = "TOKHOI_SAVE_START",
    SAVE_SUCCESS = "TOKHOI_SAVE_SUCCESS",
    SAVE_ERR = "TOKHOI_SAVE_ERR"
}

export interface IToKhoiLoadStart {
    type: eToKhoiActionTypeIds.LOAD_START,
}
export interface IToKhoiLoadSuccess {
    type: eToKhoiActionTypeIds.LOAD_SUCCESS,
    payload: sis_tokhoi_view[]
}
export interface IToKhoiLoadErr {
    type: eToKhoiActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IToKhoiLoadSelectViewStart {
    type: eToKhoiActionTypeIds.LOAD_SELECT_VIEW_START,
}
export interface IToKhoiLoadSelectViewSuccess {
    type: eToKhoiActionTypeIds.LOAD_SELECT_VIEW_SUCCESS,
    payload: sis_tokhoi_view[]
}
export interface IToKhoiLoadSelectViewErr {
    type: eToKhoiActionTypeIds.LOAD_SELECT_VIEW_ERROR,
    payload: string
}
export interface IToKhoiShowDetail {
    type: eToKhoiActionTypeIds.SHOW_DETAIL,
    payload?: sis_tokhoi
}
export interface IToKhoiCloseDetail {
    type: eToKhoiActionTypeIds.CLOSE_DETAIL,
}
export interface IToKhoiChangeSelectedIds {
    type: eToKhoiActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IToKhoiShowConfirm {
    type: eToKhoiActionTypeIds.SHOW_CONFIRM
}
export interface IToKhoiCloseConfirm {
    type: eToKhoiActionTypeIds.CLOSE_CONFIRM
}

export interface IToKhoiDeleteStart {
    type: eToKhoiActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IToKhoiDeleteSuccess {
    type: eToKhoiActionTypeIds.DELETE_SUCCESS,
}
export interface IToKhoiDeleteErr {
    type: eToKhoiActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IToKhoiSaveStart {
    type: eToKhoiActionTypeIds.SAVE_START,
    payload: sis_tokhoi
}
export interface IToKhoiSaveSuccess {
    type: eToKhoiActionTypeIds.SAVE_SUCCESS
}
export interface IToKhoiSaveErr {
    type: eToKhoiActionTypeIds.SAVE_ERR,
    payload: string
}
export type IToKhoiActionTypes = IToKhoiLoadStart | IToKhoiLoadSuccess | IToKhoiLoadErr |
    IToKhoiLoadSelectViewStart | IToKhoiLoadSelectViewSuccess | IToKhoiLoadSelectViewErr |
    IToKhoiShowDetail | IToKhoiCloseDetail |
    IToKhoiChangeSelectedIds |
    IToKhoiShowConfirm | IToKhoiCloseConfirm |
    IToKhoiDeleteStart | IToKhoiDeleteSuccess | IToKhoiDeleteErr |
    IToKhoiSaveStart | IToKhoiSaveSuccess | IToKhoiSaveErr

import { IDiemDanhConfigSelectRequest } from "../../../models/request/diem-danh/IDiemDanhConfigSelectRequest";
import { IDiemDanhLopBoMonChangeRequest } from "../../../models/request/diem-danh/IDiemDanhLopBoMonChangeRequest";
import { IDiemDanhLopBoMonPublishMarkRequest } from "../../../models/request/diem-danh/IDiemDanhLopBoMonPublishMarkRequest";
import { IDiemDanhLopBoMonSelectRequest } from "../../../models/request/diem-danh/IDiemDanhLopBoMonSelectRequest";
import { IDiemDanhLopChuNhiemChangeRequest } from "../../../models/request/diem-danh/IDiemDanhLopChuNhiemChangeRequest";
import { IDiemDanhLopChuNhiemPublishMarkRequest } from "../../../models/request/diem-danh/IDiemDanhLopChuNhiemPublishMarkRequest";
import { IDiemDanhLopChuNhiemSelectRequest } from "../../../models/request/diem-danh/IDiemDanhLopChuNhiemSelectRequest";
import { DiemDanhResponse } from "../../../models/response/diem-danh/DiemDanhResponse";
import { ILopDiemDanhResponse } from "../../../models/response/diem-danh/LopDiemDanhResponse";
import { eDiemDanhActionTypeIds, IDiemDanhChangeDanhSachDiemDanh, IDiemDanhChangeSelectedIds, IDiemDanhLopBoMonErr, IDiemDanhLopBoMonStart, IDiemDanhLopBoMonSuccess, IDiemDanhLopChuNhiemErr, IDiemDanhLopChuNhiemStart, IDiemDanhLopChuNhiemSuccess, IDiemDanhPublishMarkLopBoMonErr, IDiemDanhPublishMarkLopBoMonStart, IDiemDanhPublishMarkLopBoMonSuccess, IDiemDanhPublishMarkLopChuNhiemErr, IDiemDanhPublishMarkLopChuNhiemStart, IDiemDanhPublishMarkLopChuNhiemSuccess, IDiemDanhSelectConfigErr, IDiemDanhSelectConfigStart, IDiemDanhSelectConfigSuccess, IDiemDanhSelectLopBoMonErr, IDiemDanhSelectLopBoMonStart, IDiemDanhSelectLopBoMonSuccess, IDiemDanhSelectLopChuNhiemErr, IDiemDanhSelectLopChuNhiemStart, IDiemDanhSelectLopChuNhiemSuccess, IDiemDanhShowModal } from "../../action-types/diem-danh/IDiemDanhActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const diemDanhActions = {
    selectConfigStart: (request: IDiemDanhConfigSelectRequest): IDiemDanhSelectConfigStart => BaseAction(request, eDiemDanhActionTypeIds.SELECT_CONFIG_START),
    selectConfigSuccess: (respone: ILopDiemDanhResponse[]): IDiemDanhSelectConfigSuccess => BaseAction(respone, eDiemDanhActionTypeIds.SELECT_CONFIG_SUCCESS),
    selectConfigError: (messege: string): IDiemDanhSelectConfigErr => BaseAction(messege, eDiemDanhActionTypeIds.SELECT_CONFIG_ERROR),

    selectLopBoMonStart: (request: IDiemDanhLopBoMonSelectRequest): IDiemDanhSelectLopBoMonStart => BaseAction(request, eDiemDanhActionTypeIds.SELECT_LOPBOMON_START),
    selectLopBoMonSuccess: (respone: DiemDanhResponse[]): IDiemDanhSelectLopBoMonSuccess => BaseAction(respone, eDiemDanhActionTypeIds.SELECT_LOPBOMON_SUCCESS),
    selectLopBoMonError: (messege: string): IDiemDanhSelectLopBoMonErr => BaseAction(messege, eDiemDanhActionTypeIds.SELECT_LOPBOMON_ERROR),
    
    selectLopChuNhiemStart: (request: IDiemDanhLopChuNhiemSelectRequest): IDiemDanhSelectLopChuNhiemStart => BaseAction(request, eDiemDanhActionTypeIds.SELECT_LOPCHUNHIEM_START),
    selectLopChuNhiemSuccess: (respone: DiemDanhResponse[]): IDiemDanhSelectLopChuNhiemSuccess => BaseAction(respone, eDiemDanhActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS),
    selectLopChuNhiemError: (messege: string): IDiemDanhSelectLopChuNhiemErr => BaseAction(messege, eDiemDanhActionTypeIds.SELECT_LOPCHUNHIEM_ERROR),
    
    showDiemDanhModal: (respone: boolean): IDiemDanhShowModal => BaseAction(respone, eDiemDanhActionTypeIds.SHOW_DIEMDANH_MODAL),
    changeDanhSachDiemDanh: (respone: DiemDanhResponse[]): IDiemDanhChangeDanhSachDiemDanh => BaseAction(respone, eDiemDanhActionTypeIds.CHANGE_DANHSACH_DIEMDANH),

    diemDanhLopBoMonStart: (data: IDiemDanhLopBoMonChangeRequest): IDiemDanhLopBoMonStart => BaseAction(data, eDiemDanhActionTypeIds.DIEMDANH_LOPBOMON_START),
    diemDanhLopBoMonSuccess: (): IDiemDanhLopBoMonSuccess => BaseAction(undefined, eDiemDanhActionTypeIds.DIEMDANH_LOPBOMON_SUCCESS),
    diemDanhLopBoMonError: (message: string): IDiemDanhLopBoMonErr =>  BaseAction(message, eDiemDanhActionTypeIds.DIEMDANH_LOPBOMON_ERROR),
    
    diemDanhLopChuNhiemStart: (data: IDiemDanhLopChuNhiemChangeRequest): IDiemDanhLopChuNhiemStart => BaseAction(data, eDiemDanhActionTypeIds.DIEMDANH_LOPCHUNHIEM_START),
    diemDanhLopChuNhiemSuccess: (): IDiemDanhLopChuNhiemSuccess => BaseAction(undefined, eDiemDanhActionTypeIds.DIEMDANH_LOPCHUNHIEM_SUCCESS),
    diemDanhLopChuNhiemError: (message: string): IDiemDanhLopChuNhiemErr =>  BaseAction(message, eDiemDanhActionTypeIds.DIEMDANH_LOPCHUNHIEM_ERROR),

    publishMarkLopBoMonStart: (request: IDiemDanhLopBoMonPublishMarkRequest): IDiemDanhPublishMarkLopBoMonStart => BaseAction(request, eDiemDanhActionTypeIds.PUBLISHMARK_LOPBOMON_START),
    publishMarkLopBoMonSuccess: (): IDiemDanhPublishMarkLopBoMonSuccess => BaseAction(undefined, eDiemDanhActionTypeIds.PUBLISHMARK_LOPBOMON_SUCCESS),
    publishMarkLopBoMonError: (messege: string): IDiemDanhPublishMarkLopBoMonErr => BaseAction(messege, eDiemDanhActionTypeIds.PUBLISHMARK_LOPBOMON_ERROR),
    
    publishMarkLopChuNhiemSuccess: (): IDiemDanhPublishMarkLopChuNhiemSuccess => BaseAction(undefined, eDiemDanhActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_SUCCESS),
    publishMarkLopChuNhiemStart: (request: IDiemDanhLopChuNhiemPublishMarkRequest): IDiemDanhPublishMarkLopChuNhiemStart => BaseAction(request, eDiemDanhActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_START),
    publishMarkLopChuNhiemError: (messege: string): IDiemDanhPublishMarkLopChuNhiemErr => BaseAction(messege, eDiemDanhActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_ERROR),

    changeSelectedIds: (ids: number[]): IDiemDanhChangeSelectedIds => BaseAction(ids, eDiemDanhActionTypeIds.CHANGE_SELECTED_IDS),
}
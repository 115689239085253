import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { BaseAction } from "../IBaseActionResult";
import { app_baiviet } from './../../../models/response/app-phuhuynh/app_baiviet';
import { eBaiVietActionTypeIds, IBaiVietChangeSelectedIds, IBaiVietCloseConfirm, IBaiVietCountWaitingApproveErr, IBaiVietCountWaitingApproveStart, IBaiVietCountWaitingApproveSuccess, IBaiVietDeleteErr, IBaiVietDeleteStart, IBaiVietDeleteSuccess, IBaiVietLoadApprovedErr, IBaiVietLoadApprovedStart, IBaiVietLoadApprovedSuccess, IBaiVietLoadErr, IBaiVietLoadStart, IBaiVietLoadSuccess, IBaiVietLoadWaitingApproveErr, IBaiVietLoadWaitingApproveStart, IBaiVietLoadWaitingApproveSuccess, IBaiVietShowConfirm } from './../../action-types/bai-viet/IBaiVietActionTypes';

export const baiVietActions = {
    loadStart: (truongId: number): IBaiVietLoadStart => BaseAction(truongId, eBaiVietActionTypeIds.LOAD_START),
    loadSuccess: (data: app_baiviet[]): IBaiVietLoadSuccess => BaseAction(data, eBaiVietActionTypeIds.LOAD_SUCCESS),
    loadError: (message: string): IBaiVietLoadErr => BaseAction(message, eBaiVietActionTypeIds.LOAD_ERROR),

    loadApprovedStart: (truongId: number): IBaiVietLoadApprovedStart => BaseAction(truongId, eBaiVietActionTypeIds.LOAD_APPROVED_START),
    loadApprovedSuccess: (data: app_baiviet[]): IBaiVietLoadApprovedSuccess => BaseAction(data, eBaiVietActionTypeIds.LOAD_APPROVED_SUCCESS),
    loadApprovedError: (message: string): IBaiVietLoadApprovedErr => BaseAction(message, eBaiVietActionTypeIds.LOAD_APPROVED_ERROR),

    loadWaitingApproveStart: (truongId: number): IBaiVietLoadWaitingApproveStart => BaseAction(truongId, eBaiVietActionTypeIds.LOAD_WAITING_APPROVE_START),
    loadWaitingApproveSuccess: (data: app_baiviet[]): IBaiVietLoadWaitingApproveSuccess => BaseAction(data, eBaiVietActionTypeIds.LOAD_WAITING_APPROVE_SUCCESS),
    loadWaitingApproveError: (message: string): IBaiVietLoadWaitingApproveErr => BaseAction(message, eBaiVietActionTypeIds.LOAD_WAITING_APPROVE_ERROR),

    countWaitingApproveStart: (truongId: number): IBaiVietCountWaitingApproveStart => BaseAction(truongId, eBaiVietActionTypeIds.COUNT_WAITING_APPROVE_START),
    countWaitingApproveSuccess: (data: number): IBaiVietCountWaitingApproveSuccess => BaseAction(data, eBaiVietActionTypeIds.COUNT_WAITING_APPROVE_SUCCESS),
    countWaitingApproveError: (message: string): IBaiVietCountWaitingApproveErr => BaseAction(message, eBaiVietActionTypeIds.COUNT_WAITING_APPROVE_ERROR),

    showConfirm: (): IBaiVietShowConfirm => BaseAction(undefined, eBaiVietActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IBaiVietCloseConfirm => BaseAction(undefined, eBaiVietActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IBaiVietChangeSelectedIds => BaseAction(ids, eBaiVietActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IBaiVietDeleteStart => BaseAction(ids, eBaiVietActionTypeIds.DELETE_START),
    deleteSuccess: (): IBaiVietDeleteSuccess => BaseAction(undefined, eBaiVietActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IBaiVietDeleteErr => BaseAction(message, eBaiVietActionTypeIds.DELETE_ERROR),
}
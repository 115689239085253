import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eTKBChiTietActionTypeIds, ITKBChiTietDeleteMultipleStart, ITKBChiTietDeleteStart, ITKBChiTietDuplicateStart, ITKBChiTietPreviewByGiaoVienStart, ITKBChiTietSaveExcelStartAction, ITKBChiTietSaveStart, ITKBChiTietSelectByGiaoVienStart, ITKBChiTietSelectStart, ITKBChiTietUploadExcelStartAction, ITKBChiTietUploadExcelSuccessAction } from "../../action-types/thoi-khoa-bieu/ITKBChiTietActionTypes";
import { actions } from "../../actions/actionsWrapper";

const thoiKhoaBieuChiTietApi = apiWrapper.thoiKhoaBieuChiTietApi;

export function* thoiKhoaBieuChiTietSaga(): any {
    yield takeLatest(eTKBChiTietActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eTKBChiTietActionTypeIds.SELECT_START, selectWorker)
    yield takeLatest(eTKBChiTietActionTypeIds.SELECT_BYGIAOVIEN_START, selectByGiaoVienWorker)
    yield takeLatest(eTKBChiTietActionTypeIds.PREVIEW_BYGIAOVIEN_START, previewByGiaoVienWorker)
    yield takeEvery(eTKBChiTietActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eTKBChiTietActionTypeIds.SAVE_START, saveWorker)
    yield takeEvery(eTKBChiTietActionTypeIds.UPLOAD_EXCEL_START, loadFromExcelWorker)
    yield takeEvery(eTKBChiTietActionTypeIds.SAVE_EXCEL_START, saveFromExcelWorker)
    yield takeEvery(eTKBChiTietActionTypeIds.DUPLICATE_START, duplicateWorker)
    yield takeEvery(eTKBChiTietActionTypeIds.DELETE_MULTIPLE_START, deleteMultipleWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.SelectAll])
    if (res.is_success) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.loadSuccess(res.data))
    } else {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.loadError(res.message || "Không tải được thời khóa biểu chi tiết"))
    }
}
function* selectWorker(data: ITKBChiTietSelectStart): any {
    const res: IBaseResponeModel = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.SelectByTkb], data.payload)
    if (res.is_success) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.selectSuccess(res.data))
    } else {
        // console.log(res);
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.selectError(res.message || "Không tải được thời khóa biểu chi tiết"))
    }
}
function* selectByGiaoVienWorker(data: ITKBChiTietSelectByGiaoVienStart): any {
    const res: IBaseResponeModel = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.SelectByGiaoVien], data.payload)
    if (res.is_success) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.selectByGiaoVienSuccess(res.data))
    } else {
        // console.log(res);
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.selectByGiaoVienError(res.message || "Không tải được thời khóa biểu chi tiết"))
    }
}
function* previewByGiaoVienWorker(data: ITKBChiTietPreviewByGiaoVienStart): any {
    const res: IBaseResponeModel = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.PreviewByGiaoVien], data.payload)
    if (res.is_success) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.previewByGiaoVienSuccess(res.data))
    } else {
        // console.log(res);
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.previewByGiaoVienError(res.message || "Không tải được thời khóa biểu chi tiết"))
    }
}
function* deleteWorker(action: ITKBChiTietDeleteStart): any {
    if(action.payload.ids.length == 1){
        const id = action.payload.ids[0];
        const res: IBaseResponeModel = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.Delete], id)
        if (res.is_success) {
            yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.deleteSuccess())
            // yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.loadStart())
        } else {
            yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.deleteError(res.message || "Không xóa được thời khóa biểu chi tiết"))
        }
    }else{
        const res: IBaseResponeModel = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.DeleteMultiple], action.payload)
        if (res.is_success) {
            yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.deleteSuccess())
            // yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.loadStart())
        } else {
            yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.deleteError(res.message || "Không xóa được thời khóa biểu chi tiết"))
        }
    }
}
function* saveWorker(action: ITKBChiTietSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.Update], action.payload);
    } else {
        res = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.saveSuccess())
        // yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.loadStart())
    } else {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.saveError(res.message || "Không lưu được thời khóa biểu"))
    }
}

function* loadFromExcelWorker(data: ITKBChiTietUploadExcelStartAction): any {
    try {
        const res: IBaseResponeModel = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.LoadFromExcel], data.payload);
        if (res.is_success) {
            yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.loadFromExcelSuccess(res.data))
        } else {
            yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.loadFromExcelError(res.message || "Không upload được file excel"))
        }
    } catch (error) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.loadFromExcelError("Upload file excel có lỗi"))
    }
}


function* saveFromExcelWorker(data: ITKBChiTietSaveExcelStartAction): any {
    try {
        const res: IBaseResponeModel = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.SaveDataExcel], data.payload);
        if (res.is_success) {
            yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.saveFromExcelSuccess(res.data))
        } else {
            yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.saveFromExcelError(res.message || "Không lưu được dữ liệu excel"))
        }
    } catch (error) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.saveFromExcelError("Lưu được dữ liệu excel có lỗi"))
    }
}

function* duplicateWorker(action: ITKBChiTietDuplicateStart): any {
    let res: IBaseResponeModel = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.Duplicate], action.payload);
    if (res.is_success) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.duplicateSuccess(res.data))
    } else {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.duplicateError(res.message || "Không nhân bản được thời khóa biểu"))
    }
}

function* deleteMultipleWorker(action: ITKBChiTietDeleteMultipleStart): any {
    let res: IBaseResponeModel = yield call([thoiKhoaBieuChiTietApi, thoiKhoaBieuChiTietApi.DeleteMultipleAsync], action.payload);
    if (res.is_success) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.deleteMultipleSuccess())
    } else {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieuChiTiet.deleteMultipleError(res.message || "Không xóa được thời khóa biểu"))
    }
}
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { IThaiDoHocTapTieuChiSelectRequest } from "../../../models/request/nhan-xet/IThaiDoHocTapTieuChiSelectRequest"
import { sis_thaidohoctap_tieuchi } from "../../../models/response/nhan-xet/sis_thaidohoctap_tieuchi"

export enum eThaiDoHocTapTieuChiActionTypeIds {
    LOAD_START = "ThaiDoHocTapTieuChi_LOAD_START",
    LOAD_SUCCESS = "ThaiDoHocTapTieuChi_LOAD_SUCCESS",
    LOAD_ERROR = "ThaiDoHocTapTieuChi_LOAD_ERROR",

    SELECT_START = "ThaiDoHocTapTieuChi_SELECT_START",
    SELECT_SUCCESS = "ThaiDoHocTapTieuChi_SELECT_SUCCESS",
    SELECT_ERROR = "ThaiDoHocTapTieuChi_SELECT_ERROR",

    SHOW_DETAIL = "ThaiDoHocTapTieuChi_SHOW_DETAIL",
    CLOSE_DETAIL = "ThaiDoHocTapTieuChi_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "ThaiDoHocTapTieuChi_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "ThaiDoHocTapTieuChi_SHOW_CONFIRM",
    CLOSE_CONFIRM = "ThaiDoHocTapTieuChi_CLOSE_CONFIRM",

    DELETE_START = "ThaiDoHocTapTieuChi_DELETE_START",
    DELETE_SUCCESS = "ThaiDoHocTapTieuChi_DELETE_SUCCESS",
    DELETE_ERROR = "ThaiDoHocTapTieuChi_DELETE_ERROR",

    SAVE_START = "ThaiDoHocTapTieuChi_SAVE_START",
    SAVE_SUCCESS = "ThaiDoHocTapTieuChi_SAVE_SUCCESS",
    SAVE_ERR = "ThaiDoHocTapTieuChi_SAVE_ERR"
}

export interface IThaiDoHocTapTieuChiLoadStart {
    type: eThaiDoHocTapTieuChiActionTypeIds.LOAD_START,
}
export interface IThaiDoHocTapTieuChiLoadSuccess {
    type: eThaiDoHocTapTieuChiActionTypeIds.LOAD_SUCCESS,
    payload: sis_thaidohoctap_tieuchi[]
}
export interface IThaiDoHocTapTieuChiLoadErr {
    type: eThaiDoHocTapTieuChiActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IThaiDoHocTapTieuChiSelectStart {
    type: eThaiDoHocTapTieuChiActionTypeIds.SELECT_START,
    payload: IThaiDoHocTapTieuChiSelectRequest
}
export interface IThaiDoHocTapTieuChiSelectSuccess {
    type: eThaiDoHocTapTieuChiActionTypeIds.SELECT_SUCCESS,
    payload: sis_thaidohoctap_tieuchi[]
}
export interface IThaiDoHocTapTieuChiSelectErr {
    type: eThaiDoHocTapTieuChiActionTypeIds.SELECT_ERROR,
    payload: string
}
export interface IThaiDoHocTapTieuChiShowDetail {
    type: eThaiDoHocTapTieuChiActionTypeIds.SHOW_DETAIL,
    payload?: sis_thaidohoctap_tieuchi
}
export interface IThaiDoHocTapTieuChiCloseDetail {
    type: eThaiDoHocTapTieuChiActionTypeIds.CLOSE_DETAIL,
}
export interface IThaiDoHocTapTieuChiChangeSelectedIds {
    type: eThaiDoHocTapTieuChiActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IThaiDoHocTapTieuChiShowConfirm {
    type: eThaiDoHocTapTieuChiActionTypeIds.SHOW_CONFIRM
}
export interface IThaiDoHocTapTieuChiCloseConfirm {
    type: eThaiDoHocTapTieuChiActionTypeIds.CLOSE_CONFIRM
}

export interface IThaiDoHocTapTieuChiDeleteStart {
    type: eThaiDoHocTapTieuChiActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IThaiDoHocTapTieuChiDeleteSuccess {
    type: eThaiDoHocTapTieuChiActionTypeIds.DELETE_SUCCESS,
}
export interface IThaiDoHocTapTieuChiDeleteErr {
    type: eThaiDoHocTapTieuChiActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IThaiDoHocTapTieuChiSaveStart {
    type: eThaiDoHocTapTieuChiActionTypeIds.SAVE_START,
    payload: sis_thaidohoctap_tieuchi
}
export interface IThaiDoHocTapTieuChiSaveSuccess {
    type: eThaiDoHocTapTieuChiActionTypeIds.SAVE_SUCCESS
}
export interface IThaiDoHocTapTieuChiSaveErr {
    type: eThaiDoHocTapTieuChiActionTypeIds.SAVE_ERR,
    payload: string
}
export type IThaiDoHocTapTieuChiActionTypes = IThaiDoHocTapTieuChiLoadStart | IThaiDoHocTapTieuChiLoadSuccess | IThaiDoHocTapTieuChiLoadErr |
    IThaiDoHocTapTieuChiSelectStart | IThaiDoHocTapTieuChiSelectSuccess | IThaiDoHocTapTieuChiSelectErr |
    IThaiDoHocTapTieuChiShowDetail | IThaiDoHocTapTieuChiCloseDetail |
    IThaiDoHocTapTieuChiChangeSelectedIds |
    IThaiDoHocTapTieuChiShowConfirm | IThaiDoHocTapTieuChiCloseConfirm |
    IThaiDoHocTapTieuChiDeleteStart | IThaiDoHocTapTieuChiDeleteSuccess | IThaiDoHocTapTieuChiDeleteErr |
    IThaiDoHocTapTieuChiSaveStart | IThaiDoHocTapTieuChiSaveSuccess | IThaiDoHocTapTieuChiSaveErr
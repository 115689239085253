import { IBaiTapKetQuaItemRespone } from './../../../models/response/bai-tap/IBaiTapKetQuaItemRespone';
import { IBaiTap } from '../../../models/response/bai-tap/IBaiTap';
import { IBaiTapTreeViewItem } from '../../../models/response/bai-tap/IBaiTapTreeViewItem';
import { IBaiTapSelectRequest } from './../../../models/request/bai-tap/IBaiTapSelectRequest';
import { IActionTypeBase } from './../IActionTypeBase';
import { IBaiTapKetQua } from '../../../models/response/bai-tap/IBaiTapKetQua';
export enum eBaiTapActionTypeIds {
    LOAD_TREEVIEW_START = "BAI_TAP_LOAD_TREEVIEW_START",
    LOAD_TREEVIEW_SUCCESS = "BAI_TAP_LOAD_TREEVIEW_SUCCESS",
    LOAD_TREEVIEW_ERRR = "BAI_TAP_LOAD_TREEVIEW_ERROR",

    SHOW_DETAIL_MODAL = "BAI_TAP_SHOW_DETAIL_MODAL",
    CLOSE_DETAIL_MODAL = "BAI_TAP_CLOSE_DETAIL_MODAL",

    SAVE_START = "BAI_TAP_SAVE_START",
    SAVE_SUCCESS = "BAI_TAP_SAVE_SUCCESS",
    SAVE_ERROR = "BAI_TAP_SAVE_ERROR",

    CHANGE_SELECTED = "BAI_TAP_CHANGE_SELECTED",

    SHOW_DELETE_CONFIRM = "BAI_TAP_SHOW_DELETE_CONFIRM",
    CLOSE_DELETE_CONFIRM = "BAI_TAP_CLOSE_DELETE_CONFIRM",

    DELETE_START = "BAI_TAP_DELETE_START",
    DELETE_SUCCESS = "BAI_TAP_DELETE_SUCCESS",
    DELETE_ERROR = "BAI_TAP_DELETE_ERROR",

    CHANGE_EXPANDED_NODE_KEYS = "BAI_TAP_CHANGE_EXPANDED_NODE_KEYS"

}
export interface IBaiTapLoadTreeViewStart extends IActionTypeBase<eBaiTapActionTypeIds.LOAD_TREEVIEW_START, IBaiTapSelectRequest> { }
export interface IBaiTapLoadTreeViewSuccess extends IActionTypeBase<eBaiTapActionTypeIds.LOAD_TREEVIEW_SUCCESS, IBaiTapTreeViewItem[]> { }
export interface IBaiTapLoadTreeViewError extends IActionTypeBase<eBaiTapActionTypeIds.LOAD_TREEVIEW_ERRR, string> { }

export interface IBaiTapShowDetailModal extends IActionTypeBase<eBaiTapActionTypeIds.SHOW_DETAIL_MODAL, IBaiTap | undefined> { }
export interface IBaiTapCloseDetailModal extends IActionTypeBase<eBaiTapActionTypeIds.CLOSE_DETAIL_MODAL, undefined> { }

export interface IBaiTapSaveStart extends IActionTypeBase<eBaiTapActionTypeIds.SAVE_START, IBaiTap> { }
export interface IBaiTapSaveSuccess extends IActionTypeBase<eBaiTapActionTypeIds.SAVE_SUCCESS, undefined> { }
export interface IBaiTapSaveError extends IActionTypeBase<eBaiTapActionTypeIds.SAVE_ERROR, string> { }


export interface IBaiTapChangeSelected extends IActionTypeBase<eBaiTapActionTypeIds.CHANGE_SELECTED, IBaiTapTreeViewItem> { }


export interface IBaiTapShowDeleteConfirm extends IActionTypeBase<eBaiTapActionTypeIds.SHOW_DELETE_CONFIRM, IBaiTap> { }
export interface IBaiTapCloseDeleteConfirm extends IActionTypeBase<eBaiTapActionTypeIds.CLOSE_DELETE_CONFIRM, undefined> { }

export interface IBaiTapDeleteStart extends IActionTypeBase<eBaiTapActionTypeIds.DELETE_START, number> { }
export interface IBaiTapDeleteSuccess extends IActionTypeBase<eBaiTapActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface IBaiTapDeleteError extends IActionTypeBase<eBaiTapActionTypeIds.DELETE_ERROR, string> { }

export interface IBaiTapChangeExpandNodeKeys extends IActionTypeBase<eBaiTapActionTypeIds.CHANGE_EXPANDED_NODE_KEYS, string[]> { }

export type IBaiTapActionTypes = IBaiTapLoadTreeViewStart | IBaiTapLoadTreeViewSuccess | IBaiTapLoadTreeViewError |
    IBaiTapShowDetailModal | IBaiTapCloseDetailModal |
    IBaiTapSaveStart | IBaiTapSaveSuccess | IBaiTapSaveError |
    IBaiTapChangeSelected |
    IBaiTapShowDeleteConfirm | IBaiTapCloseDeleteConfirm |
    IBaiTapDeleteStart | IBaiTapDeleteSuccess | IBaiTapDeleteError |
    IBaiTapChangeExpandNodeKeys
import { ILopBoMonUpdateSoDiemRequest } from '../../../models/request/lop-bo-mon/ILopBoMonUpdateSoDiemRequest';
import { baseAction } from '../IActionBase';
import { NotifyHelper } from './../../../helpers/toast';
import { ILopBoMonSelectRequest } from './../../../models/request/lop-bo-mon/ILopBoMonSelectRequest';
import { pl_lopbomon_viewmodel } from './../../../models/response/lop-bo-mon/pl_lopbomon';
import { eLopBoMonSoDiemActionTypeIds } from './../../action-types/lop-bo-mon/ILopBoMonSoDiemActionTypes';
export const lopBoMonSoDiemActions = {
    loadStart: (request: ILopBoMonSelectRequest) => baseAction(eLopBoMonSoDiemActionTypeIds.LOAD_START, request),
    loadSuccess: (data: pl_lopbomon_viewmodel[]) => baseAction(eLopBoMonSoDiemActionTypeIds.LOAD_SUCCESS, data),
    loadError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopBoMonSoDiemActionTypeIds.LOAD_ERROR, message)
    },

    changeSelectedIds: (ids: number[]) => baseAction(eLopBoMonSoDiemActionTypeIds.CHANGE_SELECTED_IDS, ids),

    showSoDiemSelectionModal: (isOpen: boolean) => baseAction(eLopBoMonSoDiemActionTypeIds.SHOW_SODIEM_SELECTION_MODAL, isOpen),

    updateSoDiemStart: (request: ILopBoMonUpdateSoDiemRequest) => baseAction(eLopBoMonSoDiemActionTypeIds.UPDATE_SODIEM_START, request),
    updateSoDiemSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eLopBoMonSoDiemActionTypeIds.UPDATE_SODIEM_SUCCESS, undefined)
    },
    updateSoDiemError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopBoMonSoDiemActionTypeIds.UPDATE_SODIEM_ERROR, message)
    },

    showSoNhanXetDinhKySelectionModal: (isOpen: boolean) => baseAction(eLopBoMonSoDiemActionTypeIds.SHOW_DGDK_HOCTAP_SELECTION_MODAL, isOpen),

    updateSoNhanXetDinhKyStart: (request: ILopBoMonUpdateSoDiemRequest) => baseAction(eLopBoMonSoDiemActionTypeIds.UPDATE_DGDK_HOCTAP_START, request),
    updateSoNhanXetDinhKySuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eLopBoMonSoDiemActionTypeIds.UPDATE_DGDK_HOCTAP_SUCCESS, undefined)
    },
    updateSoNhanXetDinhKyError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopBoMonSoDiemActionTypeIds.UPDATE_DGDK_HOCTAP_ERROR, message)
    },
    showSoNhanXetThuongXuyenSelectionModal: (isOpen: boolean) => baseAction(eLopBoMonSoDiemActionTypeIds.SHOW_DGTX_HOCTAP_SELECTION_MODAL, isOpen),

    updateSoNhanXetThuongXuyenStart: (request: ILopBoMonUpdateSoDiemRequest) => baseAction(eLopBoMonSoDiemActionTypeIds.UPDATE_DGTX_HOCTAP_START, request),
    updateSoNhanXetThuongXuyenSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eLopBoMonSoDiemActionTypeIds.UPDATE_DGTX_HOCTAP_SUCCESS, undefined)
    },
    updateSoNhanXetThuongXuyenError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopBoMonSoDiemActionTypeIds.UPDATE_DGTX_HOCTAP_ERROR, message)
    },
}
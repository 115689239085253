import { BaseAction } from '../IBaseActionResult';
import { app_baiviet } from './../../../models/response/app-phuhuynh/app_baiviet';
import { eBaiVietDetailActionTypeIds, IBaiVietDetailLoadError, IBaiVietDetailLoadStart, IBaiVietDetailLoadSuccess, IBaiVietDetailChangeData, IBaiVietDetailSaveStart, IBaiVietDetailSaveSuccess, IBaiVietDetailSaveError } from './../../action-types/bai-viet/IBaiVietDetailActionTypes';
export const baiVietDetailActions = {
    loadStart: (id: number): IBaiVietDetailLoadStart => BaseAction(id, eBaiVietDetailActionTypeIds.LOAD_START),
    loadSuccess: (data: app_baiviet): IBaiVietDetailLoadSuccess => BaseAction(data, eBaiVietDetailActionTypeIds.LOAD_SUCCESS),
    loadErr: (message: string): IBaiVietDetailLoadError => BaseAction(message, eBaiVietDetailActionTypeIds.LOAD_ERROR),

    changeData: (data: app_baiviet): IBaiVietDetailChangeData => BaseAction(data, eBaiVietDetailActionTypeIds.CHANGE_DATA),

    saveStart: (data: app_baiviet): IBaiVietDetailSaveStart => BaseAction(data, eBaiVietDetailActionTypeIds.SAVE_START),
    saveSuccess: (id: number): IBaiVietDetailSaveSuccess => BaseAction(id, eBaiVietDetailActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IBaiVietDetailSaveError => BaseAction(message, eBaiVietDetailActionTypeIds.SAVE_ERROR),
}
import { IDeleteMultipleBaseRequest } from './../../../models/request/base/deleteRequest';
import { ILopBoMonListLoadStart, ILopBoMonListSaveStart, ILopBoMonGenerateStart, ILopBoMonDeleteStart } from './../../action-types/lop-bo-mon/ILopBoMonListActionTypes';
import { apiWrapper } from './../../../api/apiWrapper';
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { IBaseResponeModel } from "../../../models/response/base-response"
import { eLopBoMonListActionTypeIds } from "../../action-types/lop-bo-mon/ILopBoMonListActionTypes"
import { actions } from '../../actions/actionsWrapper';

export function* lopBoMonListSaga(): any {
    yield takeLatest(eLopBoMonListActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eLopBoMonListActionTypeIds.SAVE_START, saveWorker)
    yield takeEvery(eLopBoMonListActionTypeIds.GENERATE_START, generateWorker)
    yield takeEvery(eLopBoMonListActionTypeIds.DELETE_START, deleteWorker)

}

function* loadWorker(data: ILopBoMonListLoadStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMon, apiWrapper.lopBoMon.SelectTreeView], data.payload)
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonList.loadTreeViewItemSuccess(res.data))
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonList.loadTreeViewError(res.message || "Không tải được môn học"))
    }
}

function* generateWorker(data: ILopBoMonGenerateStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMon, apiWrapper.lopBoMon.Generate], data.payload)
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonList.generateSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonList.generateError(res.message || "Không tải được môn học"))
    }
}

function* deleteWorker(data: ILopBoMonDeleteStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMon, apiWrapper.lopBoMon.DeleteMultiple], data.payload)
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonList.deleteSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonList.deleteError(res.message || "Error"))
    }
}

function* saveWorker(data: ILopBoMonListSaveStart): any {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call([apiWrapper.lopBoMon, apiWrapper.lopBoMon.Update], data.payload)
    } else {
        res = yield call([apiWrapper.lopBoMon, apiWrapper.lopBoMon.Insert], data.payload)
    }
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonList.saveSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonList.saveError(res.message || "Cập nhật thất bại"))
    }
}
import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eThaiDoHocTapActionTypeIds, IThaiDoHocTapActionTypes } from "../../action-types/nhan-xet/IThaiDoHocTapActionTypes"
import { IThaiDoHocTapState } from "../../page-state-model/nhan-xet/IThaiDoHocTapState"

const initialState: IThaiDoHocTapState = {
    status: ePageBaseStatus.is_not_initialization,
    sis_thaidohoctaps: [],
    sis_thaidohoctap_selected_ids: [],
    sis_thaidohoctap_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const thaiDoHocTapReducer = (state: IThaiDoHocTapState = initialState, action: IThaiDoHocTapActionTypes): IThaiDoHocTapState => {
    switch (action.type) {
        case eThaiDoHocTapActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eThaiDoHocTapActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_thaidohoctaps: action.payload
            }
        case eThaiDoHocTapActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_thaidohoctaps: []
            }
        case eThaiDoHocTapActionTypeIds.SELECT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eThaiDoHocTapActionTypeIds.SELECT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_thaidohoctaps: action.payload
            }
        case eThaiDoHocTapActionTypeIds.SELECT_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_thaidohoctaps: []
            }
        case eThaiDoHocTapActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_thaidohoctap_editing: action.payload
            }
        case eThaiDoHocTapActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_thaidohoctap_editing: undefined
            }
        case eThaiDoHocTapActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_thaidohoctap_selected_ids: action.payload
            }
        case eThaiDoHocTapActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eThaiDoHocTapActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        
            case eThaiDoHocTapActionTypeIds.DELETE_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_deleting
                }
            case eThaiDoHocTapActionTypeIds.DELETE_SUCCESS:
                NotifyHelper.Success("Xoá dữ liệu thành công.")
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }
            case eThaiDoHocTapActionTypeIds.DELETE_ERROR:
                NotifyHelper.Error(action.payload)
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }

        
            case eThaiDoHocTapActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eThaiDoHocTapActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }
        case eThaiDoHocTapActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }

        default:
            return state;
    }

}
import { IUpdateDgdkNlpcRequest } from './../models/request/danh-gia/dinh-ky/IUpdateDgdkNlpcRequest';
import { ISelectDgdkNlpcRequest } from './../models/request/danh-gia/dinh-ky/ISelectDgdkNlpcRequest';
import { apiClient } from './apiClient';
import { ImportDgdkNlpcRequest } from '../models/request/danh-gia/dinh-ky/IImportDgdkNlpcRequest';
import { IExportDgdkNlpcRequest } from '../models/request/danh-gia/dinh-ky/IExportDgdkNlpcRequest';
export const API_APP_DGDK_KETQUA_NLPC_PATH = "dgdk-nlpc"
export const dgdkKetQuaNlpcApi = {
    Select: (request: ISelectDgdkNlpcRequest) => apiClient.post(`${API_APP_DGDK_KETQUA_NLPC_PATH}/select`, request),
    Save: (request: IUpdateDgdkNlpcRequest) => apiClient.post(`${API_APP_DGDK_KETQUA_NLPC_PATH}/save`, request),
    ValidateImport: (request: ImportDgdkNlpcRequest) => apiClient.post(`${API_APP_DGDK_KETQUA_NLPC_PATH}/import/validate`, request),
    Import: (request: ImportDgdkNlpcRequest) => apiClient.post(`${API_APP_DGDK_KETQUA_NLPC_PATH}/import`, request),
    Export: (request: IExportDgdkNlpcRequest) => apiClient.post(`${API_APP_DGDK_KETQUA_NLPC_PATH}/export`, request),

}
import { log } from 'console';
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { IBaoCaoTienDo } from '../../../models/response/bao-cao-tien-do/IBaoCaoTienDo';
import { eBaoCaoTienBoActionTypeIds, IBaoCaoTienBoActionTypes } from './../../action-types/bao-cao-tien-bo/IBaoCaoTienBoActionType';
import { IBaoCaoTienBoPageState } from './../../page-state-model/bao-cao-tien-bo/IBaoCaoTienBoPageState';
const iniState: IBaoCaoTienBoPageState = {
    filter: {
        dm_khoi_id: 0,
        dm_lop_id: 0,
        dm_truong_id: 0,
        nam_hoc: "",
        sis_giaidoan_id: 0,
        hoc_ky: 0
    },
    isShowCreateConfirm: false,
    isShowSendNotifyConfirm: false,
    hocSinhs: [],
    baoCaoTienBos: [],
    loading: {
        hocSinhs: "",
        baoCaoTienBos: ""
    },
    importData: [],
    selectedIds: [],
    status: ePageBaseStatus.is_not_initialization
}
const mergeData = (list: IBaoCaoTienDo[], obj: IBaoCaoTienDo): IBaoCaoTienDo[] => {
    let newList = list.map(x => {
        return x.id == obj.id ? obj : x;
    });
    if (!newList.find(x => x.id == obj.id)) newList.push(obj);
    return newList;
}
const mergeDataList = (list: IBaoCaoTienDo[], objNews: IBaoCaoTienDo[]): IBaoCaoTienDo[] => {
    let newList = list.map(x => {
        const obj = objNews.find(y => x.id == y.id);
        if (obj) return obj;
        return x;
    });
    objNews.forEach(x => {
        if (!list.find(y => y.id == x.id)) {
            newList.push(x);
        }
    })
    return newList;
}
export const baoCaoTienBoReducer = (state: IBaoCaoTienBoPageState = iniState, action: IBaoCaoTienBoActionTypes): IBaoCaoTienBoPageState => {
    switch (action.type) {
        case eBaoCaoTienBoActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case eBaoCaoTienBoActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                selectedIds: action.payload
            }
        case eBaoCaoTienBoActionTypeIds.HOCSINH_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinhs: "isLoading"
                }
            }
        case eBaoCaoTienBoActionTypeIds.HOCSINH_LOAD_SUCCESS:
            return {
                ...state,
                hocSinhs: action.payload,
                selectedIds: [],
                loading: {
                    ...state.loading,
                    hocSinhs: ""
                }
            }
        case eBaoCaoTienBoActionTypeIds.HOCSINH_LOAD_ERROR:
            return {
                ...state,
                selectedIds: [],
                loading: {
                    ...state.loading,
                    hocSinhs: ""
                }
            }
        case eBaoCaoTienBoActionTypeIds.DATA_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    baoCaoTienBos: "isLoading"
                }
            }
        case eBaoCaoTienBoActionTypeIds.DATA_LOAD_SUCCESS:
            return {
                ...state,
                baoCaoTienBos: action.payload,
                loading: {
                    ...state.loading,
                    baoCaoTienBos: ""
                }
            }
        case eBaoCaoTienBoActionTypeIds.DATA_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    baoCaoTienBos: ""
                }
            }
        case eBaoCaoTienBoActionTypeIds.SHOW_CREATE_CONFIRM:
            return {
                ...state,
                isShowCreateConfirm: action.payload
            }
        case eBaoCaoTienBoActionTypeIds.CREATE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eBaoCaoTienBoActionTypeIds.CREATE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                isShowCreateConfirm: false,
                baoCaoTienBos: mergeDataList(state.baoCaoTienBos, action.payload)
            }
        case eBaoCaoTienBoActionTypeIds.CREATE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eBaoCaoTienBoActionTypeIds.SHOW_SEND_NOTIFY_CONFIRM:
            return {
                ...state,
                isShowSendNotifyConfirm: action.payload
            }
        case eBaoCaoTienBoActionTypeIds.SEND_NOTIFY_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eBaoCaoTienBoActionTypeIds.SEND_NOTIFY_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                isShowSendNotifyConfirm: false,
                baoCaoTienBos: action.payload
            }
        case eBaoCaoTienBoActionTypeIds.SEND_NOTIFY_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eBaoCaoTienBoActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eBaoCaoTienBoActionTypeIds.SAVE_SUCCESS:
            console.log({
                payload: action.payload
            });

            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                baoCaoTienBos: mergeData(state.baoCaoTienBos, action.payload)
            }
        case eBaoCaoTienBoActionTypeIds.SAVE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eBaoCaoTienBoActionTypeIds.VALIDATE_IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaoCaoTienBoActionTypeIds.VALIDATE_IMPORT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: action.payload
            }
        case eBaoCaoTienBoActionTypeIds.VALIDATE_IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: []
            }
        case eBaoCaoTienBoActionTypeIds.IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eBaoCaoTienBoActionTypeIds.IMPORT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eBaoCaoTienBoActionTypeIds.IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        default:
            return {
                ...state
            }
    }
}
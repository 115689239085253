import { IDeleteMultipleBaseRequest } from '../../../models/request/base/deleteRequest';
import { ITuanGenerateRequest } from '../../../models/request/category/ITuanGenerateRequest';
import { IPLTuanSelectRequest } from '../../../models/request/danh-muc/IPLTuanSelectRequest';
import { pl_tuan } from '../../../models/response/danh-muc/pl_tuan';
import {
	eTuanActionTypeIds,
	ITuanChangeSelectedIds,
	ITuanCloseConfirm,
	ITuanCloseDetail,
	ITuanDeleteErr,
	ITuanDeleteStart,
	ITuanDeleteSuccess,
	ITuanGenerateError,
	ITuanGenerateStart,
	ITuanGenerateSuccess,
	ITuanLoadErr,
	ITuanLoadStart,
	ITuanLoadSuccess,
	ITuanSaveErr,
	ITuanSaveStart,
	ITuanSaveSuccess,
	ITuanSelectErr,
	ITuanSelectStart,
	ITuanSelectSuccess,
	ITuanShowConfirm,
	ITuanShowDetail,
} from '../../action-types/danh-muc/ITuanActionTypes';
import { BaseAction } from '../IBaseActionResult';

export const tuanActions = {
	loadStart: (): ITuanLoadStart => BaseAction(undefined, eTuanActionTypeIds.LOAD_START),
	loadSuccess: (respone: pl_tuan[]): ITuanLoadSuccess => BaseAction(respone, eTuanActionTypeIds.LOAD_SUCCESS),
	loadError: (messege: string): ITuanLoadErr => BaseAction(messege, eTuanActionTypeIds.LOAD_ERROR),

	selectStart: (request: IPLTuanSelectRequest): ITuanSelectStart =>
		BaseAction(request, eTuanActionTypeIds.SELECT_START),
	selectSuccess: (respone: pl_tuan[]): ITuanSelectSuccess => BaseAction(respone, eTuanActionTypeIds.SELECT_SUCCESS),
	selectError: (messege: string): ITuanSelectErr => BaseAction(messege, eTuanActionTypeIds.SELECT_ERROR),

	showDetail: (data?: pl_tuan): ITuanShowDetail => BaseAction(data, eTuanActionTypeIds.SHOW_DETAIL),
	closeDetail: (): ITuanCloseDetail => BaseAction(undefined, eTuanActionTypeIds.CLOSE_DETAIL),
	showConfirm: (): ITuanShowConfirm => BaseAction(undefined, eTuanActionTypeIds.SHOW_CONFIRM),
	closeConfirm: (): ITuanCloseConfirm => BaseAction(undefined, eTuanActionTypeIds.CLOSE_CONFIRM),
	changeSelectedIds: (ids: number[]): ITuanChangeSelectedIds => BaseAction(ids, eTuanActionTypeIds.CHANGE_SELECTED_IDS),
	deleteStart: (ids: IDeleteMultipleBaseRequest): ITuanDeleteStart => BaseAction(ids, eTuanActionTypeIds.DELETE_START),
	deleteSuccess: (): ITuanDeleteSuccess => BaseAction(undefined, eTuanActionTypeIds.DELETE_SUCCESS),
	deleteError: (message: string): ITuanDeleteErr => BaseAction(message, eTuanActionTypeIds.DELETE_ERROR),
	//SAVE
	saveStart: (data: pl_tuan): ITuanSaveStart => BaseAction(data, eTuanActionTypeIds.SAVE_START),
	saveSuccess: (): ITuanSaveSuccess => BaseAction(undefined, eTuanActionTypeIds.SAVE_SUCCESS),
	saveError: (message: string): ITuanSaveErr => BaseAction(message, eTuanActionTypeIds.SAVE_ERR),

	generateStart: (request: ITuanGenerateRequest): ITuanGenerateStart => BaseAction(request, eTuanActionTypeIds.GENERATE_START),
	generateSuccess: (): ITuanGenerateSuccess => BaseAction(undefined, eTuanActionTypeIds.GENERATE_SUCCESS),
	generateError: (message: string): ITuanGenerateError => BaseAction(message, eTuanActionTypeIds.GENERATE_ERROR),
};

import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { app_menu, app_menu_view } from "../../../models/response/app-phuhuynh/app_menu";
import { eAppMenuActionTypeIds, IAppMenuChangeSelectedIds, IAppMenuCloseConfirm, IAppMenuCloseDetail, IAppMenuDeleteErr, IAppMenuDeleteStart, IAppMenuDeleteSuccess, IAppMenuLoadErr, IAppMenuLoadStart, IAppMenuLoadSuccess, IAppMenuSaveErr, IAppMenuSaveStart, IAppMenuSaveSuccess, IAppMenuShowConfirm, IAppMenuShowDetail } from "../../action-types/app-phuhuynh/IAppMenuActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const appMenuActions = {
    loadStart: (): IAppMenuLoadStart => BaseAction(undefined, eAppMenuActionTypeIds.LOAD_START),
    loadSuccess: (respone: app_menu_view[]): IAppMenuLoadSuccess => BaseAction(respone, eAppMenuActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IAppMenuLoadErr => BaseAction(messege, eAppMenuActionTypeIds.LOAD_ERROR),
    showDetail: (data?: app_menu_view): IAppMenuShowDetail => BaseAction(data, eAppMenuActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IAppMenuCloseDetail => BaseAction(undefined, eAppMenuActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IAppMenuShowConfirm => BaseAction(undefined, eAppMenuActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IAppMenuCloseConfirm => BaseAction(undefined, eAppMenuActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IAppMenuChangeSelectedIds => BaseAction(ids, eAppMenuActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IAppMenuDeleteStart => BaseAction(ids, eAppMenuActionTypeIds.DELETE_START),
    deleteSuccess: (): IAppMenuDeleteSuccess => BaseAction(undefined, eAppMenuActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IAppMenuDeleteErr => BaseAction(message, eAppMenuActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: app_menu): IAppMenuSaveStart => BaseAction(data, eAppMenuActionTypeIds.SAVE_START),
    saveSuccess: ():IAppMenuSaveSuccess => BaseAction(undefined, eAppMenuActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IAppMenuSaveErr =>  BaseAction(message, eAppMenuActionTypeIds.SAVE_ERR)
}
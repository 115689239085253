import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { HocKyItemRespone } from '../../../models/response/danh-muc/IHocKyItemRespone';
import { pl_hocky } from "../../../models/response/danh-muc/pl_hocky";
import { INamHocTruongFilter } from './../../../models/INamHocTruongFilter';

export enum eHocKyActionTypeIds {
    LOAD_START = "HOCKY_LOAD_START",
    LOAD_SUCCESS = "HOCKY_LOAD_SUCCESS",
    LOAD_ERROR = "HOCKY_LOAD_ERROR",

    SHOW_DETAIL = "HOCKY_SHOW_DETAIL",
    CLOSE_DETAIL = "HOCKY_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "HOCKY_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "HOCKY_SHOW_CONFIRM",
    CLOSE_CONFIRM = "HOCKY_CLOSE_CONFIRM",

    DELETE_START = "HOCKY_DELETE_START",
    DELETE_SUCCESS = "HOCKY_DELETE_SUCCESS",
    DELETE_ERROR = "HOCKY_DELETE_ERROR",

    SAVE_START = "HOCKY_SAVE_START",
    SAVE_SUCCESS = "HOCKY_SAVE_SUCCESS",
    SAVE_ERR = "HOCKY_SAVE_ERR"
}

export interface IHocKyLoadStart {
    type: eHocKyActionTypeIds.LOAD_START,
    payload: INamHocTruongFilter
}
export interface IHocKyLoadSuccess {
    type: eHocKyActionTypeIds.LOAD_SUCCESS,
    payload: HocKyItemRespone[]
}
export interface IHocKyLoadErr {
    type: eHocKyActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IHocKyShowDetail {
    type: eHocKyActionTypeIds.SHOW_DETAIL,
    payload?: pl_hocky
}
export interface IHocKyCloseDetail {
    type: eHocKyActionTypeIds.CLOSE_DETAIL,
}
export interface IHocKyChangeSelectedIds {
    type: eHocKyActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IHocKyShowConfirm {
    type: eHocKyActionTypeIds.SHOW_CONFIRM
}
export interface IHocKyCloseConfirm {
    type: eHocKyActionTypeIds.CLOSE_CONFIRM
}

export interface IHocKyDeleteStart {
    type: eHocKyActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IHocKyDeleteSuccess {
    type: eHocKyActionTypeIds.DELETE_SUCCESS,
}
export interface IHocKyDeleteErr {
    type: eHocKyActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IHocKySaveStart {
    type: eHocKyActionTypeIds.SAVE_START,
    payload: pl_hocky
}
export interface IHocKySaveSuccess {
    type: eHocKyActionTypeIds.SAVE_SUCCESS
}
export interface IHocKySaveErr {
    type: eHocKyActionTypeIds.SAVE_ERR,
    payload: string
}
export type IHocKyActionTypes = IHocKyLoadStart | IHocKyLoadSuccess | IHocKyLoadErr |
    IHocKyShowDetail | IHocKyCloseDetail |
    IHocKyChangeSelectedIds |
    IHocKyShowConfirm | IHocKyCloseConfirm |
    IHocKyDeleteStart | IHocKyDeleteSuccess | IHocKyDeleteErr |
    IHocKySaveStart | IHocKySaveSuccess | IHocKySaveErr
import { NotifyHelper } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eDiemDanhActionTypeIds, IDiemDanhActionTypes } from "../../action-types/diem-danh/IDiemDanhActionTypes";
import { IDiemDanhPageState } from "../../page-state-model/diem-danh/IDiemDanhPageState";

const initialState: IDiemDanhPageState = {
    status: ePageBaseStatus.is_not_initialization,
    sis_diemdanh_hocsinhs: [],
    is_show_diemdanh_modal: false,
    sis_diemdanh_hocsinhs_modal: [],
    lop_diem_danhs: [],
    sis_diemdanh_selected_ids: []
}

export const diemDanhReducer = (state: IDiemDanhPageState = initialState, action: IDiemDanhActionTypes): IDiemDanhPageState => {
    switch (action.type) {
        case eDiemDanhActionTypeIds.SELECT_CONFIG_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading,
            }
        case eDiemDanhActionTypeIds.SELECT_CONFIG_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lop_diem_danhs: action.payload
            }
        case eDiemDanhActionTypeIds.SELECT_CONFIG_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lop_diem_danhs: []
            }
        case eDiemDanhActionTypeIds.SELECT_LOPBOMON_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDiemDanhActionTypeIds.SELECT_LOPBOMON_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_diemdanh_hocsinhs: action.payload
            }
        case eDiemDanhActionTypeIds.SELECT_LOPBOMON_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_diemdanh_hocsinhs: []
            }
        case eDiemDanhActionTypeIds.SELECT_LOPCHUNHIEM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDiemDanhActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_diemdanh_hocsinhs: action.payload
            }
        case eDiemDanhActionTypeIds.SELECT_LOPCHUNHIEM_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_diemdanh_hocsinhs: []
            }
        case eDiemDanhActionTypeIds.PUBLISHMARK_LOPBOMON_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDiemDanhActionTypeIds.PUBLISHMARK_LOPBOMON_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eDiemDanhActionTypeIds.PUBLISHMARK_LOPBOMON_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eDiemDanhActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDiemDanhActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eDiemDanhActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eDiemDanhActionTypeIds.SHOW_DIEMDANH_MODAL:
            return {
                ...state,
                is_show_diemdanh_modal: action.payload
            }
        case eDiemDanhActionTypeIds.CHANGE_DANHSACH_DIEMDANH:
            return {
                ...state,
                sis_diemdanh_hocsinhs_modal: action.payload
            }
        case eDiemDanhActionTypeIds.DIEMDANH_LOPBOMON_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDiemDanhActionTypeIds.DIEMDANH_LOPBOMON_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_diemdanh_modal: false
            }
        case eDiemDanhActionTypeIds.DIEMDANH_LOPBOMON_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_diemdanh_modal: false
            }
        case eDiemDanhActionTypeIds.DIEMDANH_LOPCHUNHIEM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDiemDanhActionTypeIds.DIEMDANH_LOPCHUNHIEM_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_diemdanh_modal: false
            }
        case eDiemDanhActionTypeIds.DIEMDANH_LOPCHUNHIEM_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_diemdanh_modal: false
            }
        case eDiemDanhActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_diemdanh_selected_ids: action.payload
            }
        default:
            return state;
    }

}
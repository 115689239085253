import { IDeleteMultipleBaseRequest } from "../../models/request/base/deleteRequest"
import { GiaoVienUpdateRequest } from "../../models/request/category/GiaoVienUpdateRequest"
import { GiaoVienTruongModifyRequest } from "../../models/request/giao-vien/GiaoVienTruongModifyRequest"
import { sis_giaovien, sis_giaovien_view } from "../../models/response/giao-vien/sis_giaovien"
import { IGiaoVienImportDsResponse } from "../../models/response/giao-vien/GiaoVienImportDsResponse";
import { IGiaoVienImportDsRequest } from "../../models/request/giao-vien/GiaoVienImportDsRequest";
import { IActionTypeBase } from "./IActionTypeBase"

export enum eGiaoVienActionTypeIds {
    LOAD_START = "GIAOVIEN_LOAD_START",
    LOAD_SUCCESS = "GIAOVIEN_LOAD_SUCCESS",
    LOAD_ERROR = "GIAOVIEN_LOAD_ERROR",

    LOAD_BYTRUONG_START = "GIAOVIEN_LOAD_BYTRUONG_START",
    LOAD_BYTRUONG_SUCCESS = "GIAOVIEN_LOAD_BYTRUONG_SUCCESS",
    LOAD_BYTRUONG_ERROR = "GIAOVIEN_LOAD_BYTRUONG_ERROR",

    SHOW_ADD_MODAL = "GIAOVIEN_SHOW_ADD_MODAL",
    SHOW_DETAIL = "GIAOVIEN_SHOW_DETAIL",
    CLOSE_DETAIL = "GIAOVIEN_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "GIAOVIEN_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "GIAOVIEN_SHOW_CONFIRM",
    CLOSE_CONFIRM = "GIAOVIEN_CLOSE_CONFIRM",

    DELETE_START = "GIAOVIEN_DELETE_START",
    DELETE_SUCCESS = "GIAOVIEN_DELETE_SUCCESS",
    DELETE_ERROR = "GIAOVIEN_DELETE_ERROR",

    SAVE_START = "GIAOVIEN_SAVE_START",
    SAVE_SUCCESS = "GIAOVIEN_SAVE_SUCCESS",
    SAVE_ERR = "GIAOVIEN_SAVE_ERR",
    
    ADD_GIAOVIEN_TRUONG_START = "GIAOVIEN_ADD_GIAOVIEN_TRUONG_START",
    ADD_GIAOVIEN_TRUONG_SUCCESS = "GIAOVIEN_ADD_GIAOVIEN_TRUONG_SUCCESS",
    ADD_GIAOVIEN_TRUONG_ERR = "GIAOVIEN_ADD_GIAOVIEN_TRUONG_ERR",

    REMOVE_GIAOVIEN_TRUONG_START = "GIAOVIEN_REMOVE_GIAOVIEN_TRUONG_START",
    REMOVE_GIAOVIEN_TRUONG_SUCCESS = "GIAOVIEN_REMOVE_GIAOVIEN_TRUONG_SUCCESS",
    REMOVE_GIAOVIEN_TRUONG_ERR = "GIAOVIEN_REMOVE_GIAOVIEN_TRUONG_ERR",

    CLEAR_STATUS = "GIAOVIEN_CLEAR_STATUS",
    
    UPLOAD_EXCEL_START = "GIAOVIEN_UPLOAD_EXCEL_START",
    UPLOAD_EXCEL_SUCCESS = "GIAOVIEN_UPLOAD_EXCEL_SUCCESS",
    UPLOAD_EXCEL_ERROR = "GIAOVIEN_UPLOAD_EXCEL_ERROR",

    SAVE_EXCEL_START = "GIAOVIEN_SAVE_EXCEL_START",
    SAVE_EXCEL_SUCCESS = "GIAOVIEN_SAVE_EXCEL_SUCCESS",
    SAVE_EXCEL_ERROR = "GIAOVIEN_SAVE_EXCEL_ERROR",
}
//ORIGIN MODEL
export interface IGiaoVienLoadStart extends IActionTypeBase<eGiaoVienActionTypeIds.LOAD_START, undefined> { }
export interface IGiaoVienLoadSuccess extends IActionTypeBase<eGiaoVienActionTypeIds.LOAD_SUCCESS, sis_giaovien_view[]> { }
export interface IGiaoVienLoadErr extends IActionTypeBase<eGiaoVienActionTypeIds.LOAD_ERROR, string> { }

export interface IGiaoVienLoadByTruongStart extends IActionTypeBase<eGiaoVienActionTypeIds.LOAD_BYTRUONG_START, number> { }
export interface IGiaoVienLoadByTruongSuccess extends IActionTypeBase<eGiaoVienActionTypeIds.LOAD_BYTRUONG_SUCCESS, sis_giaovien_view[]> { }
export interface IGiaoVienLoadByTruongErr extends IActionTypeBase<eGiaoVienActionTypeIds.LOAD_BYTRUONG_ERROR, string> { }

//DETAIL
export interface IGiaoVienShowDetail extends IActionTypeBase<eGiaoVienActionTypeIds.SHOW_DETAIL, sis_giaovien_view | undefined> { }
export interface IGiaoVienCloseDetail extends IActionTypeBase<eGiaoVienActionTypeIds.CLOSE_DETAIL, undefined> { }
export interface IGiaoVienChangeSelectedIds extends IActionTypeBase<eGiaoVienActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
//CONFIRM
export interface IGiaoVienShowAddModal extends IActionTypeBase<eGiaoVienActionTypeIds.SHOW_ADD_MODAL, boolean> { }
export interface IGiaoVienShowConfirm extends IActionTypeBase<eGiaoVienActionTypeIds.SHOW_CONFIRM, undefined> { }
export interface IGiaoVienCloseConfirm extends IActionTypeBase<eGiaoVienActionTypeIds.CLOSE_CONFIRM, undefined> { }
//DELETE
export interface IGiaoVienDeleteStart extends IActionTypeBase<eGiaoVienActionTypeIds.DELETE_START, IDeleteMultipleBaseRequest> { }
export interface IGiaoVienDeleteSuccess extends IActionTypeBase<eGiaoVienActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface IGiaoVienDeleteErr extends IActionTypeBase<eGiaoVienActionTypeIds.DELETE_ERROR, string> { }
//SAVE
export interface IGiaoVienSaveStart extends IActionTypeBase<eGiaoVienActionTypeIds.SAVE_START, GiaoVienUpdateRequest> { }
export interface IGiaoVienSaveSuccess extends IActionTypeBase<eGiaoVienActionTypeIds.SAVE_SUCCESS, undefined> { }
export interface IGiaoVienSaveErr extends IActionTypeBase<eGiaoVienActionTypeIds.SAVE_ERR, string> { }

export interface IGiaoVienClearStatus extends IActionTypeBase<eGiaoVienActionTypeIds.CLEAR_STATUS, undefined> { }

export interface IGiaoVienUploadExcelStartAction extends IActionTypeBase<eGiaoVienActionTypeIds.UPLOAD_EXCEL_START, FormData> { }
export interface IGiaoVienUploadExcelSuccessAction extends IActionTypeBase<eGiaoVienActionTypeIds.UPLOAD_EXCEL_SUCCESS, IGiaoVienImportDsResponse> { }
export interface IGiaoVienUploadExcelErrorAction extends IActionTypeBase<eGiaoVienActionTypeIds.UPLOAD_EXCEL_ERROR, string> { }

export interface IGiaoVienSaveExcelStartAction extends IActionTypeBase<eGiaoVienActionTypeIds.SAVE_EXCEL_START, IGiaoVienImportDsRequest> { }
export interface IGiaoVienSaveExcelSuccessAction extends IActionTypeBase<eGiaoVienActionTypeIds.SAVE_EXCEL_SUCCESS, IGiaoVienImportDsResponse> { }
export interface IGiaoVienSaveExcelErrorAction extends IActionTypeBase<eGiaoVienActionTypeIds.SAVE_EXCEL_ERROR, string> { }


export type IGiaoVienActionTypes =
    IGiaoVienLoadStart | IGiaoVienLoadSuccess | IGiaoVienLoadErr |
    IGiaoVienShowDetail | IGiaoVienCloseDetail |
    IGiaoVienChangeSelectedIds | IGiaoVienShowAddModal |
    IGiaoVienShowConfirm | IGiaoVienCloseConfirm |
    IGiaoVienDeleteStart | IGiaoVienDeleteSuccess | IGiaoVienDeleteErr |
    IGiaoVienSaveStart | IGiaoVienSaveSuccess | IGiaoVienSaveErr |
    IGiaoVienLoadByTruongStart | IGiaoVienLoadByTruongSuccess | IGiaoVienLoadByTruongErr  |
    IGiaoVienClearStatus |
    IGiaoVienUploadExcelStartAction | IGiaoVienUploadExcelSuccessAction | IGiaoVienUploadExcelErrorAction | 
    IGiaoVienSaveExcelStartAction | IGiaoVienSaveExcelSuccessAction | IGiaoVienSaveExcelErrorAction
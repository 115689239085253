import { NotifyHelper } from '../../../../helpers/toast';
import { IImportDgdkHocTapRequest } from '../../../../models/request/danh-gia/dinh-ky/IImportDgdkHocTapRequest';
import { ISelectDgdkHocTapRequest } from '../../../../models/request/danh-gia/dinh-ky/ISelectDgdkHocTapRequest';
import { IUpdateDgdkHocTapRequest } from '../../../../models/request/danh-gia/dinh-ky/IUpdateDgdkHocTapRequest';
import { ILopBoMonSelectHocSinhRequest } from '../../../../models/request/lop-bo-mon-hoc-sinh/ILopBoMonSelectHocSinhRequest';
import { ISoNhanXetItem } from '../../../../models/response/dgdk-dm/ISoNhanXetItem';
import { IHocSinhSelectResponeItem } from '../../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { IDgdkHocTapFilter } from '../../../page-state-model/danh-gia/dinh-ky/IDgdkHocTapPageState';
import { baseAction } from "../../IActionBase";
import { IDgdkKetQuaHocTap } from './../../../../models/response/danh-gia/dinh-ky/IDgdkKetQuaHocTap';
import { eDgdkHocTapActionTypeIds } from './../../../action-types/danh-gia/dinh-ky/IDgdkHocTapActionType';

export const dgdkHocTapActions = {
    changeFilter: (filter: IDgdkHocTapFilter) => baseAction(eDgdkHocTapActionTypeIds.CHANGE_FILTER, filter),
    loadHocSinhStart: (rq: ILopBoMonSelectHocSinhRequest) => baseAction(eDgdkHocTapActionTypeIds.HOCSINH_LOAD_START, rq),
    loadHocSinhSuccess: (hocSinh: IHocSinhSelectResponeItem[]) => baseAction(eDgdkHocTapActionTypeIds.HOCSINH_LOAD_SUCCESS, hocSinh),
    loadHocSinhError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkHocTapActionTypeIds.HOCSINH_LOAD_ERROR, message)
    },
    soNhanXetLoadItemStart: (setId: number) => baseAction(eDgdkHocTapActionTypeIds.SONHANXET_ITEM_LOAD_START, setId),
    soNhanXetLoadItemSuccess: (data: ISoNhanXetItem[]) => baseAction(eDgdkHocTapActionTypeIds.SONHANXET_ITEM_LOAD_SUCCESS, data),
    soNhanXetLoadItemError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkHocTapActionTypeIds.SONHANXET_ITEM_LOAD_ERROR, message)
    },
    loadKetQuaStart: (request: ISelectDgdkHocTapRequest) => baseAction(eDgdkHocTapActionTypeIds.KETQUA_LOAD_START, request),
    loadKetQuaSuccess: (data: IDgdkKetQuaHocTap[]) => baseAction(eDgdkHocTapActionTypeIds.KETQUA_LOAD_SUCCESS, data),
    loadKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkHocTapActionTypeIds.KETQUA_LOAD_ERROR, message)
    },
    saveKetQuaStart: (request: IUpdateDgdkHocTapRequest) => baseAction(eDgdkHocTapActionTypeIds.KETQUA_SAVE_START, request),
    saveKetQuaSuccess: (data: IDgdkKetQuaHocTap) => baseAction(eDgdkHocTapActionTypeIds.KETQUA_SAVE_SUCCESS, data),
    saveKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkHocTapActionTypeIds.KETQUA_SAVE_ERROR, message)
    },
    validateImportStart: (request: IImportDgdkHocTapRequest) => baseAction(eDgdkHocTapActionTypeIds.VALIDATE_IMPORT_START, request),
    validateImportSuccess: (data: any) => baseAction(eDgdkHocTapActionTypeIds.VALIDATE_IMPORT_SUCCESS, data),
    validateImportError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkHocTapActionTypeIds.VALIDATE_IMPORT_ERRROR, message)
    },
    importStart: (request: IImportDgdkHocTapRequest) => baseAction(eDgdkHocTapActionTypeIds.IMPORT_START, request),
    importSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eDgdkHocTapActionTypeIds.IMPORT_SUCCESS, undefined)
    },
    importError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkHocTapActionTypeIds.IMPORT_ERRROR, message)
    },
}
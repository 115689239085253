import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFormKhaoSatItemAddEditorStart, IFormKhaoSatItemAddItemsStart, IFormKhaoSatItemDeleteStart, IFormKhaoSatItemLoadByFormStart, IFormKhaoSatItemSaveStart, IFormKhaoSatItemUPDATEIDXStart, eFormKhaoSatItemActionTypeIds } from "../../action-types/form-khaosat/IFormKhaoSatItemActionTypes";

import { IBaseResponeModel } from "../../../models/response/base-response";
import { actions } from "../../actions/actionsWrapper";
import { apiWrapper } from "../../../api/apiWrapper";

const formKhaoSatItemApi = apiWrapper.formKhaoSatWrapper.formKhaoSatItem;

export function* formKhaoSatItemSaga(): any {
    yield takeLatest(eFormKhaoSatItemActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eFormKhaoSatItemActionTypeIds.LOAD_BYFORM_START, loadByFormWorker)
    yield takeEvery(eFormKhaoSatItemActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eFormKhaoSatItemActionTypeIds.SAVE_START, saveWorker) 
    yield takeEvery(eFormKhaoSatItemActionTypeIds.ADDITEMS_START, addItemsWorker) 
    yield takeEvery(eFormKhaoSatItemActionTypeIds.ADDEDITOR_START, addEditorWorker) 
    yield takeEvery(eFormKhaoSatItemActionTypeIds.UPDATEIDX_START, updateIdxWorker) 
    
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([formKhaoSatItemApi, formKhaoSatItemApi.SelectAll])
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.loadSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.loadError(res.message || "No data"))
    }
}
function* loadByFormWorker(action: IFormKhaoSatItemLoadByFormStart): any {
    const res: IBaseResponeModel = yield call([formKhaoSatItemApi, formKhaoSatItemApi.SelectByFormAsync], action.payload.sis_form_khaosat_id)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.loadByFormSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.loadByFormError(res.message || "No data"))
    }
}
function* deleteWorker(action: IFormKhaoSatItemDeleteStart): any {
    const res: IBaseResponeModel = yield call([formKhaoSatItemApi, formKhaoSatItemApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.deleteSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.deleteErr(res.message || "No data"))
    }
}
function* saveWorker(action: IFormKhaoSatItemSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([formKhaoSatItemApi, formKhaoSatItemApi.Update], action.payload);
    } else {
        res = yield call([formKhaoSatItemApi, formKhaoSatItemApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.saveSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.saveError(res.message || "Save fail"))
    }
}
function* addItemsWorker(action: IFormKhaoSatItemAddItemsStart): any {
    let res: IBaseResponeModel = yield call([formKhaoSatItemApi, formKhaoSatItemApi.InsertMultipleAsync], action.payload);
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.additemsSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.additemsError(res.message || "Save fail"))
    }
}
function* addEditorWorker(action: IFormKhaoSatItemAddEditorStart): any {
    let res: IBaseResponeModel = yield call([formKhaoSatItemApi, formKhaoSatItemApi.InsertSingleAsync], action.payload);
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.addeditorSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.addeditorError(res.message || "Save fail"))
    }
}
function* updateIdxWorker(action: IFormKhaoSatItemUPDATEIDXStart): any {
    let res: IBaseResponeModel = yield call([formKhaoSatItemApi, formKhaoSatItemApi.UpdateIdxMultipleAsync], action.payload);
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.updateIdxSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSatItem.updateIdxError(res.message || "Save fail"))
    }
}

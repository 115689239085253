import { IBaoCaoTienBoCreateRequest } from '../models/request/bao-cao-tien-do/IBaoCaoTienBoCreateRequest';
import { IBaoCaoTienBoImportRequest } from '../models/request/bao-cao-tien-do/IBaoCaoTienBoImportRequest';
import { IBaoCaoTienBoSaveRequest } from '../models/request/bao-cao-tien-do/IBaoCaoTienBoSaveRequest';
import { IBaoCaoTienBoViewHtmlRequest } from '../models/request/bao-cao-tien-do/IBaoCaoTienBoViewHtmlRequest';
import { IBaoCaoTienBoSelectRequest } from './../models/request/bao-cao-tien-do/BaoCaoTienBoSelectRequest';
import { apiClient } from './apiClient';
export const baoCaoTienBoApi = {
    Select: (rq: IBaoCaoTienBoSelectRequest) => apiClient.post('bao-cao-tien-do/select', rq),
    Create: (rq: IBaoCaoTienBoCreateRequest) => apiClient.post('bao-cao-tien-do/create', rq),
    SendNotify: (rq: IBaoCaoTienBoCreateRequest) => apiClient.post('bao-cao-tien-do/send-notify', rq),
    Save: (rq: IBaoCaoTienBoSaveRequest) => apiClient.post('bao-cao-tien-do/save', rq),
    ViewHtml: (rq: IBaoCaoTienBoViewHtmlRequest) => apiClient.post('bao-cao-tien-do/html', rq),
    ValidateImport: (request: IBaoCaoTienBoImportRequest) => apiClient.post(`bao-cao-tien-do/import/validate`, request),
    Import: (request: IBaoCaoTienBoImportRequest) => apiClient.post(`bao-cao-tien-do/import`, request),
    GetAllHtml: (request: IBaoCaoTienBoCreateRequest) => apiClient.post(`bao-cao-tien-do/get-all-html`, request),
}
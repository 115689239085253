import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from "../../../api/apiWrapper";
import { actions } from '../../actions/actionsWrapper';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { IBaiVietCountWaitingApproveStart, IBaiVietDeleteStart, IBaiVietLoadApprovedStart, IBaiVietLoadStart, IBaiVietLoadWaitingApproveStart, eBaiVietActionTypeIds } from './../../action-types/bai-viet/IBaiVietActionTypes';

const appBaiVietApi = apiWrapper.appBaiViet;

export function* baiVietSaga(): any {
    yield takeLatest(eBaiVietActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eBaiVietActionTypeIds.DELETE_START, deleteWorker)
    yield takeLatest(eBaiVietActionTypeIds.LOAD_WAITING_APPROVE_START, loadWaitngApproveWorker)
    yield takeLatest(eBaiVietActionTypeIds.COUNT_WAITING_APPROVE_START, countWaitngApproveWorker)
    yield takeLatest(eBaiVietActionTypeIds.LOAD_APPROVED_START, loadApprovedWorker)
}

function* loadWorker(action: IBaiVietLoadStart): any {

    const res: IBaseResponeModel = yield call([appBaiVietApi, appBaiVietApi.SelectBySchoolAsync], action.payload)
    if (res.is_success) {
        yield put(actions.baiViet.loadSuccess(res.data))
    } else {
        yield put(actions.baiViet.loadError(res.message || "Không tải được bài viết"))
    }
}

function* loadApprovedWorker(action: IBaiVietLoadApprovedStart): any {

    const res: IBaseResponeModel = yield call([appBaiVietApi, appBaiVietApi.SelectChoDuyetByTruongAsync], action.payload)
    if (res.is_success) {
        yield put(actions.baiViet.loadApprovedSuccess(res.data))
    } else {
        yield put(actions.baiViet.loadApprovedError(res.message || "Không tải được bài viết"))
    }
}

function* loadWaitngApproveWorker(action: IBaiVietLoadWaitingApproveStart): any {

    const res: IBaseResponeModel = yield call([appBaiVietApi, appBaiVietApi.SelectChoDuyetByTruongAsync], action.payload)
    if (res.is_success) {
        yield put(actions.baiViet.loadWaitingApproveSuccess(res.data))
    } else {
        yield put(actions.baiViet.loadWaitingApproveError(res.message || "Không tải được bài viết"))
    }
}

function* countWaitngApproveWorker(action: IBaiVietCountWaitingApproveStart): any {

    const res: IBaseResponeModel = yield call([appBaiVietApi, appBaiVietApi.SelectSoBaiChoDuyetByTruongAsync], action.payload)
    if (res.is_success) {
        yield put(actions.baiViet.countWaitingApproveSuccess(res.data))
    } else {
        yield put(actions.baiViet.countWaitingApproveError(res.message || "Không tải được bài viết"))
    }
}

function* deleteWorker(action: IBaiVietDeleteStart): any {
    const res: IBaseResponeModel = yield call([appBaiVietApi, appBaiVietApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.baiViet.deleteSuccess())
    } else {
        yield put(actions.baiViet.deleteError(res.message || "Không xóa được bài viết"))
    }
}

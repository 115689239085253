import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eLopChuNhiemActionTypeIds, ILopChuNhiemActionTypes } from "../../action-types/lop-chu-nhiem/ILopChuNhiemActionTypes";
import { ILopChuNhiemPageState } from "../../page-state-model/lop-chu-nhiem/ILopChuNhiemPageState";

const initialState: ILopChuNhiemPageState = {
    status: ePageBaseStatus.is_not_initialization,
    lopChuNhiems: [],
    isShowPhanCongGiaoVienModal: false
}

export const lopChuNhiemReducer = (state: ILopChuNhiemPageState = initialState, action: ILopChuNhiemActionTypes): ILopChuNhiemPageState => {
    switch (action.type) {
        case eLopChuNhiemActionTypeIds.SELECT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopChuNhiemActionTypeIds.SELECT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lopChuNhiems: action.payload
            }
        case eLopChuNhiemActionTypeIds.SELECT_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lopChuNhiems: []
            }
        case eLopChuNhiemActionTypeIds.SHOW_PHANCONG_MODAL:
            return {
                ...state,
                isShowPhanCongGiaoVienModal: true
            }
        case eLopChuNhiemActionTypeIds.CLOSE_PHANCONG_MODAL:
            return {
                ...state,
                isShowPhanCongGiaoVienModal: false
            }

        default:
            return state;
    }

}
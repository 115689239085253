import { sis_thongtu } from "../models/response/danh-muc/sis_thongtu";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_THONGTU_PATH = "thong-tu"
class ThongTuApi extends BaseCRUDApi<sis_thongtu> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
}
export const thongTuApi = new ThongTuApi({ path: API_THONGTU_PATH });


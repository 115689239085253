import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../../models/response/base-response";
import { actions } from '../../../actions/actionsWrapper';
import { eDgdkNlpcActionTypeIds, IDgdkNlpcHocSinhLoadStart, IDgdkNlpcDmLoadStart, IDgdkNlpcLoadKetQuaStart, IDgdkNlpcSaveKetQuaStart, IDgdkNlpcValidateStart, IDgdkNlpcImportStart, IDgdkNlpcLockCheckStart, IDgdkNlpcLockLopBoMonStart } from './../../../action-types/danh-gia/dinh-ky/IDgdkNlpcActionType';
import { dgdkLockApi } from "../../../../api/dgdkLockApi";

const hocSinhApi = apiWrapper.hocSinh;
const dgdkDmPhamChatApi = apiWrapper.soNhanXetItem;
const dgdkKetQuaNlpcApi = apiWrapper.danhGia.dinhKy.nlpc;
const dgdkNlpcLockApi = dgdkLockApi;

export function* dgdkNlpcSaga(): any {
    yield takeLatest(eDgdkNlpcActionTypeIds.HOCSINH_LOAD_START, loadHocSinhWorker)
    yield takeLatest(eDgdkNlpcActionTypeIds.DM_NLPC_LOAD_START, loadDmNLPCWorker)
    yield takeLatest(eDgdkNlpcActionTypeIds.KETQUA_LOAD_START, loadKetQuaWorker)
    yield takeEvery(eDgdkNlpcActionTypeIds.KETQUA_SAVE_START, saveKetQuaWorker)
    yield takeEvery(eDgdkNlpcActionTypeIds.VALIDATE_IMPORT_START, validateImportWorker)
    yield takeEvery(eDgdkNlpcActionTypeIds.IMPORT_START, importWorker)
    yield takeEvery(eDgdkNlpcActionTypeIds.LOCK_CHECK_START, lockCheckWorker)
    yield takeEvery(eDgdkNlpcActionTypeIds.LOCKLOPBOMON_CHECK_START, locklopbomonCheckWorker)

}

function* loadHocSinhWorker(action: IDgdkNlpcHocSinhLoadStart): any {
    const res: IBaseResponeModel = yield call(hocSinhApi.Select, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.nlpc.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.nlpc.loadHocSinhError(res.message ?? ""))
    }
}

function* loadDmNLPCWorker(action: IDgdkNlpcDmLoadStart): any {
    const res: IBaseResponeModel = yield call([dgdkDmPhamChatApi, dgdkDmPhamChatApi.SelectBySet], action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.nlpc.loadDgdkDmNlpcSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.nlpc.loadDgdkDmNlpcError(res.message ?? ""))
    }
}

function* loadKetQuaWorker(action: IDgdkNlpcLoadKetQuaStart): any {
    const res: IBaseResponeModel = yield call(dgdkKetQuaNlpcApi.Select, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.nlpc.loadKetQuaSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.nlpc.loadKetQuaError(res.message ?? ""))
    }
}

function* saveKetQuaWorker(action: IDgdkNlpcSaveKetQuaStart): any {
    const res: IBaseResponeModel = yield call(dgdkKetQuaNlpcApi.Save, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.nlpc.saveKetQuaSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.nlpc.saveKetQuaError(res.message ?? ""))
    }
}

function* validateImportWorker(action: IDgdkNlpcValidateStart): any {
    const res: IBaseResponeModel = yield call(dgdkKetQuaNlpcApi.ValidateImport, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.nlpc.validateImportSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.nlpc.validateImportError(res.message ?? ""))
    }
}

function* importWorker(action: IDgdkNlpcImportStart): any {
    const res: IBaseResponeModel = yield call(dgdkKetQuaNlpcApi.Import, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.nlpc.importSuccess())
    } else {
        yield put(actions.danhGia.dinhKy.nlpc.importError(res.message ?? ""))
    }
}


function* lockCheckWorker(action: IDgdkNlpcLockCheckStart): any {
    const res: IBaseResponeModel = yield call(dgdkNlpcLockApi.SelectDetailAsync, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.nlpc.lockCheckSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.nlpc.lockCheckError(res.message ?? ""))
    }
}


function* locklopbomonCheckWorker(action: IDgdkNlpcLockLopBoMonStart): any {
    const res: IBaseResponeModel = yield call(dgdkNlpcLockApi.SelectByLopBoMonAsync, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.nlpc.locklopbomonCheckSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.nlpc.locklopbomonCheckError(res.message ?? ""))
    }
}

import { app_baiviet } from './../../../models/response/app-phuhuynh/app_baiviet';
export enum eBaiVietDetailActionTypeIds {
    LOAD_START = "BAIVIET_DETAIL_LOAD_START",
    LOAD_SUCCESS = "BAIVIET_DETAIL_LOAD_SUCCESS",
    LOAD_ERROR = "BAIVIET_DETAIL_LOAD_ERROR",

    SAVE_START="BAIVIET_DETAIL_SAVE_START",
    SAVE_SUCCESS="BAIVIET_DETAIL_SAVE_SUCCESS",
    SAVE_ERROR="BAIVIET_DETAIL_SAVE_ERROR",

    CHANGE_DATA = "BAIVIET_DETAIL_CHANGE_DATA"
}

export interface IBaiVietDetailLoadStart {
    type: eBaiVietDetailActionTypeIds.LOAD_START,
    payload: number
}
export interface IBaiVietDetailLoadSuccess {
    type: eBaiVietDetailActionTypeIds.LOAD_SUCCESS,
    payload: app_baiviet
}
export interface IBaiVietDetailLoadError {
    type: eBaiVietDetailActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IBaiVietDetailChangeData {
    type: eBaiVietDetailActionTypeIds.CHANGE_DATA,
    payload: app_baiviet
}
export interface IBaiVietDetailSaveStart{
    type: eBaiVietDetailActionTypeIds.SAVE_START,
    payload: app_baiviet
}
export interface IBaiVietDetailSaveSuccess{
    type: eBaiVietDetailActionTypeIds.SAVE_SUCCESS,
    payload: number
}
export interface IBaiVietDetailSaveError{
    type: eBaiVietDetailActionTypeIds.SAVE_ERROR,
    payload: string
}
export type IBaiVietDetailActionTypes = IBaiVietDetailLoadStart | IBaiVietDetailLoadSuccess | IBaiVietDetailLoadError |
    IBaiVietDetailChangeData |
    IBaiVietDetailSaveStart |IBaiVietDetailSaveSuccess |IBaiVietDetailSaveError


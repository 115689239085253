import { eThuActionTypeIds, IThuActionTypes } from "../../action-types/danh-muc/IThuActionTypes"
import { IThuSourceState } from "../../page-state-model/danh-muc/IThuSourceState"

const initialState: IThuSourceState = {
    pl_thus: []
}

export const thuReducer = (state: IThuSourceState = initialState, action: IThuActionTypes): IThuSourceState => {
    switch (action.type) {
        case eThuActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                pl_thus: action.payload
            }
        default:
            return state;
    }
}
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_dgtx_dm_noidung_nlpc, sis_dgtx_dm_noidung_nlpc_treeview } from "../../../models/response/dgtx-dm/sis_dgtx_dm_noidung_nlpc";
import { eDgtxDmPhamChatActionTypeIds, IDgtxDmPhamChatChangeSelectedIds, IDgtxDmPhamChatCloseConfirm, IDgtxDmPhamChatCloseDetail, IDgtxDmPhamChatDeleteErr, IDgtxDmPhamChatDeleteStart, IDgtxDmPhamChatDeleteSuccess, IDgtxDmPhamChatLoadErr, IDgtxDmPhamChatLoadStart, IDgtxDmPhamChatLoadSuccess, IDgtxDmPhamChatShowConfirm, IDgtxDmPhamChatShowDetail } from "../../action-types/so-diem-tieu-hoc/IDgtxDmPhamChatActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const dgtxDmPhamChatActions = {
    loadDgtxDmPhamChatStart: () => BaseAction(undefined, eDgtxDmPhamChatActionTypeIds.LOAD_START),
    loadDgtxDmPhamChatSuccess: (respone: sis_dgtx_dm_noidung_nlpc_treeview[]): IDgtxDmPhamChatLoadSuccess => BaseAction(respone, eDgtxDmPhamChatActionTypeIds.LOAD_SUCCESS),
    loadDgtxDmPhamChatError: (messege: string): IDgtxDmPhamChatLoadErr => BaseAction(messege, eDgtxDmPhamChatActionTypeIds.LOAD_ERROR),
    showDgtxDmPhamChatDetail: (data?: sis_dgtx_dm_noidung_nlpc_treeview): IDgtxDmPhamChatShowDetail => BaseAction(data, eDgtxDmPhamChatActionTypeIds.SHOW_DETAIL),
    closeDgtxDmPhamChatDetail: (): IDgtxDmPhamChatCloseDetail => BaseAction(undefined, eDgtxDmPhamChatActionTypeIds.CLOSE_DETAIL),
    showDgtxDmPhamChatConfirm: (): IDgtxDmPhamChatShowConfirm => BaseAction(undefined, eDgtxDmPhamChatActionTypeIds.SHOW_CONFIRM),
    closeDgtxDmPhamChatConfirm: (): IDgtxDmPhamChatCloseConfirm => BaseAction(undefined, eDgtxDmPhamChatActionTypeIds.CLOSE_CONFIRM),
    changeDgtxDmPhamChatSelectedIds: (ids: number[]): IDgtxDmPhamChatChangeSelectedIds => BaseAction(ids, eDgtxDmPhamChatActionTypeIds.CHANGE_SELECTED_IDS),
    deleteDgtxDmPhamChatStart: (ids: IDeleteMultipleBaseRequest): IDgtxDmPhamChatDeleteStart => BaseAction(ids, eDgtxDmPhamChatActionTypeIds.DELETE_START),
    deleteDgtxDmPhamChatSuccess: (): IDgtxDmPhamChatDeleteSuccess => BaseAction(undefined, eDgtxDmPhamChatActionTypeIds.DELETE_SUCCESS),
    deleteDgtxDmPhamChatErr: (message: string): IDgtxDmPhamChatDeleteErr => BaseAction(message, eDgtxDmPhamChatActionTypeIds.DELETE_ERROR),
    //SAVE
    saveDgtxDmPhamChatStart: (data: sis_dgtx_dm_noidung_nlpc) => BaseAction(data, eDgtxDmPhamChatActionTypeIds.SAVE_START),
    saveDgtxDmPhamChatSuccess: () => BaseAction(undefined, eDgtxDmPhamChatActionTypeIds.SAVE_SUCCESS),
    saveDgtxDmPhamChatError: (message: string) =>  BaseAction(message, eDgtxDmPhamChatActionTypeIds.SAVE_ERR)
}



import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { app_menu_nhom } from "../../../models/response/app-phuhuynh/app_menu_nhom"

export enum eAppMenuNhomActionTypeIds {
    LOAD_START = "APP_MENU_NHOM_LOAD_START",
    LOAD_SUCCESS = "APP_MENU_NHOM_LOAD_SUCCESS",
    LOAD_ERROR = "APP_MENU_NHOM_LOAD_ERROR",

    SHOW_DETAIL = "APP_MENU_NHOM_SHOW_DETAIL",
    CLOSE_DETAIL = "APP_MENU_NHOM_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "APP_MENU_NHOM_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "APP_MENU_NHOM_SHOW_CONFIRM",
    CLOSE_CONFIRM = "APP_MENU_NHOM_CLOSE_CONFIRM",

    DELETE_START = "APP_MENU_NHOM_DELETE_START",
    DELETE_SUCCESS = "APP_MENU_NHOM_DELETE_SUCCESS",
    DELETE_ERROR = "APP_MENU_NHOM_DELETE_ERROR",

    SAVE_START = "APP_MENU_NHOM_SAVE_START",
    SAVE_SUCCESS = "APP_MENU_NHOM_SAVE_SUCCESS",
    SAVE_ERR = "APP_MENU_NHOM_SAVE_ERR"
}

export interface IAppMenuNhomLoadStart {
    type: eAppMenuNhomActionTypeIds.LOAD_START,
}
export interface IAppMenuNhomLoadSuccess {
    type: eAppMenuNhomActionTypeIds.LOAD_SUCCESS,
    payload: app_menu_nhom[]
}
export interface IAppMenuNhomLoadErr {
    type: eAppMenuNhomActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IAppMenuNhomShowDetail {
    type: eAppMenuNhomActionTypeIds.SHOW_DETAIL,
    payload?: app_menu_nhom
}
export interface IAppMenuNhomCloseDetail {
    type: eAppMenuNhomActionTypeIds.CLOSE_DETAIL,
}
export interface IAppMenuNhomChangeSelectedIds {
    type: eAppMenuNhomActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IAppMenuNhomShowConfirm {
    type: eAppMenuNhomActionTypeIds.SHOW_CONFIRM
}
export interface IAppMenuNhomCloseConfirm {
    type: eAppMenuNhomActionTypeIds.CLOSE_CONFIRM
}

export interface IAppMenuNhomDeleteStart {
    type: eAppMenuNhomActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IAppMenuNhomDeleteSuccess {
    type: eAppMenuNhomActionTypeIds.DELETE_SUCCESS,
}
export interface IAppMenuNhomDeleteErr {
    type: eAppMenuNhomActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IAppMenuNhomSaveStart {
    type: eAppMenuNhomActionTypeIds.SAVE_START,
    payload: app_menu_nhom
}
export interface IAppMenuNhomSaveSuccess {
    type: eAppMenuNhomActionTypeIds.SAVE_SUCCESS
}
export interface IAppMenuNhomSaveErr {
    type: eAppMenuNhomActionTypeIds.SAVE_ERR,
    payload: string
}
export type IAppMenuNhomActionTypes = IAppMenuNhomLoadStart | IAppMenuNhomLoadSuccess | IAppMenuNhomLoadErr |
    IAppMenuNhomShowDetail | IAppMenuNhomCloseDetail |
    IAppMenuNhomChangeSelectedIds |
    IAppMenuNhomShowConfirm | IAppMenuNhomCloseConfirm |
    IAppMenuNhomDeleteStart | IAppMenuNhomDeleteSuccess | IAppMenuNhomDeleteErr |
    IAppMenuNhomSaveStart | IAppMenuNhomSaveSuccess | IAppMenuNhomSaveErr
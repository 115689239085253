import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { KetQuaKhaoSatResponse, sis_form_khaosat_result } from "../../../models/response/form-khaosat/sis_form_khaosat_result";
import { sis_form_khaosat_result_detail } from "../../../models/response/form-khaosat/sis_form_khaosat_result_detail";
import { sis_khaosat } from "../../../models/response/form-khaosat/sis_khaosat";
import { IActionTypeBase } from "../IActionTypeBase";

export enum eKhaoSatActionTypeIds {
    LOAD_START = "KHAOSAT_LOAD_START",
    LOAD_SUCCESS = "KHAOSAT_LOAD_SUCCESS",
    LOAD_ERROR = "KHAOSAT_LOAD_ERROR",

    LOAD_BYTRUONG_START = "KHAOSAT_LOAD_BYTRUONG_START",
    LOAD_BYTRUONG_SUCCESS = "KHAOSAT_LOAD_BYTRUONG_SUCCESS",
    LOAD_BYTRUONG_ERROR = "KHAOSAT_LOAD_BYTRUONG_ERROR",

    LOAD_RESULT_BYTRUONG_START = "KHAOSAT_LOAD_RESULT_BYTRUONG_START",
    LOAD_RESULT_BYTRUONG_SUCCESS = "KHAOSAT_LOAD_RESULT_BYTRUONG_SUCCESS",
    LOAD_RESULT_BYTRUONG_ERROR = "KHAOSAT_LOAD_RESULT_BYTRUONG_ERROR",

    LOAD_KETQUAKHAOSAT_START = "KHAOSAT_LOAD_KETQUAKHAOSAT_START",
    LOAD_KETQUAKHAOSAT_SUCCESS = "KHAOSAT_LOAD_KETQUAKHAOSAT_SUCCESS",
    LOAD_KETQUAKHAOSAT_ERROR = "KHAOSAT_LOAD_KETQUAKHAOSAT_ERROR",

    LOAD_KETQUAKHAOSAT_CHITIET_START = "KHAOSAT_LOAD_KETQUAKHAOSAT_CHITIET_START",
    LOAD_KETQUAKHAOSAT_CHITIET_SUCCESS = "KHAOSAT_LOAD_KETQUAKHAOSAT_CHITIET_SUCCESS",
    LOAD_KETQUAKHAOSAT_CHITIET_ERROR = "KHAOSAT_LOAD_KETQUAKHAOSAT_CHITIET_ERROR",

    LOAD_RESULT_DETAIL_START = "KHAOSAT_LOAD_RESULT_DETAIL_START",
    LOAD_RESULT_DETAIL_SUCCESS = "KHAOSAT_LOAD_RESULT_DETAIL_SUCCESS",
    LOAD_RESULT_DETAIL_ERROR = "KHAOSAT_LOAD_RESULT_DETAIL_ERROR",

    SHOW_MODAL = "KHAOSAT_SHOW_MODAL",
    CHANGE_DETAIL = "KHAOSAT_CHANGE_DETAIL",

    CHANGE_SELECTED_IDS = "KHAOSAT_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "KHAOSAT_SHOW_CONFIRM",

    DELETE_START = "KHAOSAT_DELETE_START",
    DELETE_SUCCESS = "KHAOSAT_DELETE_SUCCESS",
    DELETE_ERROR = "KHAOSAT_DELETE_ERROR",

    SAVE_START = "KHAOSAT_SAVE_START",
    SAVE_SUCCESS = "KHAOSAT_SAVE_SUCCESS",
    SAVE_ERR = "KHAOSAT_SAVE_ERR",

    PUSHNOTIFY_START = "KHAOSAT_PUSHNOTIFY_START",
    PUSHNOTIFY_SUCCESS = "KHAOSAT_PUSHNOTIFY_SUCCESS",
    PUSHNOTIFY_ERR = "KHAOSAT_PUSHNOTIFY_ERR",

    CLEAR_STATUS = "KHAOSAT_CLEAR_STATUS",
}
//ORIGIN MODEL
export interface IKhaoSatLoadStart extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_START, undefined> { }
export interface IKhaoSatLoadSuccess extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_SUCCESS, sis_khaosat[]> { }
export interface IKhaoSatLoadErr extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_ERROR, string> { }

export interface IKhaoSatLoadByTruongStart extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_BYTRUONG_START, { dm_truong_id: number, nam_hoc: string }> { }
export interface IKhaoSatLoadByTruongSuccess extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_BYTRUONG_SUCCESS, sis_khaosat[]> { }
export interface IKhaoSatLoadByTruongErr extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_BYTRUONG_ERROR, string> { }

export interface IKhaoSatLoadResultByTruongStart extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_RESULT_BYTRUONG_START, { dm_truong_id: number, sis_khaosat_id: number; nam_hoc: string }> { }
export interface IKhaoSatLoadResultByTruongSuccess extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_RESULT_BYTRUONG_SUCCESS, sis_form_khaosat_result[]> { }
export interface IKhaoSatLoadResultByTruongErr extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_RESULT_BYTRUONG_ERROR, string> { }

export interface IKhaoSatLoadKetQuaKhaosatStart extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_START, { sis_khaosat_id: number; }> { }
export interface IKhaoSatLoadKetQuaKhaosatSuccess extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_SUCCESS, KetQuaKhaoSatResponse[]> { }
export interface IKhaoSatLoadKetQuaKhaosatErr extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_ERROR, string> { }

export interface IKhaoSatLoadKetQuaKhaoSatChiTietStart extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_CHITIET_START, { sis_khaosat_id: number; }> { }
export interface IKhaoSatLoadKetQuaKhaoSatChiTietSuccess extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_CHITIET_SUCCESS, sis_form_khaosat_result_detail[]> { }
export interface IKhaoSatLoadKetQuaKhaoSatChiTietErr extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_CHITIET_ERROR, string> { }

export interface IKhaoSatLoadResultDetailStart extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_RESULT_DETAIL_START, { sis_form_khaosat_result_id: number }> { }
export interface IKhaoSatLoadResultDetailSuccess extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_RESULT_DETAIL_SUCCESS, sis_form_khaosat_result_detail[]> { }
export interface IKhaoSatLoadResultDetailErr extends IActionTypeBase<eKhaoSatActionTypeIds.LOAD_RESULT_DETAIL_ERROR, string> { }

//DETAIL
export interface IKhaoSatChangeDetail extends IActionTypeBase<eKhaoSatActionTypeIds.CHANGE_DETAIL, sis_khaosat | undefined> { }
export interface IKhaoSatChangeSelectedIds extends IActionTypeBase<eKhaoSatActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
//CONFIRM
export interface IKhaoSatShowModal extends IActionTypeBase<eKhaoSatActionTypeIds.SHOW_MODAL, boolean> { }
export interface IKhaoSatShowConfirm extends IActionTypeBase<eKhaoSatActionTypeIds.SHOW_CONFIRM, boolean> { }
//DELETE
export interface IKhaoSatDeleteStart extends IActionTypeBase<eKhaoSatActionTypeIds.DELETE_START, IDeleteMultipleBaseRequest> { }
export interface IKhaoSatDeleteSuccess extends IActionTypeBase<eKhaoSatActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface IKhaoSatDeleteErr extends IActionTypeBase<eKhaoSatActionTypeIds.DELETE_ERROR, string> { }
//SAVE
export interface IKhaoSatSaveStart extends IActionTypeBase<eKhaoSatActionTypeIds.SAVE_START, sis_khaosat> { }
export interface IKhaoSatSaveSuccess extends IActionTypeBase<eKhaoSatActionTypeIds.SAVE_SUCCESS, undefined> { }
export interface IKhaoSatSaveErr extends IActionTypeBase<eKhaoSatActionTypeIds.SAVE_ERR, string> { }

export interface IKhaoSatPushNotifyStart extends IActionTypeBase<eKhaoSatActionTypeIds.PUSHNOTIFY_START, { sis_khaosat_id: number }> { }
export interface IKhaoSatPushNotifySuccess extends IActionTypeBase<eKhaoSatActionTypeIds.PUSHNOTIFY_SUCCESS, undefined> { }
export interface IKhaoSatPushNotifyErr extends IActionTypeBase<eKhaoSatActionTypeIds.PUSHNOTIFY_ERR, string> { }

export interface IKhaoSatClearStatus extends IActionTypeBase<eKhaoSatActionTypeIds.CLEAR_STATUS, undefined> { }

export type IKhaoSatActionTypes =
    IKhaoSatLoadStart | IKhaoSatLoadSuccess | IKhaoSatLoadErr |
    IKhaoSatChangeDetail |
    IKhaoSatChangeSelectedIds | IKhaoSatShowModal |
    IKhaoSatShowConfirm |
    IKhaoSatDeleteStart | IKhaoSatDeleteSuccess | IKhaoSatDeleteErr |
    IKhaoSatSaveStart | IKhaoSatSaveSuccess | IKhaoSatSaveErr |
    IKhaoSatPushNotifyStart | IKhaoSatPushNotifySuccess | IKhaoSatPushNotifyErr |
    IKhaoSatLoadByTruongStart | IKhaoSatLoadByTruongSuccess | IKhaoSatLoadByTruongErr |
    IKhaoSatLoadResultByTruongStart | IKhaoSatLoadResultByTruongSuccess | IKhaoSatLoadResultByTruongErr |
    IKhaoSatLoadResultDetailStart | IKhaoSatLoadResultDetailSuccess | IKhaoSatLoadResultDetailErr |
    IKhaoSatClearStatus |
    IKhaoSatLoadKetQuaKhaosatStart | IKhaoSatLoadKetQuaKhaosatSuccess | IKhaoSatLoadKetQuaKhaosatErr |
    IKhaoSatLoadKetQuaKhaoSatChiTietStart | IKhaoSatLoadKetQuaKhaoSatChiTietSuccess | IKhaoSatLoadKetQuaKhaoSatChiTietErr
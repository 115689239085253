import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from '../../../models/response/base-response';
import { actions } from '../../actions/actionsWrapper';
import { eBaiTapActionTypeIds, IBaiTapDeleteStart, IBaiTapLoadTreeViewStart, IBaiTapSaveStart } from './../../action-types/bai-tap/IBaiTapActionType';

const baiTapApi = apiWrapper.baiTapWrapper.baiTap;

export function* baiTapSaga(): any {
    yield takeLatest(eBaiTapActionTypeIds.LOAD_TREEVIEW_START, loadWorker)
    yield takeEvery(eBaiTapActionTypeIds.SAVE_START, saveWorker)
    
    yield takeEvery(eBaiTapActionTypeIds.DELETE_START, deleteWorker)
}

function* loadWorker(action: IBaiTapLoadTreeViewStart): any {
    const res: IBaseResponeModel = yield call([baiTapApi, baiTapApi.SelectTreeView], action.payload)
    if (res.is_success) {
        yield put(actions.baiTapWrapper.baiTap.loadTreeViewSuccess(res.data))
    } else {
        yield put(actions.baiTapWrapper.baiTap.loadTreeViewError(res.message || ""))
    }
}


function* saveWorker(action: IBaiTapSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([baiTapApi, baiTapApi.Update], action.payload)
    } else {
        res = yield call([baiTapApi, baiTapApi.Insert], action.payload)
    }
    if (res.is_success) {
        yield put(actions.baiTapWrapper.baiTap.saveSuccess())
    } else {
        yield put(actions.baiTapWrapper.baiTap.saveError(res.message || ""))
    }
}


function* deleteWorker(action: IBaiTapDeleteStart): any {
    const res: IBaseResponeModel = yield call([baiTapApi, baiTapApi.Delete], action.payload)
    if (res.is_success) {
        yield put(actions.baiTapWrapper.baiTap.deleteSuccess())
    } else {
        yield put(actions.baiTapWrapper.baiTap.deleteError(res.message ?? ""))
    }
}

import { ISoDiemUpdateMoetRequest } from '../../../models/request/so-diem/ISoDiemUpdateMoetRequest';
import { ISoDiemMapping } from '../../../models/response/so-diem/ISoDiemMapping';
import { sis_sodiem_thanhphandiem } from '../../../models/response/so-diem/sis_sodiem_thanhphandiem';
import { IActionTypeBase } from './../IActionTypeBase';
export enum eSoDiemMappingActionTypeIds {
    LOAD_MAPPING_START = "SODIEM_MAPPING_LOAD_START",
    LOAD_MAPPING_SUCCESS = "SODIEM_MAPPING_LOAD_SUCCESS",
    LOAD_MAPPING_ERROR = "SODIEM_MAPPING_LOAD_ERROR",

    LOAD_MOET_THANHPHAN_START = "SODIEM_MAPPING_LOAD_THANHPHAN_START",
    LOAD_MOET_THANHPHAN_SUCCESS = "SODIEM_MAPPING_LOAD_THANHPHAN_SUCCESS",
    LOAD_MOET_THANHPHAN_ERROR = "SODIEM_MAPPING_LOAD_THANHPHAN_ERROR",

    UPDATE_MOET_START = "SODIEM_MAPPING_UPDATE_MOET_START",
    UPDATE_MOET_SUCCESS = "SODIEM_MAPPING_UPDATE_MOET_SUCCESS",
    UPDATE_MOET_ERROR = "SODIEM_MAPPING_UPDATE_MOET_ERROR",

    SHOW_MAPPING_MODAL = "SODIEM_MAPPING_SHOW_MAPPING_MODAL",
    CLOSE_MAPPING_MODAL = "SODIEM_MAPPING_CLOSE_MAPPING_MODAL",

    SHOW_DELETE_CONFIRM = "SODIEM_MAPPING_SHOW_DELETE_CONFIRM",
    CLOSE_DELETE_CONFIRM = "SODIEM_MAPPING_CLOSE_DELETE_CONFIRM",

    SAVE_MAPPING_START = "SODIEM_MAPPING_SAVE_MAPPING_START",
    SAVE_MAPPING_SUCCESS = "SODIEM_MAPPING_SAVE_MAPPING_SUCCESS",
    SAVE_MAPPING_ERRROR = "SODIEM_MAPPING_SAVE_MAPPING_ERRROR",
}
export interface ISoDiemMappingLoadStart extends IActionTypeBase<eSoDiemMappingActionTypeIds.LOAD_MAPPING_START, number> { }
export interface ISoDiemMappingLoadSuccess extends IActionTypeBase<eSoDiemMappingActionTypeIds.LOAD_MAPPING_SUCCESS, ISoDiemMapping[]> { }
export interface ISoDiemMappingLoadError extends IActionTypeBase<eSoDiemMappingActionTypeIds.LOAD_MAPPING_ERROR, string> { }

export interface ISoDiemMappingLoadThanhPhanStart extends IActionTypeBase<eSoDiemMappingActionTypeIds.LOAD_MOET_THANHPHAN_START, number> { }
export interface ISoDiemMappingLoadThanhPhanSuccess extends IActionTypeBase<eSoDiemMappingActionTypeIds.LOAD_MOET_THANHPHAN_SUCCESS, sis_sodiem_thanhphandiem[]> { }
export interface ISoDiemMappingLoadThanhPhanError extends IActionTypeBase<eSoDiemMappingActionTypeIds.LOAD_MOET_THANHPHAN_ERROR, string> { }

export interface ISoDiemMappingUpdateMoetStart extends IActionTypeBase<eSoDiemMappingActionTypeIds.UPDATE_MOET_START, ISoDiemUpdateMoetRequest> { }
export interface ISoDiemMappingUpdateMoetSuccess extends IActionTypeBase<eSoDiemMappingActionTypeIds.UPDATE_MOET_SUCCESS, undefined> { }
export interface ISoDiemMappingUpdateMoetError extends IActionTypeBase<eSoDiemMappingActionTypeIds.UPDATE_MOET_ERROR, string> { }

export interface ISoDiemMappingShowMappingModal extends IActionTypeBase<eSoDiemMappingActionTypeIds.SHOW_MAPPING_MODAL, sis_sodiem_thanhphandiem> { }
export interface ISoDiemMappingCloseMappingModal extends IActionTypeBase<eSoDiemMappingActionTypeIds.CLOSE_MAPPING_MODAL, undefined> { }

export interface ISoDiemMappingShowDeleteConfirm extends IActionTypeBase<eSoDiemMappingActionTypeIds.SHOW_DELETE_CONFIRM, sis_sodiem_thanhphandiem> { }
export interface ISoDiemMappingCloseDeleteConfirm extends IActionTypeBase<eSoDiemMappingActionTypeIds.CLOSE_DELETE_CONFIRM, undefined> { }

export interface ISoDiemMappingSaveMappingStart extends IActionTypeBase<eSoDiemMappingActionTypeIds.SAVE_MAPPING_START, ISoDiemMapping> { }

export interface ISoDiemMappingSaveMappingSuccess extends IActionTypeBase<eSoDiemMappingActionTypeIds.SAVE_MAPPING_SUCCESS, undefined> { }
export interface ISoDiemMappingSaveMappingError extends IActionTypeBase<eSoDiemMappingActionTypeIds.SAVE_MAPPING_ERRROR, string> { }

export type ISoDiemMappingActionTypes = ISoDiemMappingLoadStart | ISoDiemMappingLoadSuccess | ISoDiemMappingLoadError |
    ISoDiemMappingLoadThanhPhanStart | ISoDiemMappingLoadThanhPhanSuccess | ISoDiemMappingLoadThanhPhanError |
    ISoDiemMappingUpdateMoetStart | ISoDiemMappingUpdateMoetSuccess | ISoDiemMappingUpdateMoetError |
    ISoDiemMappingShowMappingModal | ISoDiemMappingCloseMappingModal |
    ISoDiemMappingSaveMappingStart | ISoDiemMappingSaveMappingSuccess | ISoDiemMappingSaveMappingError |
    ISoDiemMappingShowDeleteConfirm | ISoDiemMappingCloseDeleteConfirm
import { ePageBaseStatus } from '../../../../models/ePageBaseStatus';
import { eSoNhanXetActionTypeIds, ISoNhanXetActionTypes } from '../../../action-types/so-diem-tieu-hoc/ISoNhanXetActionTypes';
import { IDgdkDmNlpcSetPageState } from "../../../page-state-model/so-diem-tieu-hoc/dinh-ky/IDgdkDmNlpcSetPageState";
const iniState: IDgdkDmNlpcSetPageState = {
    soNhanXets: [],
    isShowDeleteConfirm: false,
    isShowDetailModal: false,
    status: ePageBaseStatus.is_not_initialization,
    soNhanXetEditing: undefined
}
export const soNhanXetReducer = (state: IDgdkDmNlpcSetPageState = iniState, action: ISoNhanXetActionTypes): IDgdkDmNlpcSetPageState => {
    switch (action.type) {
        case eSoNhanXetActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eSoNhanXetActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                soNhanXets: action.payload
            }
        case eSoNhanXetActionTypeIds.LOAD_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eSoNhanXetActionTypeIds.SHOW_DETAIL_MODAL:
            return {
                ...state,
                isShowDetailModal: true,
                soNhanXetEditing: action.payload
            }
        case eSoNhanXetActionTypeIds.CLOSE_DETAIL_MODAL:
            return {
                ...state,
                isShowDetailModal: false,
                soNhanXetEditing: action.payload
            }
        case eSoNhanXetActionTypeIds.SHOW_DELETE_CONFIRM:
            return {
                ...state,
                isShowDeleteConfirm: true,
                soNhanXetEditing: action.payload
            }
        case eSoNhanXetActionTypeIds.CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                isShowDeleteConfirm: false,
                soNhanXetEditing: action.payload
            }
        case eSoNhanXetActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eSoNhanXetActionTypeIds.DELETE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDeleteConfirm: false
            }
        case eSoNhanXetActionTypeIds.DELETE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eSoNhanXetActionTypeIds.SAVING_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eSoNhanXetActionTypeIds.SAVING_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDetailModal: false
            }
        case eSoNhanXetActionTypeIds.SAVING_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eSoNhanXetActionTypeIds.CHANGE_SELECTED_DATA:
            return {
                ...state,
                soNhanXetEditing: action.payload
            }

        default:
            return {
                ...state,
            }
    }
}
import { ISoNhanXetItem } from "../models/response/dgdk-dm/ISoNhanXetItem";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_SONHANXET_ITEM_PATH = "so-nhan-xet-item"

class SoNhanXetItemApi extends BaseCRUDApi<ISoNhanXetItem> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    SelectBySet(setId: number) {
        return apiClient.get(`${API_SONHANXET_ITEM_PATH}/set/${setId}`)
    }
}
export const soNhanXetItemApi = new SoNhanXetItemApi({ path: API_SONHANXET_ITEM_PATH });


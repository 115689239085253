import React from 'react';
import styles from "./PopUpForm.module.css"
interface IPopUpFormProps {
    onSubmit: () => void,
    children?: React.ReactNode
}
interface IPopUpFormActions {
    children?: React.ReactNode
}

export const PopUpForm = (props: IPopUpFormProps) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className={styles.form}>
                {props.children}
            </div>
        </form>
    );
};

export const PopUpFormActions = (props: IPopUpFormActions) => {
    return (
        <div className={styles.actions}>
            {props.children}
        </div>
    );
};

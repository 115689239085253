import { IBaiVietCommentSelectRequest } from '../../models/request/app-phuhuynh/IBaiVietCommentSelectRequest';
import { IBaiVietRepliesCommentSelectRequest } from '../../models/request/app-phuhuynh/IBaiVietRepliesCommentSelectRequest';
import { IAppBaiVietComment } from '../../models/response/app-phuhuynh/IAppBaiVietComment';
import { apiClient } from "../apiClient";

export const appBaiVietCommentApi = {
    selectByBaiVietAsync: (request: IBaiVietCommentSelectRequest) =>
        apiClient.get(`bai-viet/${request.baiVietId}/comment?page_number=${request.page_number}&page_size=${request.page_size}`),
    selectReliesCommentAsync: (request: IBaiVietRepliesCommentSelectRequest) =>
        apiClient.get(`bai-viet/comment/${request.commentId}/replies?page_number=${request.page_number}&page_size=${request.page_size}`),
    addCommentAsync: (request: IAppBaiVietComment) =>
        apiClient.post(`bai-viet/comment`, request)
}
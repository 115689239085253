import { INamHocTruongFilter } from "../models/INamHocTruongFilter";
import { pl_hocky } from "../models/response/danh-muc/pl_hocky";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_HOCKY_PATH = "hoc-ky"

class HocKyApi extends BaseCRUDApi<pl_hocky> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    Select(request: INamHocTruongFilter) {
        return apiClient.get(`hoc-ky/nam-hoc/${request.nam_hoc}/truong/${request.dm_truong_id}`)
    }
}
export const hocKyApi = new HocKyApi({ path: API_HOCKY_PATH });
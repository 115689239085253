import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import FormKhaoSatItemAddRequest from "../../../models/request/form-khaosat/FormKhaoSatItemAddRequest";
import { FormKhaoSatItemResponse, sis_form_khaosat_item } from "../../../models/response/form-khaosat/sis_form_khaosat_item";
import { IActionTypeBase } from "../IActionTypeBase";

export enum eFormKhaoSatItemActionTypeIds {
    LOAD_START = "FORMKHAOSATITEM_LOAD_START",
    LOAD_SUCCESS = "FORMKHAOSATITEM_LOAD_SUCCESS",
    LOAD_ERROR = "FORMKHAOSATITEM_LOAD_ERROR",

    LOAD_BYFORM_START = "FORMKHAOSATITEM_LOAD_BYFORM_START",
    LOAD_BYFORM_SUCCESS = "FORMKHAOSATITEM_LOAD_BYFORM_SUCCESS",
    LOAD_BYFORM_ERROR = "FORMKHAOSATITEM_LOAD_BYFORM_ERROR",

    SHOW_MODAL = "FORMKHAOSATITEM_SHOW_MODAL",
    CHANGE_DETAIL = "FORMKHAOSATITEM_CHANGE_DETAIL",

    CHANGE_SELECTED_IDS = "FORMKHAOSATITEM_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "FORMKHAOSATITEM_SHOW_CONFIRM",

    DELETE_START = "FORMKHAOSATITEM_DELETE_START",
    DELETE_SUCCESS = "FORMKHAOSATITEM_DELETE_SUCCESS",
    DELETE_ERROR = "FORMKHAOSATITEM_DELETE_ERROR",

    SAVE_START = "FORMKHAOSATITEM_SAVE_START",
    SAVE_SUCCESS = "FORMKHAOSATITEM_SAVE_SUCCESS",
    SAVE_ERR = "FORMKHAOSATITEM_SAVE_ERR",

    ADDITEMS_START = "FORMKHAOSATITEM_ADDITEMS_START",
    ADDITEMS_SUCCESS = "FORMKHAOSATITEM_ADDITEMS_SUCCESS",
    ADDITEMS_ERR = "FORMKHAOSATITEM_ADDITEMS_ERR",

    ADDEDITOR_START = "FORMKHAOSATITEM_ADDEDITOR_START",
    ADDEDITOR_SUCCESS = "FORMKHAOSATITEM_ADDEDITOR_SUCCESS",
    ADDEDITOR_ERR = "FORMKHAOSATITEM_ADDEDITOR_ERR",

    UPDATEIDX_START = "FORMKHAOSATITEM_UPDATEIDX_START",
    UPDATEIDX_SUCCESS = "FORMKHAOSATITEM_UPDATEIDX_SUCCESS",
    UPDATEIDX_ERR = "FORMKHAOSATITEM_UPDATEIDX_ERR",

    CLEAR_STATUS = "FORMKHAOSATITEM_CLEAR_STATUS",
}
//ORIGIN MODEL
export interface IFormKhaoSatItemLoadStart extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.LOAD_START, undefined> { }
export interface IFormKhaoSatItemLoadSuccess extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.LOAD_SUCCESS, FormKhaoSatItemResponse[]> { }
export interface IFormKhaoSatItemLoadErr extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.LOAD_ERROR, string> { }

export interface IFormKhaoSatItemLoadByFormStart extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.LOAD_BYFORM_START, { sis_form_khaosat_id: number }> { }
export interface IFormKhaoSatItemLoadByFormSuccess extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.LOAD_BYFORM_SUCCESS, FormKhaoSatItemResponse[]> { }
export interface IFormKhaoSatItemLoadByFormErr extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.LOAD_BYFORM_ERROR, string> { }

//DETAIL
export interface IFormKhaoSatItemChangeDetail extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.CHANGE_DETAIL, sis_form_khaosat_item | undefined> { }
export interface IFormKhaoSatItemChangeSelectedIds extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
//CONFIRM
export interface IFormKhaoSatItemShowModal extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.SHOW_MODAL, boolean> { }
export interface IFormKhaoSatItemShowConfirm extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.SHOW_CONFIRM, boolean> { }
//DELETE
export interface IFormKhaoSatItemDeleteStart extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.DELETE_START, IDeleteMultipleBaseRequest> { }
export interface IFormKhaoSatItemDeleteSuccess extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface IFormKhaoSatItemDeleteErr extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.DELETE_ERROR, string> { }
//SAVE
export interface IFormKhaoSatItemSaveStart extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.SAVE_START, sis_form_khaosat_item> { }
export interface IFormKhaoSatItemSaveSuccess extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.SAVE_SUCCESS, undefined> { }
export interface IFormKhaoSatItemSaveErr extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.SAVE_ERR, string> { }

export interface IFormKhaoSatItemAddItemsStart extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.ADDITEMS_START, { sis_form_khaosat_id: number, sis_form_khaosat_items: sis_form_khaosat_item[] }> { }
export interface IFormKhaoSatItemAddItemsSuccess extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.ADDITEMS_SUCCESS, undefined> { }
export interface IFormKhaoSatItemAddItemsErr extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.ADDITEMS_ERR, string> { }

export interface IFormKhaoSatItemAddEditorStart extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.ADDEDITOR_START, FormKhaoSatItemAddRequest> { }
export interface IFormKhaoSatItemAddEditorSuccess extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.ADDEDITOR_SUCCESS, undefined> { }
export interface IFormKhaoSatItemAddEditorErr extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.ADDEDITOR_ERR, string> { }

export interface IFormKhaoSatItemUPDATEIDXStart extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.UPDATEIDX_START, { sis_form_khaosat_item_ids: number[] }> { }
export interface IFormKhaoSatItemUPDATEIDXSuccess extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.UPDATEIDX_SUCCESS, undefined> { }
export interface IFormKhaoSatItemUPDATEIDXErr extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.UPDATEIDX_ERR, string> { }

export interface IFormKhaoSatItemClearStatus extends IActionTypeBase<eFormKhaoSatItemActionTypeIds.CLEAR_STATUS, undefined> { }

export type IFormKhaoSatItemActionTypes =
    IFormKhaoSatItemLoadStart | IFormKhaoSatItemLoadSuccess | IFormKhaoSatItemLoadErr |
    IFormKhaoSatItemChangeDetail |
    IFormKhaoSatItemChangeSelectedIds | IFormKhaoSatItemShowModal |
    IFormKhaoSatItemShowConfirm |
    IFormKhaoSatItemDeleteStart | IFormKhaoSatItemDeleteSuccess | IFormKhaoSatItemDeleteErr |
    IFormKhaoSatItemSaveStart | IFormKhaoSatItemSaveSuccess | IFormKhaoSatItemSaveErr |
    IFormKhaoSatItemLoadByFormStart | IFormKhaoSatItemLoadByFormSuccess | IFormKhaoSatItemLoadByFormErr  |
    IFormKhaoSatItemClearStatus |
    IFormKhaoSatItemAddItemsStart |IFormKhaoSatItemAddItemsSuccess |IFormKhaoSatItemAddItemsErr |
    IFormKhaoSatItemAddEditorStart |IFormKhaoSatItemAddEditorSuccess |IFormKhaoSatItemAddEditorErr |
    IFormKhaoSatItemUPDATEIDXStart |IFormKhaoSatItemUPDATEIDXSuccess |IFormKhaoSatItemUPDATEIDXErr
import { call, put, takeLatest } from "redux-saga/effects"
import { apiWrapper } from "../../../api/apiWrapper"
import { IBaseResponeModel } from "../../../models/response/base-response"
import { eDiemDanhStatusActionTypeIds } from "../../action-types/diem-danh/IDiemDanhStatusActionTypes"
import { actions } from "../../actions/actionsWrapper"

const diemDanhStatusApi = apiWrapper.diemDanhStatus;

function* loadDiemDanhStatusWorker(): any {
    const res: IBaseResponeModel = yield call([diemDanhStatusApi, apiWrapper.diemDanhStatus.SelectAll])
    if (res.is_success) {
        yield put(actions.diemDanhStatus.loadSuccess(res.data))
    }else{
        yield put(actions.diemDanhStatus.loadError('Không load được trạng thái điểm danh')) 
    }
}

export function* diemDanhStatusSaga(): any {
    yield takeLatest(eDiemDanhStatusActionTypeIds.LOAD_START, loadDiemDanhStatusWorker)
}
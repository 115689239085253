

import { pl_lopbomon_type } from "./../models/response/lop-bo-mon/pl_lopbomon_type";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_LOPBOMON_TYPE_PATH = "lopbomon-type"

class LopBoMonTypeApi extends BaseCRUDApi<pl_lopbomon_type> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    SelectByTruongAsync(dm_truong_id: number) {
        return apiClient.get(`${this._path}/${dm_truong_id}/select-all-by-truong`)
    }
}
export const lopBoMonTypeApi = new LopBoMonTypeApi({ path: API_LOPBOMON_TYPE_PATH });
import { NotifyHelper } from './../../../../helpers/toast';
import { ISoNhanXet } from '../../../../models/response/dgdk-dm/ISoNhanXet';
import { ISoNhanXetSelectRequest } from '../../../../models/request/so-tieu-hoc/ISoNhanXetSelectRequest';
import { eSoNhanXetActionTypeIds } from '../../../action-types/so-diem-tieu-hoc/ISoNhanXetActionTypes';
import { baseAction } from "../../IActionBase";

export const dgdkDmNlpcSetActions = {
    loadStart: (request: ISoNhanXetSelectRequest) => baseAction(eSoNhanXetActionTypeIds.LOAD_START, request),
    loadSuccess: (data: ISoNhanXet[]) => baseAction(eSoNhanXetActionTypeIds.LOAD_SUCCESS, data),
    loadError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eSoNhanXetActionTypeIds.LOAD_ERROR, message)
    },
    saveStart: (request: ISoNhanXet) => baseAction(eSoNhanXetActionTypeIds.SAVING_START, request),
    saveSuccess: () => baseAction(eSoNhanXetActionTypeIds.SAVING_SUCCESS, undefined),
    saveError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eSoNhanXetActionTypeIds.SAVING_ERROR, message)
    },
    deleteStart: (request: number) => baseAction(eSoNhanXetActionTypeIds.DELETE_START, request),
    deleteSuccess: () => baseAction(eSoNhanXetActionTypeIds.DELETE_SUCCESS, undefined),
    deleteError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eSoNhanXetActionTypeIds.DELETE_ERROR, message)
    },
    showDetailModal: (data?: ISoNhanXet) => baseAction(eSoNhanXetActionTypeIds.SHOW_DETAIL_MODAL, data),
    closeDetailModal: () => baseAction(eSoNhanXetActionTypeIds.CLOSE_DETAIL_MODAL, undefined),

    showDeleteConfirm: (data?: ISoNhanXet) => baseAction(eSoNhanXetActionTypeIds.SHOW_DELETE_CONFIRM, data),
    closeDeleteConifrm: () => baseAction(eSoNhanXetActionTypeIds.CLOSE_DELETE_CONFIRM, undefined),

    changeSelectedData: (data:ISoNhanXet) => baseAction(eSoNhanXetActionTypeIds.CHANGE_SELECTED_DATA, data),
}
import { NotifyHelper } from "../../../helpers/toast";
import { INhanXetHangNgayLopSelectRequest } from "../../../models/request/nhan-xet/INhanXetHangNgayLopSelectRequest";
import { INhanXetLopBoMonPublishMarkRequest } from "../../../models/request/nhan-xet/INhanXetLopBoMonPublishMarkRequest";
import { INhanXetLopChuNhiemPublishMarkRequest } from "../../../models/request/nhan-xet/INhanXetLopChuNhiemPublishMarkRequest";
import { INhanXetThaiDoHocTapSelectRequest } from "../../../models/request/nhan-xet/INhanXetThaiDoHocTapSelectRequest";
import { INhanXetLopBoMonRequest, INhanXetLopChuNhiemRequest } from "../../../models/request/nhan-xet/NhanXetChangeDataRequest";
import { INhanXetHangNgayCommentDeleteRequest, INhanXetHangNgayCommentRequest } from "../../../models/request/nhan-xet/NhanXetHangNgayCommentRequest";
import { INhanXetLopBoMonSelectRequest, INhanXetLopChuNhiemSelectRequest } from "../../../models/request/nhan-xet/NhanXetSelectRequest";
import { DanhSachNhanXetTheoLopResponse } from "../../../models/response/nhan-xet/DanhSachNhanXetTheoLopResponse";
import { sis_nhanxet_hangngay_comment } from "../../../models/response/nhan-xet/sis_nhanxet_hangngay_comment";
import { sis_nhanxet_hangngay_lop } from "../../../models/response/nhan-xet/sis_nhanxet_hangngay_lop";
import { sis_thaidohoctap_ketqua } from "../../../models/response/nhan-xet/sis_thaidohoctap_ketqua";
import { eNhanXetHangNgayActionTypeIds, IHocTapHangNgayShowModal, INhanXetHangNgayChangeDanhSachNhanXetHangNgay, INhanXetHangNgayDeleteCommentsErr, INhanXetHangNgayDeleteCommentsStart, INhanXetHangNgayDeleteCommentsSuccess, INhanXetHangNgayLopBoMonErr, INhanXetHangNgayLopBoMonStart, INhanXetHangNgayLopBoMonSuccess, INhanXetHangNgayLopChuNhiemErr, INhanXetHangNgayLopChuNhiemStart, INhanXetHangNgayLopChuNhiemSuccess, INhanXetHangNgayPublishMarkLopBoMonErr, INhanXetHangNgayPublishMarkLopBoMonStart, INhanXetHangNgayPublishMarkLopBoMonSuccess, INhanXetHangNgayPublishMarkLopChuNhiemErr, INhanXetHangNgayPublishMarkLopChuNhiemStart, INhanXetHangNgayPublishMarkLopChuNhiemSuccess, INhanXetHangNgaySaveCommentsErr, INhanXetHangNgaySaveCommentsStart, INhanXetHangNgaySaveCommentsSuccess, INhanXetHangNgaySaveHoctapErr, INhanXetHangNgaySaveHoctapStart, INhanXetHangNgaySaveHoctapSuccess, INhanXetHangNgaySelectCommentsErr, INhanXetHangNgaySelectCommentsStart, INhanXetHangNgaySelectCommentsSuccess, INhanXetHangNgaySelectDetailErr, INhanXetHangNgaySelectDetailStart, INhanXetHangNgaySelectDetailSuccess, INhanXetHangNgaySelectHoctapErr, INhanXetHangNgaySelectHoctapStart, INhanXetHangNgaySelectHoctapSuccess, INhanXetHangNgaySelectLopBoMonErr, INhanXetHangNgaySelectLopBoMonStart, INhanXetHangNgaySelectLopBoMonSuccess, INhanXetHangNgaySelectLopChuNhiemErr, INhanXetHangNgaySelectLopChuNhiemStart, INhanXetHangNgaySelectLopChuNhiemSuccess, INhanXetHangNgaySelectTDHTLopBoMonErr, INhanXetHangNgaySelectTDHTLopBoMonStart, INhanXetHangNgaySelectTDHTLopBoMonSuccess, INhanXetHangNgayShowModal, INhanXetHangNgayTDHTLopBoMonErr, INhanXetHangNgayTDHTLopBoMonStart, INhanXetHangNgayTDHTLopBoMonSuccess } from "../../action-types/nhan-xet/INhanXetHangNgayActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const nhanXetHangNgayActions = {
    selectLopBoMonStart: (request: INhanXetLopBoMonSelectRequest): INhanXetHangNgaySelectLopBoMonStart => BaseAction(request, eNhanXetHangNgayActionTypeIds.SELECT_LOPBOMON_START),
    selectLopBoMonSuccess: (respone: DanhSachNhanXetTheoLopResponse[]): INhanXetHangNgaySelectLopBoMonSuccess => BaseAction(respone, eNhanXetHangNgayActionTypeIds.SELECT_LOPBOMON_SUCCESS),
    selectLopBoMonError: (messege: string): INhanXetHangNgaySelectLopBoMonErr => BaseAction(messege, eNhanXetHangNgayActionTypeIds.SELECT_LOPBOMON_ERROR),

    selectLopChuNhiemStart: (request: INhanXetLopChuNhiemSelectRequest): INhanXetHangNgaySelectLopChuNhiemStart => BaseAction(request, eNhanXetHangNgayActionTypeIds.SELECT_LOPCHUNHIEM_START),
    selectLopChuNhiemSuccess: (respone: DanhSachNhanXetTheoLopResponse[]): INhanXetHangNgaySelectLopChuNhiemSuccess => BaseAction(respone, eNhanXetHangNgayActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS),
    selectLopChuNhiemError: (messege: string): INhanXetHangNgaySelectLopChuNhiemErr => BaseAction(messege, eNhanXetHangNgayActionTypeIds.SELECT_LOPCHUNHIEM_ERROR),

    showNhanXetHangNgayModal: (respone: boolean): INhanXetHangNgayShowModal => BaseAction(respone, eNhanXetHangNgayActionTypeIds.SHOW_NHANXET_HANGNGAY_MODAL),
    changeDanhSachNhanXetHangNgay: (respone: DanhSachNhanXetTheoLopResponse[]): INhanXetHangNgayChangeDanhSachNhanXetHangNgay => BaseAction(respone, eNhanXetHangNgayActionTypeIds.CHANGE_DANHSACH_NHANXET_HANGNGAY),

    nhanXetLopBoMonStart: (data: INhanXetLopBoMonRequest): INhanXetHangNgayLopBoMonStart => BaseAction(data, eNhanXetHangNgayActionTypeIds.NHANXET_LOPBOMON_START),
    nhanXetLopBoMonSuccess: (): INhanXetHangNgayLopBoMonSuccess => BaseAction(undefined, eNhanXetHangNgayActionTypeIds.NHANXET_LOPBOMON_SUCCESS),
    nhanXetLopBoMonError: (message: string): INhanXetHangNgayLopBoMonErr => BaseAction(message, eNhanXetHangNgayActionTypeIds.NHANXET_LOPBOMON_ERROR),

    nhanXetLopChuNhiemStart: (data: INhanXetLopChuNhiemRequest): INhanXetHangNgayLopChuNhiemStart => BaseAction(data, eNhanXetHangNgayActionTypeIds.NHANXET_LOPCHUNHIEM_START),
    nhanXetLopChuNhiemSuccess: (): INhanXetHangNgayLopChuNhiemSuccess => BaseAction(undefined, eNhanXetHangNgayActionTypeIds.NHANXET_LOPCHUNHIEM_SUCCESS),
    nhanXetLopChuNhiemError: (message: string): INhanXetHangNgayLopChuNhiemErr => BaseAction(message, eNhanXetHangNgayActionTypeIds.NHANXET_LOPCHUNHIEM_ERROR),

    selectDetailStart: (request: number): INhanXetHangNgaySelectDetailStart => BaseAction(request, eNhanXetHangNgayActionTypeIds.SELECT_DETAIL_START),
    selectDetailSuccess: (respone: DanhSachNhanXetTheoLopResponse): INhanXetHangNgaySelectDetailSuccess => BaseAction(respone, eNhanXetHangNgayActionTypeIds.SELECT_DETAIL_SUCCESS),
    selectDetailError: (messege: string): INhanXetHangNgaySelectDetailErr => BaseAction(messege, eNhanXetHangNgayActionTypeIds.SELECT_DETAIL_ERROR),

    selectCommentsStart: (request: number): INhanXetHangNgaySelectCommentsStart => BaseAction(request, eNhanXetHangNgayActionTypeIds.SELECT_COMMENTS_START),
    selectCommentsSuccess: (respone: sis_nhanxet_hangngay_comment[]): INhanXetHangNgaySelectCommentsSuccess => BaseAction(respone, eNhanXetHangNgayActionTypeIds.SELECT_COMMENTS_SUCCESS),
    selectCommentsError: (messege: string): INhanXetHangNgaySelectCommentsErr => BaseAction(messege, eNhanXetHangNgayActionTypeIds.SELECT_COMMENTS_ERROR),

    saveCommentsStart: (request: INhanXetHangNgayCommentRequest): INhanXetHangNgaySaveCommentsStart => BaseAction(request, eNhanXetHangNgayActionTypeIds.SAVE_COMMENTS_START),
    saveCommentsSuccess: (): INhanXetHangNgaySaveCommentsSuccess => BaseAction(undefined, eNhanXetHangNgayActionTypeIds.SAVE_COMMENTS_SUCCESS),
    saveCommentsError: (messege: string): INhanXetHangNgaySaveCommentsErr => BaseAction(messege, eNhanXetHangNgayActionTypeIds.SAVE_COMMENTS_ERROR),

    deleteCommentsStart: (request: INhanXetHangNgayCommentDeleteRequest): INhanXetHangNgayDeleteCommentsStart => BaseAction(request, eNhanXetHangNgayActionTypeIds.DELETE_COMMENTS_START),
    deleteCommentsSuccess: (): INhanXetHangNgayDeleteCommentsSuccess => BaseAction(undefined, eNhanXetHangNgayActionTypeIds.DELETE_COMMENTS_SUCCESS),
    deleteCommentsError: (messege: string): INhanXetHangNgayDeleteCommentsErr => BaseAction(messege, eNhanXetHangNgayActionTypeIds.DELETE_COMMENTS_ERROR),

    selectTDHTLopBoMonStart: (request: INhanXetThaiDoHocTapSelectRequest): INhanXetHangNgaySelectTDHTLopBoMonStart => BaseAction(request, eNhanXetHangNgayActionTypeIds.SELECT_TDHT_LOPBOMON_START),
    selectTDHTLopBoMonSuccess: (respone: sis_thaidohoctap_ketqua[]): INhanXetHangNgaySelectTDHTLopBoMonSuccess => BaseAction(respone, eNhanXetHangNgayActionTypeIds.SELECT_TDHT_LOPBOMON_SUCCESS),
    selectTDHTLopBoMonError: (messege: string): INhanXetHangNgaySelectTDHTLopBoMonErr => BaseAction(messege, eNhanXetHangNgayActionTypeIds.SELECT_TDHT_LOPBOMON_ERROR),

    nhanXetTDHTLopBoMonStart: (data: sis_thaidohoctap_ketqua): INhanXetHangNgayTDHTLopBoMonStart => BaseAction(data, eNhanXetHangNgayActionTypeIds.NHANXET_TDHT_LOPBOMON_START),
    nhanXetTDHTLopBoMonSuccess: (): INhanXetHangNgayTDHTLopBoMonSuccess => BaseAction(undefined, eNhanXetHangNgayActionTypeIds.NHANXET_TDHT_LOPBOMON_SUCCESS),
    nhanXetTDHTLopBoMonError: (message: string): INhanXetHangNgayTDHTLopBoMonErr => BaseAction(message, eNhanXetHangNgayActionTypeIds.NHANXET_TDHT_LOPBOMON_ERROR),

    showHocTapHangNgayModal: (respone: boolean): IHocTapHangNgayShowModal => BaseAction(respone, eNhanXetHangNgayActionTypeIds.SHOW_HOCTAP_HANGNGAY_MODAL),
    selectHoctapStart: (request: INhanXetHangNgayLopSelectRequest): INhanXetHangNgaySelectHoctapStart => BaseAction(request, eNhanXetHangNgayActionTypeIds.SELECT_HOCTAP_START),
    selectHoctapSuccess: (respone: sis_nhanxet_hangngay_lop): INhanXetHangNgaySelectHoctapSuccess => BaseAction(respone, eNhanXetHangNgayActionTypeIds.SELECT_HOCTAP_SUCCESS),
    selectHoctapError: (messege: string): INhanXetHangNgaySelectHoctapErr => BaseAction(messege, eNhanXetHangNgayActionTypeIds.SELECT_HOCTAP_ERROR),

    saveHoctapStart: (request: sis_nhanxet_hangngay_lop): INhanXetHangNgaySaveHoctapStart => BaseAction(request, eNhanXetHangNgayActionTypeIds.SAVE_HOCTAP_START),
    saveHoctapSuccess: (): INhanXetHangNgaySaveHoctapSuccess => BaseAction(undefined, eNhanXetHangNgayActionTypeIds.SAVE_HOCTAP_SUCCESS),
    saveHoctapError: (messege: string): INhanXetHangNgaySaveHoctapErr => BaseAction(messege, eNhanXetHangNgayActionTypeIds.SAVE_HOCTAP_ERROR),

    publishMarkLopBoMonStart: (request: INhanXetLopBoMonPublishMarkRequest): INhanXetHangNgayPublishMarkLopBoMonStart => BaseAction(request, eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPBOMON_START),
    publishMarkLopBoMonSuccess: (): INhanXetHangNgayPublishMarkLopBoMonSuccess => {
        NotifyHelper.Success("Đã gửi thành công")
        return BaseAction(undefined, eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPBOMON_SUCCESS)
    },
    publishMarkLopBoMonError: (messege: string): INhanXetHangNgayPublishMarkLopBoMonErr => BaseAction(messege, eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPBOMON_ERROR),

    publishMarkLopChuNhiemSuccess: (): INhanXetHangNgayPublishMarkLopChuNhiemSuccess => {
        NotifyHelper.Success("Đã gửi thành công")
        return BaseAction(undefined, eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_SUCCESS);
    },
    publishMarkLopChuNhiemStart: (request: INhanXetLopChuNhiemPublishMarkRequest): INhanXetHangNgayPublishMarkLopChuNhiemStart => BaseAction(request, eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_START),
    publishMarkLopChuNhiemError: (messege: string): INhanXetHangNgayPublishMarkLopChuNhiemErr => BaseAction(messege, eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_ERROR),
}
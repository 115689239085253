import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_dgtx_dm_noidung, sis_dgtx_dm_noidung_treeview } from "../../../models/response/dgtx-dm/sis_dgtx_dm_noidung";
import { eDgtxDmHocTapActionTypeIds, IDgtxDmHocTapChangeSelectedIds, IDgtxDmHocTapCloseConfirm, IDgtxDmHocTapCloseDetail, IDgtxDmHocTapDeleteErr, IDgtxDmHocTapDeleteStart, IDgtxDmHocTapDeleteSuccess, IDgtxDmHocTapLoadErr, IDgtxDmHocTapLoadStart, IDgtxDmHocTapLoadSuccess, IDgtxDmHocTapShowConfirm, IDgtxDmHocTapShowDetail } from "../../action-types/so-diem-tieu-hoc/IDgtxDmHocTapActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const dgtxDmHocTapActions = {
    loadDgtxDmHocTapStart: () => BaseAction(undefined, eDgtxDmHocTapActionTypeIds.LOAD_START),
    loadDgtxDmHocTapSuccess: (respone: sis_dgtx_dm_noidung_treeview[]): IDgtxDmHocTapLoadSuccess => BaseAction(respone, eDgtxDmHocTapActionTypeIds.LOAD_SUCCESS),
    loadDgtxDmHocTapError: (messege: string): IDgtxDmHocTapLoadErr => BaseAction(messege, eDgtxDmHocTapActionTypeIds.LOAD_ERROR),
    showDgtxDmHocTapDetail: (data?: sis_dgtx_dm_noidung_treeview): IDgtxDmHocTapShowDetail => BaseAction(data, eDgtxDmHocTapActionTypeIds.SHOW_DETAIL),
    closeDgtxDmHocTapDetail: (): IDgtxDmHocTapCloseDetail => BaseAction(undefined, eDgtxDmHocTapActionTypeIds.CLOSE_DETAIL),
    showDgtxDmHocTapConfirm: (): IDgtxDmHocTapShowConfirm => BaseAction(undefined, eDgtxDmHocTapActionTypeIds.SHOW_CONFIRM),
    closeDgtxDmHocTapConfirm: (): IDgtxDmHocTapCloseConfirm => BaseAction(undefined, eDgtxDmHocTapActionTypeIds.CLOSE_CONFIRM),
    changeDgtxDmHocTapSelectedIds: (ids: number[]): IDgtxDmHocTapChangeSelectedIds => BaseAction(ids, eDgtxDmHocTapActionTypeIds.CHANGE_SELECTED_IDS),
    deleteDgtxDmHocTapStart: (ids: IDeleteMultipleBaseRequest): IDgtxDmHocTapDeleteStart => BaseAction(ids, eDgtxDmHocTapActionTypeIds.DELETE_START),
    deleteDgtxDmHocTapSuccess: (): IDgtxDmHocTapDeleteSuccess => BaseAction(undefined, eDgtxDmHocTapActionTypeIds.DELETE_SUCCESS),
    deleteDgtxDmHocTapErr: (message: string): IDgtxDmHocTapDeleteErr => BaseAction(message, eDgtxDmHocTapActionTypeIds.DELETE_ERROR),
    //SAVE
    saveDgtxDmHocTapStart: (data: sis_dgtx_dm_noidung) => BaseAction(data, eDgtxDmHocTapActionTypeIds.SAVE_START),
    saveDgtxDmHocTapSuccess: () => BaseAction(undefined, eDgtxDmHocTapActionTypeIds.SAVE_SUCCESS),
    saveDgtxDmHocTapError: (message: string) =>  BaseAction(message, eDgtxDmHocTapActionTypeIds.SAVE_ERR)
}



import { thanhPhanDiemActions } from './../../actions/so-diem/thanhPhanDiemActions';
import { actions } from './../../actions/actionsWrapper';
import { put } from 'redux-saga/effects';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { thanhPhanDiemApi } from './../../../api/thanhPhanDiemApi';
import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { eThanhPhanDiemActionTypeIds } from '../../action-types/so-diem/IThanhPhanDiemAction';
export function* thanhPhanDiemSaga(): any {
    yield takeLatest(eThanhPhanDiemActionTypeIds.LOAD_START, loadWorker);
}
function* loadWorker(): any {
    const res: IBaseResponeModel = yield call([thanhPhanDiemApi, thanhPhanDiemApi.SelectAll]);
    if (res.is_success) {
        yield put(actions.soDiem.thanhPhanDiem.loadSuccess(res.data))
    } else {
        yield put(actions.soDiem.thanhPhanDiem.loadError(res.message || "Không tải được thành phần điểm"))
    }
}
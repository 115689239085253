import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { apiWrapper } from "../../../api/apiWrapper"
import { IBaseResponeModel } from "../../../models/response/base-response"
import { eLopChuNhiemActionTypeIds, ILopChuNhiemSelectStart } from "../../action-types/lop-chu-nhiem/ILopChuNhiemActionTypes"
import { actions } from "../../actions/actionsWrapper"

export function* lopChuNhiemSaga(): any {
    yield takeLatest(eLopChuNhiemActionTypeIds.SELECT_START, selectWorker)
}

function* selectWorker(data: ILopChuNhiemSelectStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopChuNhiem, apiWrapper.lopChuNhiem.Select], data.payload)
    if (res.is_success) {
        yield put(actions.lopChuNhiem.selectSuccess(res.data))
    } else {
        yield put(actions.lopChuNhiem.selectError(res.message || "Không tải được lớp"))
    }
}
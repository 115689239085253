import { actions } from './../../actions/actionsWrapper';
import { put } from 'redux-saga/effects';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { giaiDoanApi } from './../../../api/giaiDoanApi';
import { eGiaiDoanActionTypeIds } from './../../action-types/so-diem/IGiaiDoanActions';
import { call, takeLatest } from 'redux-saga/effects';
export function* giaiDoanSaga(): any {
    yield takeLatest(eGiaiDoanActionTypeIds.LOAD_START, loadWorker)
}
function* loadWorker(): any {
    const res: IBaseResponeModel = yield call([giaiDoanApi, giaiDoanApi.SelectAll]);
    if (res.is_success) {
        yield put(actions.soDiem.giaiDoan.loadSuccess(res.data))
    } else {
        yield put(actions.soDiem.giaiDoan.loadError(res.message || "Không tải được giải đoạn"))
    }
}
import { ISoDiemLopBoMonAddItemRequest } from '../models/request/so-diem/ISoDiemLopBoMonAddItemRequest';
import { sis_sodiem } from '../models/response/so-diem/sis_sodiem';
import { apiClient } from './apiClient';
import { BaseCRUDApi } from './baseCRUDApi';


export const API_SO_DIEM_PATH = "so-diem"

class SoDiemApi extends BaseCRUDApi<sis_sodiem>{
    constructor(path: string) {
        super(path);
    }
    SelectByTruong(dm_truong_id: number) {
        return apiClient.get(`${API_SO_DIEM_PATH}/truong/${dm_truong_id}`)
    }
    SelectConfig(lopBoMonId: number){
        return apiClient.get(`${API_SO_DIEM_PATH}/lop-bo-mon/${lopBoMonId}`)

    }
    AddSoDiemLopBoMonItem(data: ISoDiemLopBoMonAddItemRequest){
        return apiClient.post(`${API_SO_DIEM_PATH}/lop-bo-mon/items`,data)

    }
}
export const soDiemApi = new SoDiemApi(API_SO_DIEM_PATH)

import { IDgdkLockDetailSelectByLopBoMonRequest, IDgdkLockDetailSelectRequest, IDgdkLockSelectRequest } from '../models/request/danh-gia/dinh-ky/IDgdkLockSelectRequest';
import { IDgdkLockUpdateRequest } from '../models/request/danh-gia/dinh-ky/IDgdkLockUpdateRequest';

import { apiClient } from './apiClient';

export const dgdkLockApi = {
    SelectAsync: (rq: IDgdkLockSelectRequest) => apiClient.post('dgdk-lock/select', rq),
    SelectDetailAsync: (rq: IDgdkLockDetailSelectRequest) => apiClient.post('dgdk-lock/select-detail', rq),
    SelectByLopBoMonAsync: (rq: IDgdkLockDetailSelectByLopBoMonRequest) => apiClient.post('dgdk-lock/select-by-lopbomon', rq),
    UpdateLockAsync: (request: IDgdkLockUpdateRequest) => apiClient.post(`dgdk-lock/update-lock`, request),
    UpdateUnLockAsync: (request: IDgdkLockUpdateRequest) => apiClient.post(`dgdk-lock/update-unlock`, request),
};

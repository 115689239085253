import { ILopSelectRequest } from "../models/request/dm-lop/ILopSelectRequest";
import { ILopMonHoc } from "../models/response/lop-mon-hoc/ILopMonHoc";
import { ILopMonHocCopyStart } from "../state/action-types/lop-mon-hoc/ILopMonHocActionTypes";
import { ILopMonHocCopyState } from "../state/page-state-model/lop-mon-hoc/ILopMonHocPageState";
import { apiClient } from "./apiClient";

export const lopMonHocApi = {
    SelectByLop: (lopId: number) => apiClient.get(`lop-mon-hoc/lop/${lopId}`),
    Select: (request: ILopSelectRequest) =>
        apiClient.get(`lop-mon-hoc/nam-hoc/${request.nam_hoc}/truong/${request.dm_truong_id}/khoi/${request.dm_khoi_id}/he/${request.dm_he_id}`),
    SaveChange: (lopMonHoc: ILopMonHoc) => apiClient.put(`lop-mon-hoc`, lopMonHoc),
    Copy:(request: ILopMonHocCopyState) => apiClient.post(`lop-mon-hoc/copy`, request)
}
import { NotifyHelper } from './../../../helpers/toast';
import { ILopSelectRequest } from "../../../models/request/dm-lop/ILopSelectRequest";
import { ILopItemResponse } from "../../../models/response/lop/ILopItemResponse";
import { eLopMonHocActionTypIds } from "../../action-types/lop-mon-hoc/ILopMonHocActionTypes";
import { baseAction } from "../IActionBase";
import { ILopMonHoc } from '../../../models/response/lop-mon-hoc/ILopMonHoc';
import { ILopMonHocCopyState } from '../../page-state-model/lop-mon-hoc/ILopMonHocPageState';
import { ILopMonHocGenerateRequest } from '../../../models/request/dm-lop/ILopGenerateRequest';

export const lopMonHocActions = {
    loadLopStart: (request: ILopSelectRequest) => baseAction(eLopMonHocActionTypIds.LOAD_LOP_START, request),
    loadLopSuccess: (respone: ILopItemResponse[]) => baseAction(eLopMonHocActionTypIds.LOAD_LOP_SUCCESS, respone),
    loadLopError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopMonHocActionTypIds.LOAD_LOP_ERROR, message)
    },
    changeKhoiSelectedId: (khoiId: number) => baseAction(eLopMonHocActionTypIds.CHANGE_KHOI_SELECTED_ID, khoiId),

    loadStart: (request: ILopSelectRequest) => baseAction(eLopMonHocActionTypIds.LOAD_LOP_MONHOC_START, request),
    loadSuccess: (respone: ILopItemResponse[]) => baseAction(eLopMonHocActionTypIds.LOAD_LOP_MONHOC_SUCCESS, respone),
    loadError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopMonHocActionTypIds.LOAD_LOP_MONHOC_ERROR, message)
    },

    saveStart: (request: ILopMonHoc) => baseAction(eLopMonHocActionTypIds.SAVE_START, request),
    saveSuccess: (respone: ILopMonHoc) => {
        NotifyHelper.Success("Cập nhật thành công")
        return baseAction(eLopMonHocActionTypIds.SAVE_SUCCESS, respone)
    },
    saveError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopMonHocActionTypIds.SAVE_ERROR, message);
    },

    changeCopy: (copy: ILopMonHocCopyState) => baseAction(eLopMonHocActionTypIds.CHANGE_COPY, copy),

    copyStart: (request: ILopMonHocCopyState) => baseAction(eLopMonHocActionTypIds.COPY_START, request),
    copySuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eLopMonHocActionTypIds.COPY_SUCCESS, undefined)
    },
    copyError: (mesage: string) => {
        NotifyHelper.Error(mesage)
        return baseAction(eLopMonHocActionTypIds.COPY_ERROR, mesage)
    },
    createLopBoMonStart: (request: ILopMonHocGenerateRequest) => baseAction(eLopMonHocActionTypIds.CREATE_LOPBOMON_START, request),
    createLopBoMonSuccess: () => baseAction(eLopMonHocActionTypIds.CREATE_LOPBOMON_SUCCESS, undefined),
    createLopBoMonError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopMonHocActionTypIds.CREATE_LOPBOMON_ERROR, message)
    },
}
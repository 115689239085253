import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { pl_tiet } from "../../../models/response/danh-muc/pl_tiet";
import { eTietActionTypeIds, ITietChangeSelectedIds, ITietCloseConfirm, ITietCloseDetail, ITietDeleteErr, ITietDeleteStart, ITietDeleteSuccess, ITietLoadErr, ITietLoadStart, ITietLoadSuccess, ITietSaveErr, ITietSaveStart, ITietSaveSuccess, ITietShowConfirm, ITietShowDetail } from "../../action-types/danh-muc/ITietActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const tietActions = {
    loadStart: (): ITietLoadStart => BaseAction(undefined, eTietActionTypeIds.LOAD_START),
    loadSuccess: (respone: pl_tiet[]): ITietLoadSuccess => BaseAction(respone, eTietActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): ITietLoadErr => BaseAction(messege, eTietActionTypeIds.LOAD_ERROR),
    
    showDetail: (data?: pl_tiet): ITietShowDetail => BaseAction(data, eTietActionTypeIds.SHOW_DETAIL),
    closeDetail: (): ITietCloseDetail => BaseAction(undefined, eTietActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): ITietShowConfirm => BaseAction(undefined, eTietActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): ITietCloseConfirm => BaseAction(undefined, eTietActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): ITietChangeSelectedIds => BaseAction(ids, eTietActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): ITietDeleteStart => BaseAction(ids, eTietActionTypeIds.DELETE_START),
    deleteSuccess: (): ITietDeleteSuccess => BaseAction(undefined, eTietActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): ITietDeleteErr => BaseAction(message, eTietActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: pl_tiet): ITietSaveStart => BaseAction(data, eTietActionTypeIds.SAVE_START),
    saveSuccess: ():ITietSaveSuccess => BaseAction(undefined, eTietActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): ITietSaveErr =>  BaseAction(message, eTietActionTypeIds.SAVE_ERR)
}
import { sis_pl_dotdangky_nhommonhoc } from "../models/response/dangky-monhoc/sis_pl_dotdangky_nhommonhoc";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_DOTDANGKY_NHOMMONHOC_PATH = "dang-ky-mon-hoc/nhom-mon-hoc";

export const dotDangKyNhomMonHocApi = new class DotDangKyNhomMonHocApi extends BaseCRUDApi<sis_pl_dotdangky_nhommonhoc> {
    constructor(path: string) {
        super(path);
    }
    SelectByDotDangKyAsync(dotdangky_id: number) {
        return apiClient.get(`${this._path}/dot-dang-ky/${dotdangky_id}/select-nhom-mon-hoc`);
    }
}(API_DOTDANGKY_NHOMMONHOC_PATH);


import moment from "moment";
import { IDiemDanhConfigSelectRequest } from "../models/request/diem-danh/IDiemDanhConfigSelectRequest";
import { IDiemDanhLopBoMonChangeRequest } from "../models/request/diem-danh/IDiemDanhLopBoMonChangeRequest";
import { IDiemDanhLopBoMonPublishMarkRequest } from "../models/request/diem-danh/IDiemDanhLopBoMonPublishMarkRequest";
import { IDiemDanhLopBoMonSelectRequest } from "../models/request/diem-danh/IDiemDanhLopBoMonSelectRequest";
import { IDiemDanhLopChuNhiemChangeRequest } from "../models/request/diem-danh/IDiemDanhLopChuNhiemChangeRequest";
import { IDiemDanhLopChuNhiemPublishMarkRequest } from "../models/request/diem-danh/IDiemDanhLopChuNhiemPublishMarkRequest";
import { IDiemDanhLopChuNhiemReportRequest } from "../models/request/diem-danh/IDiemDanhLopChuNhiemReportRequest";
import { IDiemDanhLopChuNhiemSelectRequest } from "../models/request/diem-danh/IDiemDanhLopChuNhiemSelectRequest";
import { apiClient } from "./apiClient";
import { IDiemDanhSelectByTruongRequest } from "../models/request/diem-danh/IDiemDanhSelectByTruongRequest";
import { IDiemDanhSelectTongHopByTruongLopRequest } from "../models/request/diem-danh/IDiemDanhSelectTongHopByTruongLopRequest";

export const API_DIEMDANH_PATH = "diem-danh"
export const diemDanhApi = {
    SelectLopDiemDanhAsync: (request: IDiemDanhConfigSelectRequest) => {
        return apiClient.post(`${API_DIEMDANH_PATH}/select-lop-diem-danh`, request)
    },
    SelectByLopBoMonAsync: (request: IDiemDanhLopBoMonSelectRequest) => {
        return apiClient.post(`${API_DIEMDANH_PATH}/select-by-lopbomon`, request)
    },
    DiemDanhLopBoMonAsync: (request: IDiemDanhLopBoMonChangeRequest) => {
        return apiClient.post(`${API_DIEMDANH_PATH}/diem-danh-lopbomon`, request)
    },
    SelectByLopChuNhiemAsync: (request: IDiemDanhLopChuNhiemSelectRequest) => {
        return apiClient.post(`${API_DIEMDANH_PATH}/select-by-lopchunhiem`, request)
    },
    DiemDanhLopChuNhiemAsync: (request: IDiemDanhLopChuNhiemChangeRequest) => {
        return apiClient.post(`${API_DIEMDANH_PATH}/diem-danh-lopchunhiem`, request)
    },
    DiemDanhLopBoMonPublishMarkAsync: (request: IDiemDanhLopBoMonPublishMarkRequest) => {
        return apiClient.post(`${API_DIEMDANH_PATH}/diem-danh-lopbomon-publish-mark`, request)
    },
    DiemDanhLopChuNhiemPublishMarkAsync: (request: IDiemDanhLopChuNhiemPublishMarkRequest) => {
        return apiClient.post(`${API_DIEMDANH_PATH}/diem-danh-lopchunhiem-publish-mark`, request)
    },
    LopChuNhiemReportAsync: (request: IDiemDanhLopChuNhiemReportRequest) => {
        return apiClient.get(`${API_DIEMDANH_PATH}/lop-chu-nhiem/report/${moment(request.date).format("YYYY-MM-DD")}/co-so/${request.dm_truong_id}`)
    },
    SelectByTruongAsync: (request: IDiemDanhSelectByTruongRequest) => {
        return apiClient.get(`${API_DIEMDANH_PATH}/nam-hoc/${request.nam_hoc}/from-date/${moment(request.fromDate).format("YYYY-MM-DD")}/to-date/${moment(request.toDate).format("YYYY-MM-DD")}/truong/${request.dm_truong_id}`)
    },
    SelectTongHopByLopAsync: (request: IDiemDanhSelectTongHopByTruongLopRequest) => {
        return apiClient.get(`${API_DIEMDANH_PATH}/nam-hoc/${request.nam_hoc}/month/${moment(request.month).format("YYYY-MM")}/truong/${request.dm_truong_id}/khoi/${request.dm_khoi_id}/lop/${request.dm_lop_id}`)
    }
};
import { takeEvery } from 'redux-saga/effects';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from '../../../models/response/base-response';
import { actions } from '../../actions/actionsWrapper';
import { eBaiVietDetailActionTypeIds, IBaiVietDetailLoadStart, IBaiVietDetailSaveStart } from './../../action-types/bai-viet/IBaiVietDetailActionTypes';

const appBaiVietApi = apiWrapper.appBaiViet;

export function* baiVietDetailSaga(): any {
    yield takeLatest(eBaiVietDetailActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eBaiVietDetailActionTypeIds.SAVE_START, saveWorker)
}

function* loadWorker(action: IBaiVietDetailLoadStart): any {
    const res: IBaseResponeModel = yield call([appBaiVietApi, appBaiVietApi.SelectById], action.payload)
    if (res.is_success && res.data) {
        yield put(actions.baiVietDetail.loadSuccess(res.data))
    } else {
        yield put(actions.baiVietDetail.loadErr(res.message || "Không tải được bài viết"))
    }
}

function* saveWorker(action: IBaiVietDetailSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([appBaiVietApi, appBaiVietApi.Update], action.payload)
    } else {
        res = yield call([appBaiVietApi, appBaiVietApi.Insert], action.payload)
    }
    if (res.is_success) {
        yield put(actions.baiVietDetail.saveSuccess(res.data))
    } else {
        yield put(actions.baiVietDetail.saveError(res.message || "Không upload được ảnh cover"))
    }
}

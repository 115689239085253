import { AppAccountResetPasswordRequest } from "../../models/request/app-phuhuynh/AppAccountResetPasswordRequest";
import { AppAccountSelectRequest } from "../../models/request/app-phuhuynh/AppAccountSelectRequest";
import { IAppAccountUpdateStatusRequest } from "../../models/request/app-phuhuynh/AppAccountUpdateStatusRequest";
import { apiClient } from "../apiClient";

export const API_APP_ACCOUNT_PATH = "app-account"
export const appAccountApi = {
    getAllAsync: () =>  {
        return apiClient.get(`${API_APP_ACCOUNT_PATH}`)
    },
    getAllPhuHuynhAccountAsync: (request: AppAccountSelectRequest) =>  {
        return apiClient.get(`${API_APP_ACCOUNT_PATH}/select-phuhuynh-account/truong/${request.dm_truong_id}/nam-hoc/${request.nam_hoc}`)
    },
    updateStatus: (request: IAppAccountUpdateStatusRequest) =>  {
        return apiClient.put(`${API_APP_ACCOUNT_PATH}/update-status`, request)
    },
    resetPassword: (request: AppAccountResetPasswordRequest) =>  {
        return apiClient.put(`${API_APP_ACCOUNT_PATH}/reset-password`, request)
    }
};
import { IThaiDoHocTapTieuChiSelectRequest } from "../models/request/nhan-xet/IThaiDoHocTapTieuChiSelectRequest";
import { sis_thaidohoctap_tieuchi } from "../models/response/nhan-xet/sis_thaidohoctap_tieuchi";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_THAIDOHOCTAP_TIEUCHI_PATH = "thai-do-hoc-tap/tieu-chi"
class ThaiDoHocTapTieuChiApi extends BaseCRUDApi<sis_thaidohoctap_tieuchi> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    Select(request: IThaiDoHocTapTieuChiSelectRequest){
        return apiClient.post(`${this._path}/select`, request)
    }
}
export const thaiDoHocTapTieuChiApi = new ThaiDoHocTapTieuChiApi({ path: API_THAIDOHOCTAP_TIEUCHI_PATH });
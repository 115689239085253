import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eTietActionTypeIds, ITietDeleteStart, ITietSaveStart } from "../../action-types/danh-muc/ITietActionTypes";
import { actions } from "../../actions/actionsWrapper";

const tietApi = apiWrapper.tiet;

export function* tietSaga(): any {
    yield takeLatest(eTietActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eTietActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eTietActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([tietApi, tietApi.SelectAll])
    if (res.is_success) {
        yield put(actions.danhMucWrapper.tiet.loadSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.tiet.loadError(res.message || "Không tải được tiết học"))
    }
}
function* deleteWorker(action: ITietDeleteStart): any {
    const res: IBaseResponeModel = yield call([tietApi, tietApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.danhMucWrapper.tiet.deleteSuccess())
        yield put(actions.danhMucWrapper.tiet.loadStart())
    } else {
        yield put(actions.danhMucWrapper.tiet.deleteError(res.message || "Không xóa được tiết học"))
    }
}
function* saveWorker(action: ITietSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([tietApi, tietApi.Update], action.payload);
    } else {
        res = yield call([tietApi, tietApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.danhMucWrapper.tiet.saveSuccess())
        yield put(actions.danhMucWrapper.tiet.loadStart())
    } else {
        yield put(actions.danhMucWrapper.tiet.saveError(res.message || "Không lưu được tiết học"))
    }
}
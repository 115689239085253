import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eLoaiPhongActionTypeIds, ILoaiPhongDeleteStart, ILoaiPhongSaveStart } from "../../action-types/phong-hoc/ILoaiPhongActionTypes";
import { actions } from "../../actions/actionsWrapper";

const loaiPhongApi = apiWrapper.loaiPhong;

export function* loaiPhongSaga(): any {
    yield takeLatest(eLoaiPhongActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eLoaiPhongActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eLoaiPhongActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([loaiPhongApi, loaiPhongApi.SelectAll])
    if (res.is_success) {
        yield put(actions.loaiPhong.loadSuccess(res.data))
    } else {
        yield put(actions.loaiPhong.loadError(res.message || "Không tải được loại phòng"))
    }
}
function* deleteWorker(action: ILoaiPhongDeleteStart): any {
    const res: IBaseResponeModel = yield call([loaiPhongApi, loaiPhongApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.loaiPhong.deleteSuccess())
        yield put(actions.loaiPhong.loadStart())
    } else {
        yield put(actions.loaiPhong.deleteErr(res.message || "Không tải được loại phòng"))
    }
}
function* saveWorker(action: ILoaiPhongSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([loaiPhongApi, loaiPhongApi.Update], action.payload);
    } else {
        res = yield call([loaiPhongApi, loaiPhongApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.loaiPhong.saveSuccess())
        yield put(actions.loaiPhong.loadStart())
    } else {
        yield put(actions.loaiPhong.saveTError(res.message || "Không lưu được loại phòng"))
    }
}
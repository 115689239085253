import { NotifyHelper, showNotify } from '../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eKhaoSatActionTypeIds, IKhaoSatActionTypes } from "../../action-types/form-khaosat/IKhaoSatActionTypes";
import { IKhaoSatPageStateModel } from "../../page-state-model/form-khaosat/IKhaoSatState";

const initialState: IKhaoSatPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    sis_khaosats: [],
    sis_khaosat_selected_ids: [],
    sis_khaosat_editing: undefined,
    is_show_confirm: false,
    is_show_modal: false,
    sis_khaosat_results: [],
    sis_form_khaosat_result_details: [],
    ket_qua_khao_sats: []
}
export const khaoSatReducer = (state: IKhaoSatPageStateModel = initialState, action: IKhaoSatActionTypes): IKhaoSatPageStateModel => {
    switch (action.type) {
        case eKhaoSatActionTypeIds.LOAD_START:
        case eKhaoSatActionTypeIds.LOAD_BYTRUONG_START:
        case eKhaoSatActionTypeIds.LOAD_RESULT_BYTRUONG_START:
        case eKhaoSatActionTypeIds.LOAD_RESULT_DETAIL_START:
        case eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_START:
        case eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_CHITIET_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eKhaoSatActionTypeIds.LOAD_SUCCESS:
        case eKhaoSatActionTypeIds.LOAD_BYTRUONG_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_khaosats: action.payload
            }
        case eKhaoSatActionTypeIds.LOAD_RESULT_BYTRUONG_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_khaosat_results: action.payload
            }
        case eKhaoSatActionTypeIds.LOAD_RESULT_DETAIL_SUCCESS:
        case eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_CHITIET_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_form_khaosat_result_details: action.payload
            }
        case eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                ket_qua_khao_sats: action.payload
            }
        case eKhaoSatActionTypeIds.LOAD_ERROR:
        case eKhaoSatActionTypeIds.LOAD_BYTRUONG_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_khaosats: []
            }
        case eKhaoSatActionTypeIds.LOAD_RESULT_BYTRUONG_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_khaosat_results: []
            }
        case eKhaoSatActionTypeIds.LOAD_RESULT_DETAIL_ERROR:
        case eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_CHITIET_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_form_khaosat_result_details: []
            }
        case eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                ket_qua_khao_sats: []
            }
        case eKhaoSatActionTypeIds.CHANGE_DETAIL:
            return {
                ...state,
                sis_khaosat_editing: action.payload
            }
        case eKhaoSatActionTypeIds.SHOW_MODAL:
            return {
                ...state,
                is_show_modal: action.payload,
                sis_khaosat_selected_ids: []
            }
        case eKhaoSatActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_khaosat_selected_ids: action.payload
            }
        case eKhaoSatActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_confirm: true
            }
        case eKhaoSatActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eKhaoSatActionTypeIds.DELETE_SUCCESS:
            showNotify({ type: "success", message: 'Xóa dữ liệu thành công.' })
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_confirm: false
            }
        case eKhaoSatActionTypeIds.DELETE_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_confirm: false
            }


        case eKhaoSatActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eKhaoSatActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_modal: false
            }
        case eKhaoSatActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_modal: false
            }
        case eKhaoSatActionTypeIds.PUSHNOTIFY_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eKhaoSatActionTypeIds.PUSHNOTIFY_SUCCESS:
            NotifyHelper.Success("Gửi thông báo thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eKhaoSatActionTypeIds.PUSHNOTIFY_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eKhaoSatActionTypeIds.CLEAR_STATUS:
            return {
                ...initialState
            }
        default:
            return state;
    }

}

import { IDictionaryModel } from "../../models/response/dictionary";

export enum eDictionaryActionTypeIds {
    LOAD_DICTIONARY_START = "LOAD_DICTIONARY_START",
    LOAD_DICTIONARY_SUCCESS = "LOAD_DICTIONARY_SUCCESS"
}
export interface ILoadDictionaryStart {
    type: eDictionaryActionTypeIds.LOAD_DICTIONARY_START
}
export interface ILoadDictionarySuccess {
    type: eDictionaryActionTypeIds.LOAD_DICTIONARY_SUCCESS,
    payload: IDictionaryModel[]
}
export type IDictionaryActionTypes = ILoadDictionarySuccess | ILoadDictionaryStart
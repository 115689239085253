import { eAuthActionTypeIds, IClerUserAuthAction, IGetUserAuthAction, ISetUserAuthAction } from "../action-types/IAuthActionTypes";
import { IUserInfoModel } from "../../models/response/account/user-info"

export const authActions = {
    getUser: (): IGetUserAuthAction => {
        return {
            type: eAuthActionTypeIds.GET_USER_AUTH
        }
    },
    setUser: (data: IUserInfoModel): ISetUserAuthAction => {
        return {
            type: eAuthActionTypeIds.SET_USER_AUTH,
            payload: data
        }
    },
    clearUser: (): IClerUserAuthAction => {
        return {
            type: eAuthActionTypeIds.CLEAR_USER_AUTH
        }
    }
}

import clsx from 'clsx';
import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Spinner } from "../spinners";
import TextTranslated from '../text';
import styles from './button.module.css';
type ButtonProps = {
    text?: string,
    icon?: string,
    isLoading?: boolean | false,
    isDisabled?: boolean | false,
    type?: "normal" | "primary" | "danger" | "success",
    style?: "text" | "filled",
    onClick?: () => void,
    isSubmitButton?: boolean | false,
    id?: string,
    tootip?: string
}
const Button = (props: ButtonProps) => {
    const [isShowSpinner] = useDebounce<boolean>(props.isLoading || false, 100);
    const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);
    const onMouseEnter = () => {
        if (props.tootip) {
            setIsShowTooltip(true)
        }
    }
    const onMouseLeave = () => {
        if (props.tootip) {
            setIsShowTooltip(false)
        }
    }
    return (
        <React.Fragment>
            <button
                type={props.isSubmitButton ? "submit" : "button"}
                onClick={props.onClick}
                disabled={(props.isDisabled || props.isLoading) ? true : false}
                id={props.id}
                className={clsx(
                    styles.btn,
                    props.type == "primary" && styles.btn_primary,
                    props.type == "danger" && styles.btn_danger,
                    props.type == "success" && styles.btn_success,
                    (props.style == "text" || props.style == undefined) && styles.btn_text,
                    (props.isDisabled || props.isLoading) && styles.btn_disabled
                )}
            // onMouseEnter={onMouseEnter}
            // onMouseLeave={onMouseLeave}

            >
                {props.icon && !isShowSpinner && <i className={clsx(styles.icon, props.icon, "icon-active")}></i>}
                {isShowSpinner && <>{' '} <Spinner isBlack={!props.type || props.type === "normal"} /></>}
                <TextTranslated value={props.text ?? ""} />
            </button >
            {/* {props.tootip && props.id &&
                <Tooltip
                    target={`#${props.id}`}
                    visible={true}
                    shading={true}
                    contentRender={(e) => {
                        return (<div style={{
                            color: "black",
                            padding: "1rem",
                            whiteSpace: "pre-wrap",
                            maxWidth: 300,
                            backgroundColor: "#ececec"
                        }}>{props.tootip}</div>)
                    }}
                >
                </Tooltip>
            } */}
        </React.Fragment>
    );

}

export { Button };

import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from '../../../models/response/base-response';
import { actions } from '../../actions/actionsWrapper';
import { baoCaoTienBoApi } from './../../../api/baoCaoTienBoApi';
import { hocSinhApi } from './../../../api/hocSinhApi';
import { IBaoCaoTienDoDataLoadStart, IBaoCaoTienDoHocSinhLoadStart, IBaoCaoTienDoCreateStart, IBaoCaoTienDoImportStart, IBaoCaoTienDoSaveStart, IBaoCaoTienDoValidateImportStart, eBaoCaoTienBoActionTypeIds } from './../../action-types/bao-cao-tien-bo/IBaoCaoTienBoActionType';


export function* baoCaoTienDoSaga(): any {
    yield takeLatest(eBaoCaoTienBoActionTypeIds.HOCSINH_LOAD_START, loadHocSinhWorker)
    yield takeLatest(eBaoCaoTienBoActionTypeIds.DATA_LOAD_START, loadDataWorker)
    yield takeLatest(eBaoCaoTienBoActionTypeIds.CREATE_START, createWorker)
    yield takeLatest(eBaoCaoTienBoActionTypeIds.SEND_NOTIFY_START, sendNotifyWorker)
    yield takeEvery(eBaoCaoTienBoActionTypeIds.SAVE_START, saveWorker)
    yield takeEvery(eBaoCaoTienBoActionTypeIds.VALIDATE_IMPORT_START, validateImportWorker)
    yield takeEvery(eBaoCaoTienBoActionTypeIds.IMPORT_START, importWorker)
}

function* loadHocSinhWorker(action: IBaoCaoTienDoHocSinhLoadStart): any {
    const res: IBaseResponeModel = yield call([hocSinhApi, hocSinhApi.Select], action.payload)
    if (res.is_success) {
        yield put(actions.baoCaoTienBo.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.baoCaoTienBo.loadHocSinhError(res.message ?? ""))
    }
}

function* loadDataWorker(action: IBaoCaoTienDoDataLoadStart): any {
    const res: IBaseResponeModel = yield call(baoCaoTienBoApi.Select, action.payload)
    if (res.is_success) {
        yield put(actions.baoCaoTienBo.loadDataSuccess(res.data))
    } else {
        yield put(actions.baoCaoTienBo.loadDataError(res.message ?? ""))
    }
}

function* createWorker(action: IBaoCaoTienDoCreateStart): any {
    const res: IBaseResponeModel = yield call(baoCaoTienBoApi.Create, action.payload)
    if (res.is_success) {
        yield put(actions.baoCaoTienBo.createSuccess(res.data))
    } else {
        yield put(actions.baoCaoTienBo.createError(res.message ?? ""))
    }
}

function* sendNotifyWorker(action: IBaoCaoTienDoCreateStart): any {
    const res: IBaseResponeModel = yield call(baoCaoTienBoApi.SendNotify, action.payload)
    if (res.is_success) {
        yield put(actions.baoCaoTienBo.sendNotifySuccess(res.data))
    } else {
        yield put(actions.baoCaoTienBo.sendNotifyError(res.message ?? ""))
    }
}

function* saveWorker(action: IBaoCaoTienDoSaveStart): any {
    const res: IBaseResponeModel = yield call(baoCaoTienBoApi.Save, action.payload)
    if (res.is_success) {
        yield put(actions.baoCaoTienBo.saveSuccess(res.data))
    } else {
        yield put(actions.baoCaoTienBo.saveError(res.message ?? ""))
    }
}


function* validateImportWorker(action: IBaoCaoTienDoValidateImportStart): any {
    const res: IBaseResponeModel = yield call(baoCaoTienBoApi.ValidateImport, action.payload)
    if (res.is_success) {
        yield put(actions.baoCaoTienBo.validateImportSuccess(res.data))
    } else {
        yield put(actions.baoCaoTienBo.validateImportError(res.message ?? ""))
    }
}

function* importWorker(action: IBaoCaoTienDoImportStart): any {
    const res: IBaseResponeModel = yield call(baoCaoTienBoApi.Import, action.payload)
    if (res.is_success) {
        yield put(actions.baoCaoTienBo.importSuccess())
    } else {
        yield put(actions.baoCaoTienBo.importError(res.message ?? ""))
    }
}

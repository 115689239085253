import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { IThoiKhoaBieuDeleteMultipleRequest } from "../../../models/request/thoi-khoa-bieu/IThoiKhoaBieuDeleteMultipleRequest"
import { IThoiKhoaBieuDuplicateRequest } from "../../../models/request/thoi-khoa-bieu/IThoiKhoaBieuDuplicateRequest"
import { ITKBChiTietSaveExcelRequest } from "../../../models/request/thoi-khoa-bieu/ITKBChiTietSaveExcelRequest"
import { ITKBChiTietSelectByDateRequest, ThoiKhoaBieuPreviewByGiaoVienRequest } from "../../../models/request/thoi-khoa-bieu/ITKBChiTietSelectByDateRequest"
import { ITKBChiTietSelectRequest } from "../../../models/request/thoi-khoa-bieu/ITKBChiTietSelectRequest"
import { ITKBChiTietUploadResponse, pl_tkb_chitiet, pl_tkb_chitiet_view } from "../../../models/response/thoi-khoa-bieu/pl_tkb_chitiet"
import { IThoiKhoaBieuPreviewItem } from "../../../models/response/thoi-khoa-bieu/ThoiKhoaBieuPreviewItem"

export enum eTKBChiTietActionTypeIds {
    LOAD_START = "TKB_CHITIET_LOAD_START",
    LOAD_SUCCESS = "TKB_CHITIET_LOAD_SUCCESS",
    LOAD_ERROR = "TKB_CHITIET_LOAD_ERROR",

    SELECT_START = "TKB_CHITIET_SELECT_START",
    SELECT_SUCCESS = "TKB_CHITIET_SELECT_SUCCESS",
    SELECT_ERROR = "TKB_CHITIET_SELECT_ERROR",

    SELECT_BYGIAOVIEN_START = "TKB_CHITIET_SELECT_BYGIAOVIEN_START",
    SELECT_BYGIAOVIEN_SUCCESS = "TKB_CHITIET_SELECT_BYGIAOVIEN_SUCCESS",
    SELECT_BYGIAOVIEN_ERROR = "TKB_CHITIET_SELECT_BYGIAOVIEN_ERROR",

    PREVIEW_BYGIAOVIEN_START = "TKB_CHITIET_PREVIEW_BYGIAOVIEN_START",
    PREVIEW_BYGIAOVIEN_SUCCESS = "TKB_CHITIET_PREVIEW_BYGIAOVIEN_SUCCESS",
    PREVIEW_BYGIAOVIEN_ERROR = "TKB_CHITIET_PREVIEW_BYGIAOVIEN_ERROR",

    SHOW_DETAIL = "TKB_CHITIET_SHOW_DETAIL",
    CLOSE_DETAIL = "TKB_CHITIET_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "TKB_CHITIET_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "TKB_CHITIET_SHOW_CONFIRM",
    CLOSE_CONFIRM = "TKB_CHITIET_CLOSE_CONFIRM",

    DELETE_START = "TKB_CHITIET_DELETE_START",
    DELETE_SUCCESS = "TKB_CHITIET_DELETE_SUCCESS",
    DELETE_ERROR = "TKB_CHITIET_DELETE_ERROR",

    SAVE_START = "TKB_CHITIET_SAVE_START",
    SAVE_SUCCESS = "TKB_CHITIET_SAVE_SUCCESS",
    SAVE_ERR = "TKB_CHITIET_SAVE_ERR",

    UPLOAD_EXCEL_CLEAR = "UPLOAD_TKB_EXCEL_CLEAR",
    UPLOAD_EXCEL_START = "UPLOAD_TKB_EXCEL_START",
    UPLOAD_EXCEL_SUCCESS = "UPLOAD_TKB_EXCEL_SUCCESS",
    UPLOAD_EXCEL_ERROR = "UPLOAD_TKB_EXCEL_ERROR",

    SAVE_EXCEL_START = "SAVE_TKB_EXCEL_START",
    SAVE_EXCEL_SUCCESS = "SAVE_TKB_EXCEL_SUCCESS",
    SAVE_EXCEL_ERROR = "SAVE_TKB_EXCEL_ERROR",

    DUPLICATE_START = "TKB_CHITIET_DUPLICATE_START",
    DUPLICATE_SUCCESS = "TKB_CHITIET_DUPLICATE_SUCCESS",
    DUPLICATE_ERR = "TKB_CHITIET_DUPLICATE_ERR",
    DUPLICATE_CLEAR_ERROR = "TKB_CHITIET_DUPLICATE_CLEAR_ERROR",

    DELETE_MULTIPLE_START = "TKB_CHITIET_DELETE_MULTIPLE_START",
    DELETE_MULTIPLE_SUCCESS = "TKB_CHITIET_DELETE_MULTIPLE_SUCCESS",
    DELETE_MULTIPLE_ERR = "TKB_CHITIET_DELETE_MULTIPLE_ERR",
}

export interface ITKBChiTietLoadStart {
    type: eTKBChiTietActionTypeIds.LOAD_START,
}
export interface ITKBChiTietLoadSuccess {
    type: eTKBChiTietActionTypeIds.LOAD_SUCCESS,
    payload: pl_tkb_chitiet_view[]
}
export interface ITKBChiTietLoadErr {
    type: eTKBChiTietActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface ITKBChiTietSelectStart {
    type: eTKBChiTietActionTypeIds.SELECT_START,
    payload: ITKBChiTietSelectRequest
}
export interface ITKBChiTietSelectSuccess {
    type: eTKBChiTietActionTypeIds.SELECT_SUCCESS,
    payload: IThoiKhoaBieuPreviewItem[]
}
export interface ITKBChiTietSelectErr {
    type: eTKBChiTietActionTypeIds.SELECT_ERROR,
    payload: string
}
export interface ITKBChiTietSelectByGiaoVienStart {
    type: eTKBChiTietActionTypeIds.SELECT_BYGIAOVIEN_START,
    payload: ITKBChiTietSelectByDateRequest
}
export interface ITKBChiTietSelectByGiaoVienSuccess {
    type: eTKBChiTietActionTypeIds.SELECT_BYGIAOVIEN_SUCCESS,
    payload: pl_tkb_chitiet_view[]
}
export interface ITKBChiTietSelectByGiaoVienErr {
    type: eTKBChiTietActionTypeIds.SELECT_BYGIAOVIEN_ERROR,
    payload: string
}

export interface ITKBChiTietPreviewByGiaoVienStart {
    type: eTKBChiTietActionTypeIds.PREVIEW_BYGIAOVIEN_START,
    payload: ThoiKhoaBieuPreviewByGiaoVienRequest
}
export interface ITKBChiTietPreviewByGiaoVienSuccess {
    type: eTKBChiTietActionTypeIds.PREVIEW_BYGIAOVIEN_SUCCESS,
    payload: IThoiKhoaBieuPreviewItem[]
}
export interface ITKBChiTietPreviewByGiaoVienErr {
    type: eTKBChiTietActionTypeIds.PREVIEW_BYGIAOVIEN_ERROR,
    payload: string
}

export interface ITKBChiTietShowDetail {
    type: eTKBChiTietActionTypeIds.SHOW_DETAIL,
    payload?: pl_tkb_chitiet_view
}
export interface ITKBChiTietCloseDetail {
    type: eTKBChiTietActionTypeIds.CLOSE_DETAIL,
}
export interface ITKBChiTietChangeSelectedIds {
    type: eTKBChiTietActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface ITKBChiTietShowConfirm {
    type: eTKBChiTietActionTypeIds.SHOW_CONFIRM
}
export interface ITKBChiTietCloseConfirm {
    type: eTKBChiTietActionTypeIds.CLOSE_CONFIRM
}

export interface ITKBChiTietDeleteStart {
    type: eTKBChiTietActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface ITKBChiTietDeleteSuccess {
    type: eTKBChiTietActionTypeIds.DELETE_SUCCESS,
}
export interface ITKBChiTietDeleteErr {
    type: eTKBChiTietActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface ITKBChiTietSaveStart {
    type: eTKBChiTietActionTypeIds.SAVE_START,
    payload: pl_tkb_chitiet
}
export interface ITKBChiTietSaveSuccess {
    type: eTKBChiTietActionTypeIds.SAVE_SUCCESS
}
export interface ITKBChiTietSaveErr {
    type: eTKBChiTietActionTypeIds.SAVE_ERR,
    payload: string
}

export interface ITKBChiTietUploadExcelStartAction {
    type: eTKBChiTietActionTypeIds.UPLOAD_EXCEL_START,
    payload: FormData
}
export interface ITKBChiTietUploadExcelSuccessAction {
    type: eTKBChiTietActionTypeIds.UPLOAD_EXCEL_SUCCESS,
    payload: ITKBChiTietUploadResponse
}
export interface ITKBChiTietUploadExcelErrorAction {
    type: eTKBChiTietActionTypeIds.UPLOAD_EXCEL_ERROR,
    payload: string
}

export interface ITKBChiTietUploadExcelClearAction {
    type: eTKBChiTietActionTypeIds.UPLOAD_EXCEL_CLEAR
}

export interface ITKBChiTietSaveExcelStartAction {
    type: eTKBChiTietActionTypeIds.SAVE_EXCEL_START,
    payload: ITKBChiTietSaveExcelRequest
}
export interface ITKBChiTietSaveExcelSuccessAction {
    type: eTKBChiTietActionTypeIds.SAVE_EXCEL_SUCCESS,
    payload: ITKBChiTietUploadResponse
}
export interface ITKBChiTietSaveExcelErrorAction {
    type: eTKBChiTietActionTypeIds.SAVE_EXCEL_ERROR,
    payload: string
}

export interface ITKBChiTietDuplicateStart {
    type: eTKBChiTietActionTypeIds.DUPLICATE_START,
    payload: IThoiKhoaBieuDuplicateRequest
}
export interface ITKBChiTietDuplicateSuccess {
    type: eTKBChiTietActionTypeIds.DUPLICATE_SUCCESS,
    payload: IThoiKhoaBieuPreviewItem[]
}
export interface ITKBChiTietDuplicateErr {
    type: eTKBChiTietActionTypeIds.DUPLICATE_ERR,
    payload: string
}

export interface ITKBChiTietDuplicateClearErrorStart {
    type: eTKBChiTietActionTypeIds.DUPLICATE_CLEAR_ERROR,
}

export interface ITKBChiTietDeleteMultipleStart {
    type: eTKBChiTietActionTypeIds.DELETE_MULTIPLE_START,
    payload: IThoiKhoaBieuDeleteMultipleRequest
}
export interface ITKBChiTietDeleteMultipleSuccess {
    type: eTKBChiTietActionTypeIds.DELETE_MULTIPLE_SUCCESS
}
export interface ITKBChiTietDeleteMultipleErr {
    type: eTKBChiTietActionTypeIds.DELETE_MULTIPLE_ERR,
    payload: string
}

export type ITKBChiTietActionTypes = ITKBChiTietLoadStart | ITKBChiTietLoadSuccess | ITKBChiTietLoadErr |
    ITKBChiTietSelectStart | ITKBChiTietSelectSuccess | ITKBChiTietSelectErr |
    ITKBChiTietSelectByGiaoVienStart | ITKBChiTietSelectByGiaoVienSuccess | ITKBChiTietSelectByGiaoVienErr |
    ITKBChiTietPreviewByGiaoVienStart | ITKBChiTietPreviewByGiaoVienSuccess | ITKBChiTietPreviewByGiaoVienErr |
    ITKBChiTietShowDetail | ITKBChiTietCloseDetail |
    ITKBChiTietChangeSelectedIds |
    ITKBChiTietShowConfirm | ITKBChiTietCloseConfirm |
    ITKBChiTietDeleteStart | ITKBChiTietDeleteSuccess | ITKBChiTietDeleteErr |
    ITKBChiTietSaveStart | ITKBChiTietSaveSuccess | ITKBChiTietSaveErr |
    ITKBChiTietUploadExcelStartAction | ITKBChiTietUploadExcelSuccessAction | ITKBChiTietUploadExcelErrorAction | ITKBChiTietUploadExcelClearAction |
    ITKBChiTietSaveExcelStartAction | ITKBChiTietSaveExcelSuccessAction | ITKBChiTietSaveExcelErrorAction |
    ITKBChiTietDuplicateStart | ITKBChiTietDuplicateSuccess | ITKBChiTietDuplicateErr |
    ITKBChiTietDeleteMultipleStart | ITKBChiTietDeleteMultipleSuccess | ITKBChiTietDeleteMultipleErr  | ITKBChiTietDuplicateClearErrorStart
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eDiemDanhActionTypeIds, IDiemDanhLopBoMonStart, IDiemDanhLopChuNhiemStart, IDiemDanhPublishMarkLopBoMonStart, IDiemDanhPublishMarkLopChuNhiemStart, IDiemDanhSelectConfigStart, IDiemDanhSelectLopBoMonStart, IDiemDanhSelectLopChuNhiemStart } from "../../action-types/diem-danh/IDiemDanhActionTypes";

import { actions } from "../../actions/actionsWrapper";

const diemDanhApi = apiWrapper.diemDanh;

export function* diemDanhSaga(): any {
    yield takeLatest(eDiemDanhActionTypeIds.SELECT_LOPBOMON_START, selectLopBoMonWorker)
    yield takeEvery(eDiemDanhActionTypeIds.DIEMDANH_LOPBOMON_START, diemDanhLopBoMonWorker)
    yield takeLatest(eDiemDanhActionTypeIds.SELECT_LOPCHUNHIEM_START, selectLopChuNhiemWorker)
    yield takeEvery(eDiemDanhActionTypeIds.DIEMDANH_LOPCHUNHIEM_START, diemDanhLopChuNhiemWorker)
    yield takeLatest(eDiemDanhActionTypeIds.SELECT_CONFIG_START, selectConfifWorker)
    yield takeLatest(eDiemDanhActionTypeIds.PUBLISHMARK_LOPBOMON_START, publishMarkLopBoMonWorker)
    yield takeLatest(eDiemDanhActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_START, publishMarkLopChuNhiemWorker)
}

function* selectLopBoMonWorker(data: IDiemDanhSelectLopBoMonStart): any {
    const res: IBaseResponeModel = yield call([diemDanhApi, diemDanhApi.SelectByLopBoMonAsync], data.payload)
    if (res.is_success) {
        yield put(actions.diemDanh.selectLopBoMonSuccess(res.data))
    } else {
        yield put(actions.diemDanh.selectLopBoMonError(res.message || "Không tải được danh sách học sinh"))
    }
}
function* diemDanhLopBoMonWorker(action: IDiemDanhLopBoMonStart): any {
    const res: IBaseResponeModel = yield call([diemDanhApi, diemDanhApi.DiemDanhLopBoMonAsync], action.payload)
    if (res.is_success) {
        yield put(actions.diemDanh.diemDanhLopBoMonSuccess())
        yield put(actions.diemDanh.showDiemDanhModal(false))
    } else {
        yield put(actions.diemDanh.diemDanhLopBoMonError(res.message || "Không lưu được điểm danh"))
        yield put(actions.diemDanh.showDiemDanhModal(false))
    }
}
function* selectLopChuNhiemWorker(data: IDiemDanhSelectLopChuNhiemStart): any {
    const res: IBaseResponeModel = yield call([diemDanhApi, diemDanhApi.SelectByLopChuNhiemAsync], data.payload)
    if (res.is_success) {
        yield put(actions.diemDanh.selectLopChuNhiemSuccess(res.data))
    } else {
        yield put(actions.diemDanh.selectLopChuNhiemError(res.message || "Không tải được danh sách học sinh"))
    }
}
function* diemDanhLopChuNhiemWorker(action: IDiemDanhLopChuNhiemStart): any {
    const res: IBaseResponeModel = yield call([diemDanhApi, diemDanhApi.DiemDanhLopChuNhiemAsync], action.payload)
    if (res.is_success) {
        yield put(actions.diemDanh.diemDanhLopChuNhiemSuccess())
        yield put(actions.diemDanh.showDiemDanhModal(false))
    } else {
        yield put(actions.diemDanh.diemDanhLopChuNhiemError(res.message || "Không lưu được điểm danh"))
        yield put(actions.diemDanh.showDiemDanhModal(false))
    }
}

function* selectConfifWorker(data: IDiemDanhSelectConfigStart): any {
    const res: IBaseResponeModel = yield call([diemDanhApi, diemDanhApi.SelectLopDiemDanhAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.diemDanh.selectConfigSuccess(res.data))
    }else {
        yield put(actions.diemDanh.selectConfigError(res.message || "Không tìm thấy lớp điểm danh"))
    }
}
function* publishMarkLopBoMonWorker(data: IDiemDanhPublishMarkLopBoMonStart): any {
    const res: IBaseResponeModel = yield call([diemDanhApi, diemDanhApi.DiemDanhLopBoMonPublishMarkAsync], data.payload)
    if (res.is_success) {
        yield put(actions.diemDanh.publishMarkLopBoMonSuccess())
    } else {
        yield put(actions.diemDanh.publishMarkLopBoMonError(res.message || "Không tải được danh sách học sinh"))
    }
}
function* publishMarkLopChuNhiemWorker(data: IDiemDanhPublishMarkLopChuNhiemStart): any {
    const res: IBaseResponeModel = yield call([diemDanhApi, diemDanhApi.DiemDanhLopChuNhiemPublishMarkAsync], data.payload)
    if (res.is_success) {
        yield put(actions.diemDanh.publishMarkLopChuNhiemSuccess())
    } else {
        yield put(actions.diemDanh.publishMarkLopChuNhiemError(res.message || "Không tải được danh sách học sinh"))
    }
}
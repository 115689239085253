import React from 'react';
import { useCommonContext } from '../../contexts/common';
interface ITextProps {
    value: string
}
const TextTranslated = (props: ITextProps) => {
    const { translate } = useCommonContext();
    return (
        <>
            {translate(props.value)}
        </>
    );
};

export default TextTranslated;
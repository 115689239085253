import { INamHocTruongFilter } from "../../../models/INamHocTruongFilter";
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { HocKyItemRespone } from "../../../models/response/danh-muc/IHocKyItemRespone";
import { pl_hocky } from "../../../models/response/danh-muc/pl_hocky";
import { eHocKyActionTypeIds, IHocKyChangeSelectedIds, IHocKyCloseConfirm, IHocKyCloseDetail, IHocKyDeleteErr, IHocKyDeleteStart, IHocKyDeleteSuccess, IHocKyLoadErr, IHocKyLoadStart, IHocKyLoadSuccess, IHocKySaveErr, IHocKySaveStart, IHocKySaveSuccess, IHocKyShowConfirm, IHocKyShowDetail } from "../../action-types/danh-muc/IHocKyActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const hocKyActions = {
    loadStart: (request:INamHocTruongFilter): IHocKyLoadStart => BaseAction(request, eHocKyActionTypeIds.LOAD_START),
    loadSuccess: (respone: HocKyItemRespone[]): IHocKyLoadSuccess => BaseAction(respone, eHocKyActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IHocKyLoadErr => BaseAction(messege, eHocKyActionTypeIds.LOAD_ERROR),
    showDetail: (data?: pl_hocky): IHocKyShowDetail => BaseAction(data, eHocKyActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IHocKyCloseDetail => BaseAction(undefined, eHocKyActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IHocKyShowConfirm => BaseAction(undefined, eHocKyActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IHocKyCloseConfirm => BaseAction(undefined, eHocKyActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IHocKyChangeSelectedIds => BaseAction(ids, eHocKyActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IHocKyDeleteStart => BaseAction(ids, eHocKyActionTypeIds.DELETE_START),
    deleteSuccess: (): IHocKyDeleteSuccess => BaseAction(undefined, eHocKyActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IHocKyDeleteErr => BaseAction(message, eHocKyActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: pl_hocky): IHocKySaveStart => BaseAction(data, eHocKyActionTypeIds.SAVE_START),
    saveSuccess: ():IHocKySaveSuccess => BaseAction(undefined, eHocKyActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IHocKySaveErr =>  BaseAction(message, eHocKyActionTypeIds.SAVE_ERR)
}
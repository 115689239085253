import { NotifyHelper } from '../../../../helpers/toast';
import { INamHocHocKyTruongKhoiLopThangFilter } from '../../../../models/INamHocTruongKhoiLopThangFilter';
import { IBaoCaoThuongXuyenSelectRequest } from '../../../../models/request/bao-cao-thuong-xuyen/IBaoCaoTienBoSelectRequest';
import { ImportDgtxNlpcRequest } from '../../../../models/request/danh-gia/dinh-ky/ImportDgtxNlpcRequest';
import { ISelectDgtxKetQuaRequest } from '../../../../models/request/danh-gia/thuong-xuyen/ISelectDgtxKetQuaRequest';
import { IUpdateDgtxKetQuaRequest } from '../../../../models/request/danh-gia/thuong-xuyen/IUpdateDgtxKetQuaRequest';
import { IHocSinhSelectRequest } from '../../../../models/request/hoc-sinh/IHocSinhSelectRequest';
import { IBaoCaoThuongXuyen } from '../../../../models/response/danh-gia/thuong-xuyen/IBaoCaoThuongXuyen';
import { ISoNhanXetItem } from '../../../../models/response/dgdk-dm/ISoNhanXetItem';
import { IHocSinhSelectResponeItem } from '../../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { eDgtxKetQuaActionTypeIds } from '../../../action-types/danh-gia/thuong-xuyen/IDgtxKetQuaActionType';
import { baseAction } from "../../IActionBase";
import { IDgtxKetQua } from './../../../../models/response/danh-gia/thuong-xuyen/IDgtxKetQua';

export const dgtxKetQuaActions = {
    changeFilter: (filter: INamHocHocKyTruongKhoiLopThangFilter) => baseAction(eDgtxKetQuaActionTypeIds.CHANGE_FILTER, filter),
    loadHocSinhStart: (rq: IHocSinhSelectRequest) => baseAction(eDgtxKetQuaActionTypeIds.HOCSINH_LOAD_START, rq),
    loadHocSinhSuccess: (hocSinh: IHocSinhSelectResponeItem[]) => baseAction(eDgtxKetQuaActionTypeIds.HOCSINH_LOAD_SUCCESS, hocSinh),
    loadHocSinhError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaActionTypeIds.HOCSINH_LOAD_ERROR, message)
    },
    loadSoNhanXetItemsStart: (setId: number) => baseAction(eDgtxKetQuaActionTypeIds.SONHANXET_ITEM_LOAD_START, setId),
    loadSoNhanXetItemsSuccess: (data: ISoNhanXetItem[]) => baseAction(eDgtxKetQuaActionTypeIds.SONHANXET_ITEM_LOAD_SUCCESS, data),
    loadSoNhanXetItemsError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaActionTypeIds.SONHANXET_ITEM_LOAD_ERROR, message)
    },
    loadKetQuaStart: (request: ISelectDgtxKetQuaRequest) => baseAction(eDgtxKetQuaActionTypeIds.KETQUA_LOAD_START, request),
    loadKetQuaSuccess: (data: IDgtxKetQua[]) => baseAction(eDgtxKetQuaActionTypeIds.KETQUA_LOAD_SUCCESS, data),
    loadKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaActionTypeIds.KETQUA_LOAD_ERROR, message)
    },
    saveKetQuaStart: (request: IUpdateDgtxKetQuaRequest) => baseAction(eDgtxKetQuaActionTypeIds.KETQUA_SAVE_START, request),
    saveKetQuaSuccess: (data: IDgtxKetQua) => baseAction(eDgtxKetQuaActionTypeIds.KETQUA_SAVE_SUCCESS, data),
    saveKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaActionTypeIds.KETQUA_SAVE_ERROR, message)
    },
    loadBaoCaoThuongXuyensStart: (request: IBaoCaoThuongXuyenSelectRequest) => baseAction(eDgtxKetQuaActionTypeIds.BCTX_LOAD_START, request),
    loadBaoCaoThuongXuyensSuccess: (data: IBaoCaoThuongXuyen[]) => baseAction(eDgtxKetQuaActionTypeIds.BCTX_LOAD_SUCCESS, data),
    loadBaoCaoThuongXuyensError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaActionTypeIds.BCTX_LOAD_ERROR, message)
    },
    validateImportStart: (request: ImportDgtxNlpcRequest) => baseAction(eDgtxKetQuaActionTypeIds.VALIDATE_IMPORT_START, request),
    validateImportSuccess: (data: any) => baseAction(eDgtxKetQuaActionTypeIds.VALIDATE_IMPORT_SUCCESS, data),
    validateImportError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaActionTypeIds.VALIDATE_IMPORT_ERRROR, message)
    },
    importStart: (request: ImportDgtxNlpcRequest) => baseAction(eDgtxKetQuaActionTypeIds.IMPORT_START, request),
    importSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eDgtxKetQuaActionTypeIds.IMPORT_SUCCESS, undefined)
    },
    importError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaActionTypeIds.IMPORT_ERRROR, message)
    },
}
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { IMonHocDangKyUpdateRequest } from "../../../models/request/dangky-monhoc/IDotDangKySelectRequest";
import { sis_pl_dotdangky_monhoc } from "../../../models/response/dangky-monhoc/sis_pl_dotdangky_monhoc";

import { IDotDangKyMonHocADDMONHOCErr, IDotDangKyMonHocADDMONHOCStart, IDotDangKyMonHocADDMONHOCSuccess, IDotDangKyMonHocChangeSelectedIds, IDotDangKyMonHocCloseConfirm, IDotDangKyMonHocCloseDetail, IDotDangKyMonHocDeleteErr, IDotDangKyMonHocDeleteStart, IDotDangKyMonHocDeleteSuccess, IDotDangKyMonHocLoadErr, IDotDangKyMonHocLoadStart, IDotDangKyMonHocLoadSuccess, IDotDangKyMonHocSaveErr, IDotDangKyMonHocSaveStart, IDotDangKyMonHocSaveSuccess, IDotDangKyMonHocShowConfirm, IDotDangKyMonHocShowDetail, eDotDangKyMonHocActionTypeIds } from "../../action-types/dangky-monhoc/IDotDangKyMonHocActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const dotDangKyMonHocActions = {
    loadStart: (dotdangky_id: number): IDotDangKyMonHocLoadStart => BaseAction(dotdangky_id, eDotDangKyMonHocActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_pl_dotdangky_monhoc[]): IDotDangKyMonHocLoadSuccess => BaseAction(respone, eDotDangKyMonHocActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IDotDangKyMonHocLoadErr => BaseAction(messege, eDotDangKyMonHocActionTypeIds.LOAD_ERROR),

    showDetail: (data?: sis_pl_dotdangky_monhoc): IDotDangKyMonHocShowDetail => BaseAction(data, eDotDangKyMonHocActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IDotDangKyMonHocCloseDetail => BaseAction(undefined, eDotDangKyMonHocActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IDotDangKyMonHocShowConfirm => BaseAction(undefined, eDotDangKyMonHocActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IDotDangKyMonHocCloseConfirm => BaseAction(undefined, eDotDangKyMonHocActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IDotDangKyMonHocChangeSelectedIds => BaseAction(ids, eDotDangKyMonHocActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IDotDangKyMonHocDeleteStart => BaseAction(ids, eDotDangKyMonHocActionTypeIds.DELETE_START),
    deleteSuccess: (): IDotDangKyMonHocDeleteSuccess => BaseAction(undefined, eDotDangKyMonHocActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IDotDangKyMonHocDeleteErr => BaseAction(message, eDotDangKyMonHocActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: sis_pl_dotdangky_monhoc): IDotDangKyMonHocSaveStart => BaseAction(data, eDotDangKyMonHocActionTypeIds.SAVE_START),
    saveSuccess: ():IDotDangKyMonHocSaveSuccess => BaseAction(undefined, eDotDangKyMonHocActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IDotDangKyMonHocSaveErr =>  BaseAction(message, eDotDangKyMonHocActionTypeIds.SAVE_ERR),

    addMonHocStart: (data: IMonHocDangKyUpdateRequest): IDotDangKyMonHocADDMONHOCStart => BaseAction(data, eDotDangKyMonHocActionTypeIds.ADDMONHOC_START),
    addMonHocSuccess: ():IDotDangKyMonHocADDMONHOCSuccess => BaseAction(undefined, eDotDangKyMonHocActionTypeIds.ADDMONHOC_SUCCESS),
    addMonHocError: (message: string): IDotDangKyMonHocADDMONHOCErr =>  BaseAction(message, eDotDangKyMonHocActionTypeIds.ADDMONHOC_ERR)
}
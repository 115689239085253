import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { KetQuaKhaoSatResponse, sis_form_khaosat_result } from "../../../models/response/form-khaosat/sis_form_khaosat_result";
import { sis_form_khaosat_result_detail } from "../../../models/response/form-khaosat/sis_form_khaosat_result_detail";
import { sis_khaosat } from "../../../models/response/form-khaosat/sis_khaosat";
import {
    IKhaoSatChangeDetail,
    IKhaoSatChangeSelectedIds,
    IKhaoSatDeleteErr, IKhaoSatDeleteStart, IKhaoSatDeleteSuccess,
    IKhaoSatLoadByTruongErr, IKhaoSatLoadByTruongStart, IKhaoSatLoadByTruongSuccess, IKhaoSatLoadErr,
    IKhaoSatLoadKetQuaKhaoSatChiTietErr,
    IKhaoSatLoadKetQuaKhaoSatChiTietStart,
    IKhaoSatLoadKetQuaKhaoSatChiTietSuccess,
    IKhaoSatLoadKetQuaKhaosatErr,
    IKhaoSatLoadKetQuaKhaosatStart,
    IKhaoSatLoadKetQuaKhaosatSuccess,
    IKhaoSatLoadResultByTruongErr,
    IKhaoSatLoadResultByTruongStart,
    IKhaoSatLoadResultByTruongSuccess,
    IKhaoSatLoadResultDetailErr,
    IKhaoSatLoadResultDetailStart,
    IKhaoSatLoadResultDetailSuccess,
    IKhaoSatLoadStart, IKhaoSatLoadSuccess, IKhaoSatShowConfirm,
    IKhaoSatShowModal,
    eKhaoSatActionTypeIds
} from "../../action-types/form-khaosat/IKhaoSatActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const KhaoSatActions = {
    //ALL
    loadStart: (): IKhaoSatLoadStart => BaseAction(undefined, eKhaoSatActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_khaosat[]): IKhaoSatLoadSuccess => BaseAction(respone, eKhaoSatActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IKhaoSatLoadErr => BaseAction(messege, eKhaoSatActionTypeIds.LOAD_ERROR),
    //By truong
    loadByTruongStart: (dm_truong_id: number, nam_hoc: string): IKhaoSatLoadByTruongStart => BaseAction({ dm_truong_id, nam_hoc }, eKhaoSatActionTypeIds.LOAD_BYTRUONG_START),
    loadByTruongSuccess: (respone: sis_khaosat[]): IKhaoSatLoadByTruongSuccess => BaseAction(respone, eKhaoSatActionTypeIds.LOAD_BYTRUONG_SUCCESS),
    loadByTruongError: (messege: string): IKhaoSatLoadByTruongErr => BaseAction(messege, eKhaoSatActionTypeIds.LOAD_BYTRUONG_ERROR),

    loadResultByTruongStart: (dm_truong_id: number, sis_khaosat_id: number, nam_hoc: string): IKhaoSatLoadResultByTruongStart => BaseAction({ dm_truong_id, sis_khaosat_id, nam_hoc }, eKhaoSatActionTypeIds.LOAD_RESULT_BYTRUONG_START),
    loadResultByTruongSuccess: (response: sis_form_khaosat_result[]): IKhaoSatLoadResultByTruongSuccess => BaseAction(response, eKhaoSatActionTypeIds.LOAD_RESULT_BYTRUONG_SUCCESS),
    loadResultByTruongError: (message: string): IKhaoSatLoadResultByTruongErr => BaseAction(message, eKhaoSatActionTypeIds.LOAD_RESULT_BYTRUONG_ERROR),

    loadKetQuaKhaosatStart: (sis_khaosat_id: number): IKhaoSatLoadKetQuaKhaosatStart => BaseAction({ sis_khaosat_id }, eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_START),
    loadKetQuaKhaosatSuccess: (response: KetQuaKhaoSatResponse[]): IKhaoSatLoadKetQuaKhaosatSuccess => BaseAction(response, eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_SUCCESS),
    loadKetQuaKhaosatError: (message: string): IKhaoSatLoadKetQuaKhaosatErr => BaseAction(message, eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_ERROR),

    loadKetQuaKhaoSatChiTietStart: (sis_khaosat_id: number): IKhaoSatLoadKetQuaKhaoSatChiTietStart => BaseAction({ sis_khaosat_id }, eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_CHITIET_START),
    loadKetQuaKhaoSatChiTietSuccess: (response: sis_form_khaosat_result_detail[]): IKhaoSatLoadKetQuaKhaoSatChiTietSuccess => BaseAction(response, eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_CHITIET_SUCCESS),
    loadKetQuaKhaoSatChiTietError: (message: string): IKhaoSatLoadKetQuaKhaoSatChiTietErr => BaseAction(message, eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_CHITIET_ERROR),

    loadResultDetailStart: (sis_form_khaosat_result_id: number): IKhaoSatLoadResultDetailStart => BaseAction({ sis_form_khaosat_result_id }, eKhaoSatActionTypeIds.LOAD_RESULT_DETAIL_START),
    loadResultDetailSuccess: (response: sis_form_khaosat_result_detail[]): IKhaoSatLoadResultDetailSuccess => BaseAction(response, eKhaoSatActionTypeIds.LOAD_RESULT_DETAIL_SUCCESS),
    loadResultDetailError: (message: string): IKhaoSatLoadResultDetailErr => BaseAction(message, eKhaoSatActionTypeIds.LOAD_RESULT_DETAIL_ERROR),

    //not in truong
    showModal: (show: boolean): IKhaoSatShowModal => BaseAction(show, eKhaoSatActionTypeIds.SHOW_MODAL),
    changeDetail: (data?: sis_khaosat): IKhaoSatChangeDetail => BaseAction(data, eKhaoSatActionTypeIds.CHANGE_DETAIL),
    showConfirm: (show: boolean): IKhaoSatShowConfirm => BaseAction(show, eKhaoSatActionTypeIds.SHOW_CONFIRM),
    changeSelectedIds: (ids: number[]): IKhaoSatChangeSelectedIds => BaseAction(ids, eKhaoSatActionTypeIds.CHANGE_SELECTED_IDS),
    //delete
    deleteStart: (ids: IDeleteMultipleBaseRequest): IKhaoSatDeleteStart => BaseAction(ids, eKhaoSatActionTypeIds.DELETE_START),
    deleteSuccess: (): IKhaoSatDeleteSuccess => BaseAction(undefined, eKhaoSatActionTypeIds.DELETE_SUCCESS),
    deleteErr: (message: string): IKhaoSatDeleteErr => BaseAction(message, eKhaoSatActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: sis_khaosat) => BaseAction(data, eKhaoSatActionTypeIds.SAVE_START),
    saveSuccess: () => BaseAction(undefined, eKhaoSatActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string) => BaseAction(message, eKhaoSatActionTypeIds.SAVE_ERR),
    clearStatus: () => BaseAction(undefined, eKhaoSatActionTypeIds.CLEAR_STATUS),

    pushNotifyStart: (data: { sis_khaosat_id: number }) => BaseAction(data, eKhaoSatActionTypeIds.PUSHNOTIFY_START),
    pushNotifySuccess: () => BaseAction(undefined, eKhaoSatActionTypeIds.PUSHNOTIFY_SUCCESS),
    pushNotifyError: (message: string) => BaseAction(message, eKhaoSatActionTypeIds.PUSHNOTIFY_ERR),
}



import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eHocKyActionTypeIds, IHocKyActionTypes } from "../../action-types/danh-muc/IHocKyActionTypes"
import { IHocKyPageState } from "../../page-state-model/danh-muc/IHocKyPageState"

const initialState: IHocKyPageState = {
    status: ePageBaseStatus.is_not_initialization,
    pl_hockys: [],
    pl_hocky_selected_ids: [],
    pl_hocky_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const hockyReducer = (state: IHocKyPageState = initialState, action: IHocKyActionTypes): IHocKyPageState => {
    switch (action.type) {
        case eHocKyActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eHocKyActionTypeIds.LOAD_SUCCESS:
            // debugger
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_hockys: action.payload
            }
        case eHocKyActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_hockys: []
            }
        case eHocKyActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                pl_hocky_editing: action.payload
            }
        case eHocKyActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                pl_hocky_editing: undefined
            }
        case eHocKyActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                pl_hocky_selected_ids: action.payload
            }
        case eHocKyActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eHocKyActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eHocKyActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eHocKyActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eHocKyActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eHocKyActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eHocKyActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eHocKyActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        default:
            return state;
    }

}
import { eSoDiemMappingStatus } from './../../page-state-model/so-diem/ISoDiemMappingStateModel';
import { eSoDiemMappingActionTypeIds, ISoDiemMappingActionTypes } from './../../action-types/so-diem/ISoDiemMappingActionType';
import { ISoDiemMappingStateModel } from "../../page-state-model/so-diem/ISoDiemMappingStateModel";
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';

const iniState: ISoDiemMappingStateModel = {
    soDiemId: 0,
    soDiemMappings: [],
    soDiemMoetThanhPhans: [],
    status: ePageBaseStatus.is_not_initialization,
    isShowMappingModal: false,
    isShowDeleteConfirmModal: false,
    loading: {
        soDiemMappings: "",
        soDiemMoetThanhPhans: ""
    }
}

export const soDiemMappingReducer = (state: ISoDiemMappingStateModel = iniState, action: ISoDiemMappingActionTypes): ISoDiemMappingStateModel => {
    switch (action.type) {
        case eSoDiemMappingActionTypeIds.LOAD_MAPPING_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soDiemMappings: "isLoading"
                }
            }
        case eSoDiemMappingActionTypeIds.LOAD_MAPPING_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soDiemMappings: ""
                },
                soDiemMappings: action.payload
            }
        case eSoDiemMappingActionTypeIds.LOAD_MAPPING_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soDiemMappings: ""
                }
            }

        case eSoDiemMappingActionTypeIds.LOAD_MOET_THANHPHAN_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soDiemMoetThanhPhans: "isLoading"
                }
            }
        case eSoDiemMappingActionTypeIds.LOAD_MOET_THANHPHAN_SUCCESS:
            return {
                ...state,
                soDiemMoetThanhPhans: action.payload,
                loading: {
                    ...state.loading,
                    soDiemMoetThanhPhans: ""
                },

            }
        case eSoDiemMappingActionTypeIds.LOAD_MOET_THANHPHAN_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soDiemMoetThanhPhans: ""
                }
            }

        case eSoDiemMappingActionTypeIds.UPDATE_MOET_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eSoDiemMappingActionTypeIds.UPDATE_MOET_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eSoDiemMappingActionTypeIds.UPDATE_MOET_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eSoDiemMappingActionTypeIds.SHOW_MAPPING_MODAL:
            return {
                ...state,
                isShowMappingModal: true,
                soDiemThanhPhanMapping: action.payload
            }
        case eSoDiemMappingActionTypeIds.CLOSE_MAPPING_MODAL:
            return {
                ...state,
                isShowMappingModal: false,
                soDiemThanhPhanMapping: undefined
            }
        case eSoDiemMappingActionTypeIds.SHOW_DELETE_CONFIRM:
            return {
                ...state,
                isShowDeleteConfirmModal: true,
                soDiemThanhPhanMapping: action.payload

            }
        case eSoDiemMappingActionTypeIds.CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                isShowDeleteConfirmModal: false,
                soDiemThanhPhanMapping: undefined
            }
        case eSoDiemMappingActionTypeIds.SAVE_MAPPING_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eSoDiemMappingActionTypeIds.SAVE_MAPPING_SUCCESS:
            return {
                ...state,
                status: eSoDiemMappingStatus.is_need_reload_mappings,
                isShowDeleteConfirmModal: false,
                soDiemThanhPhanMapping: undefined

            }
        case eSoDiemMappingActionTypeIds.SAVE_MAPPING_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed

            }

        default:
            return { ...state }
    }
}
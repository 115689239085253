import { IAppMenuTruongAddMenuRequest } from "../../../models/request/app-phuhuynh/AppMenuTruongAddMenuRequest";
import { IAppMenuTruongDeleteMenuRequest } from "../../../models/request/app-phuhuynh/AppMenuTruongDeleteMenuRequest";
import { app_menu_truong_view } from "../../../models/response/app-phuhuynh/app_menu_truong";
import { eAppMenuTruongActionTypeIds, IAppMenuTruongAddMenuErr, IAppMenuTruongAddMenuStart, IAppMenuTruongAddMenuSuccess, IAppMenuTruongChangeAppMenuSelectedIds, IAppMenuTruongChangeSelectedIds, IAppMenuTruongCloseConfirm, IAppMenuTruongDeleteErr, IAppMenuTruongDeleteStart, IAppMenuTruongDeleteSuccess, IAppMenuTruongLoadErr, IAppMenuTruongLoadStart, IAppMenuTruongLoadSuccess, IAppMenuTruongShowAddMenuModalStart, IAppMenuTruongShowConfirm } from "../../action-types/app-phuhuynh/IAppMenuTruongActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const appMenuTruongActions = {
    loadStart: (dm_truong_id: number): IAppMenuTruongLoadStart => BaseAction(dm_truong_id, eAppMenuTruongActionTypeIds.LOAD_START),
    loadSuccess: (respone: app_menu_truong_view[]): IAppMenuTruongLoadSuccess => BaseAction(respone, eAppMenuTruongActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IAppMenuTruongLoadErr => BaseAction(messege, eAppMenuTruongActionTypeIds.LOAD_ERROR),

    showConfirm: (): IAppMenuTruongShowConfirm => BaseAction(undefined, eAppMenuTruongActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IAppMenuTruongCloseConfirm => BaseAction(undefined, eAppMenuTruongActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IAppMenuTruongChangeSelectedIds => BaseAction(ids, eAppMenuTruongActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IAppMenuTruongDeleteMenuRequest): IAppMenuTruongDeleteStart => BaseAction(ids, eAppMenuTruongActionTypeIds.DELETE_START),
    deleteSuccess: (): IAppMenuTruongDeleteSuccess => BaseAction(undefined, eAppMenuTruongActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IAppMenuTruongDeleteErr => BaseAction(message, eAppMenuTruongActionTypeIds.DELETE_ERROR),

    //Add menu
    showAddMenuModal: (data: boolean): IAppMenuTruongShowAddMenuModalStart => BaseAction(data, eAppMenuTruongActionTypeIds.SHOW_ADD_APP_MENU_MODAL),
    changeAppMenuSelectedIds: (ids: number[]): IAppMenuTruongChangeAppMenuSelectedIds => BaseAction(ids, eAppMenuTruongActionTypeIds.CHANGE_APP_MENU_SELECTED_IDS),
    addMenuStart: (data: IAppMenuTruongAddMenuRequest): IAppMenuTruongAddMenuStart => BaseAction(data, eAppMenuTruongActionTypeIds.ADD_APP_MENU_START),
    addMenuSuccess: ():IAppMenuTruongAddMenuSuccess => BaseAction(undefined, eAppMenuTruongActionTypeIds.ADD_APP_MENU_SUCCESS),
    addMenuError: (message: string): IAppMenuTruongAddMenuErr =>  BaseAction(message, eAppMenuTruongActionTypeIds.ADD_APP_MENU_ERROR)
}
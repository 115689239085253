import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { pl_namhoc } from "../../../models/response/danh-muc/pl_namhoc"

export enum ePLNamHocActionTypeIds {
    LOAD_START = "PLNAMHOC_LOAD_START",
    LOAD_SUCCESS = "PLNAMHOC_LOAD_SUCCESS",
    LOAD_ERROR = "PLNAMHOC_LOAD_ERROR",

    SHOW_DETAIL = "PLNAMHOC_SHOW_DETAIL",
    CLOSE_DETAIL = "PLNAMHOC_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "PLNAMHOC_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "PLNAMHOC_SHOW_CONFIRM",
    CLOSE_CONFIRM = "PLNAMHOC_CLOSE_CONFIRM",

    DELETE_START = "PLNAMHOC_DELETE_START",
    DELETE_SUCCESS = "PLNAMHOC_DELETE_SUCCESS",
    DELETE_ERROR = "PLNAMHOC_DELETE_ERROR",

    SAVE_START = "PLNAMHOC_SAVE_START",
    SAVE_SUCCESS = "PLNAMHOC_SAVE_SUCCESS",
    SAVE_ERR = "PLNAMHOC_SAVE_ERR"
}

export interface IPLNamHocLoadStart {
    type: ePLNamHocActionTypeIds.LOAD_START,
}
export interface IPLNamHocLoadSuccess {
    type: ePLNamHocActionTypeIds.LOAD_SUCCESS,
    payload: pl_namhoc[]
}
export interface IPLNamHocLoadErr {
    type: ePLNamHocActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IPLNamHocShowDetail {
    type: ePLNamHocActionTypeIds.SHOW_DETAIL,
    payload?: pl_namhoc
}
export interface IPLNamHocCloseDetail {
    type: ePLNamHocActionTypeIds.CLOSE_DETAIL,
}
export interface IPLNamHocChangeSelectedIds {
    type: ePLNamHocActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IPLNamHocShowConfirm {
    type: ePLNamHocActionTypeIds.SHOW_CONFIRM
}
export interface IPLNamHocCloseConfirm {
    type: ePLNamHocActionTypeIds.CLOSE_CONFIRM
}

export interface IPLNamHocDeleteStart {
    type: ePLNamHocActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IPLNamHocDeleteSuccess {
    type: ePLNamHocActionTypeIds.DELETE_SUCCESS,
}
export interface IPLNamHocDeleteErr {
    type: ePLNamHocActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IPLNamHocSaveStart {
    type: ePLNamHocActionTypeIds.SAVE_START,
    payload: pl_namhoc
}
export interface IPLNamHocSaveSuccess {
    type: ePLNamHocActionTypeIds.SAVE_SUCCESS
}
export interface IPLNamHocSaveErr {
    type: ePLNamHocActionTypeIds.SAVE_ERR,
    payload: string
}
export type IPLNamHocActionTypes = IPLNamHocLoadStart | IPLNamHocLoadSuccess | IPLNamHocLoadErr |
    IPLNamHocShowDetail | IPLNamHocCloseDetail |
    IPLNamHocChangeSelectedIds |
    IPLNamHocShowConfirm | IPLNamHocCloseConfirm |
    IPLNamHocDeleteStart | IPLNamHocDeleteSuccess | IPLNamHocDeleteErr |
    IPLNamHocSaveStart | IPLNamHocSaveSuccess | IPLNamHocSaveErr
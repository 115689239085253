import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { app_menu_nhom } from "../../../models/response/app-phuhuynh/app_menu_nhom";
import { eAppMenuNhomActionTypeIds, IAppMenuNhomChangeSelectedIds, IAppMenuNhomCloseConfirm, IAppMenuNhomCloseDetail, IAppMenuNhomDeleteErr, IAppMenuNhomDeleteStart, IAppMenuNhomDeleteSuccess, IAppMenuNhomLoadErr, IAppMenuNhomLoadStart, IAppMenuNhomLoadSuccess, IAppMenuNhomSaveErr, IAppMenuNhomSaveStart, IAppMenuNhomSaveSuccess, IAppMenuNhomShowConfirm, IAppMenuNhomShowDetail } from "../../action-types/app-phuhuynh/IAppMenuNhomActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const appMenuNhomActions = {
    loadStart: (): IAppMenuNhomLoadStart => BaseAction(undefined, eAppMenuNhomActionTypeIds.LOAD_START),
    loadSuccess: (respone: app_menu_nhom[]): IAppMenuNhomLoadSuccess => BaseAction(respone, eAppMenuNhomActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IAppMenuNhomLoadErr => BaseAction(messege, eAppMenuNhomActionTypeIds.LOAD_ERROR),
    showDetail: (data?: app_menu_nhom): IAppMenuNhomShowDetail => BaseAction(data, eAppMenuNhomActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IAppMenuNhomCloseDetail => BaseAction(undefined, eAppMenuNhomActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IAppMenuNhomShowConfirm => BaseAction(undefined, eAppMenuNhomActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IAppMenuNhomCloseConfirm => BaseAction(undefined, eAppMenuNhomActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IAppMenuNhomChangeSelectedIds => BaseAction(ids, eAppMenuNhomActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IAppMenuNhomDeleteStart => BaseAction(ids, eAppMenuNhomActionTypeIds.DELETE_START),
    deleteSuccess: (): IAppMenuNhomDeleteSuccess => BaseAction(undefined, eAppMenuNhomActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IAppMenuNhomDeleteErr => BaseAction(message, eAppMenuNhomActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: app_menu_nhom): IAppMenuNhomSaveStart => BaseAction(data, eAppMenuNhomActionTypeIds.SAVE_START),
    saveSuccess: ():IAppMenuNhomSaveSuccess => BaseAction(undefined, eAppMenuNhomActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IAppMenuNhomSaveErr =>  BaseAction(message, eAppMenuNhomActionTypeIds.SAVE_ERR)
}
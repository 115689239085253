import { IBaiTap } from '../../../models/response/bai-tap/IBaiTap';
import { IBaiTapKetQua } from '../../../models/response/bai-tap/IBaiTapKetQua';
import { IBaiTapKetQuaItemRespone } from './../../../models/response/bai-tap/IBaiTapKetQuaItemRespone';
import { IActionTypeBase } from './../IActionTypeBase';
export enum eBaiTapKetQuaActionTypeIds {
    SET_BAITAP = "BAI_TAP_KET_QUA_SET_BAITAP",

    LOAD_KETQUA_START = "BAI_TAP_KET_QUA_LOAD_KETQUA_START",
    LOAD_KETQUA_SUCCESS = "BAI_TAP_KET_QUA_LOAD_KETQUA_SUCCESS",
    LOAD_KETQUA_ERROR = "BAI_TAP_KET_QUA_LOAD_KETQUA_ERROR",

    SAVE_KETQUA_START = "BAI_TAP_KET_QUA_SAVE_KETQUA_START",
    SAVE_KETQUA_SUCCESS = "BAI_TAP_KET_QUA_SAVE_KETQUA_SUCCESS",
    SAVE_KETQUA_ERROR = "BAI_TAP_KET_QUA_SAVE_KETQUA_ERROR",

    SHOW_PUBLISH_MARK_MODAL = "SHOW_PUBLISH_MARK_MODAL",

    PUBLISH_MARK_START = "BAI_TAP_KET_QUA_PUBLISH_MARK_START",
    PUBLISH_MARK_SUCCESS = "BAI_TAP_KET_QUA_PUBLISH_MARK_SUCCESS",
    PUBLISH_MARK_ERROR = "BAI_TAP_KET_QUA_PUBLISH_MARK_ERROR",

    SHOW_NOTIFY_MODAL = "BAI_TAP_KET_QUA_SHOW_NOTIFY_MODAL",

    SEND_NOTIFY_START = "BAI_TAP_KET_QUA_SEND_NOTIFY_START",
    SEND_NOTIFY_SUCCESS = "BAI_TAP_KET_QUA_SEND_NOTIFY_SUCCESS",
    SEND_NOTIFY_ERROR = "BAI_TAP_KET_QUA_SEND_NOTIFY_ERROR",

}


export interface IBaiTapKetQuaLoadStart extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.LOAD_KETQUA_START, number> { }
export interface IBaiTapKetQuaLoadSuccess extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.LOAD_KETQUA_SUCCESS, IBaiTapKetQuaItemRespone[]> { }
export interface IBaiTapKetQuaLoadError extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.LOAD_KETQUA_ERROR, string> { }

export interface IBaiTapKetQuaSetBaiTap extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.SET_BAITAP, IBaiTap> { }

export interface IBaiTapKetQuaSaveStart extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.SAVE_KETQUA_START, IBaiTapKetQua> { }
export interface IBaiTapKetQuaSaveSuccess extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.SAVE_KETQUA_SUCCESS, IBaiTapKetQua> { }
export interface IBaiTapKetQuaSaveError extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.SAVE_KETQUA_ERROR, string> { }

export interface IBaiTapKetQuaShowPublishMarkModal extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.SHOW_PUBLISH_MARK_MODAL, boolean> { }

export interface IBaiTapKetQuaPublishMarkStart extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.PUBLISH_MARK_START, number> { }
export interface IBaiTapKetQuaPublishMarkSuccess extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.PUBLISH_MARK_SUCCESS, undefined> { }
export interface IBaiTapKetQuaPublishMarkError extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.PUBLISH_MARK_ERROR, string> { }


export interface IBaiTapKetQuaShowNotifyConfirmModal extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.SHOW_NOTIFY_MODAL, boolean> { }

export interface IBaiTapKetQuaSendNotifyStart extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.SEND_NOTIFY_START, number> { }
export interface IBaiTapKetQuaSendNotifySuccess extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.SEND_NOTIFY_SUCCESS, undefined> { }
export interface IBaiTapKetQuaSendNotifyError extends IActionTypeBase<eBaiTapKetQuaActionTypeIds.SEND_NOTIFY_ERROR, string> { }

export type IBaiTapKetQuaActionTypes =
    IBaiTapKetQuaLoadStart | IBaiTapKetQuaLoadSuccess | IBaiTapKetQuaLoadError |
    IBaiTapKetQuaSetBaiTap |
    IBaiTapKetQuaSaveStart | IBaiTapKetQuaSaveSuccess | IBaiTapKetQuaSaveError |
    IBaiTapKetQuaShowPublishMarkModal |
    IBaiTapKetQuaPublishMarkStart | IBaiTapKetQuaPublishMarkSuccess | IBaiTapKetQuaPublishMarkError |
    IBaiTapKetQuaShowNotifyConfirmModal |
    IBaiTapKetQuaSendNotifyStart | IBaiTapKetQuaSendNotifySuccess | IBaiTapKetQuaSendNotifyError
import { IBaiTapType } from '../../../models/response/bai-tap/IBaiTapType';
import { IActionTypeBase } from '../IActionTypeBase';
export enum eBaiTapTypeActionIds {
    LOAD_START = "BAI_TAP_TYPE_LOAD_START",
    LOAD_SUCCESS = "BAI_TAP_TYPE_LOAD_SUCCESS",
    LOAD_ERROR = "BAI_TAP_TYPE_LOAD_ERROR",
}
export interface IBaiTapTypeLoadStart extends IActionTypeBase<eBaiTapTypeActionIds.LOAD_START, undefined> { }
export interface IBaiTapTypeLoadSuccess extends IActionTypeBase<eBaiTapTypeActionIds.LOAD_SUCCESS, IBaiTapType[]> { }
export interface IBaiTapTypeLoadError extends IActionTypeBase<eBaiTapTypeActionIds.LOAD_ERROR, string> { }

export type IBaiTapTypeActionTypes = IBaiTapTypeLoadStart | IBaiTapTypeLoadSuccess | IBaiTapTypeLoadError
import { AppAccountResetPasswordRequest } from "../../../models/request/app-phuhuynh/AppAccountResetPasswordRequest"
import { AppAccountSelectRequest } from "../../../models/request/app-phuhuynh/AppAccountSelectRequest"
import { IAppAccountUpdateStatusRequest } from "../../../models/request/app-phuhuynh/AppAccountUpdateStatusRequest"
import { AppAccountParentResponse } from "../../../models/response/app-phuhuynh/AppAccountParentResponse"
import { app_account } from "../../../models/response/app-phuhuynh/app_account"
import { IActionTypeBase } from "../IActionTypeBase"

export enum eAppAccountActionTypeIds {
    LOAD_START = "APP_ACCOUNT_LOAD_START",
    LOAD_SUCCESS = "APP_ACCOUNT_LOAD_SUCCESS",
    LOAD_ERROR = "APP_ACCOUNT_LOAD_ERROR",

    CHANGE_SELECTED_IDS = "APP_ACCOUNT_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "APP_ACCOUNT_SHOW_CONFIRM",
    CLOSE_CONFIRM = "APP_ACCOUNT_CLOSE_CONFIRM",

    UPDATE_STATUS_START = "APP_ACCOUNT_UPDATE_STATUS_START",
    UPDATE_STATUS_SUCCESS = "APP_ACCOUNT_UPDATE_STATUS_SUCCESS",
    UPDATE_STATUS_ERROR = "APP_ACCOUNT_UPDATE_STATUS_ERROR",

    SHOW_RESET = "APP_ACCOUNT_SHOW_RESET",
    CLOSE_RESET = "APP_ACCOUNT_CLOSE_RESET",

    CHANGE_PASSWORD_START = "APP_ACCOUNT_CHANGE_PASSWORD_START",
    CHANGE_PASSWORD_SUCCESS = "APP_ACCOUNT_CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_ERROR = "APP_ACCOUNT_CHANGE_PASSWORD_ERROR",
}

export interface IAppAccountLoadStart extends IActionTypeBase<eAppAccountActionTypeIds.LOAD_START, AppAccountSelectRequest> { }
export interface IAppAccountLoadSuccess extends IActionTypeBase<eAppAccountActionTypeIds.LOAD_SUCCESS, AppAccountParentResponse[]> { }
export interface IAppAccountLoadErr extends IActionTypeBase<eAppAccountActionTypeIds.LOAD_ERROR, string> { }
export interface IAppAccountChangeSelectedIds extends IActionTypeBase<eAppAccountActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }

export interface IAppAccountShowConfirm extends IActionTypeBase<eAppAccountActionTypeIds.SHOW_CONFIRM, undefined> { }
export interface IAppAccountCloseConfirm extends IActionTypeBase<eAppAccountActionTypeIds.CLOSE_CONFIRM, undefined> { }

export interface IAppAccountUpdateStatusStart extends IActionTypeBase<eAppAccountActionTypeIds.UPDATE_STATUS_START, IAppAccountUpdateStatusRequest> { }
export interface IAppAccountUpdateStatusSuccess extends IActionTypeBase<eAppAccountActionTypeIds.UPDATE_STATUS_SUCCESS, undefined> { }
export interface IAppAccountUpdateStatusErr extends IActionTypeBase<eAppAccountActionTypeIds.UPDATE_STATUS_ERROR, string> { }

export interface IAppAccountShowReset extends IActionTypeBase<eAppAccountActionTypeIds.SHOW_RESET, undefined> { }
export interface IAppAccountCloseReset extends IActionTypeBase<eAppAccountActionTypeIds.CLOSE_RESET, undefined> { }

export interface IAppAccountChangePasswordStart extends IActionTypeBase<eAppAccountActionTypeIds.CHANGE_PASSWORD_START, AppAccountResetPasswordRequest> { }
export interface IAppAccountChangePasswordSuccess extends IActionTypeBase<eAppAccountActionTypeIds.CHANGE_PASSWORD_SUCCESS, undefined> { }
export interface IAppAccountChangePasswordErr extends IActionTypeBase<eAppAccountActionTypeIds.CHANGE_PASSWORD_ERROR, string> { }

export type IAppAccountActionTypes = IAppAccountLoadStart | IAppAccountLoadSuccess | IAppAccountLoadErr |
    IAppAccountChangeSelectedIds |
    IAppAccountShowConfirm | IAppAccountCloseConfirm |
    IAppAccountUpdateStatusStart | IAppAccountUpdateStatusSuccess | IAppAccountUpdateStatusErr |
    IAppAccountChangePasswordStart |IAppAccountChangePasswordSuccess | IAppAccountChangePasswordErr |
    IAppAccountShowReset | IAppAccountCloseReset
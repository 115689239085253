import { sis_form_khaosat } from "../../models/response/form-khaosat/sis_form_khaosat";
import { apiClient } from "../apiClient";
import { BaseCRUDApi } from "../baseCRUDApi";

export const API_FORMKHAOSAT_PATH = "form-khao-sat";

export const formKhaoSatApi = new class FormKhaoSatApi extends BaseCRUDApi<sis_form_khaosat> {
    constructor(path: string) {
        super(path);
    }
    SelectByTruongAsync(dm_truong_id: number, nam_hoc: string) {
        return apiClient.get(`${this._path}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/select-all`);
    }
    LockAsync(id: number) {
        return apiClient.get(`${this._path}/lock/${id}`);
    }
    UnlockAsync(id: number) {
        return apiClient.get(`${this._path}/unlock/${id}`);
    }
}(API_FORMKHAOSAT_PATH);
import { NotifyHelper } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eGiaoVienNhanXetActionTypeIds, IGiaoVienNhanXetActionTypes } from "../../action-types/IGiaoVienNhanXetActionTypes";
import { IGiaoVienNhanXetPageStateModel } from "../../page-state-model/IGiaoVienNhanXetPageState";

const initialState: IGiaoVienNhanXetPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    sis_dm_nhanxets: [],
    is_show_delete_confirm: false,
    sis_dm_nhanxet_selected_ids: [],
    sis_dm_nhanxet_editing: undefined,
    is_show_detail_modal: false
}

export const giaoVienNhanXetReducer = (state: IGiaoVienNhanXetPageStateModel = initialState, action: IGiaoVienNhanXetActionTypes): IGiaoVienNhanXetPageStateModel => {
    switch (action.type) {
        case eGiaoVienNhanXetActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eGiaoVienNhanXetActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_dm_nhanxets: action.payload
            }
        case eGiaoVienNhanXetActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_dm_nhanxets: []
            }
        case eGiaoVienNhanXetActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_dm_nhanxet_editing: action.payload
            }
        case eGiaoVienNhanXetActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_dm_nhanxet_editing: undefined
            }
        case eGiaoVienNhanXetActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_dm_nhanxet_selected_ids: action.payload
            }
        case eGiaoVienNhanXetActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eGiaoVienNhanXetActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eGiaoVienNhanXetActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eGiaoVienNhanXetActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xóa dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        case eGiaoVienNhanXetActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eGiaoVienNhanXetActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eGiaoVienNhanXetActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }
        case eGiaoVienNhanXetActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        default:
            return state;
    }

}
import { sis_sodiem_thanhphandiem } from './../../../models/response/so-diem/sis_sodiem_thanhphandiem';
import { IDiem } from './../../../models/response/diem/IDiem';
import { eNhapDiemViewMode, IINhapDiemPagaStateFilter } from './../../page-state-model/nhap-diem/INhapDiemPageState';
import { pl_lopbomon_hocsinh_viewmodel } from '../../../models/response/lop-bo-mon/pl_lopbomon_hocsinh';
import { IActionTypeBase } from './../IActionTypeBase';
import { ISoDiemDetailRespone } from '../../../models/response/so-diem/ISoDiemDetailRespone';
import { IUpdateDiemHocSinhRequest } from '../../../models/request/diem/IUpdateDiemHocSinhRequest';
import { IUpdateDiemHocSinhRespone } from '../../../models/response/diem/IUpdateDiemHocSinhRespone';
import { IDiemHocSinhSelectRequest } from '../../../models/request/diem/IDiemHocSinhSelectRequest';
import { IDiemHocSinhCalculateRequest } from '../../../models/request/diem/IDiemHocSinhCalculateRequest';
import IDiemMappingBaiTapRequest from '../../../models/request/diem/IDiemMappingBaiTapRequest';
import { IConvertMoetRequest } from '../../../models/request/diem/IConvertMoetRequest';
import { IImportDiemHocSinhRequest } from '../../../models/request/diem/IImportDiemHocSinhRequest';
import { IDiemHocSinhSelectDiemChuyenDeRequest } from '../../../models/request/diem/IDiemHocSinhSelectDiemChuyenDeRequest';
export enum eNhapDiemActionTypeIds {
    LOAD_HOCSINH_START = "NHAP_DIEM_LOAD_HOCSINH_START",
    LOAD_HOCSINH_SUCCESS = "NHAP_DIEM_LOAD_HOCSINH_SUCCESS",
    LOAD_HOCSINH_ERROR = "NHAP_DIEM_LOAD_HOCSINH_ERROR",

    LOAD_DIEM_START = "NHAP_DIEM_LOAD_DIEM_START",
    LOAD_DIEM_SUCCESS = "NHAP_DIEM_LOAD_DIEM_SUCCESS",
    LOAD_DIEM_ERROR = "NHAP_DIEM_LOAD_DIEM_ERROR",

    LOAD_DIEM_CHUYEN_DE_START = "NHAP_DIEM_LOAD_DIEM_CHUYEN_DE_START",
    LOAD_DIEM_CHUYEN_DE_SUCCESS = "NHAP_DIEM_LOAD_DIEM_CHUYEN_DE_SUCCESS",
    LOAD_DIEM_CHUYEN_DE_ERROR = "NHAP_DIEM_LOAD_DIEM_CHUYEN_DE_ERROR",

    LOAD_DIEM_MOET_START = "NHAP_DIEM_LOAD_DIEM_MOET_START",
    LOAD_DIEM_MOET_SUCCESS = "NHAP_DIEM_LOAD_DIEM_MOET_SUCCESS",
    LOAD_DIEM_MOET_ERROR = "NHAP_DIEM_LOAD_DIEM_MOET_ERROR",

    CHANGE_FILTER = "NHAP_DIEM_CHANGE_FILTER",

    LOAD_SODIEM_INFO_START = "NHAP_DIEM_LOAD_SODIEM_INFO_START",
    LOAD_SODIEM_INFO_SUCCESS = "NHAP_DIEM_LOAD_SODIEM_INFO_SUCCESS",
    LOAD_SODIEM_INFO_ERROR = "NHAP_DIEM_LOAD_SODIEM_INFO_ERROR",

    LOAD_SODIEM_MOET_INFO_START = "NHAP_DIEM_LOAD_SODIEM_MOET_INFO_START",
    LOAD_SODIEM_MOET_INFO_SUCCESS = "NHAP_DIEM_LOAD_SODIEM_MOET_INFO_SUCCESS",
    LOAD_SODIEM_MOET_INFO_ERROR = "NHAP_DIEM_LOAD_SODIEM_MOET_INFO_ERROR",

    SHOW_MAPPING_DIEMBAITAP_MODAL = "NHAP_DIEM_SHOW_MAPPING_DIEMBAITAP_MODAL",

    UPDATE_DIEM_START = "NHAP_DIEM_UPDATE_DIEM_START",
    UPDATE_DIEM_SUCCESS = "NHAP_DIEM_UPDATE_DIEM_SUCCESS",
    UPDATE_DIEM_ERROR = "NHAP_DIEM_UPDATE_DIEM_ERROR",

    SHOW_CALCULATOR_MODAL = "NHAP_DIEM_SHOW_CALCULATOR_MODAL",
    CLOSE_CALCULATOR_MODAL = "NHAP_DIEM_CLOSE_CALCULATOR_MODAL",

    CALCULATE_START = "NHAP_DIEM_CALCULATE_START",
    CALCULATE_SUCCESS = "NHAP_DIEM_CALCULATE_SUCCESS",
    CALCULATE_ERROR = "NHAP_DIEM_CALCULATE_ERROR",

    MAPPING_START = "NHAP_DIEM_MAPPING_START",
    MAPPING_SUCCESS = "NHAP_DIEM_MAPPING_SUCCESS",
    MAPPING_ERROR = "NHAP_DIEM_MAPPING_ERROR",

    CHANGE_VIEW_MODE = "NHAP_DIEM_CHANGE_VIEW_MODE",

    SHOW_CONVERT_MOET_CONFIRM = "NHAP_DIEM_SHOW_CONVERT_MOET_CONFIRM",
    CLOSE_CONVERT_MOET_CONFIRM = "NHAP_DIEM_CLOSE_CONVERT_MOET_CONFIRM",

    CONVERT_MOET_START = "NHAP_DIEM_CONVERT_MOET_START",
    CONVERT_MOET_SUCCESS = "NHAP_DIEM_CONVERT_MOET_SUCCESS",
    CONVERT_MOET_ERROR = "NHAP_DIEM_CONVERT_MOET_ERROR",

    VALIDATE_IMPORT_START = "NHAP_DIEM_VALIDATE_IMPORT_START",
    VALIDATE_IMPORT_SUCCESS = "NHAP_DIEM_VALIDATE_IMPORT_SUCCESS",
    VALIDATE_IMPORT_ERRROR = "NHAP_DIEM_VALIDATE_IMPORT_ERRROR",

    IMPORT_START = "NHAP_DIEM_IMPORT_START",
    IMPORT_SUCCESS = "NHAP_DIEM_IMPORT_SUCCESS",
    IMPORT_ERRROR = "NHAP_DIEM_IMPORT_ERRROR",

    VALIDATE_IMPORT_LOP_START = "NHAP_DIEM_VALIDATE_IMPORT_LOP_START",
    VALIDATE_IMPORT_LOP_SUCCESS = "NHAP_DIEM_VALIDATE_IMPORT_LOP_SUCCESS",
    VALIDATE_IMPORT_LOP_ERRROR = "NHAP_DIEM_VALIDATE_IMPORT_LOP_ERRROR",

    IMPORT_LOP_START = "NHAP_DIEM_IMPORT_LOP_START",
    IMPORT_LOP_SUCCESS = "NHAP_DIEM_IMPORT_LOP_SUCCESS",
    IMPORT_LOP_ERRROR = "NHAP_DIEM_IMPORT_LOP_ERRROR",

    UPDATE_DIEM_ALL_THANHPHAN_START = "UPDATE_DIEM_ALL_THANHPHAN_START",
    UPDATE_DIEM_ALL_THANHPHAN_SUCCESS = "UPDATE_DIEM_ALL_THANHPHAN_SUCCESS",
    UPDATE_DIEM_ALL_THANHPHAN_ERROR = "UPDATE_DIEM_ALL_THANHPHAN_ERROR",
}
export interface INhapDiemLoadHocSinhStart extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_HOCSINH_START, number> { }
export interface INhapDiemLoadHocSinhSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_HOCSINH_SUCCESS, pl_lopbomon_hocsinh_viewmodel[]> { }
export interface INhapDiemLoadHocSinhError extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_HOCSINH_ERROR, string> { }

export interface INhapDiemLoadDiemStart extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_DIEM_START, IDiemHocSinhSelectRequest> { }
export interface INhapDiemLoadDiemSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_DIEM_SUCCESS, IDiem[]> { }
export interface INhapDiemLoadDiemError extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_DIEM_ERROR, string> { }


export interface INhapDiemLoadDiemChuyenDeStart extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_DIEM_CHUYEN_DE_START, IDiemHocSinhSelectDiemChuyenDeRequest> { }
export interface INhapDiemLoadDiemChuyenDeSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_DIEM_CHUYEN_DE_SUCCESS, IDiem[]> { }
export interface INhapDiemLoadDiemChuyenDeError extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_DIEM_CHUYEN_DE_ERROR, string> { }


export interface INhapDiemLoadDiemMoetStart extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_DIEM_MOET_START, IDiemHocSinhSelectRequest> { }
export interface INhapDiemLoadDiemMoetSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_DIEM_MOET_SUCCESS, IDiem[]> { }
export interface INhapDiemLoadDiemMoetError extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_DIEM_MOET_ERROR, string> { }

export interface INhapDiemChangeFilter extends IActionTypeBase<eNhapDiemActionTypeIds.CHANGE_FILTER, IINhapDiemPagaStateFilter> { }

export interface IShowMappingDiemBaiTapModal extends IActionTypeBase<eNhapDiemActionTypeIds.SHOW_MAPPING_DIEMBAITAP_MODAL, boolean> { }

export interface INhapDiemLoadSoDiemInfoStart extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_SODIEM_INFO_START, number> { }
export interface INhapDiemLoadSoDiemInfoSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_SODIEM_INFO_SUCCESS, ISoDiemDetailRespone | undefined> { }
export interface INhapDiemLoadSoDiemInfoError extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_SODIEM_INFO_ERROR, string> { }

export interface INhapDiemLoadSoDiemMoetInfoStart extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_SODIEM_MOET_INFO_START, number> { }
export interface INhapDiemLoadSoDiemMoetInfoSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_SODIEM_MOET_INFO_SUCCESS, ISoDiemDetailRespone | undefined> { }
export interface INhapDiemLoadSoDiemMoetInfoError extends IActionTypeBase<eNhapDiemActionTypeIds.LOAD_SODIEM_MOET_INFO_ERROR, string> { }

export interface INhapDiemUpdateDiemStart extends IActionTypeBase<eNhapDiemActionTypeIds.UPDATE_DIEM_START, IUpdateDiemHocSinhRequest> { }
export interface INhapDiemUpdateDiemSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.UPDATE_DIEM_SUCCESS, IUpdateDiemHocSinhRespone> { }
export interface INhapDiemUpdateDiemError extends IActionTypeBase<eNhapDiemActionTypeIds.UPDATE_DIEM_ERROR, string> { }


export interface INhapDiemShowCalculatorModal extends IActionTypeBase<eNhapDiemActionTypeIds.SHOW_CALCULATOR_MODAL, sis_sodiem_thanhphandiem> { }
export interface INhapDiemCloseCalculatorModal extends IActionTypeBase<eNhapDiemActionTypeIds.CLOSE_CALCULATOR_MODAL, undefined> { }

export interface INhapDiemCalculateStart extends IActionTypeBase<eNhapDiemActionTypeIds.CALCULATE_START, IDiemHocSinhCalculateRequest> { }
export interface INhapDiemCalculateSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.CALCULATE_SUCCESS, IDiem[]> { }
export interface INhapDiemCalculateError extends IActionTypeBase<eNhapDiemActionTypeIds.CALCULATE_ERROR, undefined> { }

export interface INhapDiemMappingStart extends IActionTypeBase<eNhapDiemActionTypeIds.MAPPING_START, IDiemMappingBaiTapRequest> { }
export interface INhapDiemMappingSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.MAPPING_SUCCESS, undefined> { }
export interface INhapDiemMappingError extends IActionTypeBase<eNhapDiemActionTypeIds.MAPPING_ERROR, string> { }

export interface INhapDIemChageViewMode extends IActionTypeBase<eNhapDiemActionTypeIds.CHANGE_VIEW_MODE, eNhapDiemViewMode> { }

export interface INhapDiemShowConvertMoetConfirm extends IActionTypeBase<eNhapDiemActionTypeIds.SHOW_CONVERT_MOET_CONFIRM, undefined> { }
export interface INhapDiemCloseConvertMoetConfirm extends IActionTypeBase<eNhapDiemActionTypeIds.CLOSE_CONVERT_MOET_CONFIRM, undefined> { }

export interface INhapDiemConvertMoetStart extends IActionTypeBase<eNhapDiemActionTypeIds.CONVERT_MOET_START, IConvertMoetRequest> { }
export interface INhapDiemConvertMoetSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.CONVERT_MOET_SUCCESS, undefined> { }
export interface INhapDiemConvertMoetError extends IActionTypeBase<eNhapDiemActionTypeIds.CONVERT_MOET_ERROR, string> { }

export interface INhapDiemValidateStart extends IActionTypeBase<eNhapDiemActionTypeIds.VALIDATE_IMPORT_START, IImportDiemHocSinhRequest> { }
export interface INhapDiemValidateSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.VALIDATE_IMPORT_SUCCESS, any> { }
export interface INhapDiemValidateError extends IActionTypeBase<eNhapDiemActionTypeIds.VALIDATE_IMPORT_ERRROR, any> { }

export interface INhapDiemImportStart extends IActionTypeBase<eNhapDiemActionTypeIds.IMPORT_START, IImportDiemHocSinhRequest> { }
export interface INhapDiemImportSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.IMPORT_SUCCESS, undefined> { }
export interface INhapDiemImportError extends IActionTypeBase<eNhapDiemActionTypeIds.IMPORT_ERRROR, any> { }


export interface INhapDiemValidateLopStart extends IActionTypeBase<eNhapDiemActionTypeIds.VALIDATE_IMPORT_LOP_START, IImportDiemHocSinhRequest> { }
export interface INhapDiemValidateLopSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.VALIDATE_IMPORT_LOP_SUCCESS, any> { }
export interface INhapDiemValidateLopError extends IActionTypeBase<eNhapDiemActionTypeIds.VALIDATE_IMPORT_LOP_ERRROR, any> { }

export interface INhapDiemImportLopStart extends IActionTypeBase<eNhapDiemActionTypeIds.IMPORT_LOP_START, IImportDiemHocSinhRequest> { }
export interface INhapDiemImportLopSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.IMPORT_LOP_SUCCESS, undefined> { }
export interface INhapDiemImportLopError extends IActionTypeBase<eNhapDiemActionTypeIds.IMPORT_LOP_ERRROR, any> { }


export interface INhapDiemUpdateAllThanhPhanStart extends IActionTypeBase<eNhapDiemActionTypeIds.UPDATE_DIEM_ALL_THANHPHAN_START, IUpdateDiemHocSinhRequest> { }
export interface INhapDiemUpdateAllThanhPhanSuccess extends IActionTypeBase<eNhapDiemActionTypeIds.UPDATE_DIEM_ALL_THANHPHAN_SUCCESS, undefined> { }
export interface INhapDiemUpdateAllThanhPhanError extends IActionTypeBase<eNhapDiemActionTypeIds.UPDATE_DIEM_ALL_THANHPHAN_ERROR, any> { }

export type INhapDiemActionTypes = INhapDiemLoadHocSinhStart | INhapDiemLoadHocSinhSuccess | INhapDiemLoadHocSinhError |
    INhapDiemLoadDiemStart | INhapDiemLoadDiemSuccess | INhapDiemLoadDiemError |
    INhapDiemLoadDiemMoetStart | INhapDiemLoadDiemMoetSuccess | INhapDiemLoadDiemMoetError |
    INhapDiemChangeFilter |
    IShowMappingDiemBaiTapModal |
    INhapDiemLoadSoDiemInfoStart | INhapDiemLoadSoDiemInfoSuccess | INhapDiemLoadSoDiemInfoError |
    INhapDiemLoadSoDiemMoetInfoStart | INhapDiemLoadSoDiemMoetInfoSuccess | INhapDiemLoadSoDiemMoetInfoError |
    INhapDiemUpdateDiemStart | INhapDiemUpdateDiemSuccess | INhapDiemUpdateDiemError |
    INhapDiemShowCalculatorModal | INhapDiemCloseCalculatorModal |
    INhapDiemCalculateStart | INhapDiemCalculateSuccess | INhapDiemCalculateError |
    INhapDiemMappingStart | INhapDiemMappingSuccess | INhapDiemMappingError |
    INhapDIemChageViewMode |
    INhapDiemShowConvertMoetConfirm | INhapDiemCloseConvertMoetConfirm |
    INhapDiemConvertMoetStart | INhapDiemConvertMoetSuccess | INhapDiemConvertMoetError |
    INhapDiemValidateStart | INhapDiemValidateSuccess | INhapDiemValidateError |
    INhapDiemImportStart | INhapDiemImportSuccess | INhapDiemImportError |
    INhapDiemValidateLopStart | INhapDiemValidateLopSuccess | INhapDiemValidateLopError |
    INhapDiemImportLopStart | INhapDiemImportLopSuccess | INhapDiemImportLopError |
    INhapDiemUpdateAllThanhPhanStart | INhapDiemUpdateAllThanhPhanSuccess | INhapDiemUpdateAllThanhPhanError |
    INhapDiemLoadDiemChuyenDeStart | INhapDiemLoadDiemChuyenDeSuccess | INhapDiemLoadDiemChuyenDeError


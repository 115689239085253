import { ISoDiemAddThanhPhanRequest } from './../models/request/so-diem/ISoDiemAddThanhPhanRequest';
import { sis_sodiem_thanhphandiem } from "../models/response/so-diem/sis_sodiem_thanhphandiem";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";
import { ISoDiemRemoveThanhPhanRequest } from '../models/request/so-diem/ISoDiemRemoveThanhPhanRequest';

export const API_SO_DIEM_THANH_PHAN_PATH = 'so-diem-thanh-phan';

class SoDiemThanhPhanApi extends BaseCRUDApi<sis_sodiem_thanhphandiem>{
    constructor(path: string) {
        super(path)
    }
    SelectBySoDiem(sis_sodiem_id: number) {
        return apiClient.get(`${API_SO_DIEM_THANH_PHAN_PATH}/so-diem/${sis_sodiem_id}`)
    }
    AddThanhPhan(request: ISoDiemAddThanhPhanRequest) {
        return apiClient.post(`${API_SO_DIEM_THANH_PHAN_PATH}/add-thanh-phan`, request)
    }
    ReomveThanhPhan(request: ISoDiemRemoveThanhPhanRequest) {
        return apiClient.post(`${API_SO_DIEM_THANH_PHAN_PATH}/remove-thanh-phan`, request)
    }
    UpdateThanhPhan(request: sis_sodiem_thanhphandiem) {
        return apiClient.put(`${API_SO_DIEM_THANH_PHAN_PATH}`, request)
    }
}
export const soDiemThanhPhanApi = new SoDiemThanhPhanApi(API_SO_DIEM_THANH_PHAN_PATH)
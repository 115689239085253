import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eTietActionTypeIds, ITietActionTypes } from "../../action-types/danh-muc/ITietActionTypes"
import { ITietPageState } from "../../page-state-model/danh-muc/ITietPageState"

const initialState: ITietPageState = {
    status: ePageBaseStatus.is_not_initialization,
    pl_tiets: [],
    pl_tiet_selected_ids: [],
    pl_tiet_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const tietReducer = (state: ITietPageState = initialState, action: ITietActionTypes): ITietPageState => {
    switch (action.type) {
        case eTietActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eTietActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_tiets: action.payload
            }
        case eTietActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_tiets: []
            }
        case eTietActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                pl_tiet_editing: action.payload
            }
        case eTietActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                pl_tiet_editing: undefined
            }
        case eTietActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                pl_tiet_selected_ids: action.payload
            }
        case eTietActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eTietActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eTietActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eTietActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        case eTietActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eTietActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eTietActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }
        case eTietActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        default:
            return state;
    }

}
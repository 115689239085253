import { NotifyHelper } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eAppYeuCauHoTroActionTypeIds, IAppYeuCauHoTroActionTypes } from "../../action-types/app-phuhuynh/IAppYeuCauHoTroActionTypes";
import { IAppYeuCauHoTroPageState } from "../../page-state-model/app-phuhuynh/IAppYeuCauHoTroState";

const initialState: IAppYeuCauHoTroPageState = {
    status: ePageBaseStatus.is_not_initialization,
    app_yeucauhotros: [],
    app_yeucauhotro_detail: undefined,
    app_yeucauhotro_groups: [],
    app_yeucauhotrogroup_detail: undefined,
    is_show_delete_confirm: false, 
    is_show_detail_modal: false, 
    app_yeucauhotro_group_selected_ids: [], 
    app_yeucauhotro_group_editing: undefined
}

export const appYeuCauHoTroReducer = (state: IAppYeuCauHoTroPageState = initialState, action: IAppYeuCauHoTroActionTypes): IAppYeuCauHoTroPageState => {
    switch (action.type) {
        case eAppYeuCauHoTroActionTypeIds.LOAD_SELECT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppYeuCauHoTroActionTypeIds.LOAD_SELECT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_yeucauhotros: action.payload
            }
        case eAppYeuCauHoTroActionTypeIds.LOAD_SELECT_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_yeucauhotros: []
            }
        case eAppYeuCauHoTroActionTypeIds.LOAD_DETAIL_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppYeuCauHoTroActionTypeIds.LOAD_DETAIL_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_yeucauhotro_detail: action.payload
            }
        case eAppYeuCauHoTroActionTypeIds.LOAD_DETAIL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_yeucauhotro_detail: undefined
            }
        case eAppYeuCauHoTroActionTypeIds.UPDATE_PHANHOI_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eAppYeuCauHoTroActionTypeIds.UPDATE_PHANHOI_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eAppYeuCauHoTroActionTypeIds.UPDATE_PHANHOI_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_SUCCESS:
            console.log(action.payload);
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_yeucauhotro_groups: action.payload
            }
        case eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_yeucauhotro_groups: []
            }
            case eAppYeuCauHoTroActionTypeIds.SHOW_GROUP_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                app_yeucauhotro_group_editing: action.payload
            }
        case eAppYeuCauHoTroActionTypeIds.CLOSE_GROUP_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                app_yeucauhotro_group_editing: undefined
            }
        case eAppYeuCauHoTroActionTypeIds.CHANGE_GROUP_SELECTED_IDS:
            return {
                ...state,
                app_yeucauhotro_group_selected_ids: action.payload
            }
        case eAppYeuCauHoTroActionTypeIds.SHOW_GROUP_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eAppYeuCauHoTroActionTypeIds.CLOSE_GROUP_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eAppYeuCauHoTroActionTypeIds.DELETE_GROUP_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eAppYeuCauHoTroActionTypeIds.DELETE_GROUP_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eAppYeuCauHoTroActionTypeIds.DELETE_GROUP_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eAppYeuCauHoTroActionTypeIds.SAVE_GROUP_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eAppYeuCauHoTroActionTypeIds.SAVE_GROUP_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eAppYeuCauHoTroActionTypeIds.SAVE_GROUP_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }
        default:
            return state;
    }

}
import { formKhaoSatActions } from "./formKhaoSatActions";
import { formKhaoSatEditorActions } from "./formKhaoSatEditorActions";
import { formKhaoSatItemActions } from "./formKhaoSatItemActions";
import { KhaoSatActions } from "./khaoSatActions";

export const formKhaoSatActionsWrapper = {
    formKhaoSat: formKhaoSatActions,
    formKhaoSatItem: formKhaoSatItemActions,
    formKhaoSatEditor: formKhaoSatEditorActions,
    khaoSat: KhaoSatActions
}
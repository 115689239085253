import { ILopChuNhiemSelectRequest } from "../../../models/request/lop-chu-nhiem/ILopChuNhiemSelectRequest"
import { LopChuNhiemItemResponse } from "../../../models/response/lop-chu-nhiem/LopChuNhiemItemResponse"
import { IActionTypeBase } from "../IActionTypeBase"

export enum eLopChuNhiemActionTypeIds {
    SELECT_START = "LOPCHUNHIEM_SELECT_START",
    SELECT_SUCCESS = "LOPCHUNHIEM_SELECT_SUCCESS",
    SELECT_ERROR = "LOPCHUNHIEM_SELECT_ERROR",

    SHOW_PHANCONG_MODAL = "LOPCHUNHIEM_SHOW_PHANCONG_MODAL",
    CLOSE_PHANCONG_MODAL = "LOPCHUNHIEM_CLOSE_PHANCONG_MODAL",
}

export interface ILopChuNhiemSelectStart extends IActionTypeBase<eLopChuNhiemActionTypeIds.SELECT_START, ILopChuNhiemSelectRequest> { }
export interface ILopChuNhiemSelectSuccess extends IActionTypeBase<eLopChuNhiemActionTypeIds.SELECT_SUCCESS, LopChuNhiemItemResponse[]> { }
export interface ILopChuNhiemSelectError extends IActionTypeBase<eLopChuNhiemActionTypeIds.SELECT_ERROR, string> { }

export interface ILopChuNhiemShowPhanCongModal extends IActionTypeBase<eLopChuNhiemActionTypeIds.SHOW_PHANCONG_MODAL, undefined> { }
export interface ILopChuNhiemClosePhanCongModal extends IActionTypeBase<eLopChuNhiemActionTypeIds.CLOSE_PHANCONG_MODAL, undefined> { }

export type ILopChuNhiemActionTypes = ILopChuNhiemSelectStart | ILopChuNhiemSelectSuccess | ILopChuNhiemSelectError 
| ILopChuNhiemShowPhanCongModal | ILopChuNhiemClosePhanCongModal
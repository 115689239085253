import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from '../../../models/response/base-response';
import { actions } from '../../actions/actionsWrapper';
import { eBaiTapKetQuaActionTypeIds, IBaiTapKetQuaLoadStart, IBaiTapKetQuaSaveStart, IBaiTapKetQuaPublishMarkStart, IBaiTapKetQuaSendNotifyStart } from './../../action-types/bai-tap/IBaiTapKetQuaActionType';

const baiTapApi = apiWrapper.baiTapWrapper.baiTap;

export function* baiTapKetQuaSaga(): any {
    yield takeLatest(eBaiTapKetQuaActionTypeIds.LOAD_KETQUA_START, loadKetQuaWorker)
    yield takeEvery(eBaiTapKetQuaActionTypeIds.SAVE_KETQUA_START, saveKetQuaWorker)
    yield takeLatest(eBaiTapKetQuaActionTypeIds.PUBLISH_MARK_START, publishMarkWorker)
    yield takeLatest(eBaiTapKetQuaActionTypeIds.SEND_NOTIFY_START, sendNotifyWorker)
}



function* loadKetQuaWorker(action: IBaiTapKetQuaLoadStart): any {
    const res: IBaseResponeModel = yield call([baiTapApi, baiTapApi.SelectKetQua], action.payload)
    if (res.is_success) {
        yield put(actions.baiTapWrapper.baiTapKetQua.loadKetQuaSuccess(res.data))
    } else {
        yield put(actions.baiTapWrapper.baiTapKetQua.loadKetQuaError(res.message || ""))
    }
}


function* saveKetQuaWorker(action: IBaiTapKetQuaSaveStart): any {
    const res: IBaseResponeModel = yield call([baiTapApi, baiTapApi.UpdateKetQua], action.payload)
    if (res.is_success) {
        yield put(actions.baiTapWrapper.baiTapKetQua.saveKetQuaSuccess(res.data))
    } else {
        yield put(actions.baiTapWrapper.baiTapKetQua.saveKetQuaError(res.message || ""))
    }
}



function* publishMarkWorker(action: IBaiTapKetQuaPublishMarkStart): any {
    const res: IBaseResponeModel = yield call([baiTapApi, baiTapApi.PublishMark], action.payload)
    if (res.is_success) {
        yield put(actions.baiTapWrapper.baiTapKetQua.publishMarkSuccess())
    } else {
        yield put(actions.baiTapWrapper.baiTapKetQua.publishMarkError(res.message || ""))
    }
}

function* sendNotifyWorker(action: IBaiTapKetQuaSendNotifyStart): any {
    const res: IBaseResponeModel = yield call([baiTapApi, baiTapApi.SendNotify], action.payload)
    if (res.is_success) {
        yield put(actions.baiTapWrapper.baiTapKetQua.sendNotifySuccess())
    } else {
        yield put(actions.baiTapWrapper.baiTapKetQua.sendNotifyError(res.message || ""))
    }
}

import { NotifyHelper } from '../../../helpers/toast';
import { ILopHanhChinhUpdateSoDiemRequest } from '../../../models/request/dm-lop/ILopHanhChinhUpdateSoDiemRequest';
import { ILopBoMonSelectRequest } from '../../../models/request/lop-bo-mon/ILopBoMonSelectRequest';
import { ILopItemResponse } from '../../../models/response/lop/ILopItemResponse';
import { eLopHanhChinhSoDiemActionTypeIds } from '../../action-types/lop-hanh-chinh/ILopHanhChinhSoDiemActionTypes';
import { baseAction } from '../IActionBase';
export const lopHanhChinhSoDiemActions = {
    loadStart: (request: ILopBoMonSelectRequest) => baseAction(eLopHanhChinhSoDiemActionTypeIds.LOAD_START, request),
    loadSuccess: (data: ILopItemResponse[]) => baseAction(eLopHanhChinhSoDiemActionTypeIds.LOAD_SUCCESS, data),
    loadError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopHanhChinhSoDiemActionTypeIds.LOAD_ERROR, message)
    },

    changeSelectedIds: (ids: number[]) => baseAction(eLopHanhChinhSoDiemActionTypeIds.CHANGE_SELECTED_IDS, ids),



    showDgdkNlpcSelectionModal: (isOpen: boolean) => baseAction(eLopHanhChinhSoDiemActionTypeIds.SHOW_DGDK_NLPC_SELECTION_MODAL, isOpen),

    updateDgdkNlpcStart: (request: ILopHanhChinhUpdateSoDiemRequest) => baseAction(eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGDK_NLPC_START, request),
    updateDgdkNlpcSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGDK_NLPC_SUCCESS, undefined)
    },
    updateDgdkNlpcError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGDK_NLPC_ERROR, message)
    },
    showDgtxNlpcSelectionModal: (isOpen: boolean) => baseAction(eLopHanhChinhSoDiemActionTypeIds.SHOW_DGTX_NLPC_SELECTION_MODAL, isOpen),

    updateDgtxNlpcStart: (request: ILopHanhChinhUpdateSoDiemRequest) => baseAction(eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGTX_NLPC_START, request),
    updateDgtxNlpcSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGTX_NLPC_SUCCESS, undefined)
    },
    updateDgtxNlpcError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGTX_NLPC_ERROR, message)
    },
}
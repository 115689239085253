import { app_baiviet_trangthai } from "../../../models/response/app-phuhuynh/app_baiviet_trangthai"

export enum eBaiVietTrangThaiActionTypeIds {
    LOAD_START = "BAVIET_TRANGTHAI_LOAD_START",
    LOAD_SUCCESS = "BAVIET_TRANGTHAI_LOAD_SUCCESS",
    LOAD_ERROR = "BAVIET_TRANGTHAI_LOAD_ERROR"
}
export interface IBaiVietTrangThaiLoadStart {
    type: eBaiVietTrangThaiActionTypeIds.LOAD_START
}
export interface IBaiVietTrangThaiLoadSuccess {
    type: eBaiVietTrangThaiActionTypeIds.LOAD_SUCCESS,
    payload: app_baiviet_trangthai[]
}
export interface IBaiVietTrangThaiLoadError {
    type: eBaiVietTrangThaiActionTypeIds.LOAD_ERROR,
    payload: string
}
export type IBaiVietTrangThaiActionTypes = IBaiVietTrangThaiLoadStart | IBaiVietTrangThaiLoadSuccess | IBaiVietTrangThaiLoadError
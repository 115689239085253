import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../api/apiWrapper";
import { IBaseResponeModel } from "../../models/response/base-response";
import { eToKhoiActionTypeIds, IToKhoiDeleteStart, IToKhoiSaveStart } from "../action-types/IToKhoiActionTypes";
import { actions } from '../actions/actionsWrapper';

const toKhoiApi = apiWrapper.toKhoi;

export function* toKhoiSaga(): any {
    yield takeLatest(eToKhoiActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eToKhoiActionTypeIds.LOAD_SELECT_VIEW_START, loadSelectViewWorker)
    yield takeEvery(eToKhoiActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eToKhoiActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([toKhoiApi, toKhoiApi.SelectAll])
    if (res.is_success) {
        yield put(actions.toKhoi.loadToKhoiSuccess(res.data))
    } else {
        yield put(actions.toKhoi.loadToKhoiError(res.message || "Không tải được tổ khối"))
    }
}
function* loadSelectViewWorker(): any {

    const res: IBaseResponeModel = yield call([toKhoiApi, toKhoiApi.SelectAllView])
    if (res.is_success) {
        yield put(actions.toKhoi.loadToKhoiSelectViewSuccess(res.data))
    } else {
        yield put(actions.toKhoi.loadToKhoiSelectViewError(res.message || "Không tải được tổ khối"))
    }
}
function* deleteWorker(action: IToKhoiDeleteStart): any {
    const res: IBaseResponeModel = yield call([toKhoiApi, toKhoiApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.toKhoi.deleteToKhoiSuccess())
        yield put(actions.toKhoi.loadToKhoiSelectViewStart())
    } else {
        yield put(actions.toKhoi.deleteToKhoiErr(res.message || "Không tải được tổ khối"))
    }
}
function* saveWorker(action: IToKhoiSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([toKhoiApi, toKhoiApi.Update], action.payload);
    } else {
        res = yield call([toKhoiApi, toKhoiApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.toKhoi.saveToKhoiSuccess())
        yield put(actions.toKhoi.loadToKhoiSelectViewStart())
    } else {
        yield put(actions.toKhoi.saveToKhoiError(res.message || "Không lưu được tổ khối"))
    }
}


import { ChamTietCaNhanUpdateRequest } from "../models/request/cham-tiet/ChamTietCaNhanUpdateRequest";
import { ChamTietCaNhanSelectRequest } from "../models/request/cham-tiet/ChamTietCaNhanSelectRequest";
import { ChamTietQuanLySelectRequest } from "../models/request/cham-tiet/ChamTietQuanLySelectRequest";
import { sis_chamtiet } from "../models/response/cham-tiet/sis_chamtiet";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";
import { ChamTietDuyetRequest } from "../models/request/cham-tiet/ChamTietDuyetRequest";

export const API_CHAMTIET_PATH = "cham-tiet";

export const chamTietApi = new class ChamTietApi extends BaseCRUDApi<sis_chamtiet> {
    constructor(path: string) {
        super(path);
    }
    SelectAllTrangThaiDuyetAsync() {
        return apiClient.get(`${this._path}/dm-trangthai-duyet`);
    }
    SelectChamTietCaNhanAsync(request: ChamTietCaNhanSelectRequest) {
        return apiClient.post(`${this._path}/cham-tiet-ca-nhan`, request);
    }
    SelectChamTietQuanLyAsync(request: ChamTietQuanLySelectRequest) {
        return apiClient.post(`${this._path}/select`, request);
    }
    SelectChamTietDuyetAsync(request: ChamTietQuanLySelectRequest) {
        return apiClient.post(`${this._path}/select-danh-sach-duyet`, request);
    }
    ChangeTrangThaiAsync(request: ChamTietCaNhanUpdateRequest) {
        return apiClient.post(`${this._path}/update-trang-thai-multiple`, request);
    }
    ChamLaiAsync(ngay_cham: string) {
        return apiClient.post(`${this._path}/cham-lai`, { ngay_cham: ngay_cham });
    } 
    DuyetAsync(request: ChamTietDuyetRequest) {
        return apiClient.post(`${this._path}/update-trang-thai-duyet`, request);
    }
}(API_CHAMTIET_PATH);


import { sis_dm_nhanxet } from "../models/response/giao-vien-nhan-xet/sis_dm_nhanxet";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_GIAO_VIEN_NHAN_XET_PATH = "giao-vien-nhan-xet"

class GiaoVienNhanXetApi extends BaseCRUDApi<sis_dm_nhanxet> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
}
export const giaoVienNhanXetApi = new GiaoVienNhanXetApi({ path: API_GIAO_VIEN_NHAN_XET_PATH });


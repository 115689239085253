import { actions } from './../../actions/actionsWrapper';
import { put, takeEvery } from 'redux-saga/effects';
import { thangDiemApi } from './../../../api/thangDiemApi';
import { call } from 'redux-saga/effects';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { IThangDiemDeleteStart, IThangDiemSaveStart, eThangDiemActionTypeIds } from './../../action-types/so-diem/IThangDiemActions';
import { takeLatest } from 'redux-saga/effects';
export function* thangDiemSaga(): any {
    yield takeLatest(eThangDiemActionTypeIds.LOAD_START, loadWorker);
    yield takeEvery(eThangDiemActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eThangDiemActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {
    const res: IBaseResponeModel = yield call([thangDiemApi, thangDiemApi.SelectAll])
    if (res.is_success) {
        yield put(actions.soDiem.thangDiem.loadSuccess(res.data))
    } else {
        yield put(actions.soDiem.thangDiem.loadError(res.message || "Không tải được thang điểm"))

    }
}

function* deleteWorker(action: IThangDiemDeleteStart): any {
    const res: IBaseResponeModel = yield call([thangDiemApi, thangDiemApi.Delete], action.payload)
    if (res.is_success) {
        yield put(actions.soDiem.thangDiem.deleteThangDiemSuccess())
    } else {
        yield put(actions.soDiem.thangDiem.deleteThangDiemErr(res.message || "Err"))
    }
}
function* saveWorker(action: IThangDiemSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([thangDiemApi, thangDiemApi.Update], action.payload);
    } else {
        res = yield call([thangDiemApi, thangDiemApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.soDiem.thangDiem.saveThangDiemSuccess())
    } else {
        yield put(actions.soDiem.thangDiem.saveThangDiemError(res.message || "Err"))
    }
}

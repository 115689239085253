import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eLoaiPhongActionTypeIds, ILoaiPhongActionTypes } from "../../action-types/phong-hoc/ILoaiPhongActionTypes"
import { ILoaiPhongPageStateModel } from "../../page-state-model/phong-hoc/ILoaiPhongPageState"

const initialState: ILoaiPhongPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    pl_loaiphongs: [],
    is_show_delete_confirm: false,
    pl_loaiphong_selected_ids: [],
    pl_loaiphong_editing: undefined,
    is_show_detail_modal: false
}
export const loaiPhongReducer = (state: ILoaiPhongPageStateModel = initialState, action: ILoaiPhongActionTypes): ILoaiPhongPageStateModel => {
    switch (action.type) {
        case eLoaiPhongActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLoaiPhongActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_loaiphongs: action.payload
            }
        case eLoaiPhongActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_loaiphongs: []
            }
        case eLoaiPhongActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                pl_loaiphong_editing: action.payload
            }
        case eLoaiPhongActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                pl_loaiphong_editing: undefined
            }
        case eLoaiPhongActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                pl_loaiphong_selected_ids: action.payload
            }
        case eLoaiPhongActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eLoaiPhongActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        
            case eLoaiPhongActionTypeIds.DELETE_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_deleting
                }
            case eLoaiPhongActionTypeIds.DELETE_SUCCESS:
                NotifyHelper.Success("Xoá dữ liệu thành công.")
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }
            case eLoaiPhongActionTypeIds.DELETE_ERROR:
                NotifyHelper.Error(action.payload)
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }

        
            case eLoaiPhongActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLoaiPhongActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }
        case eLoaiPhongActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }

        default:
            return state;
    }

}

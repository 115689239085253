import { accountApi } from "./accountApi";
import { processAccountApi } from "./processAccountApi";
import { appAccountApi } from './app/appAccountApi';
import { appBaiVietApi } from './app/appBaiVietApi';
import { appBaiVietTrangThaiApi } from './app/appBaiVietTrangThaiApi';
import { appMenuApi } from './app/appMenuApi';
import { appMenuNhomApi } from './app/appMenuNhomApi';
import { appMenuTruongApi } from './app/appMenuTruongApi';
import { appXinNghiApi } from './app/appXinNghiApi';
import { baiTapApi } from './baiTapApi';
import { categorySourceApi } from "./categorySourceApi";
import { dgdkKetQuaHocTapApi } from "./dgdkKetQuaHocTapApi";
import { dgdkKetQuaNlpcApi } from './dgdkKetQuaNlpcApi';
import { dgtxKetQuaApi } from './dgtxKetQuaApi';
import { dgtxKetQuaHocTapApi } from './dgtxKetQuaHocTapApi';
import { dictionaryApi } from "./dictionaryApi";
import { giaoVienApi } from "./giaoVienApi";
import { giaoVienNhanXetApi } from './giaoVienNhanXetApi';
import { hocKyApi } from './hocKyApi';
import { hocSinhApi } from "./hocSinhApi";
import { loaiPhongApi } from './loaiPhongApi';
import { localizedReourceApi } from './localizedReourceApi';
import { lopApi } from "./lopApi";
import { lopBoMonApi } from "./lopBoMonApi";
import { lopBoMonHocSinhApi } from './LopBoMonHocSinhApi';
import { monHocApi } from "./monHocApi";
import { diemVanMinhKetQuaApi } from './diemVanMinhKetQuaApi';
import { diemVanMinhTieuChiApi } from './diemVanMinhTieuChiApi';
import { notifyApi } from './notifyApi';
import { phongHocApi } from './phongHocApi';
import { plNamHocApi } from './plNamHocApi';
import { soNhanXetApi } from './soNhanXetApi';
import { soNhanXetItemApi } from './soNhanXetItemApi';
import { thoiKhoaBieuApi } from './thoiKhoaBieuApi';
import { thoiKhoaBieuChiTietApi } from './thoiKhoaBieuChiTietApi';
import { thuApi } from './thuApi';
import { tietApi } from './tietApi';
import { toKhoiApi } from "./toKhoiApi";
import { tuanApi } from './tuanApi';
import { uploadApi } from './uploadApi';
import { diemDanhApi } from './diemDanhApi';
import { diemDanhAnApi } from './diemDanhAnApi';
import { diemDanhStatusApi } from './diemDanhStatusApi';
import { nhanXetHangNgayApi } from './nhanXetHangNgayApi';
import { baiTapTypeApi } from './baiTapTypeApi';
import { baiTapKetQuaApi } from './baiTapKetQuaApi';
import { lopChuNhiemApi } from './lopChuNhiemApi';
import { thaiDoHocTapApi } from './thaiDoHocTapApi';
import { thaiDoHocTapTieuChiApi } from './thaiDoHocTapTieuChiApi';
import { thongTuApi } from './thongTuApi';
import { tongHopDinhKyApi } from "./tongHopDinhKyApi";
import { appYeuCauHoTroApi } from "./app/appYeuCauHoTroApi";
import { dotDangKyApi } from "./dotDangKyApi";
import { dotDangKyMonHocApi } from "./dotDangKyMonHocApi";
import { dotDangKyNhomMonHocApi } from "./dotDangKyNhomMonHocApi";
import { dotDangKyKetQuaApi } from "./dotDangKyKetQuaApi";
import { dotDangKyKetQuaChiTietApi } from "./dotDangKyKetQuaChiTietApi";
import { formKhaoSatApi } from "./form-khaosat/formKhaoSatApi";
import { formKhaoSatEditorApi } from "./form-khaosat/formKhaoSatEditorApi";
import { formKhaoSatItemApi } from "./form-khaosat/formKhaoSatItemApi";
import { khaoSatApi } from "./form-khaosat/khaoSatApi";
import { appNhomBaiVietApi } from "./app/appNhomBaiVietApi";
import { hocSinhHoTroApi } from "./hocsinhHoTroApi";
import { mealApi } from "./mealApi";
import { lopBoMonTypeApi } from "./lopBoMonTypeApi";

export const apiWrapper = {
    account: accountApi,
    processAccount: processAccountApi,
    uploadFile: uploadApi,
    categorySource: categorySourceApi,
    lopBoMon: lopBoMonApi,
    lopBoMonHocSinh: lopBoMonHocSinhApi,
    dictionary: dictionaryApi,
    monHoc: monHocApi,
    lop: lopApi,
    lopChuNhiem: lopChuNhiemApi,
    hocSinhHoTro: hocSinhHoTroApi,
    hocSinh: hocSinhApi,
    giaoVien: giaoVienApi,
    toKhoi: toKhoiApi,
    soNhanXetItem: soNhanXetItemApi,
    soNhanXet: soNhanXetApi,
    giaoVienNhanXet: giaoVienNhanXetApi,
    loaiPhong: loaiPhongApi,
    phongHoc: phongHocApi,
    thoiKhoaBieu: thoiKhoaBieuApi,
    thoiKhoaBieuChiTietApi: thoiKhoaBieuChiTietApi,
    thu: thuApi,
    tuan: tuanApi,
    tiet: tietApi,
    plNamHoc: plNamHocApi,
    hocKy: hocKyApi,
    appMenu: appMenuApi,
    appMenuNhom: appMenuNhomApi,
    appMenuTruong: appMenuTruongApi,
    appAccount: appAccountApi,
    appBaiViet: appBaiVietApi,
    appBaiVietTrangThai: appBaiVietTrangThaiApi,
    appNhomBaiViet: appNhomBaiVietApi,
    appXinNghi: appXinNghiApi,
    appYeuCauHoTro: appYeuCauHoTroApi,
    notify: notifyApi,
    diemDanh: diemDanhApi,
    diemDanhAn: diemDanhAnApi,
    diemDanhStatus: diemDanhStatusApi,
    nhanXetHangNgay: nhanXetHangNgayApi,
    localizedResource: localizedReourceApi,
    baiTapWrapper: {
        type: baiTapTypeApi,
        baiTap: baiTapApi,
        baiTapKetQua: baiTapKetQuaApi
    },
    thaiDoHocTapWrapper: {
        thaiDoHocTap: thaiDoHocTapApi,
        thaiDoHocTapTieuChi: thaiDoHocTapTieuChiApi
    },
    thongTu: thongTuApi,
    diemVanMinhKetQua: diemVanMinhKetQuaApi,
    diemVanMinhTieuChi: diemVanMinhTieuChiApi,
    danhGia: {
        dinhKy: {
            nlpc: dgdkKetQuaNlpcApi,
            hocTap: dgdkKetQuaHocTapApi
        },
        thuongXuyen: {
            nlpc: dgtxKetQuaApi,
            hocTap: dgtxKetQuaHocTapApi
        }
    },
    tongHop:{
        dinhKy: tongHopDinhKyApi
    },
    dangKyMonHoc: {
        dotDangKy: dotDangKyApi,
        dotDangKyNhomMonHoc: dotDangKyNhomMonHocApi,
        dotDangKyMonHoc: dotDangKyMonHocApi,
        dotDangKyKetQua: dotDangKyKetQuaApi,
        dotDangKyKetQuaChiTiet: dotDangKyKetQuaChiTietApi
    },
    formKhaoSatWrapper: {
        formKhaoSat: formKhaoSatApi,
        formKhaoSatEditor: formKhaoSatEditorApi,
        formKhaoSatItem: formKhaoSatItemApi,
        khaoSat: khaoSatApi
    },
    meal: mealApi,
    lopBoMonType: lopBoMonTypeApi

}
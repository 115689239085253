import { sis_sodiem } from './../../../models/response/so-diem/sis_sodiem';
export enum eSoDiemListActionTypeIds {
    LOAD_START = "SODIEM_LIST_LOAD_START",
    LOAD_SUCCESS = "SODIEM_LIST_LOAD_SUCCESS",
    LOAD_ERROR = "SODIEM_LIST_LOAD_ERROR",
    CHANGE_EDITING_DATA = "SODIEM_LIST_CHANGE_EDITING_DATA",
    SHOW_DELETE_CONFIRM = "SODIEM_LIST_SHOW_CONFIRM",

    DELETE_START = "SODIEM_LIST_DELETE_START",
    DELETE_SUCCESS = "SODIEM_LIST_DELETE_SUCCESS",
    DELETE_ERROR = "SODIEM_LIST_DELETE_ERROR",

    SHOW_DETAIL_MODAL = "SODIEM_LIST_SHOW_DETAIL_MODAL",

    SAVE_START = "SODIEM_LIST_SAVE_START",
    SAVE_SUCCESS = "SODIEM_LIST_SAVE_SUCCESS",
    SAVE_ERROR = "SODIEM_LIST_SAVE_ERROR"

}
export interface ISoDiemListLoadStart {
    type: eSoDiemListActionTypeIds.LOAD_START,
    payload: number
}
export interface ISoDiemListLoadSuccess {
    type: eSoDiemListActionTypeIds.LOAD_SUCCESS,
    payload: sis_sodiem[]
}
export interface ISoDiemListLoadError {
    type: eSoDiemListActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface ISoDiemListChangeEditingData {
    type: eSoDiemListActionTypeIds.CHANGE_EDITING_DATA,
    payload?: sis_sodiem
}
export interface ISoDiemListShowDeleteConfirm {
    type: eSoDiemListActionTypeIds.SHOW_DELETE_CONFIRM,
    payload: boolean
}
export interface ISoDiemListDeleteStart {
    type: eSoDiemListActionTypeIds.DELETE_START,
    payload: number
}
export interface ISoDiemListDeleteSuccess {
    type: eSoDiemListActionTypeIds.DELETE_SUCCESS
}

export interface ISoDiemListDeleteError {
    type: eSoDiemListActionTypeIds.DELETE_ERROR,
    payload: string
}

export interface ISoDiemListShowDetailModal {
    type: eSoDiemListActionTypeIds.SHOW_DETAIL_MODAL,
    payload: boolean
}

export interface ISoDiemListSaveStart {
    type: eSoDiemListActionTypeIds.SAVE_START,
    payload: sis_sodiem
}
export interface ISoDiemListSaveSuccess {
    type: eSoDiemListActionTypeIds.SAVE_SUCCESS
}
export interface ISoDiemListSaveError {
    type: eSoDiemListActionTypeIds.SAVE_ERROR,
    payload: string
}

export type ISoDiemListActionTypes = ISoDiemListLoadStart | ISoDiemListLoadSuccess | ISoDiemListLoadError |
    ISoDiemListChangeEditingData | ISoDiemListShowDeleteConfirm |
    ISoDiemListDeleteStart | ISoDiemListDeleteSuccess | ISoDiemListDeleteError |
    ISoDiemListShowDetailModal |
    ISoDiemListSaveStart | ISoDiemListSaveSuccess | ISoDiemListSaveError
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { pl_loaiphong } from "../../../models/response/phong-hoc/pl_loaiphong"


export enum eLoaiPhongActionTypeIds {
    LOAD_START = "LOAI_PHONG_LOAD_START",
    LOAD_SUCCESS = "LOAI_PHONG_LOAD_SUCCESS",
    LOAD_ERROR = "LOAI_PHONG_LOAD_ERROR",

    SHOW_DETAIL = "LOAI_PHONG_SHOW_DETAIL",
    CLOSE_DETAIL = "LOAI_PHONG_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "LOAI_PHONG_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "LOAI_PHONG_SHOW_CONFIRM",
    CLOSE_CONFIRM = "LOAI_PHONG_CLOSE_CONFIRM",

    DELETE_START = "LOAI_PHONG_DELETE_START",
    DELETE_SUCCESS = "LOAI_PHONG_DELETE_SUCCESS",
    DELETE_ERROR = "LOAI_PHONG_DELETE_ERROR",

    SAVE_START = "LOAI_PHONG_SAVE_START",
    SAVE_SUCCESS = "LOAI_PHONG_SAVE_SUCCESS",
    SAVE_ERR = "LOAI_PHONG_SAVE_ERR"
}

export interface ILoaiPhongLoadStart {
    type: eLoaiPhongActionTypeIds.LOAD_START,
}
export interface ILoaiPhongLoadSuccess {
    type: eLoaiPhongActionTypeIds.LOAD_SUCCESS,
    payload: pl_loaiphong[]
}
export interface ILoaiPhongLoadErr {
    type: eLoaiPhongActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface ILoaiPhongShowDetail {
    type: eLoaiPhongActionTypeIds.SHOW_DETAIL,
    payload?: pl_loaiphong
}
export interface ILoaiPhongCloseDetail {
    type: eLoaiPhongActionTypeIds.CLOSE_DETAIL,
}
export interface ILoaiPhongChangeSelectedIds {
    type: eLoaiPhongActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface ILoaiPhongShowConfirm {
    type: eLoaiPhongActionTypeIds.SHOW_CONFIRM
}
export interface ILoaiPhongCloseConfirm {
    type: eLoaiPhongActionTypeIds.CLOSE_CONFIRM
}

export interface ILoaiPhongDeleteStart {
    type: eLoaiPhongActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface ILoaiPhongDeleteSuccess {
    type: eLoaiPhongActionTypeIds.DELETE_SUCCESS,
}
export interface ILoaiPhongDeleteErr {
    type: eLoaiPhongActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface ILoaiPhongSaveStart {
    type: eLoaiPhongActionTypeIds.SAVE_START,
    payload: pl_loaiphong
}
export interface ILoaiPhongSaveSuccess {
    type: eLoaiPhongActionTypeIds.SAVE_SUCCESS
}
export interface ILoaiPhongSaveErr {
    type: eLoaiPhongActionTypeIds.SAVE_ERR,
    payload: string
}
export type ILoaiPhongActionTypes = ILoaiPhongLoadStart | ILoaiPhongLoadSuccess | ILoaiPhongLoadErr |
    ILoaiPhongShowDetail | ILoaiPhongCloseDetail |
    ILoaiPhongChangeSelectedIds |
    ILoaiPhongShowConfirm | ILoaiPhongCloseConfirm |
    ILoaiPhongDeleteStart | ILoaiPhongDeleteSuccess | ILoaiPhongDeleteErr |
    ILoaiPhongSaveStart | ILoaiPhongSaveSuccess | ILoaiPhongSaveErr
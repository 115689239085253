import { IAppXinNghiChangePhanHoiRequest } from "../../../models/request/app-phuhuynh/AppXinNghiChangePhanHoiRequest";
import { IAppXinNghiChangeStatusRequest } from "../../../models/request/app-phuhuynh/AppXinNghiChangeStatusRequest";
import { IAppXinNghiSelectRequest } from "../../../models/request/app-phuhuynh/AppXinNghiSelectRequest";
import { app_xinnghi_view } from "../../../models/response/app-phuhuynh/app_xinnghi";
import { eAppXinNghiActionTypeIds, IAppXinNghiLoadDetailErr, IAppXinNghiLoadDetailStart, IAppXinNghiLoadDetailSuccess, IAppXinNghiLoadErr, IAppXinNghiLoadSelectErr, IAppXinNghiLoadSelectStart, IAppXinNghiLoadSelectSuccess, IAppXinNghiLoadStart, IAppXinNghiLoadSuccess, IAppXinNghiUpdatePhanHoiErr, IAppXinNghiUpdatePhanHoiStart, IAppXinNghiUpdatePhanHoiSuccess, IAppXinNghiUpdateStatusErr, IAppXinNghiUpdateStatusStart, IAppXinNghiUpdateStatusSuccess } from "../../action-types/app-phuhuynh/IAppXinNghiActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const appXinNghiActions = {
    loadStart: (): IAppXinNghiLoadStart => BaseAction(undefined, eAppXinNghiActionTypeIds.LOAD_START),
    loadSuccess: (respone: app_xinnghi_view[]): IAppXinNghiLoadSuccess => BaseAction(respone, eAppXinNghiActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IAppXinNghiLoadErr => BaseAction(messege, eAppXinNghiActionTypeIds.LOAD_ERROR),

    loadSelectStart: (request: IAppXinNghiSelectRequest): IAppXinNghiLoadSelectStart => BaseAction(request, eAppXinNghiActionTypeIds.LOAD_SELECT_START),
    loadSelectSuccess: (respone: app_xinnghi_view[]): IAppXinNghiLoadSelectSuccess => BaseAction(respone, eAppXinNghiActionTypeIds.LOAD_SELECT_SUCCESS),
    loadSelectError: (messege: string): IAppXinNghiLoadSelectErr => BaseAction(messege, eAppXinNghiActionTypeIds.LOAD_SELECT_ERROR),

    loadDetailStart: (id: number): IAppXinNghiLoadDetailStart => BaseAction(id, eAppXinNghiActionTypeIds.LOAD_DETAIL_START),
    loadDetailSuccess: (respone: app_xinnghi_view): IAppXinNghiLoadDetailSuccess => BaseAction(respone, eAppXinNghiActionTypeIds.LOAD_DETAIL_SUCCESS),
    loadDetailError: (messege: string): IAppXinNghiLoadDetailErr => BaseAction(messege, eAppXinNghiActionTypeIds.LOAD_DETAIL_ERROR),

    updateStatusStart: (data: IAppXinNghiChangeStatusRequest): IAppXinNghiUpdateStatusStart => BaseAction(data, eAppXinNghiActionTypeIds.UPDATE_STATUS_START),
    updateStatusSuccess: (): IAppXinNghiUpdateStatusSuccess => BaseAction(undefined, eAppXinNghiActionTypeIds.UPDATE_STATUS_SUCCESS),
    updateStatusError: (message: string): IAppXinNghiUpdateStatusErr => BaseAction(message, eAppXinNghiActionTypeIds.UPDATE_STATUS_ERROR),

    updatePhanHoiStart: (data: IAppXinNghiChangePhanHoiRequest): IAppXinNghiUpdatePhanHoiStart => BaseAction(data, eAppXinNghiActionTypeIds.UPDATE_PHANHOI_START),
    updatePhanHoiSuccess: (): IAppXinNghiUpdatePhanHoiSuccess => BaseAction(undefined, eAppXinNghiActionTypeIds.UPDATE_PHANHOI_SUCCESS),
    updatePhanHoiError: (message: string): IAppXinNghiUpdatePhanHoiErr => BaseAction(message, eAppXinNghiActionTypeIds.UPDATE_PHANHOI_ERROR),
}
import { ITuanGenerateRequest } from "../models/request/category/ITuanGenerateRequest";
import { IPLTuanSelectRequest } from "../models/request/danh-muc/IPLTuanSelectRequest";
import { pl_tuan } from "../models/response/danh-muc/pl_tuan";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_TUAN_PATH = "tuan"
class TuanApi extends BaseCRUDApi<pl_tuan> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    Select(request: IPLTuanSelectRequest) {
        return apiClient.post(`${this._path}/select`, request);
    }
    Generate(request: ITuanGenerateRequest){
        return apiClient.post(`${this._path}/generate`, request);
    }
}
export const tuanApi = new TuanApi({ path: API_TUAN_PATH });


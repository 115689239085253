import { NotifyHelper, showNotify } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eMonHocActionTypeIds, IMonHocActionTypes } from "../../action-types/IMonHocActionTypes";
import { IMonHocPageStateModel } from "../../page-state-model/IMonHocPageState";

const initialState: IMonHocPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    sis_monhocs: [],
    is_show_delete_confirm: false,
    sis_monhoc_selected_ids: [],
    sis_monhoc_editing: undefined,
    is_show_detail_modal: false
}
export const monHocReducer = (state: IMonHocPageStateModel = initialState, action: IMonHocActionTypes): IMonHocPageStateModel => {
    switch (action.type) {
        case eMonHocActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eMonHocActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_monhocs: action.payload
            }
        case eMonHocActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_monhocs: []
            }
        case eMonHocActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_monhoc_editing: action.payload
            }
        case eMonHocActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_monhoc_editing: undefined
            }
        case eMonHocActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_monhoc_selected_ids: action.payload
            }
        case eMonHocActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eMonHocActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eMonHocActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eMonHocActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eMonHocActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eMonHocActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eMonHocActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eMonHocActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        default:
            return state;
    }

}

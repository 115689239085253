import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_form_khaosat_editor } from "../../../models/response/form-khaosat/sis_form_khaosat_editor";
import { sis_form_khaosat_editor_type } from "../../../models/response/form-khaosat/sis_form_khaosat_editor_type";
import {
    IFormKhaoSatEditorChangeDetail,
    IFormKhaoSatEditorChangeSelectedIds,
    IFormKhaoSatEditorDeleteErr, IFormKhaoSatEditorDeleteStart, IFormKhaoSatEditorDeleteSuccess,
    IFormKhaoSatEditorLoadByFormErr, IFormKhaoSatEditorLoadByFormStart, IFormKhaoSatEditorLoadByFormSuccess, IFormKhaoSatEditorLoadEditorTypeErr, IFormKhaoSatEditorLoadEditorTypeStart, IFormKhaoSatEditorLoadEditorTypeSuccess, IFormKhaoSatEditorLoadErr,
    IFormKhaoSatEditorLoadStart, IFormKhaoSatEditorLoadSuccess, IFormKhaoSatEditorShowConfirm,
    IFormKhaoSatEditorShowModal,
    eFormKhaoSatEditorActionTypeIds
} from "../../action-types/form-khaosat/IFormKhaoSatEditorActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const formKhaoSatEditorActions = {
    //ALL
    loadStart: (): IFormKhaoSatEditorLoadStart => BaseAction(undefined, eFormKhaoSatEditorActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_form_khaosat_editor[]): IFormKhaoSatEditorLoadSuccess => BaseAction(respone, eFormKhaoSatEditorActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IFormKhaoSatEditorLoadErr => BaseAction(messege, eFormKhaoSatEditorActionTypeIds.LOAD_ERROR),
    //By truong
    loadByFormStart: (dm_truong_id: number): IFormKhaoSatEditorLoadByFormStart => BaseAction({ dm_truong_id }, eFormKhaoSatEditorActionTypeIds.LOAD_BYFORM_START),
    loadByFormSuccess: (respone: sis_form_khaosat_editor[]): IFormKhaoSatEditorLoadByFormSuccess => BaseAction(respone, eFormKhaoSatEditorActionTypeIds.LOAD_BYFORM_SUCCESS),
    loadByFormError: (messege: string): IFormKhaoSatEditorLoadByFormErr => BaseAction(messege, eFormKhaoSatEditorActionTypeIds.LOAD_BYFORM_ERROR),

    loadEdItorTypeStart: (): IFormKhaoSatEditorLoadEditorTypeStart => BaseAction(undefined, eFormKhaoSatEditorActionTypeIds.LOAD_EDITORTYPE_START),
    loadEdItorTypeSuccess: (respone: sis_form_khaosat_editor_type[]): IFormKhaoSatEditorLoadEditorTypeSuccess => BaseAction(respone, eFormKhaoSatEditorActionTypeIds.LOAD_EDITORTYPE_SUCCESS),
    loadEdItorTypeError: (messege: string): IFormKhaoSatEditorLoadEditorTypeErr => BaseAction(messege, eFormKhaoSatEditorActionTypeIds.LOAD_EDITORTYPE_ERROR),
    //not in truong
    showModal: (show: boolean): IFormKhaoSatEditorShowModal => BaseAction(show, eFormKhaoSatEditorActionTypeIds.SHOW_MODAL),
    changeDetail: (data?: sis_form_khaosat_editor): IFormKhaoSatEditorChangeDetail => BaseAction(data, eFormKhaoSatEditorActionTypeIds.CHANGE_DETAIL),
    showConfirm: (show: boolean): IFormKhaoSatEditorShowConfirm => BaseAction(show, eFormKhaoSatEditorActionTypeIds.SHOW_CONFIRM),
    changeSelectedIds: (ids: number[]): IFormKhaoSatEditorChangeSelectedIds => BaseAction(ids, eFormKhaoSatEditorActionTypeIds.CHANGE_SELECTED_IDS),
    //delete
    deleteStart: (ids: IDeleteMultipleBaseRequest): IFormKhaoSatEditorDeleteStart => BaseAction(ids, eFormKhaoSatEditorActionTypeIds.DELETE_START),
    deleteSuccess: (): IFormKhaoSatEditorDeleteSuccess => BaseAction(undefined, eFormKhaoSatEditorActionTypeIds.DELETE_SUCCESS),
    deleteErr: (message: string): IFormKhaoSatEditorDeleteErr => BaseAction(message, eFormKhaoSatEditorActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: sis_form_khaosat_editor) => BaseAction(data, eFormKhaoSatEditorActionTypeIds.SAVE_START),
    saveSuccess: () => BaseAction(undefined, eFormKhaoSatEditorActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string) => BaseAction(message, eFormKhaoSatEditorActionTypeIds.SAVE_ERR),

    updateSelectOptionsStart: (data: { sis_form_khaosat_editor_id: number, select_options: string }) => BaseAction(data, eFormKhaoSatEditorActionTypeIds.UPDATE_SELECT_OPTIONS_START),
    updateselectoptionsSuccess: () => BaseAction(undefined, eFormKhaoSatEditorActionTypeIds.UPDATE_SELECT_OPTIONS_SUCCESS),
    updateselectoptionsError: (message: string) => BaseAction(message, eFormKhaoSatEditorActionTypeIds.UPDATE_SELECT_OPTIONS_ERR),

    clearStatus: () => BaseAction(undefined, eFormKhaoSatEditorActionTypeIds.CLEAR_STATUS)
}



import { lopMonHocApi } from './../../../api/lopMonHocApi';
import { ILopMonHocCopyStart, ILopMonHocCreateLopBoMonStart, ILopMonHocLoadLopStart, ILopMonHocLoadStart, ILopMonHocSavetart } from './../../action-types/lop-mon-hoc/ILopMonHocActionTypes';
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { eLopMonHocActionTypIds } from "../../action-types/lop-mon-hoc/ILopMonHocActionTypes";
import { apiWrapper } from '../../../api/apiWrapper';
import { IBaseResponeModel } from '../../../models/response/base-response';
import { actions } from '../../actions/actionsWrapper';
import { lopBoMonApi } from '../../../api/lopBoMonApi';


//watcher
export default function* lopMonHocSaga(): any {
    yield takeLatest(eLopMonHocActionTypIds.LOAD_LOP_START, loadLopWorker)
    yield takeLatest(eLopMonHocActionTypIds.LOAD_LOP_MONHOC_START, loadLopMonHocWorker)
    yield takeEvery(eLopMonHocActionTypIds.SAVE_START, saveWorker)
    yield takeEvery(eLopMonHocActionTypIds.COPY_START, copyWorker)
    yield takeEvery(eLopMonHocActionTypIds.CREATE_LOPBOMON_START, createLopBoMonWorker)
}


//workers
function* loadLopWorker(data: ILopMonHocLoadLopStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.Select], data.payload)
    if (res.is_success) {
        yield put(actions.lopMonHoc.loadLopSuccess(res.data))
    } else {
        yield put(actions.lopMonHoc.loadLopError(res.message ?? "Error"))
    }

}
function* loadLopMonHocWorker(data: ILopMonHocLoadStart): any {
    const res: IBaseResponeModel = yield call(lopMonHocApi.Select, data.payload)
    if (res.is_success) {
        yield put(actions.lopMonHoc.loadSuccess(res.data))
    } else {
        yield put(actions.lopMonHoc.loadError(res.message ?? "Error"))
    }

}

function* saveWorker(data: ILopMonHocSavetart): any {
    const res: IBaseResponeModel = yield call(lopMonHocApi.SaveChange, data.payload)
    if (res.is_success) {
        yield put(actions.lopMonHoc.saveSuccess(res.data))
    } else {
        yield put(actions.lopMonHoc.saveError(res.message ?? "Error"))
    }

}

function* copyWorker(data: ILopMonHocCopyStart): any {
    const res: IBaseResponeModel = yield call(lopMonHocApi.Copy, data.payload)
    if (res.is_success) {
        yield put(actions.lopMonHoc.copySuccess())
    } else {
        yield put(actions.lopMonHoc.copyError(res.message ?? "Error"))
    }

}


function* createLopBoMonWorker(data: ILopMonHocCreateLopBoMonStart): any {
    const res: IBaseResponeModel = yield call([lopBoMonApi, lopBoMonApi.GenerateFromLopMonHoc], data.payload)
    if (res.is_success) {
        yield put(actions.lopMonHoc.createLopBoMonSuccess())
    } else {
        yield put(actions.lopMonHoc.createLopBoMonError(res.message ?? "Error"))
    }

}

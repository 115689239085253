import { sis_thanhphandiem } from './../models/response/so-diem/sis_thanhphandiem';
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_THANH_PHAN_DIEM_PATH = 'thanh-phan-diem';

class ThanhPhanDiemApi extends BaseCRUDApi<sis_thanhphandiem>{
    constructor(path: string) {
        super(path)
    }
}
export const thanhPhanDiemApi = new ThanhPhanDiemApi(API_THANH_PHAN_DIEM_PATH)
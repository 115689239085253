import { NotifyHelper, showNotify } from '../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eFormKhaoSatItemActionTypeIds, IFormKhaoSatItemActionTypes } from "../../action-types/form-khaosat/IFormKhaoSatItemActionTypes";
import { IFormKhaoSatItemPageStateModel } from "../../page-state-model/form-khaosat/IFormKhaoSatItemState";

const initialState: IFormKhaoSatItemPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    sis_form_khaosat_items: [],
    sis_form_khaosat_item_selected_ids: [],
    sis_form_khaosat_item_editing: undefined,
    is_show_confirm: false,
    is_show_modal: false
}
export const formKhaoSatItemReducer = (state: IFormKhaoSatItemPageStateModel = initialState, action: IFormKhaoSatItemActionTypes): IFormKhaoSatItemPageStateModel => {
    switch (action.type) {
        case eFormKhaoSatItemActionTypeIds.LOAD_START:
        case eFormKhaoSatItemActionTypeIds.LOAD_BYFORM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eFormKhaoSatItemActionTypeIds.LOAD_SUCCESS:
        case eFormKhaoSatItemActionTypeIds.LOAD_BYFORM_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_form_khaosat_items: action.payload
            }
        case eFormKhaoSatItemActionTypeIds.LOAD_ERROR:
        case eFormKhaoSatItemActionTypeIds.LOAD_BYFORM_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_form_khaosat_items: []
            }
        case eFormKhaoSatItemActionTypeIds.CHANGE_DETAIL:
            return {
                ...state,
                sis_form_khaosat_item_editing: action.payload
            }
        case eFormKhaoSatItemActionTypeIds.SHOW_MODAL:
            return {
                ...state,
                is_show_modal: action.payload,
                sis_form_khaosat_item_selected_ids: []
            }
        case eFormKhaoSatItemActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_form_khaosat_item_selected_ids: action.payload
            }
        case eFormKhaoSatItemActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_confirm: true
            }
        case eFormKhaoSatItemActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eFormKhaoSatItemActionTypeIds.DELETE_SUCCESS:
            showNotify({ type: "success", message: 'Xóa dữ liệu thành công.' })
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_confirm: false
            }
        case eFormKhaoSatItemActionTypeIds.DELETE_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_confirm: false
            }


        case eFormKhaoSatItemActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eFormKhaoSatItemActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_modal: false
            }
        case eFormKhaoSatItemActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_modal: false
            }
        case eFormKhaoSatItemActionTypeIds.ADDITEMS_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eFormKhaoSatItemActionTypeIds.ADDITEMS_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_modal: false
            }
        case eFormKhaoSatItemActionTypeIds.ADDITEMS_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_modal: false
            }
        case eFormKhaoSatItemActionTypeIds.ADDEDITOR_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eFormKhaoSatItemActionTypeIds.ADDEDITOR_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_modal: false
            }
        case eFormKhaoSatItemActionTypeIds.ADDEDITOR_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_modal: false
            }
        case eFormKhaoSatItemActionTypeIds.UPDATEIDX_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eFormKhaoSatItemActionTypeIds.UPDATEIDX_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eFormKhaoSatItemActionTypeIds.UPDATEIDX_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eFormKhaoSatItemActionTypeIds.CLEAR_STATUS:
            return {
                ...initialState
            }
        default:
            return state;
    }

}

import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eXepHangHocSinhActionTypeIds, IXepHangHocSinhActionTypes } from './../../action-types/xep-hang-hoc-sinh/IXepHangHocSinhActionTypes';
import { IXepHangHocSinhPageState } from './../../page-state-model/xep-hang-hoc-sinh/IXepHangHocSinhPageState';
const iniState: IXepHangHocSinhPageState = {
    filter: {
        dm_khoi_id: 0,
        dm_lop_id: 0,
        dm_truong_id: 0,
        nam_hoc: "",
        sis_giaidoan_id: 0,
        hoc_ky: 0
    },
    hocSinhs: [],
    monHocs: [],
    ketQuas: [],
    diems: [],
    isShowConfirm: false,
    loading: {
        hocSinhs: "",
        monHocs: "",
        ketQuas: "",
        diems: ""
    },
    status: ePageBaseStatus.is_not_initialization
}
export const xepHangHocSinhReducer = (state: IXepHangHocSinhPageState = iniState, action: IXepHangHocSinhActionTypes): IXepHangHocSinhPageState => {
    switch (action.type) {
        case eXepHangHocSinhActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case eXepHangHocSinhActionTypeIds.HOCSINH_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinhs: "isLoading"
                }
            }
        case eXepHangHocSinhActionTypeIds.HOCSINH_LOAD_SUCCESS:
            return {
                ...state,
                hocSinhs: action.payload,
                loading: {
                    ...state.loading,
                    hocSinhs: ""
                }
            }
        case eXepHangHocSinhActionTypeIds.HOCSINH_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinhs: ""
                }
            }
        case eXepHangHocSinhActionTypeIds.MONHOC_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    monHocs: "isLoading"
                }
            }
        case eXepHangHocSinhActionTypeIds.MONHOC_LOAD_SUCCESS:
            return {
                ...state,
                monHocs: action.payload,
                loading: {
                    ...state.loading,
                    monHocs: ""
                }
            }
        case eXepHangHocSinhActionTypeIds.MONHOC_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    monHocs: ""
                }
            }
        case eXepHangHocSinhActionTypeIds.SHOW_XEPHANG_CONFIRM:
            return {
                ...state,
                isShowConfirm: action.payload
            }
        case eXepHangHocSinhActionTypeIds.XEPHANG_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eXepHangHocSinhActionTypeIds.XEPHANG_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                isShowConfirm: false,
                ketQuas: action.payload

            }
        case eXepHangHocSinhActionTypeIds.XEPHANG_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eXepHangHocSinhActionTypeIds.KETQUA_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: "isLoading"
                }
            }
        case eXepHangHocSinhActionTypeIds.KETQUA_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
                ketQuas: action.payload

            }
        case eXepHangHocSinhActionTypeIds.KETQUA_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
            }
        case eXepHangHocSinhActionTypeIds.DIEM_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    diems: "isLoading"
                }
            }
        case eXepHangHocSinhActionTypeIds.DIEM_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    diems: ""
                },
                diems: action.payload

            }
        case eXepHangHocSinhActionTypeIds.DIEM_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    diems: ""
                },
            }

        default:
            return {
                ...state
            }
    }
}
import { IReadUploadedExcelFileRequest } from "../../../models/request/upload-file/IReadUploadedExcelFileRequest"
import { IReadExcelResult } from "../../../models/response/upload-file/IReadExcelResult"
import { IUploadExcelResult } from "../../../models/response/upload-file/IUploadExcelResult"
import { IUploadImageResult } from "../../../models/response/upload-file/IUploadImageResult"

export enum eUploadActionTypeIds {
    UPLOAD_IMAGE_START = "UPLOAD_IMAGE_START",
    UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS",
    UPLOAD_IMAGE_ERROR = "UPLOAD_IMAGE_ERROR",
    UPLOAD_IMAGE_COMPLETE = "UPLOAD_IMAGE_COMPLETE",

    UPLOAD_SVG_START = "UPLOAD_SVG_START",
    UPLOAD_SVG_SUCCESS = "UPLOAD_SVG_SUCCESS",
    UPLOAD_SVG_ERROR = "UPLOAD_SVG_ERROR",
    UPLOAD_SVG_COMPLETE = "UPLOAD_SVG_COMPLETE",

    UPLOAD_EXCEL_START = "UPLOAD_EXCEL_START",
    UPLOAD_EXCEL_SUCCESS = "UPLOAD_EXCEL_SUCCESS",
    UPLOAD_EXCEL_ERROR = "UPLOAD_EXCEL_ERROR",

    READ_EXCEL_START = "READ_EXCEL_START",
    READ_EXCEL_SUCCESS = "READ_EXCEL_SUCCESS",
    READ_EXCEL_ERROR = "READ_EXCEL_ERROR",
}

export interface IUploadExcelStartAction {
    type: eUploadActionTypeIds.UPLOAD_EXCEL_START,
    payload: File
}
export interface IUploadExcelSuccessAction {
    type: eUploadActionTypeIds.UPLOAD_EXCEL_SUCCESS,
    payload: IUploadExcelResult
}
export interface IUploadExcelErrorAction {
    type: eUploadActionTypeIds.UPLOAD_EXCEL_ERROR,
    payload: string
}

export interface IUploadSvgStartAction {
    type: eUploadActionTypeIds.UPLOAD_SVG_START,
    payload: File
}
export interface IUploadSvgSuccessAction {
    type: eUploadActionTypeIds.UPLOAD_SVG_SUCCESS,
    payload?: IUploadImageResult
}
export interface IUploadSvgErrorAction {
    type: eUploadActionTypeIds.UPLOAD_SVG_ERROR,
    payload: string
}

export interface IUploadSvgCompleteAction {
    type: eUploadActionTypeIds.UPLOAD_SVG_COMPLETE,
}

export interface IUploadImageStartAction {
    type: eUploadActionTypeIds.UPLOAD_IMAGE_START,
    payload: File
}
export interface IUploadImageSuccessAction {
    type: eUploadActionTypeIds.UPLOAD_IMAGE_SUCCESS,
    payload?: IUploadImageResult
}
export interface IUploadImageErrorAction {
    type: eUploadActionTypeIds.UPLOAD_IMAGE_ERROR,
    payload: string
}

export interface IUploadImageCompleteAction {
    type: eUploadActionTypeIds.UPLOAD_IMAGE_COMPLETE,
}


export interface IReadExcelStartAction {
    type: eUploadActionTypeIds.READ_EXCEL_START,
    payload: IReadUploadedExcelFileRequest
}
export interface IReadExcelSuccessAction {
    type: eUploadActionTypeIds.READ_EXCEL_SUCCESS,
    payload: IReadExcelResult
}
export interface IReadExcelErrorAction {
    type: eUploadActionTypeIds.READ_EXCEL_ERROR,
    payload: string
}

export type IUploadActionTypes = IUploadExcelStartAction | IUploadExcelSuccessAction | IUploadExcelErrorAction | 
IUploadSvgStartAction | IUploadSvgSuccessAction | IUploadSvgErrorAction | IUploadSvgCompleteAction |
IUploadImageStartAction | IUploadImageSuccessAction | IUploadImageErrorAction | IUploadImageCompleteAction |
IReadExcelStartAction | IReadExcelSuccessAction | IReadExcelErrorAction;
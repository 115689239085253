import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { ePLNamHocActionTypeIds, IPLNamHocDeleteStart, IPLNamHocSaveStart } from "../../action-types/danh-muc/IPLNamHocActionTypes";
import { actions } from "../../actions/actionsWrapper";

const plNamHocApi = apiWrapper.plNamHoc;

export function* plNamHocSaga(): any {
    yield takeLatest(ePLNamHocActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(ePLNamHocActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(ePLNamHocActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([plNamHocApi, plNamHocApi.SelectAll])
    if (res.is_success) {
        yield put(actions.danhMucWrapper.namHoc.loadSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.namHoc.loadError(res.message || "Không tải được năm học"))
    }
}
function* deleteWorker(action: IPLNamHocDeleteStart): any {
    const res: IBaseResponeModel = yield call([plNamHocApi, plNamHocApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.danhMucWrapper.namHoc.deleteSuccess())
        yield put(actions.danhMucWrapper.namHoc.loadStart())
    } else {
        yield put(actions.danhMucWrapper.namHoc.deleteError(res.message || "Không xóa được năm học"))
    }
}
function* saveWorker(action: IPLNamHocSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([plNamHocApi, plNamHocApi.Update], action.payload);
    } else {
        res = yield call([plNamHocApi, plNamHocApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.danhMucWrapper.namHoc.saveSuccess())
        yield put(actions.danhMucWrapper.namHoc.loadStart())
    } else {
        yield put(actions.danhMucWrapper.namHoc.saveError(res.message || "Không lưu được năm học"))
    }
}
import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eAppNhomBaiVietActionTypeIds, IAppNhomBaiVietActionTypes } from "../../action-types/bai-viet/IAppNhomBaiVietActionTypes"
import { IAppNhomBaiVietPageState } from "../../page-state-model/bai-viet/IAppNhomBaiVietState"

const initialState: IAppNhomBaiVietPageState = {
    status: ePageBaseStatus.is_not_initialization,
    app_nhombaiviets: [],
    app_nhombaiviet_selected_ids: [],
    app_nhombaiviet_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const appNhomBaiVietReducer = (state: IAppNhomBaiVietPageState = initialState, action: IAppNhomBaiVietActionTypes): IAppNhomBaiVietPageState => {
    switch (action.type) {
        case eAppNhomBaiVietActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppNhomBaiVietActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_nhombaiviets: action.payload
            }
        case eAppNhomBaiVietActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_nhombaiviets: []
            }
        case eAppNhomBaiVietActionTypeIds.LOAD_BY_TRUONG_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppNhomBaiVietActionTypeIds.LOAD_BY_TRUONG_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_nhombaiviets: action.payload
            }
        case eAppNhomBaiVietActionTypeIds.LOAD_BY_TRUONG_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_nhombaiviets: []
            }
        case eAppNhomBaiVietActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                app_nhombaiviet_editing: action.payload
            }
        case eAppNhomBaiVietActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                app_nhombaiviet_editing: undefined
            }
        case eAppNhomBaiVietActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                app_nhombaiviet_selected_ids: action.payload
            }
        case eAppNhomBaiVietActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eAppNhomBaiVietActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eAppNhomBaiVietActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eAppNhomBaiVietActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eAppNhomBaiVietActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eAppNhomBaiVietActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eAppNhomBaiVietActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eAppNhomBaiVietActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        default:
            return state;
    }

}
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { pl_phonghoc, pl_phonghoc_view } from "../../../models/response/phong-hoc/pl_phonghoc"


export enum ePhongHocActionTypeIds {
    LOAD_START = "PHONG_HOC_LOAD_START",
    LOAD_SUCCESS = "PHONG_HOC_LOAD_SUCCESS",
    LOAD_ERROR = "PHONG_HOC_LOAD_ERROR",

    SHOW_DETAIL = "PHONG_HOC_SHOW_DETAIL",
    CLOSE_DETAIL = "PHONG_HOC_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "PHONG_HOC_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "PHONG_HOC_SHOW_CONFIRM",
    CLOSE_CONFIRM = "PHONG_HOC_CLOSE_CONFIRM",

    DELETE_START = "PHONG_HOC_DELETE_START",
    DELETE_SUCCESS = "PHONG_HOC_DELETE_SUCCESS",
    DELETE_ERROR = "PHONG_HOC_DELETE_ERROR",

    SAVE_START = "PHONG_HOC_SAVE_START",
    SAVE_SUCCESS = "PHONG_HOC_SAVE_SUCCESS",
    SAVE_ERR = "PHONG_HOC_SAVE_ERR"
}

export interface IPhongHocLoadStart {
    type: ePhongHocActionTypeIds.LOAD_START,
}
export interface IPhongHocLoadSuccess {
    type: ePhongHocActionTypeIds.LOAD_SUCCESS,
    payload: pl_phonghoc_view[]
}
export interface IPhongHocLoadErr {
    type: ePhongHocActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IPhongHocShowDetail {
    type: ePhongHocActionTypeIds.SHOW_DETAIL,
    payload?: pl_phonghoc_view
}
export interface IPhongHocCloseDetail {
    type: ePhongHocActionTypeIds.CLOSE_DETAIL,
}
export interface IPhongHocChangeSelectedIds {
    type: ePhongHocActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IPhongHocShowConfirm {
    type: ePhongHocActionTypeIds.SHOW_CONFIRM
}
export interface IPhongHocCloseConfirm {
    type: ePhongHocActionTypeIds.CLOSE_CONFIRM
}

export interface IPhongHocDeleteStart {
    type: ePhongHocActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IPhongHocDeleteSuccess {
    type: ePhongHocActionTypeIds.DELETE_SUCCESS,
}
export interface IPhongHocDeleteErr {
    type: ePhongHocActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IPhongHocSaveStart {
    type: ePhongHocActionTypeIds.SAVE_START,
    payload: pl_phonghoc
}
export interface IPhongHocSaveSuccess {
    type: ePhongHocActionTypeIds.SAVE_SUCCESS
}
export interface IPhongHocSaveErr {
    type: ePhongHocActionTypeIds.SAVE_ERR,
    payload: string
}
export type IPhongHocActionTypes = IPhongHocLoadStart | IPhongHocLoadSuccess | IPhongHocLoadErr |
    IPhongHocShowDetail | IPhongHocCloseDetail |
    IPhongHocChangeSelectedIds |
    IPhongHocShowConfirm | IPhongHocCloseConfirm |
    IPhongHocDeleteStart | IPhongHocDeleteSuccess | IPhongHocDeleteErr |
    IPhongHocSaveStart | IPhongHocSaveSuccess | IPhongHocSaveErr
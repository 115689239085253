import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";

import { actions } from "../../actions/actionsWrapper";
import { IDotDangKyNhomMonHocDeleteStart, IDotDangKyNhomMonHocLoadStart, IDotDangKyNhomMonHocSaveStart, eDotDangKyNhomMonHocActionTypeIds } from "../../action-types/dangky-monhoc/IDotDangKyNhomMonHocActionTypes";

const nhomMonHocApi = apiWrapper.dangKyMonHoc.dotDangKyNhomMonHoc;

export function* dotDangKyNhomMonHocSaga(): any {
    yield takeLatest(eDotDangKyNhomMonHocActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eDotDangKyNhomMonHocActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eDotDangKyNhomMonHocActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(action: IDotDangKyNhomMonHocLoadStart): any {

    const res: IBaseResponeModel = yield call([nhomMonHocApi, nhomMonHocApi.SelectByDotDangKyAsync], action.payload)
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKyNhomMonHoc.loadSuccess(res.data))
    } else {
        yield put(actions.dangKyMonHoc.dotDangKyNhomMonHoc.loadError(res.message || "Không tải được nhóm môn học"))
    }
}
function* deleteWorker(action: IDotDangKyNhomMonHocDeleteStart): any {
    const res: IBaseResponeModel = yield call([nhomMonHocApi, nhomMonHocApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKyNhomMonHoc.deleteSuccess())
    } else {
        yield put(actions.dangKyMonHoc.dotDangKyNhomMonHoc.deleteError(res.message || "Không xóa được thông tư"))
    }
}
function* saveWorker(action: IDotDangKyNhomMonHocSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([nhomMonHocApi, nhomMonHocApi.Update], action.payload);
    } else {
        res = yield call([nhomMonHocApi, nhomMonHocApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKyNhomMonHoc.saveSuccess())
    } else {
        yield put(actions.dangKyMonHoc.dotDangKyNhomMonHoc.saveError(res.message || "Không lưu được thông tư"))
    }
}
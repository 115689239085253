import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eHocKyActionTypeIds, IHocKyDeleteStart, IHocKyLoadStart, IHocKySaveStart } from "../../action-types/danh-muc/IHocKyActionTypes";
import { actions } from "../../actions/actionsWrapper";

const hocKyApi = apiWrapper.hocKy;

export function* hocKySaga(): any {
    yield takeLatest(eHocKyActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eHocKyActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eHocKyActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(action: IHocKyLoadStart): any {

    const res: IBaseResponeModel = yield call([hocKyApi, hocKyApi.Select], action.payload)
    if (res.is_success) {
        yield put(actions.danhMucWrapper.hocKy.loadSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.hocKy.loadError(res.message || "Không tải được học kỳ"))
    }
}
function* deleteWorker(action: IHocKyDeleteStart): any {
    const res: IBaseResponeModel = yield call([hocKyApi, hocKyApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.danhMucWrapper.hocKy.deleteSuccess())
    } else {
        yield put(actions.danhMucWrapper.hocKy.deleteError(res.message || "Không xóa được học kỳ"))
    }
}
function* saveWorker(action: IHocKySaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([hocKyApi, hocKyApi.Update], action.payload);
    } else {
        res = yield call([hocKyApi, hocKyApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.danhMucWrapper.hocKy.saveSuccess())
    } else {
        yield put(actions.danhMucWrapper.hocKy.saveError(res.message || "Không lưu được học kỳ"))
    }
}
import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { ePhongHocActionTypeIds, IPhongHocActionTypes } from "../../action-types/phong-hoc/IPhongHocActionTypes"
import { IPhongHocPageStateModel } from "../../page-state-model/phong-hoc/IPhongHocPageState"

const initialState: IPhongHocPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    pl_phonghocs: [],
    is_show_delete_confirm: false,
    pl_phonghoc_selected_ids: [],
    pl_phonghoc_editing: undefined,
    is_show_detail_modal: false
}
export const phongHocReducer = (state: IPhongHocPageStateModel = initialState, action: IPhongHocActionTypes): IPhongHocPageStateModel => {
    switch (action.type) {
        case ePhongHocActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case ePhongHocActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_phonghocs: action.payload
            }
        case ePhongHocActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_phonghocs: []
            }
        case ePhongHocActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                pl_phonghoc_editing: action.payload
            }
        case ePhongHocActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                pl_phonghoc_editing: undefined
            }
        case ePhongHocActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                pl_phonghoc_selected_ids: action.payload
            }
        case ePhongHocActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case ePhongHocActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        
            case ePhongHocActionTypeIds.DELETE_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_deleting
                }
            case ePhongHocActionTypeIds.DELETE_SUCCESS:
                NotifyHelper.Success("Xoá dữ liệu thành công.")
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }
            case ePhongHocActionTypeIds.DELETE_ERROR:
                NotifyHelper.Error(action.payload)
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }

        
            case ePhongHocActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case ePhongHocActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }
        case ePhongHocActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }

        default:
            return state;
    }

}

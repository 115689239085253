import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../../models/response/base-response";
import { eDgtxKetQuaMonHocActionTypeIds, IDgtxHocTapImportStart, IDgtxHocTapValidateStart, IDgtxKetQuaMonHocDmLoadStart, IDgtxKetQuaMonHocHocSinhLoadStart, IDgtxKetQuaMonHocLoadKetQuaStart, IDgtxKetQuaMonHocSaveKetQuaStart } from "../../../action-types/danh-gia/thuong-xuyen/IDgtxKetQuaHocTapActionType";
import { actions } from '../../../actions/actionsWrapper';

const lopBoMonHocSinhApi = apiWrapper.lopBoMonHocSinh;
const dgdkDmPhamChatApi = apiWrapper.soNhanXetItem;
const dgtxKetQuaHocTapApi = apiWrapper.danhGia.thuongXuyen.hocTap;

export function* dgtxKetQuaMonHocSaga(): any {
    yield takeLatest(eDgtxKetQuaMonHocActionTypeIds.HOCSINH_LOAD_START, loadHocSinhWorker)
    yield takeLatest(eDgtxKetQuaMonHocActionTypeIds.SONHANXET_ITEM_LOAD_START, loadDmNLPCWorker)
    yield takeLatest(eDgtxKetQuaMonHocActionTypeIds.KETQUA_LOAD_START, loadKetQuaWorker)
    yield takeEvery(eDgtxKetQuaMonHocActionTypeIds.KETQUA_SAVE_START, saveKetQuaWorker)
    yield takeEvery(eDgtxKetQuaMonHocActionTypeIds.VALIDATE_IMPORT_START, validateImportWorker)
    yield takeEvery(eDgtxKetQuaMonHocActionTypeIds.IMPORT_START, importWorker)
}

function* loadHocSinhWorker(action: IDgtxKetQuaMonHocHocSinhLoadStart): any {
    const res: IBaseResponeModel = yield call([lopBoMonHocSinhApi, lopBoMonHocSinhApi.Select], action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.hocTap.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.danhGia.thuongXuyen.hocTap.loadHocSinhError(res.message ?? ""))
    }
}

function* loadDmNLPCWorker(action: IDgtxKetQuaMonHocDmLoadStart): any {
    const res: IBaseResponeModel = yield call([dgdkDmPhamChatApi, dgdkDmPhamChatApi.SelectBySet], action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.hocTap.loadSoNhanXetItemsSuccess(res.data))
    } else {
        yield put(actions.danhGia.thuongXuyen.hocTap.loadSoNhanXetItemsError(res.message ?? ""))
    }
}

function* loadKetQuaWorker(action: IDgtxKetQuaMonHocLoadKetQuaStart): any {
    const res: IBaseResponeModel = yield call(dgtxKetQuaHocTapApi.Select, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.hocTap.loadKetQuaSuccess(res.data))
    } else {
        yield put(actions.danhGia.thuongXuyen.hocTap.loadKetQuaError(res.message ?? ""))
    }
}

function* saveKetQuaWorker(action: IDgtxKetQuaMonHocSaveKetQuaStart): any {
    const res: IBaseResponeModel = yield call(dgtxKetQuaHocTapApi.Save, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.hocTap.saveKetQuaSuccess(res.data))
    } else {
        yield put(actions.danhGia.thuongXuyen.hocTap.saveKetQuaError(res.message ?? ""))
    }
}

function* validateImportWorker(action: IDgtxHocTapValidateStart): any {
    const res: IBaseResponeModel = yield call(dgtxKetQuaHocTapApi.ValidateImport, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.hocTap.validateImportSuccess(res.data))
    } else {
        yield put(actions.danhGia.thuongXuyen.hocTap.validateImportError(res.message ?? ""))
    }
}

function* importWorker(action: IDgtxHocTapImportStart): any {
    const res: IBaseResponeModel = yield call(dgtxKetQuaHocTapApi.Import, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.thuongXuyen.hocTap.importSuccess())
    } else {
        yield put(actions.danhGia.thuongXuyen.hocTap.importError(res.message ?? ""))
    }
}

import { apiWrapper } from './../../../api/apiWrapper';
import { actions } from './../../actions/actionsWrapper';
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { dotDangKyApi } from './../../../api/dotDangKyApi';
import { IDotDangKyDeleteStart, IDotDangKyLoadStart, IDotDangKySaveStart, eDotDangKyActionTypeIds } from '../../action-types/dangky-monhoc/IDotDangKyActionTypes';


export function* dotDangKySaga(): any {
    yield takeLatest(eDotDangKyActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eDotDangKyActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eDotDangKyActionTypeIds.SAVE_START, saveWorker)

}

function* loadWorker(action: IDotDangKyLoadStart): any {
    const res: IBaseResponeModel = yield call([dotDangKyApi, dotDangKyApi.SelectByTruongAsync], action.payload)
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKy.loadSuccess(res.data))
    } else {
        yield put(actions.dangKyMonHoc.dotDangKy.loadError(res.message || "Không tải được đợt đăng ký"))
    }
}

function* deleteWorker(action: IDotDangKyDeleteStart): any {
    const res: IBaseResponeModel = yield call([dotDangKyApi, dotDangKyApi.Delete], action.payload)
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKy.deleteSuccess())
    } else {
        yield put(actions.dangKyMonHoc.dotDangKy.deleteError(res.message || "Không xóa được đợt đăng ký"))
    }
}

function* saveWorker(action: IDotDangKySaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([dotDangKyApi, dotDangKyApi.Update], action.payload)
    } else {
        res = yield call([dotDangKyApi, dotDangKyApi.Insert], action.payload)
    }
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKy.saveSuccess())
    } else {
        yield put(actions.dangKyMonHoc.dotDangKy.saveError(res.message || "Cập nhật thất bại"))
    }
}
import { sis_meal } from '../models/response/meal/sis_meal';
import { apiClient } from './apiClient';
import { BaseCRUDApi } from './baseCRUDApi';


export const API_MON_HOC_PATH = "meal"

class MealApi extends BaseCRUDApi<sis_meal>{
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(path: string) {
        super(path);
    }
    SelectByTruong(truongId: number) {
        return apiClient.get(`${API_MON_HOC_PATH}/truong/${truongId}`);
    }

}
export const mealApi = new MealApi(API_MON_HOC_PATH)

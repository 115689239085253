import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { IHocSinhHoTroLoadByTruongStart, IHocSinhHoTroSaveStart, eHocSinhHoTroActionTypeIds } from "../../action-types/hoc-sinh/IHocSinhHoTroActionTypes"
import { apiWrapper } from "../../../api/apiWrapper"
import { IBaseResponeModel } from "../../../models/response/base-response"
import { actions } from "../../actions/actionsWrapper"

export function* hocSinhHoTroSaga(): any {
    yield takeLatest(eHocSinhHoTroActionTypeIds.LOAD_BY_TRUONG_START, loadByTruongWorker)
    yield takeEvery(eHocSinhHoTroActionTypeIds.SAVE_START, saveWorker)
}
function* loadByTruongWorker(data: IHocSinhHoTroLoadByTruongStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.hocSinhHoTro, apiWrapper.hocSinhHoTro.SelectByLopAsync], data.payload)
    if (res.is_success) {
        yield put(actions.hocSinh.hocSinhHoTro.loadByTruongSuccess(res.data))
    } else {
        yield put(actions.hocSinh.hocSinhHoTro.loadByTruongError(res.message || "Không tải được học sinh"))
    }
}

function* saveWorker(action: IHocSinhHoTroSaveStart): any {
    let res: IBaseResponeModel = yield call([apiWrapper.hocSinhHoTro, apiWrapper.hocSinhHoTro.SaveHoTroAsync], action.payload);
    if (res.is_success) {
        yield put(actions.hocSinh.hocSinhHoTro.saveSuccess())
    } else {
        yield put(actions.hocSinh.hocSinhHoTro.saveError(res.message || "Không lưu được học kỳ"))
    }
}
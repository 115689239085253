import { IUpdateDgdkNlpcRequest } from './../../../../models/request/danh-gia/dinh-ky/IUpdateDgdkNlpcRequest';
import { IDgdkKetQuaNlpc } from './../../../../models/response/danh-gia/dinh-ky/IDgdkKetQuaNlpc';
import { ISelectDgdkNlpcRequest } from './../../../../models/request/danh-gia/dinh-ky/ISelectDgdkNlpcRequest';
import { IHocSinhSelectRequest } from './../../../../models/request/hoc-sinh/IHocSinhSelectRequest';
import { NotifyHelper } from './../../../../helpers/toast';
import { IHocSinhSelectResponeItem } from './../../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { eDgdkNlpcActionTypeIds } from './../../../action-types/danh-gia/dinh-ky/IDgdkNlpcActionType';
import { INamHocHocKyTruongKhoiLopGiaiDoanFilter } from './../../../../models/INamHocTruongKhoiLopGiaiDoanFilter';
import { baseAction } from "../../IActionBase";
import { ISoNhanXetItem } from '../../../../models/response/dgdk-dm/ISoNhanXetItem';
import { ImportDgdkNlpcRequest } from '../../../../models/request/danh-gia/dinh-ky/IImportDgdkNlpcRequest';
import { ICheckLockDgdkNlpcRequest } from '../../../../models/request/danh-gia/dinh-ky/ICheckLockDgdkNlpcRequest';
import { IDgdkLockDetailSelectByLopBoMonRequest, IDgdkLockDetailSelectRequest } from '../../../../models/request/danh-gia/dinh-ky/IDgdkLockSelectRequest';
import { sis_dgdk_lock } from '../../../../models/response/danh-gia/dinh-ky/sis_dgdk_lock';

export const dgdkNlpcActions = {
    changeFilter: (filter: INamHocHocKyTruongKhoiLopGiaiDoanFilter) => baseAction(eDgdkNlpcActionTypeIds.CHANGE_FILTER, filter),
    loadHocSinhStart: (rq: IHocSinhSelectRequest) => baseAction(eDgdkNlpcActionTypeIds.HOCSINH_LOAD_START, rq),
    loadHocSinhSuccess: (hocSinh: IHocSinhSelectResponeItem[]) => baseAction(eDgdkNlpcActionTypeIds.HOCSINH_LOAD_SUCCESS, hocSinh),
    loadHocSinhError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkNlpcActionTypeIds.HOCSINH_LOAD_ERROR, message)
    },
    loadDgdkDmNlpcStart: (setId: number) => baseAction(eDgdkNlpcActionTypeIds.DM_NLPC_LOAD_START, setId),
    loadDgdkDmNlpcSuccess: (data: ISoNhanXetItem[]) => baseAction(eDgdkNlpcActionTypeIds.DM_NLPC_LOAD_SUCCESS, data),
    loadDgdkDmNlpcError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkNlpcActionTypeIds.DM_NLPC_LOAD_ERROR, message)
    },
    loadKetQuaStart: (request: ISelectDgdkNlpcRequest) => baseAction(eDgdkNlpcActionTypeIds.KETQUA_LOAD_START, request),
    loadKetQuaSuccess: (data: IDgdkKetQuaNlpc[]) => baseAction(eDgdkNlpcActionTypeIds.KETQUA_LOAD_SUCCESS, data),
    loadKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkNlpcActionTypeIds.KETQUA_LOAD_ERROR, message)
    },
    saveKetQuaStart: (request: IUpdateDgdkNlpcRequest) => baseAction(eDgdkNlpcActionTypeIds.KETQUA_SAVE_START, request),
    saveKetQuaSuccess: (data: IDgdkKetQuaNlpc) => baseAction(eDgdkNlpcActionTypeIds.KETQUA_SAVE_SUCCESS, data),
    saveKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkNlpcActionTypeIds.KETQUA_SAVE_ERROR, message)
    },

    validateImportStart: (request: ImportDgdkNlpcRequest) => baseAction(eDgdkNlpcActionTypeIds.VALIDATE_IMPORT_START, request),
    validateImportSuccess: (data: any) => baseAction(eDgdkNlpcActionTypeIds.VALIDATE_IMPORT_SUCCESS, data),
    validateImportError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkNlpcActionTypeIds.VALIDATE_IMPORT_ERRROR, message)
    },
    importStart: (request: ImportDgdkNlpcRequest) => baseAction(eDgdkNlpcActionTypeIds.IMPORT_START, request),
    importSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eDgdkNlpcActionTypeIds.IMPORT_SUCCESS, undefined)
    },
    importError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgdkNlpcActionTypeIds.IMPORT_ERRROR, message)
    },
    lockCheckStart: (request: IDgdkLockDetailSelectRequest) => baseAction(eDgdkNlpcActionTypeIds.LOCK_CHECK_START, request),
    lockCheckSuccess: (data: sis_dgdk_lock) => {
        return baseAction(eDgdkNlpcActionTypeIds.LOCK_CHECK_SUCCESS, data)
    },
    lockCheckError: (message: string) => {
        return baseAction(eDgdkNlpcActionTypeIds.LOCK_CHECK_ERRROR, message)
    },
    locklopbomonCheckStart: (request: IDgdkLockDetailSelectByLopBoMonRequest) => baseAction(eDgdkNlpcActionTypeIds.LOCKLOPBOMON_CHECK_START, request),
    locklopbomonCheckSuccess: (data: sis_dgdk_lock) => {
        return baseAction(eDgdkNlpcActionTypeIds.LOCKLOPBOMON_CHECK_SUCCESS, data)
    },
    locklopbomonCheckError: (message: string) => {
        return baseAction(eDgdkNlpcActionTypeIds.LOCKLOPBOMON_CHECK_ERRROR, message)
    },
}
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eBaiTapKetQuaActionTypeIds, IBaiTapKetQuaActionTypes } from '../../action-types/bai-tap/IBaiTapKetQuaActionType';
import { IBaiTapKetQuaPageState } from './../../page-state-model/bai-tap/IBaiTapKetQuaPageState';
const iniState: IBaiTapKetQuaPageState = {
    status: ePageBaseStatus.is_not_initialization,
    baiTapKetQuas: [],
    isShowPublishMarkModal: false,
    isShowSendNotifyModal: false,
    baiTapEditing: undefined
}
export const baiTapKetQuaReducer = (state: IBaiTapKetQuaPageState = iniState, action: IBaiTapKetQuaActionTypes): IBaiTapKetQuaPageState => {
    switch (action.type) {

        case eBaiTapKetQuaActionTypeIds.LOAD_KETQUA_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiTapKetQuaActionTypeIds.LOAD_KETQUA_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                baiTapKetQuas: action.payload
            }
        case eBaiTapKetQuaActionTypeIds.LOAD_KETQUA_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eBaiTapKetQuaActionTypeIds.SET_BAITAP:
            return {
                ...state,
                baiTapEditing: action.payload
            }
        case eBaiTapKetQuaActionTypeIds.SAVE_KETQUA_SUCCESS:
            return {
                ...state,
                baiTapKetQuas: state.baiTapKetQuas.map(x => {
                    if (x.id == action.payload.id) return {
                        ...x,
                        diem_so: action.payload.diem_so,
                        diem_chu: action.payload.diem_chu,
                        ghi_chu: action.payload.ghi_chu
                    };
                    return x;
                })
            }

        case eBaiTapKetQuaActionTypeIds.SHOW_PUBLISH_MARK_MODAL:
            return {
                ...state,
                isShowPublishMarkModal: action.payload
            }
        case eBaiTapKetQuaActionTypeIds.PUBLISH_MARK_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eBaiTapKetQuaActionTypeIds.PUBLISH_MARK_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowPublishMarkModal: false
            }
        case eBaiTapKetQuaActionTypeIds.PUBLISH_MARK_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eBaiTapKetQuaActionTypeIds.SHOW_NOTIFY_MODAL:
            return {
                ...state,
                isShowSendNotifyModal: action.payload
            }
        case eBaiTapKetQuaActionTypeIds.SEND_NOTIFY_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eBaiTapKetQuaActionTypeIds.SEND_NOTIFY_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowSendNotifyModal: false
            }
        case eBaiTapKetQuaActionTypeIds.SEND_NOTIFY_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        default:
            return { ...state }
    }
}
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { actions } from '../../actions/actionsWrapper';
import { apiWrapper } from '../../../api/apiWrapper';
import { eLopHanhChinhSoDiemActionTypeIds, ILopHanhChinhSoDiemLoadStart, ILopHanhChinhUpdateDgdkNlpcStart } from "../../action-types/lop-hanh-chinh/ILopHanhChinhSoDiemActionTypes";

export function* lopHanhChinhSoDiemSaga(): any {
    yield takeLatest(eLopHanhChinhSoDiemActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGDK_NLPC_START, saveDgdkNlpcSetWorker)
    yield takeEvery(eLopHanhChinhSoDiemActionTypeIds.UPDATE_DGTX_NLPC_START, saveDgtxNlpcSetWorker)

}

function* loadWorker(data: ILopHanhChinhSoDiemLoadStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.Select], {
        ...data.payload,
        dm_khoi_id: 0,
        dm_he_id: 0
    })
    if (res.is_success) {
        yield put(actions.lopHanhChinhSoDiem.loadSuccess(res.data))
    } else {
        yield put(actions.lopHanhChinhSoDiem.loadError(res.message ?? ""))
    }
}


function* saveDgdkNlpcSetWorker(data: ILopHanhChinhUpdateDgdkNlpcStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.UpdateDgdkHocTap], data.payload)
    if (res.is_success) {
        yield put(actions.lopHanhChinhSoDiem.updateDgdkNlpcSuccess())
    } else {
        yield put(actions.lopHanhChinhSoDiem.updateDgdkNlpcError(res.message ?? ""))
    }
}


function* saveDgtxNlpcSetWorker(data: ILopHanhChinhUpdateDgdkNlpcStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.UpdateDgtxHocTap], data.payload)
    if (res.is_success) {
        yield put(actions.lopHanhChinhSoDiem.updateDgtxNlpcSuccess())
    } else {
        yield put(actions.lopHanhChinhSoDiem.updateDgtxNlpcError(res.message ?? ""))
    }
}
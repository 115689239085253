import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFormKhaoSatDeleteStart, IFormKhaoSatLoadByTruongStart, IFormKhaoSatSaveStart, eFormKhaoSatActionTypeIds } from "../../action-types/form-khaosat/IFormKhaoSatActionTypes";

import { IBaseResponeModel } from "../../../models/response/base-response";
import { actions } from "../../actions/actionsWrapper";
import { apiWrapper } from "../../../api/apiWrapper";

const formKhaoSatApi = apiWrapper.formKhaoSatWrapper.formKhaoSat;

export function* formKhaoSatSaga(): any {
    yield takeLatest(eFormKhaoSatActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eFormKhaoSatActionTypeIds.LOAD_BYTRUONG_START, loadByTruongWorker)
    yield takeEvery(eFormKhaoSatActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eFormKhaoSatActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([formKhaoSatApi, formKhaoSatApi.SelectAll])
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSat.loadSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSat.loadError(res.message || "No data"))
    }
}
function* loadByTruongWorker(action: IFormKhaoSatLoadByTruongStart): any {
    const res: IBaseResponeModel = yield call([formKhaoSatApi, formKhaoSatApi.SelectByTruongAsync], action.payload.dm_truong_id, action.payload.nam_hoc)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSat.loadByTruongSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSat.loadByTruongError(res.message || "No data"))
    }
}
function* deleteWorker(action: IFormKhaoSatDeleteStart): any {
    const res: IBaseResponeModel = yield call([formKhaoSatApi, formKhaoSatApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSat.deleteSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSat.deleteErr(res.message || "No data"))
    }
}
function* saveWorker(action: IFormKhaoSatSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([formKhaoSatApi, formKhaoSatApi.Update], action.payload);
    } else {
        res = yield call([formKhaoSatApi, formKhaoSatApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.formKhaoSat.saveSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.formKhaoSat.saveError(res.message || "Save fail"))
    }
}


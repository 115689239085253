import { IDictionaryModel } from "../../models/response/dictionary";
import { eDictionaryActionTypeIds, IDictionaryActionTypes } from "../action-types/IDictionaryActionTypes";
interface DictionaryState {
    lan: string
    dictionary: IDictionaryModel[],
    translate: (vi: string) => string
}
const initialState: DictionaryState = {
    lan: "vi",
    dictionary: [],
    translate: (vi: string) => {
        return vi;

    }
}
export const dictionaryReducer = (state: DictionaryState = initialState, action: IDictionaryActionTypes): DictionaryState => {
    switch (action.type) {
        case eDictionaryActionTypeIds.LOAD_DICTIONARY_SUCCESS:
            return {
                ...state,
                dictionary: action.payload
            }
        default:
            return state;
    }

}

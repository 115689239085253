import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_meal } from "../../../models/response/meal/sis_meal";
import { eMealActionTypeIds, IMealChangeSelectedIds, IMealCloseConfirm, IMealCloseDetail, IMealDeleteErr, IMealDeleteStart, IMealDeleteSuccess, IMealLoadErr, IMealLoadStart, IMealLoadSuccess, IMealShowConfirm, IMealShowDetail } from "../../action-types/IMealActionTypes";

export const mealActions = {
    loadMealStart: (truongId: number): IMealLoadStart => {
        return {
            type: eMealActionTypeIds.LOAD_START,
            payload: truongId
        }
    },

    loadMealSuccess: (data: sis_meal[]): IMealLoadSuccess => {
        return {
            type: eMealActionTypeIds.LOAD_SUCCESS,
            payload: data
        }
    },

    loadMealError: (messege: string): IMealLoadErr => {
        return {
            type: eMealActionTypeIds.LOAD_ERROR,
            payload: messege
        }
    },
    showMealDetail: (data?: sis_meal): IMealShowDetail => {
        return {
            type: eMealActionTypeIds.SHOW_DETAIL,
            payload: data
        }
    },
    closeMealDetail: (): IMealCloseDetail => {
        return {
            type: eMealActionTypeIds.CLOSE_DETAIL,

        }
    },
    showMealConfirm: (): IMealShowConfirm => {
        return {
            type: eMealActionTypeIds.SHOW_CONFIRM,
        }
    },
    closeMealConfirm: (): IMealCloseConfirm => {
        return {
            type: eMealActionTypeIds.CLOSE_CONFIRM,
        }
    },
    changeMealSelectedIds: (ids: number[]): IMealChangeSelectedIds => {
        return {
            type: eMealActionTypeIds.CHANGE_SELECTED_IDS,
            payload: ids
        }
    },
    deleteMealStart: (ids: IDeleteMultipleBaseRequest): IMealDeleteStart => {
        return {
            type: eMealActionTypeIds.DELETE_START,
            payload: ids
        }
    },
    deleteMealSuccess: (): IMealDeleteSuccess => {
        return {
            type: eMealActionTypeIds.DELETE_SUCCESS,
        }
    },
    deleteMealErr: (message: string): IMealDeleteErr => {
        return {
            type: eMealActionTypeIds.DELETE_ERROR,
            payload: message
        }
    },
    saveMealStart: (data: sis_meal) => {
        console.log(data);
        return {
            type: eMealActionTypeIds.SAVE_START,
            payload: data
        }
    },
    saveMealSuccess: () => {
        return {
            type: eMealActionTypeIds.SAVE_SUCCESS,
        }
    },
    saveMealError: (message: string) => {
        return {
            type: eMealActionTypeIds.SAVE_ERR,
            payload: message
        }
    }
}



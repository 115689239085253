import { IDeleteMultipleBaseRequest } from "../../models/request/base/deleteRequest"
import { sis_dm_nhanxet } from "../../models/response/giao-vien-nhan-xet/sis_dm_nhanxet"

export enum eGiaoVienNhanXetActionTypeIds {
    LOAD_START = "GIAOVIEN_NHANXET_LOAD_START",
    LOAD_SUCCESS = "GIAOVIEN_NHANXET_LOAD_SUCCESS",
    LOAD_ERROR = "GIAOVIEN_NHANXET_LOAD_ERROR",

    SHOW_DETAIL = "GIAOVIEN_NHANXET_SHOW_DETAIL",
    CLOSE_DETAIL = "GIAOVIEN_NHANXET_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "GIAOVIEN_NHANXET_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "GIAOVIEN_NHANXET_SHOW_CONFIRM",
    CLOSE_CONFIRM = "GIAOVIEN_NHANXET_CLOSE_CONFIRM",

    DELETE_START = "GIAOVIEN_NHANXET_DELETE_START",
    DELETE_SUCCESS = "GIAOVIEN_NHANXET_DELETE_SUCCESS",
    DELETE_ERROR = "GIAOVIEN_NHANXET_DELETE_ERROR",

    SAVE_START = "GIAOVIEN_NHANXET_SAVE_START",
    SAVE_SUCCESS = "GIAOVIEN_NHANXET_SAVE_SUCCESS",
    SAVE_ERR = "GIAOVIEN_NHANXET_SAVE_ERR"
}

//ORIGIN MODEL
export interface IGiaoVienNhanXetLoadStart {
    type: eGiaoVienNhanXetActionTypeIds.LOAD_START,
}
export interface IGiaoVienNhanXetLoadSuccess {
    type: eGiaoVienNhanXetActionTypeIds.LOAD_SUCCESS,
    payload: sis_dm_nhanxet[]
}
export interface IGiaoVienNhanXetLoadErr {
    type: eGiaoVienNhanXetActionTypeIds.LOAD_ERROR,
    payload: string
}
//DETAIL
export interface IGiaoVienNhanXetShowDetail {
    type: eGiaoVienNhanXetActionTypeIds.SHOW_DETAIL,
    payload?: sis_dm_nhanxet
}
export interface IGiaoVienNhanXetCloseDetail {
    type: eGiaoVienNhanXetActionTypeIds.CLOSE_DETAIL,
}
export interface IGiaoVienNhanXetChangeSelectedIds {
    type: eGiaoVienNhanXetActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
//CONFIRM
export interface IGiaoVienNhanXetShowConfirm {
    type: eGiaoVienNhanXetActionTypeIds.SHOW_CONFIRM
}
export interface IGiaoVienNhanXetCloseConfirm {
    type: eGiaoVienNhanXetActionTypeIds.CLOSE_CONFIRM
}
//DELETE
export interface IGiaoVienNhanXetDeleteStart {
    type: eGiaoVienNhanXetActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IGiaoVienNhanXetDeleteSuccess {
    type: eGiaoVienNhanXetActionTypeIds.DELETE_SUCCESS,
}
export interface IGiaoVienNhanXetDeleteErr {
    type: eGiaoVienNhanXetActionTypeIds.DELETE_ERROR,
    payload: string
}
//SAVE
export interface IGiaoVienNhanXetSaveStart {
    type: eGiaoVienNhanXetActionTypeIds.SAVE_START,
    payload: sis_dm_nhanxet
}
export interface IGiaoVienNhanXetSaveSuccess {
    type: eGiaoVienNhanXetActionTypeIds.SAVE_SUCCESS
}
export interface IGiaoVienNhanXetSaveErr {
    type: eGiaoVienNhanXetActionTypeIds.SAVE_ERR,
    payload: string
}

export type IGiaoVienNhanXetActionTypes = 
    IGiaoVienNhanXetLoadStart | IGiaoVienNhanXetLoadSuccess | IGiaoVienNhanXetLoadErr |
    IGiaoVienNhanXetShowDetail | IGiaoVienNhanXetCloseDetail |
    IGiaoVienNhanXetChangeSelectedIds |
    IGiaoVienNhanXetShowConfirm | IGiaoVienNhanXetCloseConfirm |
    IGiaoVienNhanXetDeleteStart | IGiaoVienNhanXetDeleteSuccess | IGiaoVienNhanXetDeleteErr |
    IGiaoVienNhanXetSaveStart | IGiaoVienNhanXetSaveSuccess | IGiaoVienNhanXetSaveErr
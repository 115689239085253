import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { actions } from '../../actions/actionsWrapper';
import { apiWrapper } from './../../../api/apiWrapper';
import { eLopBoMonHocSinhActionTypeIds, ILopBoMonAddHocSinhStart, ILopBoMonChangeHocSinhThuTuStart, ILopBoMonHocSinhDeleteStart, 
    ILopBoMonHocSinhLoadStart, ILopBoMonSaveExcelStartAction, ILopBoMonUploadExcelStartAction, ILopBoMonChangeThuTuMacDinhStart, ILopBoMonChangeHocSinhStart
 } from './../../action-types/lop-bo-mon/ILopBoMonHocSinhActionTypes';

export function* lopBoMonHocSinhSaga(): any {
    yield takeLatest(eLopBoMonHocSinhActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eLopBoMonHocSinhActionTypeIds.DELETE_START, deleteWorker)
    yield takeLatest(eLopBoMonHocSinhActionTypeIds.ADD_HOCSINH_START, addHocSinhWorker)
    yield takeLatest(eLopBoMonHocSinhActionTypeIds.UPLOAD_EXCEL_START, loadFromExcelWorker)
    yield takeLatest(eLopBoMonHocSinhActionTypeIds.SAVE_EXCEL_START, saveFromExcelWorker)
    yield takeEvery(eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_THUTU_START, changeHocSinhThuTuWorker)
    yield takeEvery(eLopBoMonHocSinhActionTypeIds.CHANGE_THUTU_MACDINH_START, changeThuTuMacDinhWorker)
    yield takeEvery(eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_START, changeHocSinhWorker)
}

function* addHocSinhWorker(data: ILopBoMonAddHocSinhStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMonHocSinh, apiWrapper.lopBoMonHocSinh.AddHocSinh], data.payload)

    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.addHocSinhSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.addHocSinhError(res.message || "Có lỗi khi thêm dữ liệu"))
    }
}
function* deleteWorker(data: ILopBoMonHocSinhDeleteStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMonHocSinh, apiWrapper.lopBoMonHocSinh.DeleteHocSinh], data.payload)

    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.deleteHocSinhSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.deleteHocSinhError(res.message || "Có lỗi khi xóa dữ liệu"))
    }
}

function* loadWorker(data: ILopBoMonHocSinhLoadStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMonHocSinh, apiWrapper.lopBoMonHocSinh.Select], data.payload)

    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.loadHocSinhError(res.message || "Không tải được danh sách"))
    }
}

function* loadFromExcelWorker(data: ILopBoMonUploadExcelStartAction): any {
    try {
        const res: IBaseResponeModel = yield call([apiWrapper.lopBoMonHocSinh, apiWrapper.lopBoMonHocSinh.LoadFromExcel], data.payload);
        if (res.is_success) {
            yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.loadFromExcelSuccess(res.data))
        } else {
            yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.loadFromExcelError(res.message || "Không upload được file excel"))
        }
    } catch (error) {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.loadFromExcelError("Upload file excel có lỗi"))
    }
}


function* saveFromExcelWorker(data: ILopBoMonSaveExcelStartAction): any {
    try {
        const res: IBaseResponeModel = yield call([apiWrapper.lopBoMonHocSinh, apiWrapper.lopBoMonHocSinh.SaveDataExcel], data.payload);
        if (res.is_success) {
            yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.saveFromExcelSuccess(res.data))
        } else {
            yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.saveFromExcelError(res.message || "Không lưu được dữ liệu excel"))
        }
    } catch (error) {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.saveFromExcelError("Lưu được dữ liệu excel có lỗi"))
    }
}
function* changeHocSinhThuTuWorker(data: ILopBoMonChangeHocSinhThuTuStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMonHocSinh, apiWrapper.lopBoMonHocSinh.ChangeThuTuHocSinhAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.changeLopBoMonHocSinhThuTuSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.changeLopBoMonHocSinhThuTuError(res.message || "Không lưu được thay đổi"))
    }
}

function* changeThuTuMacDinhWorker(data: ILopBoMonChangeThuTuMacDinhStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMonHocSinh, apiWrapper.lopBoMonHocSinh.ChangeThuTuTheoHoTenABCAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.changeLopBoMonThuTuMacDinhSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.changeLopBoMonThuTuMacDinhError(res.message || "Không lưu được thay đổi"))
    }
}

function* changeHocSinhWorker(data: ILopBoMonChangeHocSinhStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMonHocSinh, apiWrapper.lopBoMonHocSinh.ChangeHocSinhClassAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.changeHocSinhSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonHocSinh.changeHocSinhError(res.message || "Không lưu được thay đổi"))
    }
}

import React, { useEffect, useMemo, useState } from 'react';
import { lopApi } from '../../api/lopApi';
import { useCommonContext } from '../../contexts/common';
import { dm_lop_viewmodel } from '../../models/response/dm-lop/dm_lop';
import SelectBox from 'devextreme-react/select-box';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/reducers';
type ComboboxLopTheoKhoiProps = {
	isReadonly?: boolean;
	value?: number;
	onValueChanged: (id: number, data?: dm_lop_viewmodel) => void;
	className?: string;
	isShowClearButton?: boolean;
	preText?: string;
	width?: string | number;
	dm_khoi_id?: number;
	dm_truong_id?: number;
	dm_he_id?: number;
	nam_hoc?: string;
	stylingMode?: 'outlined' | 'filled' | 'underlined';
};
const ComboboxLopTheoKhoi = (props: ComboboxLopTheoKhoiProps) => {
	const { translate } = useCommonContext();
	const { language } = useSelector((x: RootState) => x.common);
	const [lops, setLops] = useState<dm_lop_viewmodel[]>([]);
	const handleReloadLops = async () => {
		const res = await lopApi.Select({
			nam_hoc: props.nam_hoc || '',
			dm_he_id: props.dm_he_id || 0,
			dm_khoi_id: props.dm_khoi_id || 0,
			dm_truong_id: props.dm_truong_id || 0,
		});
		if (res.is_success) {
			setLops(res.data);
		}
	};

	useEffect(() => {
		handleReloadLops();
	}, [props.nam_hoc, props.dm_truong_id, props.dm_khoi_id, props.dm_khoi_id, props.dm_he_id]);

	const source: dm_lop_viewmodel[] = useMemo(() => {
		return lops && props.nam_hoc && props.dm_truong_id
			? lops.filter((x) => x.dm_khoi_id == props.dm_khoi_id && x.nam_hoc == props.nam_hoc && x.dm_truong_id == (props.dm_truong_id ?? 0)).sort((a, b) => (a.ten_lop > b.ten_lop) ? 1 : -1)
			: [];
	}, [lops, props.dm_truong_id, props.nam_hoc, props.dm_khoi_id]);

	return (
		<SelectBox
			dataSource={source}
			displayExpr={'ten_lop'}
			valueExpr={'id'}
			value={props.value}
			stylingMode={props.stylingMode || 'outlined'}
			readOnly={props.isReadonly === true}
			placeholder={translate('Base.Label.ChooseClass')}
			width={props.width ? props.width : '100%'}
			className={'default_selectbox ' + (props.className ? props.className : '')}
			showClearButton={props.isShowClearButton === true}
			onValueChanged={(e) => {
				if (e.event)
					props.onValueChanged(
						e.value,
						lops.find((x) => x.id == e.value)
					);
			}}
		/>
	);
};

export default ComboboxLopTheoKhoi;

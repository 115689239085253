import { ILopBoMonSelectRequest } from "../../../models/request/lop-bo-mon/ILopBoMonSelectRequest";
import { ILopBoMonUpdateSoDiemRequest } from '../../../models/request/lop-bo-mon/ILopBoMonUpdateSoDiemRequest';
import { IActionTypeBase } from "../IActionTypeBase";
import { pl_lopbomon } from './../../../models/response/lop-bo-mon/pl_lopbomon';

export enum eLopBoMonSoDiemActionTypeIds {
    LOAD_START = "LOPBOMON_SODIEM_LOAD_START",
    LOAD_SUCCESS = "LOPBOMON_SODIEM_LOAD_SUCCESS",
    LOAD_ERROR = "LOPBOMON_SODIEM_LOAD_ERROR",

    CHANGE_SELECTED_IDS = "CHANGE_SELECTED_IDS",

    SHOW_SODIEM_SELECTION_MODAL = "SHOW_SODIEM_SELECTION_MODAL",

    UPDATE_SODIEM_START = "LOPBOMON_UPDATE_SODIEM_START",
    UPDATE_SODIEM_SUCCESS = "LOPBOMON_UPDATE_SODIEM_SUCCESS",
    UPDATE_SODIEM_ERROR = "LOPBOMON_UPDATE_SODIEM_ERROR",

    SHOW_DGDK_HOCTAP_SELECTION_MODAL = "SHOW_DGDK_HOCTAP_SELECTION_MODAL",

    UPDATE_DGDK_HOCTAP_START = "LOPBOMON_UPDATE_DGDK_HOCTAP_START",
    UPDATE_DGDK_HOCTAP_SUCCESS = "LOPBOMON_UPDATE_DGDK_HOCTAP_SUCCESS",
    UPDATE_DGDK_HOCTAP_ERROR = "LOPBOMON_UPDATE_DGDK_HOCTAP_ERROR",

    SHOW_DGTX_HOCTAP_SELECTION_MODAL = "SHOW_DGTX_HOCTAP_SELECTION_MODAL",

    UPDATE_DGTX_HOCTAP_START = "LOPBOMON_UPDATE_DGTX_HOCTAP_START",
    UPDATE_DGTX_HOCTAP_SUCCESS = "LOPBOMON_UPDATE_DGTX_HOCTAP_SUCCESS",
    UPDATE_DGTX_HOCTAP_ERROR = "LOPBOMON_UPDATE_DGTX_HOCTAP_ERROR",
}

export interface ILopBoMonSoDiemLoadStart extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.LOAD_START, ILopBoMonSelectRequest> { }
export interface ILopBoMonSoDiemLoadSuccess extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.LOAD_SUCCESS, pl_lopbomon[]> { }
export interface ILopBoMonSoDiemLoadError extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.LOAD_ERROR, string> { }

export interface ILopBoMonSoDiemChangeSelectedIds extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }

export interface ILopBoMonSoDiemShowSelectionModal extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.SHOW_SODIEM_SELECTION_MODAL, boolean> { }
export interface ILopBoMonUpdateSoDiemStart extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.UPDATE_SODIEM_START, ILopBoMonUpdateSoDiemRequest> { }
export interface ILopBoMonUpdateSoDiemSuccess extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.UPDATE_SODIEM_SUCCESS, undefined> { }
export interface ILopBoMonUpdateSoDiemError extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.UPDATE_SODIEM_ERROR, string> { }

export interface ILopBoMonSoDiemShowSoNhanXetDinhKySelectionModal extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.SHOW_DGDK_HOCTAP_SELECTION_MODAL, boolean> { }
export interface ILopBoMonUpdateSoNhanXetDinhKyStart extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.UPDATE_DGDK_HOCTAP_START, ILopBoMonUpdateSoDiemRequest> { }
export interface ILopBoMonUpdateSoNhanXetDinhKySuccess extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.UPDATE_DGDK_HOCTAP_SUCCESS, undefined> { }
export interface ILopBoMonUpdateSoNhanXetDinhKyError extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.UPDATE_DGDK_HOCTAP_ERROR, string> { }

export interface ILopBoMonSoDiemShowSoNhanXetThuongXuyenSelectionModal extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.SHOW_DGTX_HOCTAP_SELECTION_MODAL, boolean> { }
export interface ILopBoMonUpdateSoNhanXetThuongXuyenStart extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.UPDATE_DGTX_HOCTAP_START, ILopBoMonUpdateSoDiemRequest> { }
export interface ILopBoMonUpdateSoNhanXetThuongXuyenSuccess extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.UPDATE_DGTX_HOCTAP_SUCCESS, undefined> { }
export interface ILopBoMonUpdateSoNhanXetThuongXuyenError extends IActionTypeBase<eLopBoMonSoDiemActionTypeIds.UPDATE_DGTX_HOCTAP_ERROR, string> { }





export type ILopBoMonSoDiemActionTypes = ILopBoMonSoDiemLoadStart | ILopBoMonSoDiemLoadSuccess | ILopBoMonSoDiemLoadError |
    ILopBoMonUpdateSoDiemStart | ILopBoMonUpdateSoDiemSuccess | ILopBoMonUpdateSoDiemError |
    ILopBoMonSoDiemChangeSelectedIds | ILopBoMonSoDiemShowSelectionModal |
    ILopBoMonSoDiemShowSoNhanXetDinhKySelectionModal |
    ILopBoMonUpdateSoNhanXetDinhKyStart | ILopBoMonUpdateSoNhanXetDinhKySuccess | ILopBoMonUpdateSoNhanXetDinhKyError |
    ILopBoMonSoDiemShowSoNhanXetThuongXuyenSelectionModal |
    ILopBoMonUpdateSoNhanXetThuongXuyenStart | ILopBoMonUpdateSoNhanXetThuongXuyenSuccess | ILopBoMonUpdateSoNhanXetThuongXuyenError



import React from 'react';
import styles from './spinner.module.css';
import clsx from 'clsx'
type SpinnerProps = {
    isBlack?: boolean,
    width?: number,
    top?: number
}
const Spinner = (props: SpinnerProps) => {
    const blades = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const _width = props.width || "1rem";
    const _top = props.top || 2;
    return (
        <div style={{ width: _width, top: _top, height: _width }}
            className={styles.spinner}
        >
            {blades.map((x, idx) => <div key={idx.toString()} className={clsx(styles.spinner_blade, props.isBlack && styles.black)} > </div>)}
        </div>
    );
}
export { Spinner };

import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eDiemVanMinhTieuChiActionTypeIds, IDiemVanMinhTieuChiActionTypes } from "../../action-types/diemvanminh-tieuchi/IDiemVanMinhTieuChiActionTypes"
import { IDiemVanMinhTieuChiPageState } from "../../page-state-model/diemvanminh-tieuchi/IDiemVanMinhTieuChiState"

const initialState: IDiemVanMinhTieuChiPageState = {
    status: ePageBaseStatus.is_not_initialization,
    diemvanminh_tieuchis: [],
    selected_ids: [],
    diemvanminh_tieuchi_editing: undefined,
    filter: {
        dm_truong_id: 0,
        nam_hoc: ""
    },
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const diemVanMinhTieuChiReducer = (state: IDiemVanMinhTieuChiPageState = initialState, action: IDiemVanMinhTieuChiActionTypes): IDiemVanMinhTieuChiPageState => {
    switch (action.type) {
        case eDiemVanMinhTieuChiActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDiemVanMinhTieuChiActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                diemvanminh_tieuchis: action.payload
            }
        case eDiemVanMinhTieuChiActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                diemvanminh_tieuchis: []
            }
        case eDiemVanMinhTieuChiActionTypeIds.SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                diemvanminh_tieuchi_editing: action.payload
            }
        case eDiemVanMinhTieuChiActionTypeIds.CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                diemvanminh_tieuchi_editing: undefined
            }
        case eDiemVanMinhTieuChiActionTypeIds.CHANGE_SELECTED:
            return {
                ...state,
                selected_ids: action.payload
            }
        case eDiemVanMinhTieuChiActionTypeIds.SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eDiemVanMinhTieuChiActionTypeIds.CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eDiemVanMinhTieuChiActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eDiemVanMinhTieuChiActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        case eDiemVanMinhTieuChiActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eDiemVanMinhTieuChiActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDiemVanMinhTieuChiActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eDiemVanMinhTieuChiActionTypeIds.SAVE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }
        case eDiemVanMinhTieuChiActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }

        default:
            return state;
    }

}
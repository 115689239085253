import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eLopBoMonTypeActionTypeIds, ILopBoMonTypeDeleteStart, ILopBoMonTypeLoadByTruongStart, ILopBoMonTypeSaveStart } from "../../action-types/lop-bo-mon/ILopBoMonTypeActionTypes";
import { actions } from "../../actions/actionsWrapper";

const lopBoMonTypeApi = apiWrapper.lopBoMonType;

export function* lopBoMonTypeSaga(): any {
    yield takeLatest(eLopBoMonTypeActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eLopBoMonTypeActionTypeIds.LOAD_BY_TRUONG_START, loadByTruongWorker)
    yield takeEvery(eLopBoMonTypeActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eLopBoMonTypeActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([lopBoMonTypeApi, lopBoMonTypeApi.SelectAll])
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonType.loadSuccess(res.data))
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonType.loadError(res.message || "Không tải được nhóm bài viết"))
    }
}
function* loadByTruongWorker(action: ILopBoMonTypeLoadByTruongStart): any {

    const res: IBaseResponeModel = yield call([lopBoMonTypeApi, lopBoMonTypeApi.SelectByTruongAsync], action.payload)
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonType.loadByTruongSuccess(res.data))
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonType.loadByTruongError(res.message || "Không tải được danh sách loại lớp"))
    }
}
function* deleteWorker(action: ILopBoMonTypeDeleteStart): any {
    const res: IBaseResponeModel = yield call([lopBoMonTypeApi, lopBoMonTypeApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonType.deleteSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonType.deleteError(res.message || "Không xóa được loại lớp"))
    }
}
function* saveWorker(action: ILopBoMonTypeSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([lopBoMonTypeApi, lopBoMonTypeApi.Update], action.payload);
    } else {
        res = yield call([lopBoMonTypeApi, lopBoMonTypeApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMonType.saveSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMonType.saveError(res.message || "Không lưu được loại lớp"))
    }
}
import { ILopChuNhiemSelectRequest } from "../../../models/request/lop-chu-nhiem/ILopChuNhiemSelectRequest";
import { LopChuNhiemItemResponse } from "../../../models/response/lop-chu-nhiem/LopChuNhiemItemResponse";
import { eLopChuNhiemActionTypeIds, ILopChuNhiemClosePhanCongModal, ILopChuNhiemSelectError, ILopChuNhiemSelectStart, ILopChuNhiemSelectSuccess, ILopChuNhiemShowPhanCongModal } from "../../action-types/lop-chu-nhiem/ILopChuNhiemActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const lopChuNhiemActions = {
    selectStart: (request: ILopChuNhiemSelectRequest): ILopChuNhiemSelectStart => BaseAction(request, eLopChuNhiemActionTypeIds.SELECT_START),
    selectSuccess: (respone: LopChuNhiemItemResponse[]): ILopChuNhiemSelectSuccess => BaseAction(respone, eLopChuNhiemActionTypeIds.SELECT_SUCCESS),
    selectError: (messege: string): ILopChuNhiemSelectError => BaseAction(messege, eLopChuNhiemActionTypeIds.SELECT_ERROR),

    showPhanCong: (): ILopChuNhiemShowPhanCongModal => BaseAction(undefined, eLopChuNhiemActionTypeIds.SHOW_PHANCONG_MODAL),
    closePhanCong: (): ILopChuNhiemClosePhanCongModal => BaseAction(undefined, eLopChuNhiemActionTypeIds.CLOSE_PHANCONG_MODAL),
}
import { ISdtAccountPhuHuynh, IEmailAccountGiaoVien, ISdtAccountPhuHuynhDetail, IEmailAccountGiaoVienDetail } from "../../models/response/account/process-account";
export enum eProcessAccountActionTypeIds {
    PROCESS_ACCOUNT_PHU_HUYNH_START = "PROCESS_ACCOUNT_PHU_HUYNH_START",
    PROCESS_ACCOUNT_PHU_HUYNH_ERROR = "PROCESS_ACCOUNT_PHU_HUYNH_ERROR",
    PROCESS_ACCOUNT_PHU_HUYNH_SUCCESS = "PROCESS_ACCOUNT_PHU_HUYNH_SUCCESS",
    PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_START = "PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_START",
    PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_ERROR = "PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_ERROR",
    PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_SUCCESS = "PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_SUCCESS",
    PROCESS_ACCOUNT_GIAO_VIEN_START = "PROCESS_ACCOUNT_GIAO_VIEN_START",
    PROCESS_ACCOUNT_GIAO_VIEN_ERROR = "PROCESS_ACCOUNT_GIAO_VIEN_ERROR",
    PROCESS_ACCOUNT_GIAO_VIEN_SUCCESS = "PROCESS_ACCOUNT_GIAO_VIEN_SUCCESS",
    PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_START = "PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_START",
    PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_ERROR = "PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_ERROR",
    PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_SUCCESS = "PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_SUCCESS",
    PROCESS_ACCOUNT_GIAO_VIEN_SHOW_DETAIL = "PROCESS_ACCOUNT_GIAO_VIEN_SHOW_DETAIL",
    PROCESS_ACCOUNT_GIAO_VIEN_CLOSE_DETAIL = "PROCESS_ACCOUNT_GIAO_VIEN_CLOSE_DETAIL",
    PROCESS_ACCOUNT_PHU_HUYNH_SHOW_DETAIL = "PROCESS_ACCOUNT_PHU_HUYNH_SHOW_DETAIL",
    PROCESS_ACCOUNT_PHU_HUYNH_CLOSE_DETAIL = "PROCESS_ACCOUNT_PHU_HUYNH_CLOSE_DETAIL"
}
export interface IProcessAccountPhuHuynhStart {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_START,
    payload: string
}
export interface IProcessAccountPhuHuynhError {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_ERROR,
    payload: string
}
export interface IProcessAccountPhuHuynhSuccess {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_SUCCESS,
    payload: ISdtAccountPhuHuynh[]
}

export interface IProcessAccountPhuHuynhDetailStart {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_START,
    payload: string
}
export interface IProcessAccountPhuHuynhDetailError {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_ERROR,
    payload: string
}
export interface IProcessAccountPhuHuynhDetailSuccess {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_SUCCESS,
    payload: ISdtAccountPhuHuynhDetail[]
}

export interface IProcessAccountGiaoVienStart {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_START,
    payload: string
}
export interface IProcessAccountGiaoVienError {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_ERROR,
    payload: string
}
export interface IProcessAccountGiaoVienSuccess {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_SUCCESS,
    payload: IEmailAccountGiaoVien[]
}

export interface IProcessAccountGiaoVienDetailStart {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_START,
    payload: string
}
export interface IProcessAccountGiaoVienDetailError {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_ERROR,
    payload: string
}
export interface IProcessAccountGiaoVienDetailSuccess {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_SUCCESS,
    payload: IEmailAccountGiaoVienDetail[]
}

export interface IProcessAccountGiaoVienShowDetail {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_SHOW_DETAIL,
    payload?: IEmailAccountGiaoVien
}
export interface IProcessAccountGiaoVienCloseDetail {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_CLOSE_DETAIL,
}

export interface IProcessAccountPhuHuynhShowDetail {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_SHOW_DETAIL,
    payload?: ISdtAccountPhuHuynh
}
export interface IProcessAccountPhuHuynhCloseDetail {
    type: eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_CLOSE_DETAIL,
}

export type IProcessAccountActionTypes = IProcessAccountPhuHuynhStart | IProcessAccountPhuHuynhError | IProcessAccountPhuHuynhSuccess |
IProcessAccountPhuHuynhDetailStart | IProcessAccountPhuHuynhDetailError | IProcessAccountPhuHuynhDetailSuccess |
IProcessAccountGiaoVienStart | IProcessAccountGiaoVienError | IProcessAccountGiaoVienSuccess |
IProcessAccountGiaoVienDetailStart | IProcessAccountGiaoVienDetailError | IProcessAccountGiaoVienDetailSuccess |
IProcessAccountGiaoVienShowDetail | IProcessAccountGiaoVienCloseDetail | IProcessAccountPhuHuynhShowDetail | IProcessAccountPhuHuynhCloseDetail
import { IAppMenuTruongAddMenuRequest } from "../../models/request/app-phuhuynh/AppMenuTruongAddMenuRequest";
import { IDeleteMultipleBaseRequest } from "../../models/request/base/deleteRequest";
import { apiClient } from "../apiClient";

export const API_APP_MENU_TRUONG_PATH = "app-menu-truong"
export const appMenuTruongApi = {
    getAllAsync: (dm_truong_id: number) =>  {
        return apiClient.get(`${API_APP_MENU_TRUONG_PATH}?dm_truong_id=${dm_truong_id}`)
    },
    addAppMenuAsync: (request: IAppMenuTruongAddMenuRequest) =>  {
        return apiClient.post(`${API_APP_MENU_TRUONG_PATH}`, request)
    },
    deleteAppMenuAsync: (request: IDeleteMultipleBaseRequest) =>  {
        return apiClient.post(`${API_APP_MENU_TRUONG_PATH}/deletes`, request)
    }
};